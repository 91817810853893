import React from "react";
import { Modal, Row, Col, Button, Form, InputGroup, ListGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { modalSale, checkLogin, checkAddIdol, addidolId, checkToken} from "../redux/actions";
import Rating from 'react-rating'
import Select from 'react-select'
import api from '../utils/apis';
import helper from "../utils/helper"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SelectForm from "./SelectForm";
const API = api.getAPI();
const MySwal = withReactContent(Swal)

class ModalManage extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            selectButton: false,
            remark: '',
            idIdol: 0,
            allProduct: [],
            idolProduct: [],
            productSelected: [],
        };
    }

    async componentDidMount() {
        await this.setProduct()
        // await this.props.checkAddIdol();

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.data != prevProps.data) {
            // await this.props.checkAddIdol();
            await this.setProduct()
        }
        if (this.props.show != prevProps.show) {
            await this.setProduct()
        }
    }

    setProduct = async () => {
        const { producstList, data } = this.props;
        let allProduct = [];
        let tempIdolProduct = [];

        await producstList.map((item) => {
            // if (item.criteria !== null && item.criteria) {
            if (item.product_code != "ALL_PRODUCT" && item.product_code != "NO_PRODUCT" && item.product_code != "PHOTO_NO_PRODUCT") {
                allProduct.push({ label: item.product_name, value: item.product_code, criteria: item.criteria });
            }
        });

        if (data) {

            tempIdolProduct = await allProduct.filter(item => {
                if (data.products_info.some(i => i.product_code.includes(item.value))) {
                    return item;
                }
            });
        }
        if (tempIdolProduct.length > 0) {
            this.setState({ allProduct: allProduct, idolProduct: [...tempIdolProduct], productSelected: [...tempIdolProduct] });
        } else {
            this.setState({ allProduct: allProduct, idolProduct: [...tempIdolProduct], productSelected: [] });
        }
    }

    showTextCondition = () => {
        const { productSelected } = this.state;
        return productSelected.map((item, index) => {
            if (item.criteria) {
                return (
                    <Col className="mb-3" key={index}>
                        <h6 className="text-bold">เงื่อนไข {item.label}</h6>
                        {this.splitCriteria(item.criteria)}
                    </Col>
                )
            }
        })
    }

    splitCriteria = (str) => {
        const res = str.split("||");
        return res.map((rItem, rIndex) => {
            return (
                <div className="ml-3" key={rIndex}>
                    <label className="mb-1">{(rIndex + 1)}. {rItem}</label>
                </div >
            )
        });
    }

    onHide = (value) => {
        this.setState({ selectButton: false, remark: '', productSelected: [], idolProduct: [] })
        this.props.onHide(value);
    };

    addProduct = async (type) => {
        const { remark, productSelected } = this.state;
        const { data: { id: idolId }, idolList, history } = this.props;
        // let idolId = this.props.idol.id;
        // let id = this.props.addidolId.id;
        let response;
        const data = {
            "product_codes": productSelected.map(item => {
                return item.value
            }),
            "remark": `${remark}`,
        }
        // console.log('datadddddddddddddd', data);
        // return;
        try {
            response = await API.addProduct(idolId, data)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        if (response && response.status != 500) {
            if (response.status == 200) {
                this.onHide();
                this.props.updateIdolList();
            } else {
                MySwal.fire({
                    // customClass: 'swal-wide',
                    icon: "error",
                    title: "Error",
                    text: response.data.message
                })
                console.log(response.data.message)
            }
        }
    }

    render() {
        const { show, data, type } = this.props;
        const { idolProduct, productSelected } = this.state;
        // console.log('this', this.props);

        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="md"
                // id="login-form"
                // aria-labelledby="contained-modal-title-vcenter-l"
                centered
            >
                <div className="bg-modal">
                    <Row className="position m-3">
                        <h5 className="text-bold">Manage idol's product</h5>
                        <Col md={12} className="p-0 mt-3">
                            <div className="txt-style">Do you want to assign <b className="font-blue">{data.ig_username ? data.ig_username : 'idol'}</b> to:</div>
                            {/* {
                                type === 'single' ?
                                    <>
                                        <div className="txt-style">Do you want to change <b className="font-blue">{data.sales_name ? data.sales_name : 'sales'}</b> to:</div>
                                    </>
                                    :
                                    <>
                                        <div className="txt-style">Do you want to change <b className="font-blue">sales</b> to:</div>
                                    </>
                            } */}
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <Form.Group className="mx-3">
                        <Form.Label className="color-gray font-12">Product</Form.Label>
                        <SelectForm options={this.state.allProduct}
                            placeholder="Product" value={productSelected}
                            getData={async (data) => {
                                if (helper.arrayIsEqual(data, idolProduct)) {
                                    await this.setState({ productSelected: data, selectButton: false })
                                } else {
                                    await this.setState({ productSelected: data, selectButton: true })
                                }

                            }}
                        />
                    </Form.Group>

                    <Col className="overFlow">
                        {this.showTextCondition()}
                    </Col>

                    <Form.Group className="m-3">
                        <Form.Label className="color-gray font-12">Enter remark</Form.Label>
                        <Form.Control as="textarea" rows={3}
                            value={this.state.remark}
                            onChange={e => {
                                let remarks = e.target.value;
                                this.setState({ remark: remarks, selectButton: true })
                            }}
                        />
                    </Form.Group>

                    <Row className="m-0">
                        <Col className="pb-3 text-end">
                            <Button className="btn-cancel" onClick={() => this.onHide('cancelMulti')}>
                                CANCEL
                                    </Button>
                            {this.state.selectButton ?
                                <>
                                    {type === 'single' ?
                                        <Button className="btn-addmore active mx-2" onClick={() => this.addProduct(type)} >
                                            SAVE CHANGES
                                                </Button>
                                        :
                                        <Button className="btn btn-dark active mx-2" onClick={() => this.addProduct(type)} >
                                            SAVE CHANGES
                                                </Button>
                                    }
                                </>
                                :
                                <>
                                    <Button className="btn-addmore mx-2 cursor-auto ">
                                        SAVE CHANGES
                                    </Button>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {
    return { login: state.login, addidolId: state.addidolId };
};

function mapDispatchToProps(dispatch) {
    return {
        checkToken: value => dispatch(checkToken(value)),
        // openModal: (value) => dispatch(modalSale(value)),
        // checkLogin: value => dispatch(checkLogin(value)),
        // checkAddIdol: value => dispatch(checkAddIdol(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalManage));
