import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Table } from "antd";
import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import XLSX from "xlsx";
import api from "../utils/apis";
import helper from "../utils/helper";
import { loading, checkLogin, checkToken } from "../redux/actions";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class ReportExtractDataBySale extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportData: null,
      pagination: {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 30,
        totalItems: 0,
        totalPages: 1,
      },
    };
  }

  async componentDidMount() {
    await this.getReport();
  }

  getReport = async () => {
    this.props.loading(true);
    let response;
    const { pagination } = this.state;
    const { history } = this.props;

    try {
      response = await API.getExtractDataBySalesReport(
        pagination.currentPage,
        pagination.itemsPerPage
      );
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    // console.log("get creator report", response.data);
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 200) {
        const { pagination } = this.state;
        pagination.totalItems = response.data.totalItems;
        pagination.totalPages = response.data.totalPages;

        const colTotal = {
          colName: "รวม (คน)",
          waiting_data_count_total: 0,
          extracting_data_count_total: 0,
          completed_data_count_total: 0,
          grand_total: 0,
          salesId: 0,
        };
        response.data.rows.map((item, index) => {
          item.rowTotal =
            item.waiting_data_count +
            item.extracting_data_count +
            item.completed_data_count;
          colTotal.waiting_data_count_total += item.waiting_data_count;
          colTotal.extracting_data_count_total += item.extracting_data_count;
          colTotal.completed_data_count_total += item.completed_data_count;
          // colTotal.grand_total += item.rowTotal;
          if (index + 1 == response.data.rows.length) {
            colTotal.grand_total +=
              colTotal.waiting_data_count_total +
              colTotal.extracting_data_count_total +
              colTotal.completed_data_count_total;
            response.data.rows.push(colTotal);
          }
          return item;
        });

        await this.setState({
          reportData: response.data.rows,
          pagination,
        });
      } else {
        console.log(response.data.message);
      }
    }
  };

  toExport = async () => {
    this.props.loading(true);
    let response;
    const { pagination } = this.state;
    const { history } = this.props;

    try {
      response = await API.getExtractDataBySalesReport(
        1,
        pagination.totalItems
      );
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    // console.log("get report export", response.data);
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.mapExportData(response.data);
      } else {
        console.log(response.data.message);
      }
    }
  };

  mapExportData = async (data) => {
    const { rows } = await data;
    let dataArray = [];
    let totalRow = [];
    let headColumn = [
      "ชื่อ",
      "รอแกะข้อมูล (คน)",
      "กำลังแกะข้อมูล (คน)",
      "รอ assign product (คน)",
      "รวม (คน)",
    ];

    dataArray.push(headColumn);

    const colTotal = {
      colName: "รวม (คน)",
      waiting_data_count_total: 0,
      extracting_data_count_total: 0,
      completed_data_count_total: 0,
      grand_total: 0,
    };
    rows.map((item, index) => {
      item.rowTotal =
        item.waiting_data_count +
        item.extracting_data_count +
        item.completed_data_count;
      colTotal.waiting_data_count_total += item.waiting_data_count;
      colTotal.extracting_data_count_total += item.extracting_data_count;
      colTotal.completed_data_count_total += item.completed_data_count;
      // colTotal.grand_total += item.rowTotal;
      dataArray.push([
        item.sales_name,
        item.waiting_data_count,
        item.extracting_data_count,
        item.completed_data_count,
        item.rowTotal,
      ]);
    });
    colTotal.grand_total +=
      colTotal.waiting_data_count_total +
      colTotal.extracting_data_count_total +
      colTotal.completed_data_count_total;
    dataArray.push([
      "รวม (คน)",
      colTotal.waiting_data_count_total,
      colTotal.extracting_data_count_total,
      colTotal.completed_data_count_total,
      colTotal.grand_total,
    ]);

    if (dataArray.length > 0) {
      this.toExcel(dataArray);
    }
  };

  toExcel = (data, rSpan, gSpan) => {
    const dt = new Date();
    const filename = `sales_report_${dt.getDate()}_${
      Number(dt.getMonth()) + 1
    }_${dt.getFullYear()}`;
    let wb = XLSX.utils.book_new();

    let ws = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  renderContent = () => {
    const { reportData, pagination } = this.state;
    let columns = [];
    // console.log("reportData", reportData);

    let scrolling = { x: 600 };
    if (reportData && reportData.length >= 10) {
      scrolling = { x: 600, y: 350 };
    }

    if (reportData) {
      columns = [
        {
          title: "ชื่อ",
          render: (value, row, i) => {
            if (i + 1 == reportData.length) {
              return <div className="font-weight-bold ml-3">{row.colName}</div>;
            } else {
              return (
                <div className="d-flex align-items-center px-3">
                  <img className="img-acc" src={row.image_url} />
                  <div className="font-weight-bold ml-3">{row.sales_name}</div>
                </div>
              );
            }
          },
          align: "center",
          className: "table-col",
          showSorterTooltip: false,
        },
        {
          title: "รอแกะข้อมูล (คน)",
          render: (value, row, i) => {
            if (i + 1 == reportData.length) {
              return <div>{row.waiting_data_count_total}</div>;
            } else {
              return <div>{row.waiting_data_count}</div>;
            }
          },
          align: "center",
          className: "table-col",
          showSorterTooltip: false,
        },
        {
          title: "กำลังแกะข้อมูล (คน)",
          render: (value, row, i) => {
            if (i + 1 == reportData.length) {
              return <div>{row.extracting_data_count_total}</div>;
            } else {
              return <div>{row.extracting_data_count}</div>;
            }
          },
          align: "center",
          className: "table-col",
          showSorterTooltip: false,
        },
        {
          title: "รอ assign product (คน)",
          render: (value, row, i) => {
            if (i + 1 == reportData.length) {
              return <div>{row.completed_data_count_total}</div>;
            } else {
              return <div>{row.completed_data_count}</div>;
            }
          },
          align: "center",
          className: "table-col",
          showSorterTooltip: false,
        },
        {
          title: "รวม (คน)",
          render: (value, row, i) => {
            if (i + 1 == reportData.length) {
              return <div>{row.grand_total}</div>;
            } else {
              return <div>{row.rowTotal}</div>;
            }
          },
          align: "center",
          className: "table-col",
          showSorterTooltip: false,
        },
      ];
    }

    return (
      <section className="pt-3 w-100 bg-gray">
        <Row className="">
          <Col className="d-flex align-items-center">
            <h6 className="mb-0">
              <b>สถานะการแกะข้อมูล</b>
            </h6>
          </Col>
          <Col className="d-flex justify-content-end-table">
            <Button
              className="btn btn-success"
              onClick={() => this.toExport()}
            >
              Export to Excel
            </Button>
          </Col>
        </Row>
        <Row className="bg-gray">
          <Col className="my-2">
            {reportData && (
              <Table
                columns={columns}
                dataSource={reportData}
                size="small"
                className={`table-width`}
                scroll={scrolling}
                pagination={{
                  className: "my-3",
                  position: ["bottomCenter"],
                  size: "default",
                  showSizeChanger: false,
                  current: pagination.currentPage,
                  total: pagination.totalItems,
                  pageSize: pagination.itemsPerPage,
                  hideOnSinglePage: false,
                }}
                rowKey={(record) => record.salesId}
                responsive
                onChange={async (paginate, filters, sorter) => {
                  // console.log("paginate", paginate,  "sorter", sorter);
                  pagination.currentPage = await paginate.current;

                  await this.getReport();
                }}
              />
            )}
          </Col>
        </Row>
      </section>
    );
  };

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
};

// CreatorReport.propTypes = {
//     rolesOptions: PropTypes.array,
//     role: PropTypes.string
// };

// CreatorReport.defaultProps = {
//     rolesOptions: [],
//     role: '',
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportExtractDataBySale));
