import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Table } from "antd";
import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import XLSX from "xlsx";
import api from "../utils/apis";
import helper from "../utils/helper";
import { loading, checkLogin, checkToken } from "../redux/actions";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class RejectReasonReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportData: null,
      pagination: {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 30,
        totalItems: 0,
        totalPages: 1,
      },
    };
  }

  async componentDidMount() {
    await this.getReport();
  }

  getReport = async () => {
    this.props.loading(true);
    let response;
    const { pagination } = this.state;
    const { history } = this.props;
    try {
      response = await API.getRejectReasonReport(
        pagination.currentPage,
        pagination.itemsPerPage
      );
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    // console.log("get reject reason report", response.data);
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 200) {
        const { pagination } = this.state;
        pagination.totalItems = response.data.totalItems? response.data.totalItems:0;
        pagination.totalPages = response.data.totalPages? response.data.totalPages:1;

        const colTotal = {
          colName: "รวม (คน)",
          rowTotal: [],
          total: 0
        };

        const dmaping = JSON.parse(JSON.stringify(response.data));

        dmaping.header.map((hItem, hIndex) => {
          colTotal.rowTotal.push(0);
        });

        dmaping.rows.map((rItem, rIndex) => {
          rItem.id = rIndex;
          rItem.rowTotal = 0;
          rItem.count.forEach((cItem, cIndex) => {
            rItem.colName = rItem.acceptanceName;
            rItem.rowTotal += cItem;
          })
          dmaping.header.map((hItem, hIndex) => {
            colTotal.rowTotal[hIndex] +=  rItem.count[hIndex];
          });
          if (rIndex + 1 == dmaping.rows.length) {
            colTotal.rowTotal.map((tItem, tIndex) => {
              colTotal.total += tItem;
              colTotal.id = rIndex+1;
            });
            dmaping.rows.push(colTotal);
          }
          return rItem;
        });

        dmaping.header.splice(0, 0, {name:"เหตุผล"});
        dmaping.header.splice(dmaping.header.length, 0, {name:"รวม"});

        await this.setState({
          reportData: dmaping,
          pagination,
        });

      } else {
        console.log(response.data.message);
      }
    }

  };

  mapExportData = async (data) => {
    const { header, rows } = await data;
    let dataArray = [];
    let totalRow = [];
    let headColumn = ["เหตุผล"];    

    header.map((item, index) => {
      headColumn.push(item.name);
      totalRow.push(0);
      if (index + 1 == header.length) {
        headColumn.push("รวม (คน)");
      }
    });

    dataArray.push(headColumn);

    rows.map((rItem, rIndex) => {
      let mRow = [];
      mRow.push(rItem.reject_reason);
      rItem.count.map((cItem, cIndex) => {
        mRow.push(cItem);
        totalRow[cIndex] += cItem;
        if (cIndex + 1 == rItem.count.length) {
          mRow.push(rItem.total);
        }
        
      });
      if (rIndex + 1 == rows.length) {
        let grandTotal = 0;
        totalRow.forEach((tItem, tIndex) => {
          grandTotal += tItem;
        });
        totalRow.push(grandTotal);
      }
      dataArray.push(mRow);
    });
    totalRow.splice(0, 0, "รวม (คน)");
    dataArray.push(totalRow);

    if (dataArray.length > 0) {
      // console.log(dataArray);
      this.toExcel(dataArray);
    }
  };

  toExcel = (data, rSpan, gSpan) => {
    const dt = new Date();
    const filename = `reject_reason_report_${dt.getDate()}_${
      Number(dt.getMonth()) + 1
    }_${dt.getFullYear()}`;
    let wb = XLSX.utils.book_new();

    let ws = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  toExport = async () => {
    // this.props.loading(true);
    let response;
    const { pagination } = this.state;

    try {
      response = await API.getRejectReasonReport(1, pagination.totalItems);
    } catch (error) {
      response = error.response;
    }
    // console.log("get report export", response.data);
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.mapExportData(response.data);
      } else {
        console.log(response.data.message);
      }
    }
  };

  renderContent = () => {
    const { reportData, pagination } = this.state;
    let columns = [];
    // console.log("reportData", reportData);

    let scrolling = { x: 600 };
    if (reportData && reportData.rows.length >= 10) {
      scrolling = { x: 600, y: 350 };
    }

    if (reportData) {
      columns = reportData.header.map((item, index) => {
        return {
          title: index==0? item.name:item.name + " (คน)",
          render: (value, row, i) => {
            // console.log("table row", row, i)
            if (index == 0) {
              if (i + 1 == reportData.rows.length) {
                return <div>{row.colName}</div>;
              } else {
                return <div>{row.reject_reason}</div>;
              }
            } else {
              if (index + 1 == reportData.header.length){
                return <div>{row.total}</div>;
              } else {
                if (i + 1 == reportData.rows.length) {
                  return <div>{row.rowTotal[index-1]}</div>;
                } else {
                  return <div>{row.count[index-1]}</div>;
                }
              }
            }
          },
          align: "center",
          className: "table-col",
          showSorterTooltip: false,
        };
      });
    }

    return (
      <section className="pt-3 w-100 bg-gray">
        <Row className="">
          <Col className="d-flex align-items-center">
            <h6 className="mb-0">
              <b>เหตุผลการถูกปฏิเสธ</b>
            </h6>
          </Col>
          <Col className="d-flex justify-content-end-table">
            <Button
              className="btn btn-success"
              onClick={() => this.toExport()}
            >
              Export to Excel
            </Button>
          </Col>
        </Row>
        <Row className="bg-gray">
          <Col className="my-2">
            {reportData && (
              <Table
                columns={columns}
                dataSource={reportData.rows}
                size="small"
                className={`table-width`}
                scroll={scrolling}
                pagination={{
                  className: "my-3",
                  position: ["bottomCenter"],
                  size: "default",
                  showSizeChanger: false,
                  current: pagination.currentPage,
                  total: pagination.totalItems,
                  pageSize: pagination.itemsPerPage,
                  hideOnSinglePage: false,
                }}
                rowKey={(record) => record.id}
                responsive
                onChange={async (paginate, filters, sorter) => {
                  // console.log("paginate", paginate,  "sorter", sorter);
                  pagination.currentPage = await paginate.current;

                  await this.getReport();
                }}
              />
            )}
          </Col>
        </Row>
      </section>
    );
  };

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
};

// CreatorReport.propTypes = {
//     rolesOptions: PropTypes.array,
//     role: PropTypes.string
// };

// CreatorReport.defaultProps = {
//     rolesOptions: [],
//     role: '',
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RejectReasonReport));
