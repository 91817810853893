import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, OverlayTrigger, Popover, Nav, Modal, Form, ListGroup, Spinner } from 'react-bootstrap';
import { Table } from "antd";
import { connect } from 'react-redux';
import { MdStar, MdMoreHoriz, MdMoreVert, MdControlPoint, MdClear } from "react-icons/md";
import { loading, addModal, checkLogin, modalSale, addidolId, setIdolTab, setWorkflowTab, setIdolTabName, setWorkflowTabName, searchIdol, checkToken } from '../redux/actions';
import { Link, withRouter } from 'react-router-dom';
import Pagination from '../components/Pagination';
import Rating from 'react-rating'
import Select from 'react-select'
import api from '../utils/apis';
import helper from "../utils/helper"
import ModalSale from "../components/ModalSale";
import ModalManageProduct from "../components/ModalManageProduct";
import AddIdolModal from "../components/AddIdolpopup";
import ModalStatus from '../components/ModalStatus';
import Layout from '../components/Layout'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const API = api.getAPI();
const MySwal = withReactContent(Swal)

class IdolList extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        data: false,
        modalQuickAdd: false,
        isIgSelect: false,
        selectButton: false,

        pagination: {
            currentPage: 1,
            itemCount: 0,
            itemsPerPage: 100,
            totalItems: 0,
            totalPages: 1,
        },

        currentSort: {
            currentPage: 1,
            sortField: "",
            sortOrder: "ASC"
        },

        saleSteamList: [],
        saleSteamSelectValue: [],
        saleSteamId: 0,
        remark: '',

        selectedRowKeys: [],
        selectedRows: [],
        type: '',

        idolList: [],
        workflows_tab: [],
        allProduct: [],

        idolId: '',
        id_idol: 0,
        id: '',

        close: '',
        role: '',

        // platform: 'ig',
        // addidolList: [],
        // idolList: [],
        // igname: '',
        // igSelect: '',
        // igUser: '',
        // engRate: '',
        // igUserName: '',
        idolType: 'idols',
        productTxt: '',
        productType: [],

        selectProduct: false,

        modalChangeSale: false,
        modalChangeStatus: false,
        modalManage: false,

        product_code: "ALL_PRODUCT",
        workflow_code: "",
        workflowStatusOptions: []
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        const currentSortMem = helper.sessionGet("currentSort");

        // console.log("params", params)
        this.props.checkLogin();
        if (this.props.login) {
            this.setState({ role: this.props.login.data.role })
        }
        if (params.productTab) {
            this.setState({ product_code: helper.converseLinkToCode(params.productTab) });
            this.props.setIdolTabName(helper.converseLinkToCode(params.productTab));
        }
        if (params.workflowTab) {
            this.setState({ workflow_code: helper.converseLinkToCode(params.workflowTab) });
            this.props.setWorkflowTabName(helper.converseLinkToCode(params.workflowTab));
        }

        if (currentSortMem) {
            this.setState({ currentSort: currentSortMem })
        }
        this.getIdolList(this.props.addDatasearch);

    }

    async componentDidUpdate(prevProps, prevState) {
        const { match: { params } } = this.props;
        if (this.props.login != prevProps.login && this.props.login) {
            this.setState({ role: this.props.login.data.role })
        }
        if (params.productTab != prevProps.match.params.productTab) {
            this.resetCurrentSort();
            // console.log("params", helper.converseLinkToCode(params.productTab))
            if (params.productTab) {
                await this.setState({ product_code: helper.converseLinkToCode(params.productTab) });
                await this.props.setIdolTabName(helper.converseLinkToCode(params.productTab));
            } else {
                this.setState({ product_code: "" });
                await this.props.setIdolTabName("");
            }
            await this.getIdolList(this.props.addDatasearch);
            helper.sessionRemove("currentSort");
        }
        if (params.workflowTab && params.workflowTab != prevProps.match.params.workflowTab) {
            this.resetCurrentSort();
            // console.log("params", helper.converseLinkToCode(params.productTab))
            await this.setState({ workflow_code: helper.converseLinkToCode(params.workflowTab) });
            await this.props.setWorkflowTabName(helper.converseLinkToCode(params.workflowTab));
            await this.getIdolList(this.props.addDatasearch);
            helper.sessionRemove("currentSort");
        }
        if (this.props.addDatasearch != prevProps.addDatasearch) {
            await this.getIdolList(this.props.addDatasearch);
        }
    }

    componentWillUnmount() {
        this.props.setIdolTab([])
    }

    getIdolList = async (data) => {
        this.props.loading(true);
        const { match: { params }, history } = this.props;
        let response;
        const { product_code, workflow_code, pagination, currentSort, idolType } = this.state;

        try {
            response = await API.getIdols(product_code, workflow_code, currentSort.currentPage, pagination.itemsPerPage, idolType, currentSort.sortField, currentSort.sortOrder, data)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        // console.log("get IdolList", response.data)
        
        if (response && response.status != 500) {
            this.props.loading(false);
            if (response.status == 200) {
                this.setState({ idolList: response.data.idols, pagination: response.data.paginate_meta, allProduct: response.data.idols_list_tabs })
                this.props.setIdolTab(response.data.idols_list_tabs);
                // console.log("idol list", response.data.idols);
                this.setWorkflowTab(response.data.idols_list_tabs);
                // await this.props.searchIdol(response.data)
                this.setOptionWork(response.data)

                if (!params.productTab) {
                    this.props.history.push("idol-list/" + helper.converseCodeToLink(response.data.idols_list_tabs[0].product_code))
                }

                // this.props.history.push("idol-list")
            } else {
                console.log(response.data.message)
            }
        }
    }

    setOptionWork = (data) => {
        const { available_attractive_point, available_language, available_occupation, available_reputation, available_work_type } = data;

        const attractive_point_options = available_attractive_point.map(item => {
            return { label: item, value: item }
        })
        const available_language_options = available_language.map(item => {
            return { label: item, value: item }
        })
        const available_occupation_options = available_occupation.map(item => {
            return { label: item, value: item }
        })
        const available_reputation_options = available_reputation.map(item => {
            return { label: item, value: item }
        })
        const available_work_type_options = available_work_type.map(item => {
            return { label: item, value: item }
        })

        const all_search_options = {
            available_language_options,
            attractive_point_options,
            available_occupation_options,
            available_reputation_options,
            available_work_type_options,
        }
        // console.log('hhh',all_search_options);
        this.props.searchIdol(all_search_options)
    }



    resetCurrentSort = () => {
        this.setState({
            currentSort: {
                currentPage: 1,
                sortField: "",
                sortOrder: "ASC"
            }
        });
    }

    setWorkflowTab = (data) => {
        // console.log('===>', data);
        const { match: { params } } = this.props;
        let workflowData;
        if (data && params.productTab) {
            workflowData = data.find(item => {
                return item.product_code == helper.converseLinkToCode(params.productTab);
            })
            if (workflowData && workflowData.workflows_list_tabs) {
                this.props.setWorkflowTab(workflowData.workflows_list_tabs);
                this.setWorkflowStatusOptions(workflowData.workflows_list_tabs)
            } else {
                this.props.setWorkflowTab([]);
                this.setWorkflowStatusOptions([])
            }
        }
    }

    setWorkflowStatusOptions = (data) => {
        // console.log("workflow status", data)
        const { match: { params } } = this.props;
        let tempData;
        // let tempData = [
        //     {
        //     label: "Group 1",
        //     options: [
        //       { label: "Group 1, option 1", value: "value_1" },
        //       { label: "Group 1, option 2", value: "value_2" }
        //     ]
        //   },
        //   { label: "A root option", value: "value_3" },
        //   { label: "Another root option", value: "value_4" }
        // ]
        if (params.productTab && params.productTab === "no-product") {
            let tempArray = []
            data.map((item, i) => {
                item.available_workflow_statuses.map((option, j) => {
                    tempArray.push({ label: option.workflow_status_name, value: option.workflow_status_code, workflowcode: item.workflow_code });
                })
            })
            tempData = tempArray;
        } else {
            tempData = data.map((item, i) => {
                return {
                    label: item.workflow_name,
                    workflow: item.workflow_code,
                    options: item.available_workflow_statuses.map((option, j) => {
                        if (option.workflow_status_name === "ขอเหตุผล") {
                            return { label: option.workflow_status_name, value: option.workflow_status_code, workflowcode: item.workflow_code, reject: option.available_idol_reject_reason}
                        }else{
                            return { label: option.workflow_status_name, value: option.workflow_status_code, workflowcode: item.workflow_code }
                        }
                    })
                }
            })
        }

        this.setState({ workflowStatusOptions: tempData })
    }

    modalTrue = async () => {
        // this.props.openModal(true);
        // handleShow
        await this.setState({
            modalQuickAdd: true,
        });
    }

    ChangeSaleModal = async (text) => {
        const type = text
        if (type === 'multi') {
            if (this.state.selectedRowKeys.length === 0) {
                MySwal.fire({
                    icon: "warning",
                    title: "เลือกอย่างน้อย 1 รายการ",
                }).then((value) => {
                    this.setState({
                        modalChangeSale: false,
                    });
                });
            } else {
                this.setState({ close: '', modalChangeSale: true, type: type })
            }
        } else {
            this.setState({ close: '', modalChangeSale: true, type: type })
        }

        // this.props.opensaleModal(value);
        // this.props.addidolId(this.state.idolId);
    }

    ChangeStatusModal = async (text) => {
        const type = text
        if (type === 'multi') {
            if (this.state.selectedRowKeys.length === 0) {
                MySwal.fire({
                    icon: "warning",
                    title: "เลือกอย่างน้อย 1 รายการ",
                }).then((value) => {
                    this.setState({
                        modalChangeStatus: false,
                    });
                });
            } else {
                this.setState({ close: '', modalChangeStatus: true, type: type })
            }
        } else {
            this.setState({ close: '', modalChangeStatus: true, type: type })
        }
    }

    ChangeDelete = async (text) => {
        const type = text
        if (type === 'multi') {
            if (this.state.selectedRowKeys.length === 0) {
                MySwal.fire({
                    icon: "warning",
                    title: "เลือกอย่างน้อย 1 รายการ",
                })
            } else {
                this.deleteIdol(this.state.selectedRowKeys, type)
            }
        }
    }


    ChangeManageModal = async () => {
        this.setState({ close: '', modalManage: true, })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
        this.setState({ selectedRowKeys, selectedRows });
    };

    checkIsEditing = (link) => {
        if (this.props.isEditing) {
            MySwal.fire({
                icon: 'warning',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
                text: 'ข้อมูลบางอย่างที่คุณแก้ไขไว้ยังไม่ได้ถูกบันทึก คุณยืนยันที่จะออกจากหน้านี้หรือไม่?'
            }).then((result) => {
                // console.log('rrr', result)
                if (result.value) {
                    // console.log('sssssssssssssssssssssssss', result.value)
                    this.props.setIsEditing(false);
                    this.props.history.push(link);

                }
            });
        } else {
            this.props.history.push(link);
        }
    }

    deleteIdol = async (id, type) => {
        const { history } = this.props;
        if (type === 'multi') {
            if (id.length > 0) {
                let response;
                MySwal.fire({
                    icon: 'warning',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    showCancelButton: true,
                    html: `ข้อมูลทุกอย่างเกี่ยวกับไอดอล จะถูกลบจากระบบ <br/>ยืนยันการลบหรือไม่`
                }).then(async (result) => {
                    if (result.value) {
                        id.forEach(async (item, index) => {
                            try {
                                response = await API.deleteIdol(item)
                                // console.log("response", response);
                            } catch (error) {
                                response = error.response
                                const token = { response, history }
                                this.props.checkToken(token);
                            }
                            if (response && response.status != 500) {
                                if (response.status == 200) {
                                    // if (index == id.length-1) {
                                    this.getIdolList();
                                    // } 
                                }
                            }
                        });
                    }
                })
            }
        } else if (type === 'single') {
            let response;
            MySwal.fire({
                icon: 'warning',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
                html: `ข้อมูลทุกอย่างเกี่ยวกับไอดอล จะถูกลบจากระบบ <br/>ยืนยันการลบหรือไม่`
            }).then(async (result) => {
                if (result.value) {
                    try {
                        response = await API.deleteIdol(id)
                        // console.log("response", response);
                    } catch (error) {
                        response = error.response
                    }
                    if (response && response.status != 500) {
                        if (response.status == 200) {
                            this.getIdolList();
                        }
                    }
                }
            });
        }
    }

    renderTableData = () => {
        const { match: { params }, workflowTabsList, history } = this.props;
        const { idolList, id_idol, role, selectedRowKeys, pagination, currentSort } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            // getCheckboxProps: record => {
            //     if (role == 'SALES' || role == 'AGENT') {
            //         return (({ disabled: record.id }))  // Column configuration not to be checked
            //     }
            // },
        };

        const popover = (item) => (
            <Popover id={`popover-${item.id}`} >
                {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                {role == 'ADMIN' || role == 'SUPERVISOR' ?
                    <Popover.Content className="w-pop">
                        <Dropdown.Item href="" className="point3 pl-2" onClick={() => { this.setState({ close: '' }); history.push(`/edit-idol/${item.id}`) }}>Edit</Dropdown.Item>
                        {/* <Dropdown.Item className="point3 pl-2">Approve</Dropdown.Item> */}
                        <Dropdown.Item onClick={() => (this.ChangeSaleModal('single'), window.document.body.click(), this.setState({ close: '' }))} className="point3 pl-2">Change Sale</Dropdown.Item>
                        <Dropdown.Item onClick={() => (this.ChangeManageModal(), window.document.body.click(), this.setState({ close: '' }))} className="point3 pl-2">Manage product</Dropdown.Item>
                        <Dropdown.Item className="point3 point-delete pl-2" onClick={() => this.deleteIdol(item.id, 'single')}>Delete</Dropdown.Item>
                    </Popover.Content>
                    :
                    <Popover.Content className="w-pop">
                        <Dropdown.Item href="" className="point3" onClick={() => { this.setState({ close: '' }); history.push(`/edit-idol/${item.id}`) }}>Edit</Dropdown.Item>
                        {/* <Dropdown.Item className="point3">Approve</Dropdown.Item> */}
                    </Popover.Content>
                }
            </Popover>
        );
        const columns = [
            {
                title: 'Social accounts',
                dataIndex: "SOCIAL",
                render: (name, item) => {
                    return (<div className="text-center d-flex flex-row" style={{ width: 240 }}>
                        <img className="img-acc" src={item.profile_pic_url} />
                        <div className="mr-1 text-start a-self pl-2">
                            <Link to={{
                                pathname: `/edit-idol/${item.id}`
                            }} style={{ cursor: "pointer" }} className="pointer-link">
                                <h5 className="text-align font-16">{item.ig_username}</h5>
                            </Link>
                            {/* <h5 className="text-detail font-16">n/a</h5> */}
                        </div>
                    </div>
                    )
                },
                // sorter: (a, b) => a.ig_username.localeCompare(b.ig_username),
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Followers',
                dataIndex: "FOLLOWER",
                render: (name, item) => {
                    return (
                        <div className="p-0 pt-2 align-ver mr-1 text-start" style={{ width: 100 }}>
                            <h5 className="txt-poin font-16">
                                <img width="35px" height="25px" className="pr-2" src={window.location.origin + "/image/instagram.png"} />{helper.kFormath(item.ig_follower_count)}
                            </h5>
                        </div>
                    )
                },
                // sorter: (a, b) => a.ig_follower_count - b.ig_follower_count,
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Engagement %',
                dataIndex: "ENGAGEMENT",
                render: (name, item) => {
                    return (
                        <div className="p-0 pt-2 align-ver mr-1 text-center" style={{ width: 120 }}>
                            <h5 className="txt-poin font-16">{item.ig_engagement_rate.toFixed(2)}%</h5>

                            {/* <h5 className="text-detail font-16">{item.fb_engagement_rate.toFixed(2)}%</h5> */}
                        </div>
                    )
                },
                // sorter: (a, b) => a.ig_engagement_rate - b.ig_engagement_rate,
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Avg.like',
                dataIndex: "AVERAGELIKE",
                render: (name, item) => {
                    return (
                        <div className="p-0 pt-2 align-ver mr-1 text-center" style={{ width: 100 }}>
                            <h5 className="txt-poin font-16">{helper.kFormath(item.ig_average_like)}</h5>

                            {/* <h5 className="text-detail font-16">{item.fb_engagement_rate.toFixed(2)}%</h5> */}
                        </div>
                    )
                },
                // sorter: (a, b) => a.ig_engagement_rate - b.ig_engagement_rate,
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Reputation',
                render: (item) => {
                    return (
                        <div className="text-start p-0 pt-2 text-center" style={{ width: 150 }}>
                            {
                                item.reputation.length > 0 ? item.reputation.map((req, index) => {
                                    return (<h5 className="btn btn-status mr-2 font-12" key={index}>{req}</h5>)
                                })
                                    : ''
                            }
                            {/* <h5 className="btn btn-status mr-2 font-14">Entertainer</h5> */}
                        </div>
                    )
                },
                // sorter: (a, b) => a.id - b.id,
                align: 'center',
            },
            {
                title: 'Beauty',
                dataIndex: "BEAUTY",
                render: (name, item) => {
                    return (
                        <div className="text-center p-2 align-ver flex-row" style={{ width: 150 }}>
                            <Rating
                                placeholderRating={item.beauty_level}
                                emptySymbol={<MdStar color="#E0E0E0" size={18} />}
                                placeholderSymbol={<MdStar color="#E6AF2E" size={18} />}
                                readonly={true}
                            />
                        </div>
                    )
                },
                // sorter: (a, b) => a.beauty_level - b.beauty_level,
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'จุดเด่น',
                render: (item) => {
                    return (
                        <div className="text-start p-0 pt-2 text-center" style={{ width: 150 }}>
                            {
                                item.attractive_point.length > 0 ? item.attractive_point.map((att, index) => {
                                    return (<h5 className="btn btn-status mr-2 font-12" key={index}>{att}</h5>)
                                })
                                    : ''
                            }
                        </div>
                    )
                },
                align: 'center',
            },
            {
                title: params.productTab === "all-product" ? 'Product' : 'Status',
                render: (item) => {
                    return (
                        <div className="text-start p-0 pt-2 align-ver text-center d-flex flex-column " style={{ width: 150 }}>
                            {
                                // item.products_info.length > 1 ? item.products_info.map((prdInf, index) => {
                                //     return (<h5 key={index}>{prdInf.product_name === 'No Product' ? '' : <div className="btn btn-status mr-2 font-16">{prdInf.product_name}</div>}</h5>)
                                // })
                                //     : item.products_info.map((prdInf, index) => {
                                //         return (<h5 className="btn btn-status mr-2 font-12" key={index}>{prdInf.product_name === 'No Product' ? 'N/A' : ''}</h5>)
                                //     })
                                this.renderIdolStatus(item)

                            }
                        </div>
                    )
                },
                // sorter: (a, b) => a.id - b.id,
                align: 'center',
                className: `table-col ${params.productTab === "all-product" ? "" : "width-select"}`,
                showSorterTooltip: false
            },
            {
                title: 'Sales',
                dataIndex: "SALES",
                render: (name, item) => {
                    return (
                        <div className="text-center p-0 pt-2 align-ver text-center d-flex" style={{ width: 150 }}>
                            {item.sales_name ?
                                <>
                                    <img className="img-sale" src={item.sales_image_url} />
                                    <h5 className="txt-poin font-16 mr-2 a-self pl-2">{item.sales_name}</h5>
                                </>
                                :
                                <h5 className="text-detail font-16 pl-5">N/A</h5>
                            }

                        </div>
                    )
                },
                // sorter: (a, b) => a.sales_name.localeCompare(b.sales_name),
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Creator',
                dataIndex: "CREATOR",
                render: (name, item) => {
                    return (
                        <div className="text-center p-0 pt-2 align-ver text-center d-flex" style={{ width: 150 }}>
                            <div className="text-center d-flex">
                                <img className="img-sale" src={item.creator_image_url} />
                                <h5 className="txt-poin font-16 a-self pl-2">{item.creator_name}</h5>
                            </div>
                        </div>
                    )
                },
                // sorter: (a, b) => a.creator_name.localeCompare(b.creator_name),
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: '',
                // selector: (item, index) => {
                //     return (
                //         <div className="text-center p-0 pr-2 align-ver">
                //             <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose >
                //                 <a style={{ cursor: "pointer" }} className="pointer-link" onClick={(e) => this.setState({ idolId: item, close: index })}><MdMoreHoriz className="icon-more" /></a>
                //             </OverlayTrigger>
                //         </div>
                //     )
                // },
                render: (item, index) => <div className="text-center p-0 pr-2 align-ver" style={{ width: 50 }}>
                    <OverlayTrigger trigger={["click", "focus"]} placement="left" overlay={popover(item)} rootClose>
                        <a style={{ cursor: "pointer" }} className="pointer-link" onClick={() => this.setState({ idolId: item, id_idol: item.id, close: true })}><MdMoreHoriz className="icon-more" /></a>
                    </OverlayTrigger>
                </div>,
                align: 'center',
            },
        ];

        return (
            <Row>
                <Col className="my-3 bg-gray">
                    {/* <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={idolList}
                        size="small"
                        className={'table-width'}
                        scroll={{ x: 600 }}
                        pagination={{
                            total: idolList.length,
                            pageSize: 100,
                            hideOnSinglePage: false
                        }}
                        rowKey="id"
                        responsive
                    /> */}
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={idolList}
                        size="small"
                        className={'table-width'}
                        scroll={{ x: 600 }}
                        pagination={{
                            className: 'my-4',
                            position: ["bottomCenter"],
                            size: 'default',
                            showSizeChanger: false,
                            current: currentSort.currentPage,
                            total: pagination.totalItems,
                            pageSize: pagination.itemsPerPage,
                            hideOnSinglePage: false
                        }}
                        rowKey="id"
                        responsive
                        onChange={async (pagination, filters, sorter) => {
                            // console.log("pagination", pagination,  "sorter", sorter);
                            // const { currentSort } = await this.state;
                            currentSort.currentPage = await pagination.current;
                            currentSort.sortField = await sorter.field;
                            if (sorter.order == "ascend") {
                                currentSort.sortOrder = "ASC";
                            } else if (sorter.order == "descend") {
                                currentSort.sortOrder = "DESC";
                            } else {
                                currentSort.sortOrder = "ASC";
                            }
                            await this.getIdolList(this.props.addDatasearch);
                            await helper.sessionSave("currentSort", currentSort);
                        }}
                    />
                </Col>
            </Row>
        )
    }



    renderIdolStatus = (data) => {
        const { match: { params }, workflowTabsList } = this.props;
        const { workflowStatusOptions, product_code, workflow_code } = this.state;
        if (params.productTab === "all-product") {
            if (data.products_info.length) {
                return (<h5 className="btn btn-status mr-2 font-12">{data.products_info[0].product_code === "NO_PRODUCT" ? 'N/A' : data.products_info[0].product_name}</h5>)
            }
        } else {
            data.workflowsSelected = data.products_info.filter(item => {
                return item.product_code == product_code;
            }).map(item => {
                return { label: item.workflow_status_name, value: item.workflow_status_code, workflowcode: item.workflow_code }
            })
            return (<Select
                placeholder={'Status'}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 13 }) }}
                id={`product_${data.id}`}
                value={data.workflowsSelected}
                options={workflowStatusOptions}
                onChange={(value) => {
                    data.product_code = product_code;
                    data.workflow_code = value.workflowcode;
                    data.status = value;
                    data.workflowsSelected = value;
                    // console.log('ddddddd', value);
                    this.setState({ idolId: data, modalChangeStatus: true, data })
                }}
                isSearchable={true}
                isDisabled={false}
            />)
        }
    }

    getPage = (page) => {
        // console.log('page', page)
        const url = `/idol-list?page=${page}`
        this.props.history.push(url);
    }

    onHide = (cancel) => {
        // console.log('cancel',cancel)
        if (cancel === 'cancelMulti') {
            this.setState({
                isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false,
                modalQuickAdd: false, saleSteamSelectValue: [], saleSteamId: '', productType: [],
                remark: '', modalChangeSale: false, modalChangeStatus: false, modalManage: false, type: '', modalChangeStatusEdit: false
            })
        } else {
            this.setState({
                isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false,
                modalQuickAdd: false, saleSteamSelectValue: [], saleSteamId: '', productType: [],
                remark: '', modalChangeSale: false, modalChangeStatus: false, modalManage: false,
                selectedRowKeys: [], type: '', modalChangeStatusEdit: false
            })
        }
        // this.props.openModal(false);
    };

    checkStatusCode = () => {
        const { igUser, igUserName, engRate, igSelect } = this.state;
        // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', igUser)
        if (igUser.statusCode == 401) {
            return (
                <Row className="mt-3 mb-3">
                    <Col className="text-center text-danger">
                        <label>{igUserName}</label>
                    </Col>
                </Row>)
        } else if (igUser.is_duplicate === true) {
            return (
                <Card className="bg-card border-danger">
                    <Card.Header className="card-ig">
                        <img width="70px" height="70px" className="border-radius mr-3" src={igSelect.profile_pic_url} /> <label className="text-ig">{igSelect.username}</label>
                    </Card.Header>
                    {igUser === '' ?
                        <Row className="mt-3 mb-3">
                            <Col className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                        :
                        <>
                            <Row className="d-flex ml-2 mt-3">
                                <Col md={2} className="">
                                    <img className="pr-2" width="55px" src={window.location.origin + "/image/instagram.png"} />
                                </Col>
                                <Col md={3} className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(igUser.follower_count)}</h5>
                                    <label className="font-gray font-12">Followers</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">N/A</h5>
                                    <label className="font-gray font-12">Growth rate</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{engRate}%</h5>
                                    <label className="font-gray font-12">Engaement rate</label>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="row-check">
                                    <label className="text-danger">Profile has already been added</label>
                                </Col>
                            </Row>
                        </>
                    }
                </Card>
            )
        } else {
            return (
                <Card className="bg-card">
                    <Card.Header className="card-ig">
                        <img width="70px" height="70px" className="border-radius mr-3" src={igSelect.profile_pic_url} /> <label className="text-ig">{igSelect.username}</label>
                    </Card.Header>
                    {igUser === '' ?
                        <Row className="mt-3 mb-3">
                            <Col className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                        :
                        <>
                            <Row className="d-flex ml-2 mt-3">
                                <Col md={2} className="">
                                    <img className="pr-2" width="55px" src={window.location.origin + "/image/instagram.png"} />
                                </Col>
                                <Col md={3} className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(igUser.follower_count)}</h5>
                                    <label className="font-gray font-12">Followers</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">N/A</h5>
                                    <label className="font-gray font-12">Growth rate</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{engRate}%</h5>
                                    <label className="font-gray font-12">Engaement rate</label>
                                </Col>
                            </Row>
                        </>
                    }
                </Card>
            )
        }
    }


    render() {
        const { match: { params }, workflowTabsList, login } = this.props;
        const { igUser, isIgSelect, platform, addidolList, igSelect, selectButton, idolId, productTxt, role, selectedRowKeys } = this.state;
        // console.log("idol list props", this.props);

        return (
            <Layout>
                <Container className="width-full pt-2">

                    {/* <section className="w-100 bg-gray"> */}
                    <Row className="bg-gray">
                        <Col className="d-flex justify-content-end-table m-0">
                            {
                                (login && (login.data.role == "ADMIN"||login.data.role == "SUPERVISOR")) &&
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-action mr-3 font-12 font-weight-bold ">
                                        Action
                                                    </Dropdown.Toggle>
                                    {role == 'ADMIN' || role == 'SUPERVISOR' ?
                                        <Dropdown.Menu>
                                            {/* <Dropdown.Item href="" className="point3">Approve</Dropdown.Item> */}
                                            <Dropdown.Item href="" onClick={() => (this.ChangeSaleModal('multi'), this.setState({ close: '' }))} className="point3">Change Sale</Dropdown.Item>
                                            {params.productTab === "all-product" ?
                                                ''
                                                :
                                                <Dropdown.Item href="" onClick={() => (this.ChangeStatusModal('multi'), this.setState({ close: '' }))} className="point3">Set idol status</Dropdown.Item>
                                            }
                                            <Dropdown.Item href="" onClick={() => (this.ChangeDelete('multi'))} className="point3 point-delete">Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                        :
                                        <Dropdown.Menu>
                                            {/* <Dropdown.Item href="" className="point3">Approve</Dropdown.Item> */}
                                            {params.productTab === "all-product" ?
                                                ''
                                                :
                                                <Dropdown.Item href="" onClick={() => (this.ChangeStatusModal('multi'), this.setState({ close: '' }))} className="point3">Set idol status</Dropdown.Item>
                                            }
                                            {/* <Dropdown.Item href="">Delete</Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    }
                                </Dropdown>
                            }
                            
                            <Button className="btn btn-quick font-12 font-weight-bold" onClick={() => this.modalTrue()}><MdControlPoint className="mr-1 icon-quick" />Quick Add</Button>
                        </Col>
                    </Row>
                    {/* </section> */}

                    {this.renderTableData()}


                </Container>

                {this.state.modalChangeSale &&
                    <ModalSale show={this.state.modalChangeSale} data={idolId}
                        idolList={selectedRowKeys}
                        type={this.state.type}
                        onHide={value => this.onHide(value)}
                        updateIdolList={() => this.getIdolList(this.props.addDatasearch)}
                    />
                }


                <AddIdolModal show={this.state.modalQuickAdd}
                    type={this.state.idolType}
                    onHide={value => this.onHide(value)}
                    updateIdolList={() => this.getIdolList(this.props.addDatasearch)}
                />

                <ModalStatus show={this.state.modalChangeStatus} data={idolId}
                    idolList={this.state.selectedRows}
                    options={this.state.workflowStatusOptions}
                    workflow={this.state.product_code}
                    type={this.state.type}
                    onHide={value => this.onHide(value)}
                    updateIdolList={() => this.getIdolList(this.props.addDatasearch)}
                />

                <ModalManageProduct show={this.state.modalManage} data={idolId}
                    producstList={this.state.allProduct}

                    type={this.state.type}
                    onHide={value => this.onHide(value)}
                    updateIdolList={() => this.getIdolList(this.props.addDatasearch)}
                />
            </Layout >

        );
    }
}

const mapStateToProps = state => {
    // console.log('state', state)
    return {
        login: state.login,
        isEditing: state.isEditing,
        workflowTabsList: state.workflowTabsList,
        addDatasearch: state.addDatasearch
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loading: value => dispatch(loading(value)),
        openModal: value => dispatch(addModal(value)),
        checkLogin: value => dispatch(checkLogin(value)),
        opensaleModal: value => dispatch(modalSale(value)),
        addidolId: value => dispatch(addidolId(value)),
        setIdolTab: value => dispatch(setIdolTab(value)),
        setWorkflowTab: value => dispatch(setWorkflowTab(value)),
        setIdolTabName: value => dispatch(setIdolTabName(value)),
        setWorkflowTabName: value => dispatch(setWorkflowTabName(value)),
        searchIdol: value => dispatch(searchIdol(value)),
        checkToken: value => dispatch(checkToken(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IdolList));