import React, { Component } from 'react';
import { Container, Row, Col, Form, InputGroup, Button, Collapse, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loading, addDatasearch, delectDataSearch } from '../../../redux/actions';
import { Link, withRouter } from 'react-router-dom';
import { FaChevronCircleRight, FaChevronCircleLeft, FaSearch, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import SelectForm from "../../../components/SelectForm";
import { Slider } from 'antd';
import InputMask from 'react-input-mask';
import '../../../css/slider.css';
import helper from "../../../utils/helper"
import api from '../../../utils/apis';
const API = api.getAPI();


class SideMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: true,
            open: true,
            openMetric: false,

            attractive: [],
            availableLanguage: [],
            availableOccupation: [],
            availableReputation: [],
            availableWork_type: [],

            selectButton: false,
            platform: 'ig',
            profileName: '',
            ageOne: false,
            ageTwo: false,
            ageThree: false,
            ageFour: false,
            ageFive: false,
            genderF: false,
            genderM: false,
            height: [140, 250],
            heightCheck: false,
            weight: [30, 200],
            weightCheck: false,
            country: '',
            region: '',
            infoLanguage: [],
            infoAttractivepoint: [],
            infoOccupation: [],
            infoReputation: [],
            infoWorktype: [],
            // Followers: [0, 10000000],
            // FollowersCheck: false,
            // Growth: [0, 100],
            // GrowthCheck: false,
            // Engagement: [0, 100],
            // EngagementCheck: false,
            // Average: [0, 10000000],
            // AverageCheck: false,

            role: '',
            infoSales: [],
            FollowersMin: '',
            FollowersMax: '',
            GrowthMin: '',
            GrowthMax: '',
            EngagementMin: '',
            EngagementMax: '',
            AverageMin: '',
            AverageMax: '',
            saleSteamList: [],
        };
    }

    async componentDidMount() {
        if (this.props.login) {
            await this.setState({ role: this.props.login.data.role })
            await this.getSaleSteam()
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const { match: { params } } = this.props;
        if (this.props.login != prevProps.login && this.props.login) {
            await this.setState({ role: this.props.login.data.role })
            // await this.getSaleSteam()
        }
        if (this.props.searchIdol != prevProps.searchIdol) {
            // console.log('33444',this.props.searchIdol);
            await this.setState({
                attractive: this.props.searchIdol.attractive_point_options, availableLanguage: this.props.searchIdol.available_language_options,
                availableOccupation: this.props.searchIdol.available_occupation_options, availableReputation: this.props.searchIdol.available_reputation_options,
                availableWork_type: this.props.searchIdol.available_work_type_options
            })
        }
    }

    handleViewSidebar = () => {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    getSaleSteam = async () => {
        const { history } = this.props;
        let response;
        if (this.state.role == "ADMIN" || this.state.role == "SUPERVISOR") {
            try {
                response = await API.getSaleSteam()
            } catch (error) {
                response = error.response
                const token = { response, history }
                this.props.checkToken(token);
            }
            if (response && response.status != 500) {
                let saleSteamArray = [];
                if (response.status == 200) {
                    response.data.forEach(element => {
                        saleSteamArray.push({ value: element.id, label: element.firstname });
                        // saleSteamArray.push({ value: element.id, label: <div><img src={element.image_url} className="img-change-sale" />{element.firstname}</div> });
                    });
                    this.setState({
                        saleSteamList: saleSteamArray
                    });
                    // console.log('rrrrrr=>', this.state.saleSteamList);
                } else {
                    console.log(response.data.message)
                }
            }
        }
    }

    SearchIdol = () => {
        const { genderF, genderM, profileName, ageOne, ageTwo, height, heightCheck, weight, weightCheck, Growth, GrowthCheck, Engagement, EngagementCheck, AverageCheck, Average, Followers, FollowersCheck, ageThree, ageFour, ageFive, country, region, infoLanguage, infoAttractivepoint, infoOccupation, infoReputation, infoWorktype,
            FollowersMin, FollowersMax, GrowthMin, GrowthMax, EngagementMin, EngagementMax, AverageMin, AverageMax, infoSales } = this.state;
        let gender = [];
        if (genderF) {
            gender.push("Female")
        }
        if (genderM) {
            gender.push("Male")
        }

        let sales = [];
        if (infoSales.length > 0) {
            infoSales.map((item) => {
                sales.push(item.value)
            })
        }

        const data = {};
        if (profileName) { data.display_name = profileName }

        if (FollowersMin) {
            data.ig_follower_min = FollowersMin
            if (FollowersMax === '') { data.ig_follower_max = '999999999' }
        }
        if (FollowersMax) {
            data.ig_follower_max = FollowersMax
            if (FollowersMin === '') { data.ig_follower_min = '0' }
        }
        if (GrowthMin) {
            data.ig_growth_rate_min = GrowthMin
            if (GrowthMax === '') { data.ig_growth_rate_max = '200' }
        }
        if (GrowthMax) {
            data.ig_growth_rate_max = GrowthMax
            if (GrowthMin === '') { data.ig_growth_rate_min = '0' }
        }
        if (EngagementMin) {
            data.ig_engagement_rate_min = EngagementMin
            if (EngagementMax === '') { data.ig_engagement_rate_max = '200' }
        }
        if (EngagementMax) {
            data.ig_engagement_rate_max = EngagementMax
            if (EngagementMin === '') { data.ig_engagement_rate_min = '0' }
        }
        if (AverageMin) {
            data.ig_average_like_min = AverageMin
            if (AverageMax === '') { data.ig_average_like_max = '999999999' }
        }
        if (AverageMax) {
            data.ig_average_like_max = AverageMax
            if (AverageMin === '') { data.ig_average_like_min = '0' }
        }

        if (ageOne) { data.age_13_18_check = ageOne }
        if (ageTwo) { data.age_19_25_check = ageTwo }
        if (ageThree) { data.age_26_35_check = ageThree }
        if (ageFour) { data.age_36_45_check = ageFour }
        if (ageFive) { data.age_46_up_check = ageFive }
        if (country) { data.country = country }
        if (region) {
            if (country !== '') {
                data.city = region
            }
        }
        if (gender.length > 0) { data.gender = gender }
        if (infoLanguage.length > 0) { data.language = infoLanguage }
        if (infoAttractivepoint.length > 0) { data.attractive_point = infoAttractivepoint }
        if (infoOccupation.length > 0) { data.occupation = infoOccupation }
        if (infoReputation.length > 0) { data.reputation = infoReputation }
        if (infoWorktype.length > 0) { data.work_type = infoWorktype }
        if (heightCheck) {
            data.height_min = height[0]
            data.height_max = height[1]
        }
        if (weightCheck) {
            data.weight_min = weight[0]
            data.weight_max = weight[1]
        }

        if (sales.length > 0) { data.sales_ids = JSON.stringify(sales) }

        // if (FollowersCheck) {
        //     data.ig_follower_min = Followers[0]
        //     data.ig_follower_max = Followers[1]
        // }
        // if (GrowthCheck) {
        //     data.ig_growth_rate_min = Growth[0]
        //     data.ig_growth_rate_max = Growth[1]
        // }
        // if (EngagementCheck) {
        //     data.ig_engagement_rate_min = Engagement[0]
        //     data.ig_engagement_rate_max = Engagement[1]
        // }
        // if (AverageCheck) {
        //     data.ig_average_like_min = Average[0]
        //     data.ig_average_like_max = Average[1]
        // }

        // console.log('dataaaa', data);
        this.props.addDatasearch(data)
    }

    resetSearch = () => {
        this.props.delectDataSearch(false)
        this.setState({
            profileName: '', ageOne: '', ageTwo: '', ageThree: '', ageFour: '', ageFive: '', genderF: '', genderM: '', selectButton: false,
            country: '', region: '', infoLanguage: [], infoAttractivepoint: [], infoOccupation: [], infoReputation: [], infoWorktype: [], height: [140, 250], heightCheck: false,
            weight: [30, 200], weightCheck: false, Followers: [0, 10000000], FollowersCheck: false, Growth: [0, 200], GrowthCheck: false, Engagement: [0, 200],
            EngagementCheck: false, Average: [0, 10000000], AverageCheck: false, FollowersMin: '', FollowersMax: '', GrowthMin: '', GrowthMax: '',
            EngagementMin: '', EngagementMax: '', AverageMin: '', AverageMax: '', infoSales: [],
        })
    }

    onChangeHeight = (value) => {
        if (value[0] === 140 && value[1] === 250) {
            this.setState({ height: value, selectButton: true, heightCheck: false })
        } else {
            this.setState({ height: value, selectButton: true, heightCheck: true })
        }
    }

    onChangeWeight = (value) => {
        if (value[0] === 30 && value[1] === 200) {
            this.setState({ weight: value, selectButton: true, weightCheck: false })
        } else {
            this.setState({ weight: value, selectButton: true, weightCheck: true })
        }
    }

    onChangeFollowers = (value) => {
        if (value[0] === 0 && value[1] === 10000000) {
            this.setState({ Followers: value, selectButton: true, FollowersCheck: false })
        } else {
            this.setState({ Followers: value, selectButton: true, FollowersCheck: true })
        }
    }

    onChangeGrowth = (value) => {
        if (value[0] === 0 && value[1] === 100) {
            this.setState({ Growth: value, selectButton: true, GrowthCheck: false })
        } else {
            this.setState({ Growth: value, selectButton: true, GrowthCheck: true })
        }
    }

    onChangeEngagement = (value) => {
        if (value[0] === 0 && value[1] === 100) {
            this.setState({ Engagement: value, selectButton: true, EngagementCheck: false })
        } else {
            this.setState({ Engagement: value, selectButton: true, EngagementCheck: true })
        }
    }

    onChangeAverage = (value) => {
        if (value[0] === 0 && value[1] === 10000000) {
            this.setState({ Average: value, selectButton: true, AverageCheck: false })
        } else {
            this.setState({ Average: value, selectButton: true, AverageCheck: true })
        }
    }



    render() {
        const { open, openMetric, platform, region, country, attractive, availableLanguage, availableOccupation, availableReputation, availableWork_type, infoLanguage, infoAttractivepoint, infoOccupation, infoReputation, infoWorktype, role, infoSales, saleSteamList } = this.state;
        let sidebarClass = this.state.sidebarOpen ? 'sidebar open' : 'sidebar';
        let sidebar = this.state.sidebarOpen ? 'icon-silde' : 'icon-sildeLeft';

        return (
            <>
                <section className="">
                    <Row className="m-0">
                        <Col className="p-0 d-flex position-side" >
                            {this.state.sidebarOpen && <>
                                <div className={sidebarClass}>
                                    <div>
                                        <div className="position-input"><FaSearch /></div>
                                        <Form.Control type="text" placeholder="Name/Social account/Alias" className="hight-input" value={this.state.profileName}
                                            onChange={(e) => {
                                                this.setState({ profileName: e.target.value.trim(), selectButton: true })
                                            }}
                                        />
                                    </div>
                                    <div className="pt-3 font-14 text-bold d-flex justify-side" onClick={() => this.setState({ open: !open })}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={open}
                                    >
                                        Photographer filter
                                    <div>
                                            {this.state.open ?
                                                <FaAngleDown /> : <FaAngleUp />
                                            }
                                        </div>
                                    </div>

                                    <Collapse in={this.state.open}>
                                        <div id="example-collapse-text">
                                            <div className="pt-2 textSize12 text-secondary">Age range</div>
                                            <Row className="magin-check">
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="13-18" defaultChecked={this.state.ageOne}
                                                            onClick={() => this.setState({ ageOne: !this.state.ageOne, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="p-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="19-25" defaultChecked={this.state.ageTwo}
                                                            onClick={() => this.setState({ ageTwo: !this.state.ageTwo, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="magin-check">
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="26-35" defaultChecked={this.state.ageThree}
                                                            onClick={() => this.setState({ ageThree: !this.state.ageThree, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="p-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="36-45" defaultChecked={this.state.ageFour}
                                                            onClick={() => this.setState({ ageFour: !this.state.ageFour, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="46+" defaultChecked={this.state.ageFive}
                                                            onClick={() => this.setState({ ageFive: !this.state.ageFive, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className="textSize12 text-secondary">Gender</div>
                                            <Row className="magin-check">
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="Male" defaultChecked={this.state.genderM}
                                                            onClick={() => this.setState({ genderM: !this.state.genderM, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="p-0">
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" label="Female" defaultChecked={this.state.genderF}
                                                            onClick={() => this.setState({ genderF: !this.state.genderF, selectButton: true })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <div className="mt-1 textSize12 text-secondary">Country </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <CountryDropdown
                                                        value={country}
                                                        className="form-control font-14"
                                                        defaultOptionLabel="Select Country"
                                                        onChange={async (val) => {
                                                            this.setState({ country: val, selectButton: true, region: '' });
                                                        }}
                                                        whitelist={["TH", "CN", "JP", "VN"]} />
                                                </Col>
                                            </Row>

                                            <div className="pt-4 textSize12 text-secondary">City </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <RegionDropdown
                                                        className="form-control font-14"
                                                        country={country}
                                                        value={region}
                                                        blankOptionLabel="Select City"
                                                        onChange={async (val) => {
                                                            this.setState({ region: val, selectButton: true });
                                                        }} />
                                                </Col>
                                            </Row>

                                            <div className="pt-4 textSize12 text-secondary">Languages </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <SelectForm options={availableLanguage}
                                                        placeholder="Languages" value={infoLanguage.map(item => ({ label: item, value: item }))}
                                                        getData={async (data) => {
                                                            await this.setState({ infoLanguage: data.map(item => item.value), selectButton: true });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <div className="pt-4 textSize12 text-secondary">Attractive points </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <SelectForm options={attractive}
                                                        placeholder="Attractive points" value={infoAttractivepoint.map(item => ({ label: item, value: item }))}
                                                        getData={async (data) => {
                                                            await this.setState({ infoAttractivepoint: data.map(item => item.value), selectButton: true });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <div className="pt-4 textSize12 text-secondary">Occupation </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <SelectForm options={availableOccupation}
                                                        placeholder="Occupation" value={infoOccupation.map(item => ({ label: item, value: item }))}
                                                        getData={async (data) => {
                                                            await this.setState({ infoOccupation: data.map(item => item.value), selectButton: true });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <div className="pt-4 textSize12 text-secondary">Reputation </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <SelectForm options={availableReputation}
                                                        placeholder="Reputation" value={infoReputation.map(item => ({ label: item, value: item }))}
                                                        getData={async (data) => {
                                                            await this.setState({ infoReputation: data.map(item => item.value), selectButton: true });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <div className="pt-4 textSize12 text-secondary">Work type </div>
                                            <Row className="magin-check">
                                                <Col className="">
                                                    <SelectForm options={availableWork_type}
                                                        placeholder="Work type" value={infoWorktype.map(item => ({ label: item, value: item }))}
                                                        getData={async (data) => {
                                                            await this.setState({ infoWorktype: data.map(item => item.value), selectButton: true });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            {role == 'ADMIN' || role == 'SUPERVISOR' ?
                                                <>
                                                    <div className="pt-4 textSize12 text-secondary">Sales</div>
                                                    <Row className="magin-check">
                                                        <Col className="">
                                                            <SelectForm options={saleSteamList}
                                                                placeholder="Sales" value={infoSales.map(item => ({ label: item.label, value: item.value }))}
                                                                getData={async (data) => {
                                                                    await this.setState({ infoSales: data.map(item => ({ label: item.label, value: item.value })), selectButton: true });
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                ''
                                            }

                                        </div>
                                    </Collapse>

                                    <div className="magin-btn-side font-14 text-bold d-flex justify-side" onClick={() => this.setState({ openMetric: !openMetric })}
                                        aria-controls="Metric"
                                        aria-expanded={openMetric}
                                    >
                                        Metric filter
                                    <div>
                                            {this.state.openMetric ?
                                                <FaAngleDown /> : <FaAngleUp />
                                            }
                                        </div>
                                    </div>
                                    <Collapse in={this.state.openMetric}>
                                        <div id="Metric">
                                            <Row className="magin-check">
                                                <Col md={12} className="pt-2 justify-content-center mr-2" >
                                                    <Form.Check
                                                        inline
                                                        type="radio"
                                                        checked={platform === 'ig'}
                                                        // label="Instagram"
                                                        className="m-0"
                                                        name="platforms"
                                                        id="platform"
                                                        value="ig"
                                                        onChange={() => { this.setState({ platform: 'ig' }) }}
                                                    />
                                                    <img
                                                        width="35px"
                                                        height="25px"
                                                        className="pr-2"
                                                        src={window.location.origin + "/image/instagram.png"}
                                                    />
                                                    <label className="mr-2 font-14">Instagram</label>
                                                </Col>
                                                {/* <Col className="pt-2 justify-content-center">
                                                    <Form.Check
                                                        inline
                                                        type="radio"
                                                        // label="Facebook"
                                                        className="m-0"
                                                        name="platforms"
                                                        id="platform"
                                                        value="fb"
                                                        onChange={() => { this.setState({ platform: 'fb' }) }}
                                                    />
                                                    <img
                                                        width="35px"
                                                        height="25px"
                                                        className="pr-2"
                                                        src={window.location.origin + "/image/facebook.png"}
                                                    />
                                                    <label className="mr-2 font-14">Facebook</label>
                                                </Col> */}
                                            </Row>

                                            <div className="pt-3 textSize12 text-secondary">Followers</div>
                                            {/* <Slider
                                                range
                                                step={10}
                                                defaultValue={this.state.Followers}
                                                value={this.state.Followers}
                                                min={0}
                                                max={10000000}
                                                onChange={this.onChangeFollowers}
                                            /> */}
                                            <Row>
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Min</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="0" 
                                                            value={this.state.FollowersMin}
                                                            onChange={e => {
                                                                this.setState({ FollowersMin: e.target.value.trim(), selectButton: true })
                                                            }}
                                                            onBlur={e => {
                                                                let min = e.target.value.trim()
                                                                if (this.state.FollowersMin && this.state.FollowersMax) {
                                                                    // console.log('dddddddd', this.state.FollowersMin,this.state.FollowersMax, this.state.FollowersMin.toString() > this.state.FollowersMax.toString());
                                                                    if (parseInt(min) > parseInt(this.state.FollowersMax)) {
                                                                        alert("กรุณาใส่ค่า Min น้อยกว่าค่า Max ")
                                                                        this.setState({ FollowersMin: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Max</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="10000000"
                                                            value={this.state.FollowersMax}
                                                            onChange={async e => {
                                                                let max = e.target.value.trim()
                                                                await this.setState({ FollowersMax: max, selectButton: true })
                                                            }}
                                                            onBlur={e => {
                                                                let max = e.target.value.trim()
                                                                if (this.state.FollowersMax) {
                                                                    // console.log('llllllllllllllll', parseInt(max) < parseInt(this.state.FollowersMin));
                                                                    if (parseInt(max) < parseInt(this.state.FollowersMin)) {
                                                                        alert("กรุณาใส่ค่า Max มากกว่าค่า Min ")
                                                                        this.setState({ FollowersMax: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {/* <Row className="mb-2">
                                                <Col>
                                                    <span className="font-12">{helper.kFormath(this.state.Followers[0])}</span>
                                                </Col>
                                                <Col className="text-right">
                                                    <span className="font-12">{helper.kFormath(this.state.Followers[1])}</span>
                                                </Col>
                                            </Row> */}

                                            <div className="textSize12 text-secondary">Growth rate (%)</div>
                                            {/* <Slider
                                                range
                                                step={1}
                                                defaultValue={this.state.Growth}
                                                value={this.state.Growth}
                                                min={0}
                                                max={100}
                                                onChange={this.onChangeGrowth}
                                            />
                                            <Row className="mb-2">
                                                <Col>
                                                    <span className="font-12">{this.state.Growth[0]}</span>
                                                </Col>
                                                <Col className="text-right">
                                                    <span className="font-12">{this.state.Growth[1]}</span>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Min</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="0" value={this.state.GrowthMin}
                                                            onChange={e => this.setState({ GrowthMin: e.target.value.trim(), selectButton: true })}
                                                            onBlur={e => {
                                                                let min = e.target.value.trim()
                                                                if (this.state.GrowthMin && this.state.GrowthMax) {
                                                                    if (parseInt(min) > parseInt(this.state.GrowthMax)) {
                                                                        alert("กรุณาใส่ค่า Min น้อยกว่าค่า Max ")
                                                                        this.setState({ GrowthMin: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Max</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="100" value={this.state.GrowthMax}
                                                            onChange={async e => {
                                                                let max = e.target.value.trim()
                                                                await this.setState({ GrowthMax: max, selectButton: true })
                                                            }}
                                                            onBlur={e => {
                                                                let max = e.target.value.trim()
                                                                if (this.state.GrowthMax) {
                                                                    if (parseInt(max) < parseInt(this.state.GrowthMin)) {
                                                                        alert("กรุณาใส่ค่า Max มากกว่าค่า Min ")
                                                                        this.setState({ GrowthMax: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <div className="textSize12 text-secondary">Engagement rate (%)</div>
                                            {/* <Slider
                                                range
                                                step={1}
                                                defaultValue={this.state.Engagement}
                                                value={this.state.Engagement}
                                                min={0}
                                                max={100}
                                                onChange={this.onChangeEngagement}
                                            />
                                            <Row>
                                                <Col>
                                                    <span className="font-12">{this.state.Engagement[0]}</span>
                                                </Col>
                                                <Col className="text-right">
                                                    <span className="font-12">{this.state.Engagement[1]}</span>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Min</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="0" value={this.state.EngagementMin}
                                                            onChange={e => this.setState({ EngagementMin: e.target.value.trim(), selectButton: true })}
                                                            onBlur={e => {
                                                                let min = e.target.value.trim()
                                                                if (this.state.EngagementMin && this.state.EngagementMax) {
                                                                    if (parseInt(min) > parseInt(this.state.EngagementMax)) {
                                                                        alert("กรุณาใส่ค่า Min น้อยกว่าค่า Max ")
                                                                        this.setState({ EngagementMin: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Max</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="100" value={this.state.EngagementMax}
                                                            onChange={async e => {
                                                                let max = e.target.value.trim()
                                                                await this.setState({ EngagementMax: max, selectButton: true })
                                                            }}
                                                            onBlur={e => {
                                                                let max = e.target.value.trim()
                                                                if (this.state.EngagementMax) {
                                                                    if (parseInt(max) < parseInt(this.state.EngagementMin)) {
                                                                        alert("กรุณาใส่ค่า Max มากกว่าค่า Min ")
                                                                        this.setState({ EngagementMax: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <div className="textSize12 text-secondary">Average like (%)</div>
                                            {/* <Slider
                                                range
                                                step={1}
                                                defaultValue={this.state.Average}
                                                value={this.state.Average}
                                                min={0}
                                                max={10000000}
                                                onChange={this.onChangeAverage}
                                            />
                                            <Row>
                                                <Col>
                                                    <span className="font-12">{helper.kFormath(this.state.Average[0])}</span>
                                                </Col>
                                                <Col className="text-right">
                                                    <span className="font-12">{helper.kFormath(this.state.Average[1])}</span>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className="pr-0">
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Min</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="0" value={this.state.AverageMin}
                                                            onChange={e => this.setState({ AverageMin: e.target.value.trim(), selectButton: true })}
                                                            onBlur={e => {
                                                                let min = e.target.value.trim()
                                                                if (this.state.AverageMin && this.state.AverageMax) {
                                                                    if (parseInt(min) > parseInt(this.state.AverageMax)) {
                                                                        alert("กรุณาใส่ค่า Min น้อยกว่าค่า Max ")
                                                                        this.setState({ AverageMin: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label className="textSize12 text-secondary mb-1">Max</Form.Label>
                                                        <InputMask mask="999999999" maskChar="" className="form-control h-sale textSize12" placeholder="10000000" value={this.state.AverageMax}
                                                            onChange={async e => {
                                                                let max = e.target.value.trim()
                                                                await this.setState({ AverageMax: max, selectButton: true })
                                                            }}
                                                            onBlur={e => {
                                                                let max = e.target.value.trim()
                                                                if (this.state.AverageMax) {
                                                                    if (parseInt(max) < parseInt(this.state.AverageMin)) {
                                                                        alert("กรุณาใส่ค่า Max มากกว่าค่า Min ")
                                                                        this.setState({ AverageMax: '' })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Collapse>

                                    <Row className="magin-btn-side pb-3">
                                        {this.state.selectButton ?
                                            <>
                                                <Col>
                                                    <Button className="btn-search" onClick={() => this.SearchIdol()}>Search</Button>
                                                </Col>
                                                <Col>
                                                    <Button className="btn btn-secondary" onClick={() => this.resetSearch()}>Reset</Button>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <Col>
                                                    <Button className="btn-war">Search</Button>
                                                </Col>
                                                <Col>
                                                    <Button className="btn-reset">Reset</Button>
                                                </Col>
                                            </>
                                        }
                                    </Row>

                                </div>
                            </>}
                            <div className={sidebar} onClick={this.handleViewSidebar}>
                                {this.state.sidebarOpen ?
                                    <FaChevronCircleLeft /> : <FaChevronCircleRight />
                                }
                            </div>
                            <div className="w-100 childen-table">
                                {this.props.children}
                            </div>
                        </Col>
                    </Row>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    // console.log('state', state)
    return {
        searchIdol: state.searchIdol,
        login: state.login,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loading: value => dispatch(loading(value)),
        addDatasearch: value => dispatch(addDatasearch(value)),
        delectDataSearch: value => dispatch(delectDataSearch(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));