import React from 'react'
import { connect } from 'react-redux';
import {Button,} from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight, FaCalendarAlt } from "react-icons/fa";
import { withTranslation } from 'react-i18next';
import DatePicker, { registerLocale }  from "react-datepicker";
import Th from 'date-fns/locale/th';
import En from 'date-fns/locale/en-US';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import range from "lodash/range";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

const years = range(1970, getYear(new Date()) + 1, 1);
const months = [
  {en:"January",th:"Jan"},
  {en:"February",th:"Feb"},
  {en:"March",th:"Mar"},
  {en:"April",th:"Apr"},
  {en:"May",th:"May"},
  {en:"June",th:"Jun"},
  {en:"July",th:"Jul"},
  {en:"August",th:"Aug"},
  {en:"September",th:"Sep"},
  {en:"October",th:"Oct"},
  {en:"November",th:"Nov"},
  {en:"December",th:"Dec"}
];
const MySwal = withReactContent(Swal);

const buttonStyle = {
    height:"2em", padding:"0 .25em"
}

const selectStyle = {
    borderRadius:"5px", padding:"0 .5em"
}

class CustomInput extends React.Component {
    handleValue = (date) => {
      if (date) {
          // const d = date.split("-");
          // const tDate = new Date(d[2]+' '+d[1]+' '+d[0]);
          // let dd = ((tDate.getDate() < 10)? '0' : '').concat(tDate.getDate());
          // let MM = (((tDate.toLocaleString('en-us', { month: 'short' })) < 10)? '0' : '').concat(tDate.toLocaleString('en-us', { month: 'short' }));
          // let yyyy = Number(tDate.getFullYear());
          
      //     let dd = (moment(date).get("date") < 10 ? '0' : '') + moment(date).get("date");
      // let MM = ((moment(date).get("month") + 1) < 10 ? '0' : '') + (moment(date).get("month") + 1);
      // let yyyy = moment(date).get("year");
      // console.log('handValue', date, dd, MM, yyyy);
          // return dd+' '+MM+' '+yyyy;
          const dateOptions = {
            day: "numeric",
            month: "short",
            year: "numeric"
          }
          return new Intl.DateTimeFormat('en-GB', dateOptions).format(new Date(date));
      } else {
        return ""
      }
    }
  
    render() {
      // console.log("gggggg",this.props);
      return (
        <input placeholder="01 Jan 2020" 
        disabled={this.props.disabled}
        className={this.props.className} name={this.props.name} onClick={this.props.onClick} value={this.handleValue(this.props.value)} onChange={()=>{}} readOnly/>
      );
    }
  }

class CustomerDatePicker extends React.Component {
   
    constructor(props) {
        super(props);
        this.state = {
            date: ''
        }
    }

    componentDidMount(){
      const { value } = this.props;
      if (value) {
        //const time = moment(value).format('DD-MM-YYYY')
        const time = new Date(value)
        if (time instanceof Date && !isNaN(time)) {
          this.setState({date: time})
        } else {
          console.log("props value is invalid time.");
        }
      }
    }

    async componentDidUpdate(prevProps) {
      if (this.props.value != prevProps.value) {
        const { value } = await this.props;
        const time = await new Date(value)
        if (time instanceof Date && !isNaN(time)) {
          await this.setState({date: time})
        } else {
          console.log("props value is invalid time.");
        }
      }
    }

    

    onDatepickerRef = (el) => {
        if (el && el.input) {
          el.input.readOnly = true;
        }
    }

    handleMonth = (date) => {
        const { t, i18n } = this.props;
        // if (i18n.language == 'th') {
        //     return months.filter((item,index) => {
        //         return index == moment(date).get("month");
        //     }).map(ele => ele.th)[0];
        // } else {
        //     return months.filter((item,index) => {
        //         return index == moment(date).get("month");
        //     }).map(ele => ele.en)[0];
        // }
        return months.filter((item,index) => {
            return index == moment(date).get("month");
        }).map(ele => ele.en)[0];
    }

    setDate = (date) => {
      let dd = (moment(date).get("date") < 10 ? '0' : '') + moment(date).get("date");
      let MM = ((moment(date).get("month") + 1) < 10 ? '0' : '') + (moment(date).get("month") + 1);
      let yyyy = moment(date).get("year");
      return dd+' '+MM+' '+yyyy;
    }

    dateChange = (date) => {

        let dd = (moment(date).get("date") < 10 ? '0' : '') + moment(date).get("date");
        // let MM = (((date.toLocaleString('en-us', { month: 'short' })) < 10)? '0' : '').concat(date.toLocaleString('en-us', { month: 'short' }));
        let MM = ((moment(date).get("month") + 1) < 10 ? '0' : '') + (moment(date).get("month") + 1);
        let yyyy = moment(date).get("year");
        this.setState({
            date: date
        });
        // this.props.onChange(dd+'-'+MM+'-'+yyyy)
        this.props.onChange(yyyy+'-'+MM+'-'+dd)
    }

    render() {
        
        const { t, i18n, className, name, placeholder,disabled } = this.props;
        
        return (
            <DatePicker 
                  placeholder={placeholder} 
                  onChange={this.dateChange}
                  maxDate={new Date()}
                  selected={this.state.date} 
                  className={className}
                  disabled={disabled}
                  name={name}
                  dateFormat="yyyy-MM-dd"
                  locale={En}
                  ref={el => this.onDatepickerRef(el)} 
                  customInput={ <CustomInput /> }
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                  }) => (
                    <div
                      style={{
                        margin: 5,
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <Button style={buttonStyle}
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        <FaChevronLeft/>
                      </Button>
                      <span style={{fontSize:"1.5em"}}>{this.handleMonth(date)}</span>
                      <select style={selectStyle}
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {/* {getYear(date)+543} */}
      
                      
                      <Button style={buttonStyle}
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        <FaChevronRight/>
                      </Button>
                    </div>
                  )}
                />
        )
    }
  }

export default withTranslation(["home"])(CustomerDatePicker);