import React, { Component } from 'react';
import { Alert, Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loading, addLogin } from '../redux/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../utils/apis';
import JwtDecode from 'jwt-decode';
import InputMask from 'react-input-mask';

const API = api.getAPI();
const MySwal = withReactContent(Swal)

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            alertShow: false,
            message: '',
        };
    }


    toLogin = async (e) => {
        const { history } = this.props;
        e.preventDefault();

        this.props.loading(true);

        let data = {
            username: this.state.username,
            password: this.state.password
        }

        let res;
        const params = JSON.stringify({
            "username": data.username,
            "password": data.password,
        });

        try {
            res = await API.login(params)
            // console.log("response", res);
        } catch (error) {
            res = error.response
        }
        this.props.loading(false);
        if (res && res.status != 500) {
            if (res.status === 201) {
                // console.log('data', res.data);
                // let data = JwtDecode
                let data = { token: res.data.accessToken, data: JwtDecode(res.data.accessToken) }
                this.props.addLogin(data);
                // this.props.addLogin();
                // console.log("login", this.props)
                this.props.history.push("/idol-list/all-product")
            } else {
                if (res.status === 401) {
                    console.log(res.data.message)
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.data.message
                    })
                }
                if (res.status === 400) {
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.data.message
                    })
                }
            }
        }
    }

    render() {
        return (
            <>
                <Container className="con-h paddin-log">
                    <div onSubmit={this.toLogin}>
                        <Row className="justify-content-center">
                            <Col md={5}>
                                <h4 className="text-bold">Log in to your account</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={5}>
                                <h6>Welcome back, log in with your account to continue managing your influencers information.</h6>
                            </Col>
                        </Row>
                        {/* <Row className="justify-content-center mt-2">
                            <Col md={5}>
                                <Form.Label>Country code</Form.Label>
                                <Select
                                    placeholder="Thailand +66"
                                />
                            </Col>
                        </Row> */}
                        <Row className="justify-content-center mt-3">
                            <Col md={5}>
                                <Alert show={this.state.alertShow} variant="danger">
                                    {this.state.message}
                                </Alert>
                                <Form className="">
                                    <Form.Group>
                                        <Form.Label>Username (7 digits number)</Form.Label>
                                        {/* <Form.Control type="number" placeholder="Username (7 digits number)" maxLength={7} onChange={e => this.setState({ username: e.target.value })} /> */}
                                        <InputMask mask="9999999" maskChar="" className="form-control" placeholder="Username (7 digits number)" value={this.state.username}
                                            onChange={e => this.setState({ username: e.target.value })}
                                        />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={5}>
                                <Form className="">
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter your password" onChange={e => this.setState({ password: e.target.value })} />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={5}>
                                <Button type="submit" className="btn btn-dark w-100" onClick={this.toLogin}>Log in</Button>
                            </Col>
                        </Row>
                        {/* <Row className="justify-content-text mt-3 mb-3">
                            <Col md={5}>
                                <label className="text-left">Don't have an account yet? <span className="text-primary">Create here</span></label>
                            </Col>
                        </Row> */}
                        <Row className="justify-content-text mt-3 mb-3">
                            <Col md={5}>
                                <a href="/resetpassword"><span className="text-primary">Change your password</span></a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        loading: value => dispatch(loading(value)),
        addLogin: value => dispatch(addLogin(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));