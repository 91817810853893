import React from 'react';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button, Form, Table, Dropdown, Navbar, Nav, Tabs, Tab, Badge, InputGroup, FormControl, ListGroup, OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { MdKeyboardBackspace, MdUnfoldMore, MdTune, MdClear, MdStar, MdFavorite, MdDoneAll } from "react-icons/md";
import { RiCheckboxBlankCircleFill, RiChat3Fill, RiDeleteBin7Line, RiTreasureMapLine } from "react-icons/ri";
import { FaFileAlt, FaImage, FaFileVideo } from "react-icons/fa"
import { BsFileEarmarkCheck } from "react-icons/bs"
import { matchPath } from "react-router-dom";
import { IoMdCalendar } from "react-icons/io";
import Dropzone from 'react-dropzone'
import Rating from 'react-rating'
import InputMask from 'react-input-mask';
import Select from 'react-select'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
// import DatePicker from "react-datepicker";
import DatePicker from "../components/datepicker-en";
import SelectForm from "../components/SelectForm";
import "react-datepicker/dist/react-datepicker.css";
import { loading, checkLogin, addLogin, modalSale, addidolId, setIsEditing, checkToken } from '../redux/actions';
import api from '../utils/apis';
import helper from "../utils/helper"
import IdolSocailProfile from '../components/IdolSocialProfile';
import ModalManageProduct from '../components/ModalManageProduct';
import ModalStatus from '../components/ModalStatus';
import axios from 'axios';
import AWS from "aws-sdk";
// import { readFileSync } from 'fs';
import * as fs from 'fs';
const API = api.getAPI();
const MySwal = withReactContent(Swal)

const CustomToggleFilter = React.forwardRef(({ children, onClick }, ref) => (
    <a href="" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }} className="normalLink">{children} <MdTune /></a>
));
const CustomToggleSort = React.forwardRef(({ children, onClick }, ref) => (
    <a href="" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }} className="normalLink">{children} <MdUnfoldMore /></a>
));
const TypeFiles = "image/*,.ppt,.pptx,file_extension,audio/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,text/plain,media_type,application/pdf,application/vnd.ms-excel,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/aac,application/x-abiword,application/x-freearc,video/x-msvideo,application/vnd.amazon.ebook,application/octet-stream,image/bmp,application/x-bzip,application/x-bzip2,application/x-csh,text/css,text/csv,application/vnd.ms-fontobject,application/epub+zip,application/gzip,text/html,image/vnd.microsoft.icon,text/calendar,application/java-archive,audio/mpeg,video/mpeg,audio/ogg,video/ogg,application/vnd.rar,audio/wav,audio/webm,video/webm,video/mp2t,image/gif,text/javascript,application/json,application/ld+json,audio/midi,audio/x-midi,application/vnd.apple.installer+xml,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/ogg,audio/opus,font/otf,application/x-httpd-php,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,application/x-sh,image/svg+xml,application/x-shockwave-flash,application/x-tar,font/ttf,application/vnd.visio,font/woff,font/woff2,application/xhtml+xml,application/xml,text/xml,application/vnd.mozilla.xul+xml,application/zip,video/3gpp,audio/3gpp,video/3gpp2,audio/3gpp2,application/x-7z-compressed,application/x-rar-compressed,application/x-zip-compressed,multipart/x-zip"

const doc = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/x-sh',
    'application/rtf',
    'application/vnd.ms-powerpoint',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'text/calendar',
    'text/html',
    'application/vnd.ms-fontobject',
    'application/epub+zip',
    'application/gzip',
    'application/msword',
    'text/css',
    'text/csv',
    'application/vnd.amazon.ebook',
    'application/octet-stream',
    'text/javascript',
    'application/json',
    'application/ld+json',
    'application/vnd.apple.installer+xml',
    'application/ogg',
    'font/otf',
    'application/x-httpd-php',
    'application/vnd.rar',
    'application/x-shockwave-flash',
    'application/x-tar',
    'font/ttf',
    'application/vnd.visio',
    'font/woff',
    'font/woff2',
    'application/xhtml+xml',
    'application/vnd.ms-excel',
    'application/xml',
    'text/xml',
    'application/vnd.mozilla.xul+xml',
    'application/zip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/x-zip-compressed',
    'multipart/x-zip'
];
const image = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/tiff',
    'image/svg+xml',
    'image/bmp',
    'image/vnd.microsoft.icon',
    'crt',
    'pem'];
const video = [
    'video/mp4',
    'audio/webm',
    'video/webm',
    'audio/wav',
    'video/ogg',
    'audio/ogg',
    'video/mpeg',
    'audio/mpeg',
    'audio/midi',
    'audio/x-midi',
    'video/x-msvideo',
    'audio/aac',
    'audio/opus',
    'video/mp2t',
    'video/3gpp',
    'audio/3gpp',
    'video/3gpp2',
    'audio/3gpp2',
];

function readFile(file) {
    return new Promise((resolve) => {
        let reader = new FileReader();
        reader.addEventListener('load', () => { resolve(reader.result); });
        reader.readAsDataURL(file);
        // console.log('aaaaaaaaa', file)
    })
    // return fs.readFileSync(file);
}

class EditIdol extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _isMounted: false,

            onEdit: true,
            selectButton: false,
            back: false,

            languageT: false,
            attractPoint: false,
            occupaTion: false,
            reputaTion: false,
            workType: false,

            selectedLan: false,
            selectedAtt: false,
            selectedOcc: false,
            selectedRep: false,
            selectedWrk: false,

            SelectList: false,
            modalManage: false,

            country: '',
            region: '',

            idolId: '',
            profile_pic: '',
            profile_alias: '',
            profile_full_name: '',
            profile_ig_username: '',
            profile_fb_username: '',
            profile_display_name: '',
            profile_id: '',
            productInfo: [],

            profileData: '',

            form: {
                sales_name: '',
                creator_name: '',
            },
            salesName: '',
            saleCreator: '',
            saleCreatedDate: '',

            ig_follower_count: '',
            ig_engagement_rate: '',
            ig_growth_rate: '',
            ig_average_like: '',

            fb_engagement_rate: '',
            fb_follower_count: '',
            fb_growth_rate: '',

            infoName: '',
            infoGender: '',
            infoDateBirth: '',
            // infoCountry: '',
            // infoCity: '',
            infoAlias: '',
            infoHeight: 0,
            infoWeight: 0,
            infoRating: 0,
            // infoAPText: '',
            // infoAttractive_point: '',
            infoAttractivepoint: [],
            // infoLgText: '',
            infoLanguage: [],
            // infoOpText: '',
            infoOccupation: [],
            // infoRtText: '',
            infoReputation: [],
            // infoWtText: '',
            infoWorktype: [],
            education_level: [],
            education_institute: [],

            infoLog: [],

            joinDate: '',
            joinReferal: '',
            contactPhone: '',
            contactLine: '',
            contactEmail: '',
            manageName: '',
            managePhone: '',
            manageLine: '',
            remark: '',

            socialFace: '',
            socialTik: '',
            socialTwit: '',
            // infoEduText: '', 
            // infoEduLevText: '',

            filesUpload: [],
            uploadPercentage: [],
            commentFiles: '',

            recentIgList: [],
            recentFbList: [],

            tabProfile: 'general',
            tabRemark: 'remark',
            filterRemark: 'All product',
            sortRemark: 'Newest first',
            sortFile: 'Newest first',

            filterIdolPhone: 'ยังไม่ติดต่อ',

            checklistVdo: false,
            checklistPictur: false,
            checklistDetail: false,

            starsSelectValue: [],
            optionStar: [],
            backPage: '',
            buttonCheck: false,
            dataProfile: [],
            allProduct: [],
            pagination: {
                currentPage: 1,
                itemCount: 0,
                itemsPerPage: 1,
                totalItems: 0,
                totalPages: 1,
            },
            currentSort: {
                currentPage: 1,
                sortField: "",
                sortOrder: "ASC"
            },
            idolType: 'idols',
            product_code: "",
            workflow_code: "",
            // infoAPTextOption: [],
            // infoAPTextSelect: [],
            testData: [
                { label: "thailand", value: 1 },
                { label: "japan", value: 2 },
                { label: "korea", value: 3 },
                { label: "England", value: 4 }
            ],

            dropFile: [],
            leaveWarning: 'ข้อมูลบางอย่างที่คุณแก้ไขไว้ยังไม่ได้ถูกบันทึก คุณยืนยันที่จะออกจากหน้านี้หรือไม่?',

            workflows_checklist: [],
            checklist_data: [],

            idolTabsList: [],
            workflows_list_tabs: [],
            available_workflow_statuses: [],

            modalChangeStatusEdit: false,
            tabCode: '',
            type: 'edit',
            invalidMsg: {
                uemail: "",
            },
        };
    }


    async componentDidMount() {
        const _this = this;
        this.unlisten = this.props.history.listen((location) => {
            // console.log("object", location)
        })

        const { match: { params } } = this.props
        if (this.props.login) {

        }
        if (this.props.location.search) {
            this.setState({ backPage: this.props.location.search })
            // console.log(this.state.backPage)
        }
        // console.log("props", this.props)
        await this.props.checkLogin();
        await this.setProfile()
        await this.optionStar()
        await this.getIdolList();

        // const match = matchPath(`/ggggggg`, {
        //     path: "/edit-idol/:id",
        //     exact: true,
        //     strict: false
        // });

        // if(match === null){
        //     alert()
        // }
        // console.log('backPage', match)
    }


    async componentDidUpdate(prevProps, prevState) {
        const _this = this;
        if (this.props.login != prevProps.login && this.props.login) {

        }

        if (this.props.isEditing) {
            window.onbeforeunload = (e) => {
                return _this.state.leaveWarning;
            }
        } else {
            window.onbeforeunload = undefined
        }
        // let match = matchPath('/edit-idol/249?editTab1');
    }

    componentWillUnmount() {
        // (alert())
        this.props.setIsEditing(false);
        window.onbeforeunload = undefined;
    }


    optionStar = () => {
        this.setState({
            optionStar: [
                {
                    value: 1,
                    label: <div>
                        <Rating
                            placeholderRating={1}
                            emptySymbol={<MdStar color="#E0E0E0" />}
                            placeholderSymbol={<MdStar color="#E6AF2E" />}
                            readonly={true}
                        />{'   '}(1 Stars)
                    </div>
                },
                {
                    value: 2,
                    label: <div>
                        <Rating
                            placeholderRating={2}
                            emptySymbol={<MdStar color="#E0E0E0" />}
                            placeholderSymbol={<MdStar color="#E6AF2E" />}
                            readonly={true}
                        />{'   '}(2 Stars)</div>
                },
                {
                    value: 3,
                    label: <div>
                        <Rating
                            placeholderRating={3}
                            emptySymbol={<MdStar color="#E0E0E0" />}
                            placeholderSymbol={<MdStar color="#E6AF2E" />}
                            readonly={true}
                        />{'   '}(3 Stars)
                    </div>
                },
                {
                    value: 4,
                    label: <div>
                        <Rating
                            placeholderRating={4}
                            emptySymbol={<MdStar color="#E0E0E0" />}
                            placeholderSymbol={<MdStar color="#E6AF2E" />}
                            readonly={true}
                        />{'   '}(4 Stars)</div>
                },
                {
                    value: 5,
                    label: <div>
                        <Rating
                            placeholderRating={5}
                            emptySymbol={<MdStar color="#E0E0E0" />}
                            placeholderSymbol={<MdStar color="#E6AF2E" />}
                            readonly={true}
                        />
                        {'   '}(5 Stars) </div>
                },
            ],
        })
    }

    checkProfile = (ntab) => {
        const tabidol = ntab;
        const { location, history } = this.props;
        history.goBack();

    }

    getIdolList = async () => {
        this.props.loading(true);
        const { match: { params }, history } = this.props;
        let response;
        const { product_code, workflow_code, pagination, currentSort, idolType } = this.state;
        try {
            response = await API.getIdols('ALL_PRODUCT', currentSort.currentPage, pagination.itemsPerPage, 1, idolType)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        // console.log("get IdolList", response.data)  
        this.props.loading(false);
        if (response && response.status != 500) {
            if (response.status == 200) {
                await this.setState({ allProduct: response.data.idols_list_tabs })
            } else {
                console.log(response.data.message)
            }
        }
    }

    setProfile = async () => {
        const { history } = this.props;
        this.props.loading(true);
        let response;
        try {
            response = await API.getIdol(this.props.match.params.id)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        // console.log("getIdolList", response)

        if (response && response.status != 500) {
            this.props.loading(false);
            if (response.status == 200) {
                // console.log('get idol data', response.data)
                let data = response.data;
                if (this.state.optionStar.length == 0) {
                    await this.optionStar()
                }
                this.state.optionStar.map(item => {
                    if (item.value == data.beauty_level) {
                        this.setState({
                            starsSelectValue: {
                                value: data.beauty_level,
                                label: item.label
                            }
                        })
                    }
                })
                let attractive_point = [];
                if (data.available_attractive_point.length > 0) {
                    data.available_attractive_point.forEach(element => {
                        attractive_point.push({ value: element.id, label: element.name })
                    });
                }
                let dateBirth = '';
                if (data.date_of_birth) {
                    dateBirth = moment(data.date_of_birth).format('yyyy-MM-DD');
                    var date_birth = helper.setDateFormat(dateBirth)
                }

                // ============Group array=================//
                let dataFIle = [];
                data.files.map((item) => {
                    // let time = moment(item.updated_at).format("DD/MM/yyyy HH:mm")
                    let time = moment(item.updated_at).format("DD/MM/yyyy HH:mm")
                    // console.log(‘datafileeeeeeeeeee : ‘,item.updated_username,time)
                    if (dataFIle.length == 0) {
                        dataFIle.push({
                            upadteUser: item.updated_username,
                            updatedAt: time,
                            updatedCreate: item.updated_at,
                            data: [item]
                        })
                    } else {
                        let same = false
                        let indexSame = 0
                        dataFIle.map((item2, index) => {
                            if (item2.upadteUser == item.updated_username && item2.updatedAt == time) {
                                indexSame = index
                                same = true
                            } else {
                                same = false
                            }
                        })
                        if (same) {
                            dataFIle[indexSame].data.push(item)
                        } else {
                            dataFIle.push({
                                upadteUser: item.updated_username,
                                updatedAt: time,
                                updatedCreate: item.updated_at,
                                data: [item]
                            })
                        }
                    }
                })
                // ============Group array=================//

                this.setState({
                    sortRemark: 'Newest first',
                    sortFile: 'Newest first',
                    profileData: response.data,
                    profile_id: data.id,
                    // infoAPTextOption: attractive_point,
                    dataProfile: Object.assign(this.state.dataProfile, {}, data),
                    profile_pic: data.profile_pic_url,
                    profile_alias: data.alias,
                    profile_full_name: data.full_name,
                    profile_ig_username: data.ig_username,
                    profile_fb_username: data.fb_username,
                    profile_display_name: data.display_name,
                    productInfo: data.products_info,

                    sale_pic: data.sales_image_url,
                    salesName: data.sales_name,
                    saleCreator: data.creator_name,
                    saleCreatedDate: '22 September 2020',

                    ig_follower_count: data.ig_follower_count,
                    ig_engagement_rate: data.ig_engagement_rate.toFixed(1),
                    ig_growth_rate: data.ig_growth_rate.toFixed(1),
                    ig_average_like: data.ig_average_like,

                    fb_engagement_rate: data.fb_engagement_rate.toFixed(1),
                    fb_follower_count: data.fb_follower_count,
                    fb_growth_rate: data.fb_growth_rate.toFixed(1),

                    infoName: data.full_name,
                    infoGender: data.gender,

                    infoDateBirth: data.date_of_birth ? date_birth : data.date_of_birth,
                    // infoCountry: data.country,
                    // infoCity: data.city,
                    // country: data.country,
                    // region: data.city,
                    filesUpload: dataFIle,
                    infoAlias: data.alias,
                    infoHeight: data.height,
                    infoWeight: data.weight,
                    infoRating: data.beauty_level,
                    // infoAttractive_point: '',
                    // infAttractivepoint: data.available_attractive_point,
                    // infLanguage: data.available_language,
                    // infOccupation: data.available_occupation,
                    // infReputation: data.available_reputation,
                    // infWorktype: data.available_work_type,
                    infoAttractivepoint: data.attractive_point === null ? [] : data.attractive_point,
                    infoLanguage: data.language === null ? [] : data.language,
                    infoOccupation: data.occupation === null ? [] : data.occupation,
                    infoReputation: data.reputation === null ? [] : data.reputation,
                    infoWorktype: data.work_type === null ? [] : data.work_type,
                    education_level: data.education_level === null ? [] : data.education_level,
                    education_institute: data.education_institute === null ? [] : data.education_institute,
                    infoLog: data.logs,

                    joinDate: '17 Aug 2020',
                    joinReferal: 'From job webboard',
                    contactPhone: data.contact_phone_number,
                    contactLine: data.contact_line_id,
                    contactEmail: data.contact_email === null ? '' : data.contact_email,
                    manageName: data.manager_full_name,
                    managePhone: data.manager_phone_number,
                    manageLine: data.manager_line_id,
                    socialFace: data.facebook_info,
                    socialTik: data.tiktok_info,
                    socialTwit: data.twitter_info,
                    country: data.country,
                    region: data.city,
                    recentIgList: [
                        { like: 21, comment: 380, url: `${window.location.origin}/image/recent01.jpg` },
                        { like: 40, comment: 300, url: `${window.location.origin}/image/recent02.jpg` },
                        { like: 80, comment: 543, url: `${window.location.origin}/image/recent03.png` },
                        { like: 21, comment: 380, url: `${window.location.origin}/image/recent01.jpg` },
                        { like: 40, comment: 300, url: `${window.location.origin}/image/recent02.jpg` },
                        { like: 80, comment: 543, url: `${window.location.origin}/image/recent03.png` },
                        { like: 21, comment: 380, url: `${window.location.origin}/image/recent01.jpg` },
                        { like: 40, comment: 300, url: `${window.location.origin}/image/recent02.jpg` },
                        { like: 80, comment: 543, url: `${window.location.origin}/image/recent03.png` },
                    ],
                    recentFbList: [
                        { like: 21, comment: 380, url: `${window.location.origin}/image/recent01.jpg` },
                        { like: 40, comment: 300, url: `${window.location.origin}/image/recent02.jpg` },
                        { like: 80, comment: 543, url: `${window.location.origin}/image/recent03.png` },
                    ],
                })
                this.setState({ buttonCheck: false })
                this.props.setIsEditing(false);
            } else {
                this.props.loading(false);
                console.log(response.data.message)
            }
        }
        this.props.loading(false);


    }

    addRemark = async () => {
        // console.log('re', this.state.remark)
        const { history } = this.props;
        let response;
        const params = await JSON.stringify({
            "remark": this.state.remark.trim(),
        });
        try {
            response = await API.addRemark(this.props.match.params.id, params)
            // console.log("response", response);
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        if (response && response.status != 500) {
            if (response.status == 201) {
                await this.setState({ remark: '', sortRemark: 'Newest first' })
                MySwal.fire({
                    icon: "success",
                    title: "Success",
                }).then((value) => {
                    this.setProfile();
                });
            }
        }
    }

    selectCountry = async (val) => {
        await this.setState({ country: val });
    }

    selectRegion = async (val) => {
        await this.setState({ region: val });
    }

    onHide = (cancel) => {
        // console.log('cancel',cancel)
        if (cancel === 'cancelMulti') {
            this.setState({
                modalManage: false,
                modalChangeStatusEdit: false
            })
        } else {
            this.setState({
                modalManage: false,
                modalChangeStatusEdit: false
            })
        }
    };


    uploadFiles = async (files) => {
        // console.log('file', file);
        const { history } = this.props;
        let params;
        files.map(async (item, index) => {
            // console.log('item', item);
            params = JSON.stringify({
                "content_type": item.type,
                "filename": item.name,
                "file_size": item.size,
            });

            // let data = await readFile(item);
            let response;
            try {
                response = await API.uploadFiles(this.props.match.params.id, params, item).then(async (response) => {
                    await this.renderAxios(response, item, index)
                })
            } catch (error) {
                response = error.response
                const token = { response, history }
                this.props.checkToken(token);
            }
        })

    }

    renderAxios = async (response, data, index) => {
        let newUploadPercentage = [...this.state.uploadPercentage]
        const option = await {
            onUploadProgress: (progressEvent) => {
                let { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`)
                if (percent < 100) {
                    newUploadPercentage[index].progress = percent
                    // this.setState({ uploadPercentage: percent })
                    // console.log('percentArry if 0000000000', newUploadPercentage)
                    this.setState({ uploadPercentage: newUploadPercentage })
                }
            },
            headers: {
                "Content-Type": response.data.content_type,
            }
        }

        axios.put(response.data.upload_link, data, option).then(res => {
            // console.log(res);
            newUploadPercentage[index].progress = 100
            this.setState({ uploadPercentage: newUploadPercentage })
            // () => {
            //     setTimeout(() => {
            //         this.setState({ uploadPercentage: [], dropFile: '' })
            //         this.setProfile();
            //     }, 1000);
            // })
            let countcomplet = 0;
            newUploadPercentage.map((item) => {
                if (item.progress === 100) {
                    countcomplet = countcomplet + 1
                }
            })
            if (countcomplet === newUploadPercentage.length) {
                this.setState({ uploadPercentage: [], dropFile: '', sortFile: 'Newest first' })
                this.setProfile();
            }
        })
    }

    deleteUploadFile = async (fullName) => {
        // console.log(fullName);
        const { history } = this.props;
        let data = JSON.stringify({
            "full_path_filename": fullName
        })
        let response;
        MySwal.fire({
            icon: 'warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true,
            text: 'Are you confirm to delete the file?'
        }).then(async (result) => {
            if (result.value) {
                try {
                    response = await API.deleteFiles(this.props.match.params.id, data)
                    // console.log("response", response);
                } catch (error) {
                    response = error.response
                    const token = { response, history }
                    this.props.checkToken(token);
                }
                if (response && response.status != 500) {
                    if (response.status == 200) {
                        this.setProfile();
                        this.setState({ sortFile: 'Newest first' })
                    }
                }
            }
        });

    }

    commentFile = async (pathname) => {
        const { history } = this.props;
        let data = JSON.stringify({
            "full_path_filename": pathname,
            "comment": this.state.commentFiles
        })
        let response;
        try {
            response = await API.commentFiles(this.props.match.params.id, data)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        if (response && response.status != 500) {
            if (response.status == 201) {
                // this.setProfile();
            }
        }
    }

    // การแสดงขอบแดง //
    removeInvalid = (element) => {
        const x = document.querySelector(`input[name='${element}']`);
        x.classList.remove("is-invalid");
        // x.nextSibling.classList.add("invalid-hidden");
        let invalid = this.state.invalidMsg;
        invalid[element] = "";
        this.setState({ invalid });
    }

    addInvalid = (element, message) => {
        const x = document.querySelector(`input[name='${element}']`);
        x.classList.add("is-invalid");
        // x.nextSibling.classList.remove("invalid-hidden");
        let invalid = this.state.invalidMsg;
        invalid[element] = message;
        this.setState({ invalid });
    }

    //ตรวจสอบข้อมูล
    validate = () => {
        let validated = true;
        const { contactEmail } = this.state;

        if (contactEmail.trim() !== "" && !helper.checkEmailFormat(contactEmail.trim())) {
            this.addInvalid('uemail', 'กรุณาระบุรูปแบบอีเมลให้ถูกต้อง');
            validated = false;
        }

        return validated;
    }

    editIdol = async () => {
        if (this.validate()) {
            this.props.loading(true);
            const { location, history } = this.props;
            // console.log("object",this.state.contactPhone)
            let date_birth;
            if (this.state.infoDateBirth) {
                date_birth = await moment(this.state.infoDateBirth).format('YYYYMMDD');
                // console.log(date);
                // var date_birth = date;
                // var date_birth = await helper.setDateData(date);

            }
            let gender;
            if (this.state.infoGender) {
                gender = this.state.infoGender
            }

            let mng_phone = "";
            if (this.state.managePhone) {
                mng_phone = this.state.managePhone.includes('66-') ? this.state.managePhone : '66-' + this.state.managePhone
            }

            let cnt_phone = "";
            if (this.state.contactPhone) {
                cnt_phone = this.state.contactPhone.includes('66-') ? this.state.contactPhone : '66-' + this.state.contactPhone
            }

            // console.log('date', date_birth)
            let response;
            const params = JSON.stringify({
                "date_of_birth": date_birth,
                "attractive_point": this.state.infoAttractivepoint,
                "language": this.state.infoLanguage,
                "occupation": this.state.infoOccupation,
                "reputation": this.state.infoReputation,
                "work_type": this.state.infoWorktype,
                "education_level": this.state.education_level,
                "education_institute": this.state.education_institute,
                "manager_phone_number": mng_phone,
                "contact_phone_number": cnt_phone,
                "full_name": this.state.infoName.trim(),
                "gender": gender,
                "country": this.state.country,
                "city": this.state.region,
                "alias": this.state.infoAlias.trim(),
                "height": parseInt(this.state.infoHeight),
                "weight": parseInt(this.state.infoWeight),
                "beauty_level": this.state.starId ? this.state.starId : this.state.infoRating,
                "manager_full_name": this.state.manageName.trim(),
                "manager_line_id": this.state.manageLine.trim(),
                "contact_line_id": this.state.contactLine.trim(),
                "contact_email": this.state.contactEmail.trim(),
                "facebook_info": this.state.socialFace.trim(),
                "tiktok_info": this.state.socialTik.trim(),
                "twitter_info": this.state.socialTwit.trim(),
            });
            // console.log('rr', params); return;
            try {
                response = await API.editIdol(this.props.match.params.id, params)
            } catch (error) {
                // console.log('err', error);
                response = error.response
                const token = { response, history }
                this.props.checkToken(token);
            }
            // console.log("Edit Idol", response)

            if (response && response.status != 500) {
                this.props.loading(false);
                if (response.status == 200) {
                    MySwal.fire({
                        icon: "success",
                        title: "บันทึกการเปลี่ยนแปลงสำเร็จ",
                    }).then((value) => {
                        // this.setState({ buttonCheck: true })
                        this.setProfile();
                        // if (this.state.back) {
                        //     if (this.state.backPage === "?editTab1") {
                        //         // window.location.href = '/idol-list';
                        //         history.goBack();
                        //     } else {
                        //         // window.location.href = '/no-product';
                        //         history.goBack();
                        //     }
                        // }
                    });
                } else {
                    if (response.status == 403) {
                        MySwal.fire({
                            icon: "warning",
                            title: response.data.message,
                        });
                    }
                    
                }
            }
            this.props.loading(false);
        }
    }


    renderGeneral() {
        const {
            salesName, saleCreator, saleCreatedDate, sale_pic,
            infoName, infoGender, infoDateBirth, infoAlias, infoHeight, infoWeight, infoAttractivepoint, infoLanguage, infoOccupation, infoReputation, infoWorktype,
            education_level, education_institute,
            joinDate, joinReferal, contactPhone, contactLine, contactEmail, manageName, managePhone, manageLine,
            recentIgList, recentFbList, country, region, buttonCheck, socialFace, socialTik, socialTwit, profileData
        } = this.state;
        if (this.state.onEdit) {
            var cntPhone = contactPhone.split('66-');
            var manPhone = managePhone.split('66-');

            // console.log('ph', moment(infoDateBirth).format('DD/MM/YYYY'));
            return (
                <>
                    <div className="bg-white rounded p-3">
                        <div className="font-weight-bold font-12">Sales information</div>
                        <Row className="m-0 p-0 mt-1">
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Sales</div>
                                <div className="m-0 p-0 d-flex align-items-center">
                                    {sale_pic ?
                                        <>
                                            <img className="position-pic-sale px-1" src={sale_pic} />
                                            <Form.Control type="text" placeholder="Name" className="pading-sale font-14" value={salesName ? salesName : 'N/A'} disabled={true} />
                                        </>
                                        :
                                        <Form.Control type="text" placeholder="Name" className="font-14" value={salesName ? salesName : 'N/A'} disabled={true} />
                                    }
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Creator</div>
                                <div className="m-0 p-0 d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Name" className="font-14" value={saleCreator ? saleCreator : 'N/A'} disabled={true} />
                                </div>
                            </Col>
                            <Col className="m-0 p-0"></Col>
                        </Row>
                    </div>
                    <div className="bg-white rounded p-3 mt-3">
                        <div className="font-weight-bold font-12">Idol’s information</div>
                        <Row className="m-0 p-0 mt-1">
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Name</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Name" className="font-14"
                                        value={infoName}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            // console.log("eee", value);
                                            await this.setState({ infoName: value })
                                            this.checkButton();
                                        }
                                        }
                                    />
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary font-12">Gender</div>
                                <div className="d-flex align-items-center">
                                    <div className="mb-3">
                                        <Form.Check inline label="Male" type="radio" name="gender" className="font-14" id="male"
                                            value="Male" checked={infoGender === 'Male'}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                await this.setState({ infoGender: value })
                                                this.checkButton()
                                            }
                                            }
                                        />
                                        <Form.Check inline label="Female" type="radio" name="gender" className="font-14" id="female"
                                            value="Female" checked={infoGender === 'Female'}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                await this.setState({ infoGender: value })
                                                this.checkButton()
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Date of birth</div>
                                <Form.Group>
                                    <div className="d-flex align-items-center inner-addon right-addon">
                                        <DatePicker
                                            value={helper.setDateFormat(infoDateBirth)}
                                            className="form-control w-100 font-14 bg-form"
                                            onChange={async (date) => {

                                                let newDate = moment(date).format('YYYY-MM-DD')
                                                // console.log('get on change ', date, newDate)
                                                // let DateString = newDate;
                                                await this.setState({ infoDateBirth: newDate })
                                                this.checkButton()
                                            }}
                                        />
                                        {/* <div className="d-flex justify-content-end align-items-center"> */}
                                            {/* <IoMdCalendar className="icon-item-calender input-icon" size={20} /> */}
                                            <IoMdCalendar className="input-icon" size={20} />
                                        {/* </div> */}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="m-0 p-0 mt-1">
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Country</div>
                                <div className="d-flex align-items-center">
                                    <CountryDropdown
                                        value={country}
                                        className="form-control font-14"
                                        defaultOptionLabel="Select Country"
                                        onChange={async (val) => {
                                            let value = val;
                                            await this.selectCountry(val)
                                            this.checkButton()
                                        }}
                                        whitelist={["TH", "CN", "JP", "VN"]} />
                                    {/* <Form.Control as="select" defaultValue={infoCountry} onChange={e => this.setState({ infoCountry: e.target.value })}>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Thailand">China</option>
                                        <option value="Thailand">Vietname</option>
                                        <option value="Thailand">Japan</option>
                                    </Form.Control> */}
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">City</div>
                                <div className="d-flex align-items-center">
                                    <RegionDropdown
                                        className="form-control font-14"
                                        country={country}
                                        value={region}
                                        blankOptionLabel="Select City"
                                        // onChange={(val) => {
                                        //     this.selectRegion(val)
                                        //     this.setState({ buttonCheck: true })
                                        // }} 
                                        onChange={async (val) => {
                                            let value = val;
                                            await this.selectRegion(val)
                                            this.checkButton()
                                        }} />
                                    {/* <Form.Control as="select" defaultValue={infoCity} onChange={e => this.setState({ infoCity: e.target.value })}>
                                        <option value="Bangkok">Bangkok</option>
                                        <option value="Bangkok">Korat</option>
                                    </Form.Control> */}
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Alias</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Alias" className="font-14" value={infoAlias}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            await this.setState({ infoAlias: value })
                                            this.checkButton()
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-0 p-0 mt-1">
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Height</div>
                                <div className="d-flex align-items-center">
                                    <InputGroup className="mb-3">
                                        <FormControl type="number" placeholder="Height" className="font-14" value={infoHeight !== 0 ? infoHeight : 0}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                await this.setState({ infoHeight: value })
                                                this.checkButton()
                                            }} />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="bg-white font-14">cm</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Weight</div>
                                <div className="d-flex align-items-center">
                                    <InputGroup className="mb-3">
                                        <FormControl type="number" placeholder="Weight" className="font-14" value={infoWeight !== 0 ? infoWeight : 0}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                await this.setState({ infoWeight: value })
                                                this.checkButton()
                                            }} />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="bg-white font-14">kg</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Beauty rating</div>
                                <div className="d-flex align-items-center">
                                    {/* <Form.Control as="select" defaultValue={infoRating} onChange={e => this.setState({ infoRating: e.target.value })}>
                                         <option value="1">1 stars</option>
                                        <option value="2">2 stars</option>
                                        <option value="3">3 stars</option>
                                        <option value="4">4 stars</option>
                                        <option value="5">5 stars</option>
                                    </Form.Control> */}
                                    <Select
                                        className="w-100"
                                        placeholder="Beauty rating"
                                        id="star"
                                        name="star"
                                        value={this.state.starsSelectValue}
                                        options={this.state.optionStar}
                                        onChange={async (value) => {
                                            let star = value;
                                            await this.setState({
                                                starsSelectValue: star,
                                                infoRating: star.value
                                            })
                                            this.checkButton()
                                        }}
                                        isSearchable={true}
                                        isDisabled={false}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="m-0 p-0 mt-1">
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Education levels</div>
                                <SelectForm options={
                                    profileData ? profileData.available_education_level.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Education levels"
                                    insertable
                                    value={education_level.map(item => ({ label: item, value: item }))}
                                    getData={async (data) => {
                                        await this.setState({ education_level: data.map(item => item.value) });
                                        await this.checkButton();
                                    }}
                                />
                            </Col>
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Education institutes</div>
                                <SelectForm options={
                                    profileData ? profileData.available_education_institute.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Education institutes" value={education_institute.map(item => ({ label: item, value: item }))}
                                    insertable
                                    getData={async (data) => {
                                        await this.setState({ education_institute: data.map(item => item.value) });
                                        await this.checkButton()
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="m-0 p-0 mt-2">
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Attractive point</div>
                                <SelectForm options={
                                    profileData ? profileData.available_attractive_point.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Attractive point" value={infoAttractivepoint.map(item => ({ label: item, value: item }))}
                                    insertable
                                    getData={async (data) => {
                                        await this.setState({ infoAttractivepoint: data.map(item => item.value) });
                                        await this.checkButton()
                                    }}
                                />
                            </Col>
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Language</div>
                                <SelectForm options={
                                    profileData ? profileData.available_language.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Language" value={infoLanguage.map(item => ({ label: item, value: item }))}
                                    insertable
                                    getData={async (data) => {
                                        await this.setState({ infoLanguage: data.map(item => item.value) });
                                        await this.checkButton()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="m-0 p-0 mt-2">
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Occupation</div>
                                <SelectForm options={
                                    profileData ? profileData.available_occupation.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Occupation" value={infoOccupation.map(item => ({ label: item, value: item }))}
                                    insertable
                                    getData={async (data) => {
                                        await this.setState({ infoOccupation: data.map(item => item.value) });
                                        await this.checkButton()
                                    }}
                                />

                            </Col>
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Reputation</div>
                                <SelectForm options={
                                    profileData ? profileData.available_reputation.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Reputation" value={infoReputation.map(item => ({ label: item, value: item }))}
                                    insertable
                                    getData={async (data) => {
                                        await this.setState({ infoReputation: data.map(item => item.value) });
                                        await this.checkButton()
                                    }}
                                />

                            </Col>
                        </Row>
                        <Row className="m-0 p-0 mt-2">
                            <Col md={6} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Work type</div>
                                <SelectForm options={
                                    profileData ? profileData.available_work_type.map(item => ({ label: item, value: item })) : []
                                }
                                    placeholder="Work type" value={infoWorktype.map(item => ({ label: item, value: item }))}
                                    insertable
                                    getData={async (data) => {
                                        await this.setState({ infoWorktype: data.map(item => item.value) });
                                        await this.checkButton()
                                    }}
                                />

                            </Col>
                        </Row>

                        {/* <div className="font-weight-bold mt-3 font-12">Joining information</div>
                        <Row className="m-0 p-0 mt-1">
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Approached date</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Approached date" value={joinDate} disabled={true} />
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Referal</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Referal" value={joinReferal}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            await this.setState({ joinReferal: value })
                                            this.checkButton()
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row> */}

                        <div className="font-weight-bold mt-3 font-12">Contact information</div>
                        <Row className="m-0 p-0 mt-1">
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Phone number</div>
                                <div className="d-flex align-items-center">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>+66</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <InputMask mask="999-999-9999" maskChar="" className="form-control" placeholder="Phone Number" value={cntPhone[1] ? cntPhone[1] : contactPhone}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                await this.setState({ contactPhone: value.replace(/\-/gi, "") })
                                                this.checkButton()
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">LINE ID</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="LINE ID" value={contactLine}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            await this.setState({ contactLine: value })
                                            this.checkButton()
                                        }} />
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">E-mail</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="email" name="uemail" placeholder="E-mail" value={contactEmail}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            await this.setState({ contactEmail: value })
                                            this.checkButton()
                                            await this.removeInvalid('uemail');
                                        }} />
                                </div>
                                <div className="invalid-error invalid-hidden">{this.state.invalidMsg.uemail}</div>
                            </Col>
                        </Row>

                        <div className="font-weight-bold mt-3 font-12">Manager information</div>
                        <Row className="m-0 p-0 mt-1">
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Name</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Name" value={manageName}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            await this.setState({ manageName: value })
                                            this.checkButton()
                                        }} />
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Phone number</div>
                                <div className="d-flex align-items-center">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>+66</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <InputMask mask="999-999-9999" maskChar="" className="form-control" placeholder="Phone Number" value={manPhone[1] ? manPhone[1] : managePhone}
                                            onChange={async (e) => {
                                                let value = e.target.value;
                                                await this.setState({ managePhone: value.replace(/\-/gi, "") })
                                                this.checkButton()
                                            }} />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">LINE ID</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="LINE ID" value={manageLine}
                                        onChange={async (e) => {
                                            let value = e.target.value;
                                            await this.setState({ manageLine: value })
                                            this.checkButton()
                                        }} />
                                </div>
                            </Col>
                        </Row>

                        {/* <div className="font-weight-bold mt-3 mb-2 font-12">Permanently delete this profile</div>
                        <Button variant="outline-danger">Delete profile <RiDeleteBin7Line size="20" /></Button> */}

                        <div className="font-weight-bold mt-3 font-12">Social media information</div>
                        <Row className="m-0 p-0 mt-1">
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Facebook</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Facebook" value={socialFace}
                                        onChange={async (e) => {
                                            // let value = e.target.value;
                                            await this.setState({ socialFace: e.target.value.trim() })
                                            this.checkButton()
                                        }} />
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Tiktok</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Tiktok" value={socialTik}
                                        onChange={async (e) => {
                                            // let value = e.target.value;
                                            await this.setState({ socialTik: e.target.value.trim() })
                                            this.checkButton()
                                        }} />
                                </div>
                            </Col>
                            <Col md={4} className="m-0 p-0 pr-2">
                                <div className="textSize12 text-secondary">Twitter</div>
                                <div className="d-flex align-items-center">
                                    <Form.Control type="text" placeholder="Twitter" value={socialTwit}
                                        onChange={async (e) => {
                                            // let value = e.target.value;
                                            await this.setState({ socialTwit: e.target.value.trim() })
                                            this.checkButton()
                                        }} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        }
    }

    renderButton = () => {
        const { buttonCheck, onEdit, tabProfile } = this.state
        if (buttonCheck) {
            if (tabProfile === 'general') {
                if (onEdit) {
                    return (
                        <>
                            <Button onClick={() => this.setProfile()} className="btn-save active mx-2 " >Cancel</Button>
                            <Button onClick={() => this.editIdol()} variant="dark" className="ml-2">Save changes</Button>
                        </>
                    )
                }
            }
        } else {
            if (tabProfile === 'general') {
                return (
                    <>
                        <Button className="btn-save mx-2 cursor-auto">Cancel</Button>
                        <Button className="btn-addmore mx-2 cursor-auto">Save changes</Button>
                    </>
                )
            }
        }
    }

    checkButton = async () => {
        const { country, region, dataProfile } = this.state
        // console.log(country, "country", dataProfile);
        const { infoName, infoGender, infoDateBirth, infoAlias, infoHeight, infoWeight, infoRating, infoAttractivepoint, infoLanguage, infoOccupation, infoReputation, infoWorktype, education_level, education_institute, contactPhone, contactLine, contactEmail, manageName, managePhone, manageLine, socialFace, socialTik, socialTwit } = this.state
        if (infoName !== dataProfile.full_name
            || infoGender !== dataProfile.gender
            || infoDateBirth !== dataProfile.date_of_birth
            || country !== '' && country !== dataProfile.country
            || region !== '' && region !== dataProfile.city
            || infoAlias !== dataProfile.alias
            || infoHeight != dataProfile.height
            || infoWeight != dataProfile.weight
            || infoRating !== dataProfile.beauty_level
            || contactPhone !== dataProfile.contact_phone_number //contactPhone !== ''
            || contactLine !== dataProfile.contact_line_id   //contactLine !== '' && 
            || contactEmail !== dataProfile.contact_email  //contactEmail !== '' && 
            || manageName !== dataProfile.manager_full_name //manageName !== '' && 
            || managePhone !== dataProfile.manager_phone_number //managePhone !== '' && 
            || manageLine !== dataProfile.manager_line_id //manageLine !== '' && 
            || socialFace !== dataProfile.facebook_info //socialFace !== '' && 
            || socialTik !== dataProfile.tiktok_info //socialTik !== '' && 
            || socialTwit !== dataProfile.twitter_info //socialTwit !== '' && 

            || !helper.arrayIsEqual(infoAttractivepoint, dataProfile.attractive_point)
            // || infoLanguage !== [] && infoLanguage !== dataProfile.language
            || !helper.arrayIsEqual(infoLanguage, dataProfile.language)
            || !helper.arrayIsEqual(infoOccupation, dataProfile.occupation)
            || !helper.arrayIsEqual(infoReputation, dataProfile.reputation)
            || !helper.arrayIsEqual(infoWorktype, dataProfile.work_type)
            || !helper.arrayIsEqual(education_level, dataProfile.education_level)
            || !helper.arrayIsEqual(education_institute, dataProfile.education_institute)
        ) {
            await this.setState({ buttonCheck: true });
            await this.props.setIsEditing(true);
        } else {
            await this.setState({ buttonCheck: false })
            await this.props.setIsEditing(false);
        }
    }

    renderMsg = (fromPos, toPos, msg) => {
        // console.log("fromPos, toPos, msg",fromPos, toPos, msg)
        let plainText1 = "";
        let plainText2 = "";
        let sale1 = "";
        let sale2 = "";
        let msgLog = "";



        if (fromPos != -1 && toPos != -1) {
            plainText1 = msg.substring(0, fromPos + 6)
            sale1 = msg.substring(fromPos + 6, toPos)
            plainText2 = msg.substring(toPos, toPos + 3)
            sale2 = msg.substring(toPos + 4, msg.length)
            // console.log("catch 2", msg, plainText1, sale1, plainText2, sale2 )
            return (
                <>
                    <label className="font-yellow mb-0 font-14">{plainText1} </label><span className="font-blue mb-0 font-14"> {sale1}</span>
                    <label className="font-yellow mb-0 font-14">{plainText2} </label><span className="font-blue mb-0 font-14"> {sale2}</span>
                </>
            )
        } else if (fromPos == -1 && toPos != -1) {
            plainText1 = msg.substring(0, toPos + 3)
            sale1 = msg.substring(toPos + 4, msg.length)
            // plainText2 = msg.substring(toPos, toPos+2)
            // sale2 = msg.substring(toPos+3, msg.length)
            // console.log("catch 1", msg, plainText1, sale1)
            return (
                <>
                    <label className="font-yellow mb-0 font-14">{plainText1} </label><span className="font-blue mb-0 font-14"> {sale1}</span>
                </>
            )
            // } else if (msg === 'กำลังแกะข้อมูล' || msg.includes('รอ assign product') || msg === 'รอแกะข้อมูล') {
            //     return <label className="font-14 mb-0 font-yellow">{msg}</label>
        } else {
            return <label className="font-14 mb-0 font-yellow">{msg}</label>
        }
    }

    renderMsgLog = (data) => {
        const { filterRemark } = this.state;
        return data.filter(log=>{
            if (filterRemark == "All product") {
                return log;
            } else {
                return log.action_summary.includes(filterRemark)
            }
        }).map((log, index) => {
            const fromPos = log.action_summary.search(" from ");
            const toPos = log.action_summary.search(" to ");
            let summary = log.action_summary.split(' ');
            // console.log("summary log", log)
            // console.log("logggggggggggg", fromPos, toPos )

            return (<Row className="mt-2" key={index}>
                <Col md={12} className="font-weight-bold font-12">{log.updated_username ? log.updated_username + '  ' : ''}<label className="text-secondary font-10 mb-0">{log.created_at ? helper.formatDateTH(log.created_at) : ''}</label></Col>
                {log.action_summary !== '' ?
                    <Col md={12} className="" >{
                        this.renderMsg(fromPos, toPos, log.action_summary)
                    }</Col>
                    : <></>
                }
                <Col md={12} className="font-14">{log.remark ? log.remark : ''}</Col>
            </Row>)
        })
    }

    handleDrop = (file) => {
        // console.log('ddddd', file);
        // this.uploadFiles(file)
        let fileArray = [];
        let percentArry = [];
        if (typeof file != "undefined") {
            file.map((item) => {
                let size = parseFloat(item.size / (1024 * 1024)).toFixed(2);
                // console.log('size', size, '6666', item.size);
                if (size < 128) {
                    fileArray.push(item)
                    percentArry.push({ progress: 0 })
                } else {
                    MySwal.fire({
                        text: 'Please select file size less than 128 MB',
                        icon: 'warning',
                    })
                }
            })
            if (fileArray.length > 0) {
                this.setState({ dropFile: fileArray, uploadPercentage: percentArry })
                this.uploadFiles(fileArray)
            }
        }

    }

    iconImage = (type) => {
        const typeImage = type
        let docs = false
        let img = false
        let vedio = false
        doc.map((item) => {
            if (item == typeImage) {
                docs = true
            }
        })
        if (docs) {
            return (
                <>
                    <FaFileAlt size="26" />
                </>
            )
        }
        image.map((item) => {
            if (item == typeImage) {
                img = true
            }
        })
        if (img) {
            return (
                <>
                    <FaImage size="26" />
                </>
            )
        }
        video.map((item) => {
            if (item == typeImage) {
                vedio = true
            }
        })
        if (vedio) {
            return (
                <>
                    <FaFileVideo size="26" />
                </>
            )
        }
    }

    ChangeManageModal = async () => {
        this.setState({ modalManage: true, })
    }

    getIdolProduct = async (product_code) => {
        const { history } = this.props;
        this.props.loading(true);
        let response;
        try {
            response = await API.getIdolProduct(this.props.match.params.id, product_code)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        // console.log("get product", response)
        this.props.loading(false);
        if (response && response.status != 500) {
            if (response.status == 200) {
                await this.setState({ workflows_checklist: response.data.workflows_checklist })
                if (this.state.workflows_checklist != null) {
                    let check_data = this.state.workflows_checklist;
                    let checkList = []
                    check_data.map((item) => {
                        checkList.push({ checklist: item.checklist_data, id: item.checklist_id, checkAll: false })
                        // checkList.push(item.checklist_data)
                    })
                    // console.log('sss', checkList);
                    await this.setState({ checklist_data: checkList })

                }
            } else {
                console.log(response.data.message)
            }
        }
    }

    updateCheck = async (item) => {
        // console.log('iteee', item);
        const { history } = this.props;
        var data = JSON.stringify({
            "checklist_id": item.id,
            "checklist_data": item.checklist
        })

        let response;
        try {
            response = await API.updateCheckList(this.props.match.params.id, data)
        } catch (error) {
            response = error.response
            const token = { response, history }
            this.props.checkToken(token);
        }
        // console.log('resss', response);
    }

    checkApprove(i) {
        const { checklist_data, check, close } = this.state;
        // console.log('data', data,i);
        checklist_data.map((item, index) => {
            if (index == i) {
                item.checkAll = !item.checkAll
                if (item.checklist.items.length > 0) {
                    item.checklist.items.map((checkLst) => {
                        if (item.checkAll) {
                            checkLst.is_checked = true
                        } else {
                            checkLst.is_checked = false
                        }
                    })
                }
                this.setState({ item })
            }
            this.updateCheck(item)
        })

    }

    renderCheckList() {
        const { checklist_data } = this.state;

        return checklist_data.length > 0 && checklist_data.map((item, i) => {
            return (
                <div className="bg-white rounded p-3 mt-3" key={i}>
                    <div className="d-flex flex-row">
                        <BsFileEarmarkCheck className="text-primary mt-1 mr-1" /> <div className="text-bold">{item.checklist.name}</div>
                    </div>
                    <Row className="m-0 p-0 mt-1">
                        <Col className="m-0 p-0">
                            {item.checkAll ?
                                <div className="text-primary mt-2 mb-2" onClick={async () => {
                                    // this.setState({ check: false })
                                    await this.checkApprove(i)
                                }}><MdDoneAll /> Uncheck all</div>
                                :
                                <div className="text-primary mt-2 mb-2" onClick={async () => {
                                    // this.setState({ check: true })
                                    await this.checkApprove(i)
                                }}><MdDoneAll /> Check all</div>
                            }
                            {item.checklist.items.length > 0 && item.checklist.items.map((item2, j) => {
                                return (
                                    <div className="d-flex flex-row" key={j}>
                                        <Form.Check className="form-magin" type='checkbox' checked={item2.is_checked}
                                            onChange={() => {
                                                item2.is_checked = !item2.is_checked
                                                this.setState({ item2 })
                                                this.updateCheck(item)
                                            }} />
                                        <label className="mr-3 font-14 mt-1">{item2.name}</label>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            )
        })

    }

    sortFile = (file) => {
        const { sortFile } = this.state;
        // console.log('ffff', file);
        file.sort((a, b) => {
            // console.log('===>', new Date(a.updatedCreate), new Date(b.updatedCreate));
            if (sortFile === 'Newest first') {
                return new Date(a.updatedCreate) - new Date(b.updatedCreate)
            }
            if (sortFile === 'Oldest first') {
                return new Date(b.updatedCreate) - new Date(a.updatedCreate)
            }


        })
    }

    sortRemarkLogs = (file) => {
        const { sortRemark } = this.state;
        // console.log('ffff', file);
        file.sort((a, b) => {
            // console.log('===>', new Date(a.updatedCreate), new Date(b.updatedCreate));
            if (sortRemark === 'Newest first') {
                return new Date(a.created_at) - new Date(b.created_at)
            }
            if (sortRemark === 'Oldest first') {
                return new Date(b.created_at) - new Date(a.created_at)
            }
        })
    }

    checkTablist() {
        const { tabProfile, allProduct } = this.state
        // console.log('idolTabsList', allProduct);
        let work_tabs;
        allProduct.map(async (item) => {
            if (item.product_name == tabProfile) {
                work_tabs = item.workflows_list_tabs;
                // console.log('item.workflows_list_tabs', work_tabs);
                await this.setState({ workflows_list_tabs: work_tabs })
                this.setWorkflowStatusOptions(this.state.workflows_list_tabs)
            }
        })

    }

    setWorkflowStatusOptions = (data) => {
        // console.log("workflow status", data)
        const { match: { params } } = this.props;
        let tempData;
        // if (params.productTab && params.productTab === "no-product") {
        //     let tempArray = []
        //     data.map((item, i) => {
        //         item.available_workflow_statuses.map((option, j) => {
        //             tempArray.push({ label: option.workflow_status_name, value: option.workflow_status_code, workflowcode: item.workflow_code });
        //         })
        //     })
        //     tempData = tempArray;
        // } else {
        tempData = data.map((item, i) => {
            return {
                label: item.workflow_name,
                workflow: item.workflow_code,
                options: item.available_workflow_statuses.map((option, j) => {
                    return {
                        label: option.workflow_status_name,
                        value: option.workflow_status_code,
                        workflowcode: item.workflow_code,
                        workflowname: item.workflow_name,
                        product_code: this.state.tabCode,
                        product_name: this.state.tabProfile
                    }
                })
            }
        })
        // }

        this.setState({ workflowStatusOptions: tempData })
    }

    renderTabList() {
        const { workflows_list_tabs, profileData, productInfo, tabProfile, workflowStatusOptions } = this.state

        let selectedItem;
        let data = profileData;
        let selected = productInfo.map((item) => {
            if (item.product_name === tabProfile) {
                selectedItem = item;
                return {
                    label: item.workflow_status_name,
                    value: item.workflow_status_code,
                    workflowcode: item.workflow_code,
                    workflowname: item.workflow_name,
                    product_code: item.product_code,
                    product_name: item.product_name
                }
            }
        })
        return (
            <Select
                styles={
                    {
                        control: (styles, state) => ({
                            ...styles,
                            backgroundColor: '#007FFF',
                        }),
                        container: (styles, state) => ({
                            ...styles,
                            width: '20%',
                        }),
                        singleValue: (styles) => ({
                            ...styles,
                            color: '#fff'
                        })
                    }
                }
                // id={`product_${data.id}`}
                value={selected}
                options={workflowStatusOptions}
                onChange={async (value) => {
                    data.product_code = value.product_code;
                    data.workflow_code = value.workflowcode;
                    data.status = value;
                    await this.setState({ profileData: data, modalChangeStatusEdit: true, profileData })
                }}
                isSearchable={true}
                isDisabled={false}
            />)

    }

    filterRemark = (item) => {
        if (item.product_code === "GENERAL") {
            this.setState({ filterRemark: item.product_name })
        } else {
            this.setState({ filterRemark: item.product_name })
        }
    }

    render() {
        const { profile_display_name, role, remark, saleCreator, selectButton, infoLog, tabRemark, filterRemark, uploadPercentage, sortRemark, sortFile, tabProfile, filterIdolPhone, profile_alias, profile_fb_username, profile_full_name, profile_ig_username, backPage, buttonCheck, leaveWarning, checklist_data, workflows_checklist } = this.state;
        // const { history: { location } } = this.props;
        const { history, idolTabsList } = this.props;
        // console.log("this.prpos", this.props);
        return (
            <React.Fragment>
                <Prompt
                    when={buttonCheck}
                    message={leaveWarning}
                />
                <Container className="width-full pl-5 pr-5">
                    <Card className="border-0">
                        <Card.Header className="p-2 border-bottom-0 bg-gray mb-n3">
                            <Row className="d-flex align-items-center">
                                <Col className="d-flex align-items-center">
                                    {/* <Link to={location.state.from}> */}
                                    <span style={{ cursor: "pointer" }} className="m-0 edit-back" onClick={() => history.goBack()}>
                                        <MdKeyboardBackspace />  {profile_display_name ? profile_display_name : ''}
                                    </span>
                                    {/* <span style={{ cursor: "pointer" }} className="m-0 edit-back">
                                            <MdKeyboardBackspace />  {profile_display_name ? profile_display_name : ''}
                                        </span> */}
                                    {/* </Link> */}
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button className="" variant="dark" onClick={() => (this.ChangeManageModal())}>Manage products</Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="pb-2 m-0 bg-gray">
                            <Tab.Container id="profile-tabs" activeKey={tabProfile}>
                                <Nav variant="pills" className="flex-row tabNav font-12">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general" onClick={() => this.setState({ tabProfile: 'general', onEdit: true })}>General</Nav.Link>
                                    </Nav.Item>
                                    {
                                        this.state.productInfo.map((item, i) => {
                                            if (item.product_name !== "No Product") {
                                                return (
                                                    <Nav.Item key={i}>
                                                        <Nav.Link eventKey={item.product_name}
                                                            onClick={async () => {
                                                                await this.setState({ tabProfile: item.product_name, tabCode: item.product_code })
                                                                await this.getIdolProduct(item.product_code)
                                                                this.checkTablist()
                                                            }}>{item.product_name}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            }
                                        })
                                    }
                                </Nav>

                                <Row>
                                    <Col md={4} className="p-0 pr-2">

                                        <IdolSocailProfile data={this.state} onEdit={this.state.onEdit} />

                                        <div className="bg-white rounded p-3 mt-3">
                                            <Tab.Container id="remark-tabs" defaultActiveKey={tabRemark}>
                                                <Row className="m-0 p-0">
                                                    <Col className="m-0 p-0">
                                                        <Nav variant="pills" className="flex-row tabNav p-1 m-0 font-12">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="remark" onClick={() => this.setState({ tabRemark: 'remark' })}>Remark</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="files" onClick={() => this.setState({ tabRemark: 'files' })}>Files</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col className="m-0 p-0">
                                                        <Row className="d-flex justify-content-end align-items-center m-0 p-0">
                                                            {tabRemark === 'files' && <Col className="m-0 p-0 d-flex justify-content-end font-12">
                                                                <Dropdown alignRight>
                                                                    <Dropdown.Toggle as={CustomToggleSort} id="dropdown-custom-components">
                                                                        {sortFile}
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item eventKey={tabRemark} disabled={sortFile === 'Newest first'}
                                                                            onClick={() => {
                                                                                this.setState({ sortFile: 'Newest first' })
                                                                                this.sortFile(this.state.filesUpload)
                                                                            }
                                                                            }>Newest first</Dropdown.Item>
                                                                        <Dropdown.Item eventKey={tabRemark} disabled={sortFile === 'Oldest first'}
                                                                            onClick={() => {
                                                                                this.setState({ sortFile: 'Oldest first' })
                                                                                this.sortFile(this.state.filesUpload)
                                                                            }}>Oldest first</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>}
                                                            {tabRemark === 'remark' && <>
                                                                <Col className="m-0 p-0 d-flex justify-content-end font-12">
                                                                    <Dropdown alignRight>
                                                                        <Dropdown.Toggle as={CustomToggleSort} id="dropdown-custom-components">
                                                                            {sortRemark}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item eventKey={tabRemark} disabled={sortRemark === 'Newest first'}
                                                                                onClick={() => {
                                                                                    this.setState({ sortRemark: 'Newest first' })
                                                                                    this.sortRemarkLogs(this.state.infoLog)
                                                                                }
                                                                                }>Newest first</Dropdown.Item>
                                                                            <Dropdown.Item eventKey={tabRemark} disabled={sortRemark === 'Oldest first'}
                                                                                onClick={() => {
                                                                                    this.setState({ sortRemark: 'Oldest first' })
                                                                                    this.sortRemarkLogs(this.state.infoLog)
                                                                                }}>Oldest first</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Col>
                                                                <Col className="m-0 p-0 d-flex justify-content-end font-12">
                                                                    <Dropdown alignRight>
                                                                        <Dropdown.Toggle as={CustomToggleFilter} id="dropdown-custom-components">
                                                                            {filterRemark}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item eventKey={tabRemark} onClick={() => {this.filterRemark({product_code:"GENERAL",product_name:"All product"})}}>All product</Dropdown.Item>
                                                                        {
                                                                            this.state.productInfo.sort((a, b) => {
                                                                                return a.product_name.localeCompare(b.product_name);
                                                                            }).map((item, i) => {

                                                                                if (item.product_code !== "NO_PRODUCT") {
                                                                                    return (
                                                                                        <Dropdown.Item eventKey={tabRemark} key={i} onClick={() => {this.filterRemark(item)}}>{item.product_name}</Dropdown.Item>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                            {/* <Dropdown.Item eventKey={tabRemark} onClick={() => this.setState({ filterRemark: 'All product' })}>All product</Dropdown.Item>
                                                                            <Dropdown.Item eventKey={tabRemark} onClick={() => this.setState({ filterRemark: 'IdolPhone' })}>IdolPhone</Dropdown.Item>
                                                                            <Dropdown.Item eventKey={tabRemark} onClick={() => this.setState({ filterRemark: 'หมอดู' })}>หมอดู</Dropdown.Item> */}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </>}
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Tab.Content>
                                                    <Tab.Pane eventKey="remark">
                                                        <Form.Group controlId="exampleForm.ControlTextarea1" className="mt-2">
                                                            <Form.Control as="textarea" rows={5} value={this.state.remark} onChange={(e) => {
                                                                // let log = e.target.value;
                                                                this.setState({ remark: e.target.value, selectButton: true })
                                                            }} />
                                                        </Form.Group>
                                                        <div className="d-flex flex-row-reverse">
                                                            {selectButton && remark !== '' ?
                                                                <Button className="btn-save  active" onClick={() => this.addRemark()}>Add remark</Button>
                                                                : <Button className="btn-save cursor-auto" >Add remark</Button>
                                                            }
                                                        </div>

                                                        <div className="mt-3">
                                                            {infoLog.length > 0 && this.renderMsgLog(infoLog)}
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="files">
                                                        <Dropzone
                                                            onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}
                                                            // onDrop={this.handleDrop(acceptedFiles)}
                                                            multiple
                                                            accept={TypeFiles}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div {...getRootProps()} className="uploadBox">
                                                                        <input {...getInputProps()} />
                                                                        <h4 className="text-center">Drop files to upload</h4>
                                                                        <div className="text-secondary text-center">or</div>
                                                                        <div className="d-flex justify-content-center mt-2">
                                                                            <Button variant="outline-dark">Select Files</Button>
                                                                        </div>
                                                                        <div className="text-secondary text-center mt-2">Maximum upload file size is 128 MB.</div>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>


                                                        <div className="mt-3">
                                                            {this.state.dropFile.length > 0 ?
                                                                this.state.dropFile.map((item, index) => {
                                                                    let date = moment().format("DD/MM/yyyy HH:mm");
                                                                    let progress = uploadPercentage.length > 0 ? uploadPercentage[index].progress : 0;
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <Row className="mb-1" >
                                                                                <Col md={12} className="font-weight-bold">{saleCreator} <label className="text-secondary textSize12">{date}</label></Col>
                                                                                <Col md={12} className="pl-4">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="pl-2 pr-2">
                                                                                            {this.iconImage(item.type)}
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className="text-primary file-width font-14">{item.name}</div>
                                                                                            <div className="text-secondary">{item.size}</div>
                                                                                        </div>
                                                                                        {/* <MdClear /> */}
                                                                                    </div>
                                                                                    {/* <Form>
                                                                                        <Form.Group controlId="formBasicEmail">
                                                                                            <Form.Control type="text" placeholder="" />
                                                                                        </Form.Group>
                                                                                    </Form> */}
                                                                                </Col>
                                                                                <Col><ProgressBar now={progress} active={true} label={`${progress}%`} /></Col>
                                                                            </Row>
                                                                        </React.Fragment>)
                                                                })
                                                                : <></>}
                                                            {this.state.filesUpload.length > 0 ?
                                                                this.state.filesUpload.map((item, index) => {
                                                                    let listUpload = item.data
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <Row className="mb-1" >
                                                                                <Col md={12} className="font-weight-bold">{item.upadteUser} <label className="text-secondary textSize12">{item.updatedAt}</label></Col>
                                                                                <Col md={12} className="pl-4">
                                                                                    {listUpload.length > 0 &&
                                                                                        listUpload.map((item, j) => {
                                                                                            const str = item.full_path_filename;
                                                                                            const res = str.split("/");
                                                                                            const arrlen = res.length;
                                                                                            const FileName = res[arrlen - 1];

                                                                                            return (
                                                                                                <React.Fragment key={j}>
                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <div className="pl-2 pr-3">
                                                                                                                {this.iconImage(item.content_type)}
                                                                                                            </div>
                                                                                                            <div className="flex-icon">
                                                                                                                {/* <div className="text-primary">{item.updated_username === "Admin" ?  item.full_path_filename.substring(17) : item.full_path_filename.substring(15)}</div> */}
                                                                                                                <a href={item.file_download_url} download>
                                                                                                                    <div className="text-primary font-14 file-width">
                                                                                                                        {FileName}
                                                                                                                    </div>
                                                                                                                </a>

                                                                                                                <div className="text-secondary font-12">{helper.sizeFormat(item.file_size)}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <MdClear onClick={() => this.deleteUploadFile(item.full_path_filename)} />
                                                                                                    </div>
                                                                                                    <Form>
                                                                                                        <Form.Group controlId={`comment-file-${index}`}>
                                                                                                            <Form.Control type="text" placeholder="" value={item.comment} className="font-14"
                                                                                                                onChange={(e) => {
                                                                                                                    item.comment = e.target.value.trim();
                                                                                                                    this.setState({ commentFiles: item.comment })
                                                                                                                }}
                                                                                                                onBlur={() => { this.commentFile(item.full_path_filename) }}
                                                                                                            />
                                                                                                        </Form.Group>
                                                                                                    </Form>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>)
                                                                })
                                                                : <></>}
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>

                                    </Col>
                                    <Col md={8} className="p-0 pl-2">
                                        <div className="btn-paddingsave d-flex justify-content-end">
                                            {this.renderButton()}
                                        </div>
                                        <Tab.Content className="pr-1 pl-1">
                                            <Tab.Pane eventKey="general">
                                                {this.renderGeneral()}
                                            </Tab.Pane>
                                            {tabProfile !== 'general'
                                                ?
                                                <Tab.Pane eventKey={tabProfile}>
                                                    <Row className="mb-2">
                                                        <Col className="d-flex justify-content-end">
                                                            {this.renderTabList()}

                                                        </Col>
                                                    </Row>
                                                    {/* <div className="bg-white rounded p-3">
                                                        <div className="font-weight-bold">Registeration information</div>
                                                        <Row className="m-0 p-0 mt-1">
                                                            <Col className="m-0 p-0">
                                                                <div className="textSize12 text-secondary">Registered date</div>
                                                                <div className="m-0 p-0 d-flex align-items-center">
                                                                    <h6>23 Oct 2020</h6>
                                                                </div>
                                                            </Col>
                                                            <Col className="m-0 p-0">
                                                                <div className="textSize12 text-secondary">Approval status</div>
                                                                <div className="m-0 p-0 d-flex align-items-center">
                                                                    <h6>Unapproved</h6>
                                                                </div>
                                                            </Col>
                                                            <Col className="m-0 p-0">
                                                                <div className="textSize12 text-secondary">Approval date</div>
                                                                <div className="m-0 p-0 d-flex align-items-center">
                                                                    <h6>N/A</h6>
                                                                </div>
                                                            </Col>
                                                            <Col className="m-0 p-0"></Col>
                                                        </Row>
                                                    </div> */}
                                                    {this.renderCheckList()}

                                                </Tab.Pane>
                                                : <></>}

                                            {/* <Tab.Pane eventKey="FOX CLUB">
                                                <div className="d-flex bg-white rounded p-3">Foxclub</div>
                                            </Tab.Pane> */}
                                            {/* <Tab.Pane eventKey="tab4">
                                                <div className="d-flex bg-white rounded p-3">หมอดู</div>
                                            </Tab.Pane> */}
                                        </Tab.Content>

                                    </Col>
                                </Row >

                            </Tab.Container >

                        </Card.Body >
                    </Card >

                </Container >

                <ModalManageProduct show={this.state.modalManage}
                    data={this.state.profileData}
                    producstList={this.state.allProduct}
                    type={this.state.type}
                    onHide={value => this.onHide(value)}
                    updateIdolList={() => this.setProfile()}
                />

                <ModalStatus show={this.state.modalChangeStatusEdit}
                    data={this.state.profileData}
                    options={this.state.workflowStatusOptions}
                    workflow={this.state.product_code}
                    type={this.state.type}
                    onHide={value => this.onHide(value)}
                    updateIdolList={() => this.setProfile()}
                />

            </React.Fragment >
        )
    }
}

const mapStateToProps = state => {
    // console.log("state", state)
    return {
        login: state.login,
        isEditing: state.isEditing,
        idolTabsList: state.idolTabsList,
        workflowTabsList: state.workflowTabsList,
        idolTabName: state.idolTabName,
        workflowTabName: state.workflowTabName
    };
};

function mapDispatchToProps(dispatch) {
    return {
        loading: value => dispatch(loading(value)),
        checkLogin: value => dispatch(checkLogin(value)),
        setIsEditing: value => dispatch(setIsEditing(value)),
        checkToken: value => dispatch(checkToken(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditIdol));