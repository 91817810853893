import React from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  ProgressBar,
  Form,
  InputGroup,
  Modal,
  Container,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from 'antd';
import { checkAddIdol, loading, checkToken } from "../redux/actions";
import Rating from "react-rating";
import Dropzone from "react-dropzone";
import Select from "react-select";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { MdClose, MdKeyboardBackspace } from "react-icons/md";
import { BiImage, BiBookAlt } from "react-icons/bi";
import { FaFileAlt, FaImage, FaFileVideo, FaSearch } from "react-icons/fa";
import SelectForm from "./SelectForm";
import api from "../utils/apis";
import helper from "../utils/helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import axios from "axios";
import ReactQuill from 'react-quill'
import TextEditor from "../components/TextEditor";
import 'react-quill/dist/quill.snow.css'
const API = api.getAPI();
const MySwal = withReactContent(Swal);

const TypeFiles =
  "image/*,.ppt,.pptx,file_extension,audio/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,text/plain,media_type,application/pdf,application/vnd.ms-excel,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/aac,application/x-abiword,application/x-freearc,video/x-msvideo,application/vnd.amazon.ebook,application/octet-stream,image/bmp,application/x-bzip,application/x-bzip2,application/x-csh,text/css,text/csv,application/vnd.ms-fontobject,application/epub+zip,application/gzip,text/html,image/vnd.microsoft.icon,text/calendar,application/java-archive,audio/mpeg,video/mpeg,audio/ogg,video/ogg,application/vnd.rar,audio/wav,audio/webm,video/webm,video/mp2t,image/gif,text/javascript,application/json,application/ld+json,audio/midi,audio/x-midi,application/vnd.apple.installer+xml,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/ogg,audio/opus,font/otf,application/x-httpd-php,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,application/x-sh,image/svg+xml,application/x-shockwave-flash,application/x-tar,font/ttf,application/vnd.visio,font/woff,font/woff2,application/xhtml+xml,application/xml,text/xml,application/vnd.mozilla.xul+xml,application/zip,video/3gpp,audio/3gpp,video/3gpp2,audio/3gpp2,application/x-7z-compressed,application/x-rar-compressed,application/x-zip-compressed,multipart/x-zip";

const doc = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "text/plain",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/x-sh",
  "application/rtf",
  "application/vnd.ms-powerpoint",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "text/calendar",
  "text/html",
  "application/vnd.ms-fontobject",
  "application/epub+zip",
  "application/gzip",
  "application/msword",
  "text/css",
  "text/csv",
  "application/vnd.amazon.ebook",
  "application/octet-stream",
  "text/javascript",
  "application/json",
  "application/ld+json",
  "application/vnd.apple.installer+xml",
  "application/ogg",
  "font/otf",
  "application/x-httpd-php",
  "application/vnd.rar",
  "application/x-shockwave-flash",
  "application/x-tar",
  "font/ttf",
  "application/vnd.visio",
  "font/woff",
  "font/woff2",
  "application/xhtml+xml",
  "application/vnd.ms-excel",
  "application/xml",
  "text/xml",
  "application/vnd.mozilla.xul+xml",
  "application/zip",
  "application/x-7z-compressed",
  "application/x-rar-compressed",
  "application/x-zip-compressed",
  "multipart/x-zip",
];
const image = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/tiff",
  "image/svg+xml",
  "image/bmp",
  "image/vnd.microsoft.icon",
  "crt",
  "pem",
];
const video = [
  "video/mp4",
  "audio/webm",
  "video/webm",
  "audio/wav",
  "video/ogg",
  "audio/ogg",
  "video/mpeg",
  "audio/mpeg",
  "audio/midi",
  "audio/x-midi",
  "video/x-msvideo",
  "audio/aac",
  "audio/opus",
  "video/mp2t",
  "video/3gpp",
  "audio/3gpp",
  "video/3gpp2",
  "audio/3gpp2",
];

class KnowledgeTopic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabProfile: "general",
      tabRemark: "remark",
      product_code: "",

      idolListTab: [],
      available_tags: [],
      topics: [],
      products_list: [],
      tags: [],

      availableTags: [],
      uploadPercentage: [],
      dropFile: [],
      activeTab: "",

      currentPage: 1,
      pageSize: 10,
      topicsMaster: [],
      activeTags: [],
      allTags: [],
      searchTxt: "",
      topicFormShow: false,
      topicDetailShow: false,
      topicDetail: null,
      topicForm: {
        title: "",
        description: "",
        tags: []
      },
      invalidMsg: {
        title: "",
        description: "",
        tags: ""
      },
      isInvalid: {
        title: false,
        description: false,
        tags: false
      }
    };
  }

  async componentDidMount() {
    const { idolTabName, type, product } = this.props;
    // console.log("product", product)
    if (
      product === "ALL_PRODUCT" ||
      product === "NO_PRODUCT" ||
      product === "PHOTO_NO_PRODUCT"
    ) {
      if (product === "PHOTO_GENERAL") {
        this.setState({ activeTab: "PHOTO_GENERAL" });
        await this.getTopic(product, type);
      } else {
        this.setState({ activeTab: "GENERAL" });
        await this.getTopic(product, type);
      }
    } else {
      this.setState({ activeTab: product });
      await this.getTopic(product, type);
    }
    // await this.getTopic(idolTabName)
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.props.idolTabName != prevProps.idolTabName &&
    //   this.props.idolTabName
    // ) {
    //   if (
    //     this.props.idolTabName === "ALL_PRODUCT" ||
    //     this.props.idolTabName === "NO_PRODUCT"
    //   ) {
    //     this.setState({ activeTab: "GENERAL" });
    //     await this.getTopic(this.props.product, this.props.type);
    //   } else {
    //     this.setState({ activeTab: this.props.idolTabName });
    //     await this.getTopic(this.props.idolTabName, this.props.type);
    //   }
    // }

    if (this.props.product != prevProps.product && this.props.product) {
      // console.log("did update", this.props.product)
      if (
        this.props.product === "ALL_PRODUCT" ||
        this.props.product === "PHOTO_NO_PRODUCT"
      ) {
        this.setState({ activeTab: "PHOTO_GENERAL" });
        await this.getTopic(this.props.product, this.props.type);
      } else {
        this.setState({ activeTab: this.props.product });
        await this.getTopic(this.props.product, this.props.type);
      }
    }
    // if (this.props.activeTab != prevProps.activeTab) {
    //   this.setState({ activeTab: this.props.activeTab });
    //   this.getTopic(this.props.activeTab, this.props.type);
    // }
  }

  getTopic = async (product_code, type, search) => {
    const { history } = this.props;
    this.props.loading(true);
    let response;
    try {
      response = await API.getTopic(product_code, type, search);
      // console.log("get topic", response.data);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      this.props.loading(false);
      if (response.status == 200) {
        let data = response.data;

        if (this.props.type === "photographers") {
          if (response.data.products_list) {
            response.data.products_list.splice(0, 0, {
              product_code: "PHOTO_GENERAL",
              product_name: "General",
            });
          }
        } else {
          if (response.data.products_list) {
            response.data.products_list.splice(0, 0, {
              product_code: "GENERAL",
              product_name: "General",
            });
          }
        }

        let tagsArray = [];
        // if(product_code !== ''){
        data.topics.map((item) => {
          if (item.tags) {
            item.tags.map((tag) => {
              if (tagsArray.length > 0) {
                let noHasTag = false;
                tagsArray.map((tagA) => {
                  if (tagA.id == tag.id) {
                    noHasTag = true;
                  }
                });
                if (!noHasTag) {
                  tagsArray.push(tag);
                }
              } else {
                tagsArray.push(tag);
              }
            });
          }
        });
        // }else{
        //     tagsArray = data.available_tags
        // }
        // console.log('tagsArray', tagsArray);
        tagsArray.sort((a, b) => {
          return a.id - b.id;
        });
        this.setState({
          available_tags: data.available_tags,
          topics: data.topics,
          products_list: data.products_list,
          topicsMaster: data.topics,
          allTags: tagsArray,
        });
      } else {
        this.props.loading(false);
        console.log(response.data.message);
      }
    }
    this.props.getProductsList({
      products_list: response.data.products_list,
      activeTab: this.state.activeTab,
    });
    this.props.loading(false);
  };

  addTags = async (id) => {
    const { activeTab, availableTags } = this.state;
    const { history } = this.props;
    let response;
    const data = {
      tags: availableTags,
      product_code: activeTab,
    };
    // console.log('datadddddddddddddd', data);
    // return;
    try {
      response = await API.addTags(id, data);
      //   console.log("res==", response);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.getTopic(this.state.activeTab, this.props.type);
      } else {
        console.log(response.data.message);
      }
    }
  };

  onHide = (value) => {
    this.props.onHide(value);
  };

  uploadFiles = async (files) => {
    // console.log('file', files);
    const { history } = this.props;
    const { activeTab } = this.state;
    let params;
    files.map(async (item, index) => {
      // console.log('item', item);
      params = JSON.stringify({
        content_type: item.type,
        filename: item.name,
        file_size: item.size,
        product_code: activeTab,
      });

      // let data = await readFile(item);
      let response;
      try {
        response = await API.uploadFilesDocument(params, item).then(
          async (response) => {
            // console.log('res====>', response);
            await this.renderAxios(response, item, index);
          }
        );
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
    });
  };

  renderAxios = async (response, data, index) => {
    let newUploadPercentage = [...this.state.uploadPercentage];
    const option = await {
      onUploadProgress: (progressEvent) => {
        let { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          newUploadPercentage[index].progress = percent;
          this.setState({ uploadPercentage: newUploadPercentage });
        }
      },
      headers: {
        "Content-Type": response.data.content_type,
      },
    };
    axios.put(response.data.upload_link, data, option).then((res) => {
      // console.log('res-----', res);
      newUploadPercentage[index].progress = 100;
      this.setState({ uploadPercentage: newUploadPercentage });

      let countcomplet = 0;
      newUploadPercentage.map((item) => {
        if (item.progress === 100) {
          countcomplet = countcomplet + 1;
        }
      });
      if (countcomplet === newUploadPercentage.length) {
        this.setState({ uploadPercentage: [], dropFile: "" });
        this.getTopic(this.state.activeTab, this.props.type);
      }
    });
  };

  handleDrop = (file) => {
    let fileArray = [];
    let percentArry = [];
    if (typeof file != "undefined") {
      file.map((item) => {
        let size = parseFloat(item.size / (1024 * 1024)).toFixed(2);
        if (size < 128) {
          fileArray.push(item);
          percentArry.push({ progress: 0 });
        } else {
          MySwal.fire({
            text: "Please select file size less than 128 MB",
            icon: "warning",
          });
        }
      });
      if (fileArray.length > 0) {
        this.setState({ dropFile: fileArray, uploadPercentage: percentArry });
        this.uploadFiles(fileArray);
      }
    }
  };

  deleteUploadFile = async (id) => {
    // console.log('id==>',id);
    const { history } = this.props;
    let response;
    MySwal.fire({
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      text: "Are you confirm to delete the file?",
    }).then(async (result) => {
      if (result.value) {
        try {
          response = await API.deleteDocument(id);
        } catch (error) {
          response = error.response;
          const token = { response, history };
          this.props.checkToken(token);
        }
        if (response && response.status != 500) {
          if (response.status == 200) {
            this.getTopic(this.state.activeTab, this.props.type);
          }
        }
      }
    });
  };

  iconImage = (type, pathImage) => {
    // console.log('icon', type);
    const typeImage = type;
    let docs = false;
    let img = false;
    let vedio = false;
    doc.map((item) => {
      if (item == typeImage) {
        docs = true;
      }
    });
    if (docs) {
      return (
        <>
          <FaFileAlt style={{ width: 150, height: 200 }} />
        </>
      );
    }
    image.map((item) => {
      if (item == typeImage) {
        img = true;
      }
    });
    if (img) {
      return (
        <>
          <img src={pathImage} className="img-fluid img-wight" />
        </>
      );
    }
    video.map((item) => {
      if (item == typeImage) {
        vedio = true;
      }
    });
    if (vedio) {
      return (
        <>
          <FaFileVideo style={{ width: 150, height: 200 }} />
        </>
      );
    }
  };

  renderTagsActive = (tag) => {
    const { activeTags } = this.state;
    // console.log('activeTab', activeTags);
    let hasTag = false;
    for (var i = 0; i < activeTags.length; i++) {
      if (activeTags[i] === tag) {
        hasTag = true;
      }
    }
    if (hasTag) {
      return "dark"; //" active ";
    } else {
      return "light";
    }
  };

  addActiveTags(tag) {
    const { activeTags } = this.state;

    let hasTag = false;
    let indexTag = null;
    let newTagList = [...activeTags];
    for (var i = 0; i < newTagList.length; i++) {
      if (newTagList[i].id === tag.id) {
        hasTag = true;
        indexTag = i;
      }
    }

    if (hasTag) {
      newTagList.splice(indexTag, 1);
    } else {
      newTagList.push(tag);
    }
    // console.log("tag", tag, newTagList)
    this.setState({ activeTags: newTagList });
  }

  filterTags = () => {
    const { activeTags, topicsMaster } = this.state;
    let newDocument = topicsMaster.filter((doc) => {
      let hasTag = false;
      activeTags.map((aTag) => {
        // console.log(doc)
        doc.tags &&
          doc.tags.map((dTag) => {
            if (aTag.id == dTag.id) {
              hasTag = true;
            }
          });
      });
      if (hasTag) {
        return true;
      }
    });
    if (newDocument.length > 0) {
      this.setState({ topics: newDocument });
    } else {
      //   this.getTopic(this.state.activeTab);
      this.setState({ topics: topicsMaster });
    }
  };

  renderTags = () => {
    const { allTags, activeTags } = this.state;
    return allTags.map((tags, index) => {
      return (
        <Badge
          pill
          variant={`${this.renderTagsActive(tags)}`}
          key={index}
          className={`border border-dark font-16 tag-text text-wrap text-left cursor mr-2 mb-2`}
          onClick={async () => {
            await this.addActiveTags(tags);
            this.filterTags();
          }}
        >
          <div>{tags.name}</div>
        </Badge>
      );
    });
  };

  handleClose = () => {
    this.setState({ topicFormShow: false,
      topicForm: {
        title: "",
        description: "",
        tags: []
      }
    });
  };

  renderTabName = (value) => {
    const { products_list } = this.state;
    // console.log("product list", products_list, value)
    const name = products_list.find(item=>{
      return item.product_code == value;
    })
    return name ? name.product_name:"";
  }

  validateForm = () => {
    const { topicForm, invalidMsg, isInvalid } = this.state;
    let validated = true;
    if (topicForm.title.trim() == "") {
      validated = false;
      invalidMsg.title = "กรุณากรอก Topic Title";
      isInvalid.title = true;
      this.setState({ invalidMsg, isInvalid });
    }
    if (topicForm.description.trim()=="") {
      invalidMsg.description = "กรุณากรอก Topic Title";
      isInvalid.description = true;
      this.setState({ invalidMsg, isInvalid });
    }

    return validated;
  };

  saveTopic = async () => {
    if (this.validateForm()) {
      const { topicForm } = this.state;
      const { history, product } = this.props;
      let response;
      const formData = {
        title: topicForm.title.trim(),
        description: topicForm.description.split('<img').join('<img class="img-fluid" '),
        product_code: product,
        tags: topicForm.tags
      }
            // await console.log("formdata", formData)
            // return;
      try {
        response = await API.saveTopic(formData);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      // console.log("add new topic", response);
      if (response && response.status != 500) {
        if (response.status == 201) {
            this.getTopic(this.props.product, this.props.type);
            this.setState({topicFormShow: false})
            this.clearForm();
        } else {
          console.log(response.data.message);
          if (response.data.statusCode==409) {
  
          }
        }
      }
    }
  }

  clearForm = () => {
    this.setState({
      topicForm: {
        title: "",
        description: "",
        tags: []
      },
    })
  }

  renderTopicTags = (data, flg = false) => {
    return data.map((item, index)=>{
       return (<span className={`text-secondary font-16 tag-text text-wrap text-left mr-2 mb-2`}  key={item.id}>
          {`${item.name}${(flg && ((index+1)!=data.length))?",":""}`}
        </span>)
    })
  }

  clearDetail = (data) => {
    this.setState({topicDetail: null, topicDetailShow: false});
  }

  render() {
    const { show, data, product, type, idolTabName } = this.props;
    const {
      tabProfile,
      tabRemark,
      uploadPercentage,
      available_tags,
      availableTags,
      tags,
      topicFormShow,
      activeTab,
      topicForm,
      invalidMsg,
      isInvalid,
      topicDetailShow,
      topicDetail,
      searchTxt,
      currentPage,
      pageSize
    } = this.state;
    // console.log(this.props, "propsss");

    return (
      <>
        <Row className="mt-1">
          <Col
            xs={12}
            className="d-flex flex-column flex-md-row justify-content-between" >
            {/* <div className="inner-addon right-addon mb-0 mr-3">
                <Form.Control type="text" placeholder="Search by topic" 
                  value={searchTxt}
                  onChange={e=>{
                    this.setState({searchTxt: e.target.value})
                    if (e.target.value.trim()=="") {
                      this.getTopic(product, type);
                    }
                  }} 
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && e.target.value.trim() != "") {
                      this.getTopic(product, type, e.target.value.trim());
                    }
                  }}/>
                <FaSearch onClick={()=>{this.getTopic(product, type, searchTxt.trim()); console.log("icon click");}}/>
            </div> */}
            <Form.Group className="search-input w-100 mb-0 mr-3">
              <InputGroup>
                <Form.Control type="text" placeholder="Search by topic" 
                  value={searchTxt}
                  onChange={e=>{
                    this.setState({searchTxt: e.target.value})
                    if (e.target.value.trim()=="") {
                      this.getTopic(product, type);
                    }
                  }} 
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && e.target.value.trim() != "") {
                      this.getTopic(product, type, e.target.value.trim());
                    }
                  }} />
                <InputGroup.Append>
                  <InputGroup.Text>
                    <FaSearch onClick={()=>{
                      if (searchTxt.trim() != "") {
                        this.getTopic(product, type, searchTxt.trim());
                      }
                    }}/>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <button type="button" className="btn-topic cursor btn" style={{width: "8em"}} onClick={()=>this.setState({topicFormShow: true})}>
              New Topic
            </button>
          </Col>
        </Row>

        <div className="d-flex flex-wrap m-2">{this.renderTags()}</div>

        <Row className="">

          {this.state.topics.length > 0 ? (
            this.state.topics.slice((currentPage-1)*pageSize,currentPage*pageSize).map((item, index) => {
              // console.log('item===docu', item);
              return (
                <Col xs={12} key={index} className="mb-3">
                  <Card onClick={()=>{this.setState({topicDetail: item, topicDetailShow: true})}}>
                    <Card.Body className="py-2 px-3">
                      
                        <h5><b>{item.title}</b></h5>
                        <div className="mt-2 mb-3">
                        { 
                          item.tags ? this.renderTopicTags(item.tags, true) : <span className="py-2">{" "}</span>
                        }
                        </div>
                        <div className="d-flex align-items-center">
                          <span>Posted by </span>
                          <div className="d-flex align-items-center px-3">
                            <img className="img-topic" src={item.posted_by_image_url} />
                            <div className="font-weight-bold ml-3">{item.posted_by_nickname}</div>
                          </div>
                          <div className="align-ver mr-1 text-center">
                            {`${new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "medium",
                              timeStyle: "short",
                            }).format(new Date(item.updated_at))}`}
                          </div>
                        </div>
                      
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col className="my-3">
            <Pagination defaultCurrent={1} current={currentPage} total={this.state.topics.length} pageSize={pageSize} responsive
            onChange={(page, pageSize)=>{
              this.setState({currentPage: page})
            }} />
          </Col>
        </Row>
        <Modal
          show={topicFormShow}
          onHide={() => this.handleClose()}
          id="topic-form"
          size="lg"
          animation={false}
          centered
        >
          <Modal.Header className="border-0">
            <Modal.Title>{this.renderTabName(activeTab)} : New topic</Modal.Title>
            <MdClose size={24} className="" onClick={() => this.handleClose()} />
          </Modal.Header>
          <Modal.Body className="pt-0">
            <Row className="border-0">
            
              <Col xs={12} className="mb-3">
                  <div className="textSize12 text-secondary">Topic title</div>
                  <div className="">
                      <Form.Control type="text" placeholder="Name" 
                          className={`font-14 ${isInvalid.title? "is-invalid":""}`}
                          autoComplete="none"
                          value={topicForm.title}
                          onChange={async (e) => {
                              isInvalid.title = false
                              topicForm.title = e.target.value;
                              await this.setState({ topicForm, isInvalid })
                          }
                          }
                      />
                      {isInvalid.title && (
                        <div className="select-invalid-feedback text-left">
                          กรุณาระบุ Topic title
                        </div>
                      )}
                  </div>
              </Col>
              <Col xs={12} className="mb-3">
                  <div className="textSize12 text-secondary">Description</div>
                  <div className="">
                  <ReactQuill className={`w-100 ${isInvalid.description? "rich-text-invalid":""}`}
                    modules = {{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image'],
                        ['clean']
                      ],
                    }}
                    value={topicForm.description}
                    onChange={(content) =>{
                      isInvalid.description = false
                      topicForm.description = content;
                      this.setState({topicForm, isInvalid})
                    }} />
                    {/* <TextEditor
                        value={topicForm.description}
                        onChange={ ( data ) => {
                            console.log( { data } );
                        } }
                       
                    /> */}
                    {isInvalid.description && (
                        <div className="select-invalid-feedback text-left">
                          กรุณากรอก Desciption
                        </div>
                    )}
                  </div>
              </Col>
              <Col xs={12} className="mb-3">
                    <div className="textSize12 text-secondary">Tag</div>
                    <SelectForm
                        options={
                          available_tags
                            ? available_tags.map((item) => ({
                                label: item.name,
                                value: item.name,
                              }))
                            : []
                        }
                        placeholder="tags"
                        value={
                          topicForm.tags.length > 0
                            ? topicForm.tags.map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                        insertable
                        tagPosition="inner"
                        htmlParse={true}
                        labelMask={(value) => {
                          // console.log('va---', value)
                          // const str = value.label
                          // const res = str.split(":");
                          // return <><span>{res[0]}</span><span>{res[1]}</span></>
                          return <span>{value.label}</span>;
                        }}
                        getData={async (data) => {
                          topicForm.tags = data.map((item) => item.value);
                          this.setState({topicForm});
                          // await this.addTags(item.id);
                        }}
                      />
                </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-repass" onClick={() => this.handleClose()}>Cancel</button>
            <button className="btn btn-topic" onClick={() => this.saveTopic()}>New Topic</button>
          </Modal.Footer>
        </Modal>
        {
          topicDetailShow &&
          <Modal
            show={topicDetailShow}
            onHide={() => this.clearDetail()}
            id="topic-detail"
            size="lg"
            animation={false}
            centered
          >
            {/* <Modal.Header className="border-0">
                
              
            </Modal.Header> */}
            <Modal.Body className="pt-1">
              <Container>
                <Row className="mt-3">
                  <Col  xs={1}>
                    
                  </Col>
                  <Col xs={10} className="d-flex justify-content-between">
                    <h4><b>{topicDetail.title}</b></h4>
                    
                  </Col>
                  <Col  xs={1} className="d-flex justify-content-end pr-0">
                    <MdClose size={24} className="" onClick={() => this.clearDetail()} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  
                  <Col xs={10}>
                  <div className="mb-3">
                  { 
                    topicDetail.tags ? this.renderTopicTags(topicDetail.tags, true) : <span className="py-2">{" "}</span>
                  }
                  </div>
                  </Col>
                  <Col xs={10} className="my-3">
                  <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <img className="img-topic" src={topicDetail.posted_by_image_url} />
                        <div className="font-weight-bold ml-3">{topicDetail.posted_by_nickname}</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className="">
                      {ReactHtmlParser(topicDetail.description)}
                    </div>
                  </Col>
                </Row>
              </Container>
            
            </Modal.Body>
          </Modal>
        }
        
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    addidolId: state.addidolId,
    idolTabsList: state.idolTabsList,
    idolTabName: state.idolTabName,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loading: (value) => dispatch(loading(value)),
    checkAddIdol: (value) => dispatch(checkAddIdol(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KnowledgeTopic));
