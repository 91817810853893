import React from 'react';
import { Link } from 'react-router-dom'
import { Pagination } from "react-bootstrap";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import helper from '../utils/helper';

library.add(fas);

class WebboardPagination extends React.Component {

    constructor(props, context) {
        super(props);
    
        this.state = {
            pageList: []
        };
    }

    componentDidMount() {
        this.calcPagination();
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.page != prevProps.page){
            this.calcPagination();
        }
        if (this.props.totalPage != prevProps.totalPage){
            this.calcPagination();
        }
    }

    pagination = (c, m, r = 2) => {
        let current = c,
            last = m,
            delta = r,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
    
        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }
    
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
    
        return rangeWithDots;
    }

    calcPagination = () => {
        this.setState({pageList: this.pagination(this.props.page-1, this.props.totalPage)})
    }

    renderPagination = (page, totalPage) => {
        return this.state.pageList.map((item, index)=>{
            if (item == '...'){
                return <Pagination.Ellipsis disabled key={index}/>
            } else if (item == Number(page)) {
                return <Pagination.Item active key={index}>{item}</Pagination.Item>
            } else {
                return <Pagination.Item onClick={e=>this.changePage(item)} key={index}>{item}</Pagination.Item>
            }
        })
    }

    changePage = (page) =>{
        if (page < 1 ) {
            page = 1;
        } else if (page > this.props.totalPage) {
            page = (this.props.totalPage);
        }
        this.props.getPage(page);
    }

    render () {
        let { page, totalPage } = this.props;
        page = (page)? page: 1;
        totalPage = (totalPage)? totalPage: 1;
        return (
            
            <div className="webboard-pagination d-flex justify-content-end mt-3">
                <Pagination>
     
                    <Pagination.First disabled={(page==1)?true:false} onClick={e=>this.changePage(1)}>
                        หน้าแรก
                    </Pagination.First>
                    <Pagination.Prev disabled={(page==1)?true:false} onClick={e=>this.changePage(page-1)}>
                        <FontAwesomeIcon className="mr-2" size="lg" icon={['fas', 'caret-left']} />ก่อนหน้า
                    </Pagination.Prev>
                    {
                        this.renderPagination(page, totalPage)
                    }
                    <Pagination.Next disabled={(page==(totalPage))?true:false} onClick={e=>this.changePage(Number(page)+1)}>
                        ถัดไป<FontAwesomeIcon className="ml-2" size="lg" icon={['fas', 'caret-right']} />
                    </Pagination.Next>
                    <Pagination.Last disabled={(page==(totalPage))?true:false} onClick={e=>this.changePage(totalPage)}>
                        สุดท้าย
                    </Pagination.Last>
                    
                </Pagination>

            </div>
        )
    }
}

export default WebboardPagination;