import React from "react";
import { Modal, Row, Col, Button, Form, InputGroup, ListGroup, Card, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { loading, addModal, checkLogin, checkToken } from "../redux/actions";
import api from '../utils/apis';
import helper from "../utils/helper"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const API = api.getAPI();
const MySwal = withReactContent(Swal)
class AddIdolpopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      platform: 'IG',
      addidolList: [],
      idolList: [],
      igname: '',
      igSelect: '',
      isIgSelect: false,
      igUser: '',
      selectButton: false,
      engRate: '',
      igUserName: '',
    };
  }

  async componentDidMount() {
    // if (this.props.login) {

    // }

    this.getIdolIns()

  }

  async componentDidUpdate(prevProps, prevState) {
    // if (this.props.show != prevProps.show) {
    //   this.setState({ platform: 'IG' })
    // }
  }

  onHide = () => {
    // console.log(this.state)
    this.setState({ isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false, nameStatus: '', platform: 'IG', })
    this.props.onHide(false);
  };

  getIdolIns = async () => {
    // console.log('getIdolIns')
    const { history } = this.props;
    if (this.state.igname == "") return;
    let response;
    try {
      response = await API.getIdolInstagram(this.state.igname)
    } catch (error) {
      response = error.response
      const token = { response, history }
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.setState({ addidolList: response.data })
        // console.log("list", this.state.addidolList)
      } else {
        console.log(response.data.message)
      }
    }
  }

  getIgUser = async (id) => {
    // console.log('--->',id)
    const { history } = this.props;
    this.setState({ igUser: '' })
    let response;
    try {
      response = await API.getIgUser(id)
    } catch (error) {
      response = error.response
      const token = { response, history }
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.setState({ igUser: response.data, engRate: response.data.engagement_rate.toFixed(2) })
      } else {
        if (response.data.statusCode == 401) {
          // console.log(response.data.message)
          this.setState({ igUserName: response.data.message, igUser: response.data })
        }
      }
    }
  }

  checkBtnSave = () => {
    const { igUser, igname } = this.state;
    if (igname === true) {
      return (
        <>
          <Button className="btn-save mx-2 cursor-auto">
            Save
                </Button>
          <Button className="btn-addmore mx-2 cursor-auto">
            {"Save & Add more"}
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button className="btn-save active mx-2 " onClick={() => this.saveIdol(1)}>
            Save
                </Button>
          <Button type="submit" className="btn-addmore active mx-2" onClick={() => this.saveIdol(2)}>
            {"Save & Add more"}
          </Button>
        </>
      )
    }
  }

  saveIdol = async (type) => {
    this.props.loading(true);
    const { igUser } = this.state
    let data = igUser;
    let response;
    // return;
    const params = JSON.stringify({
      "social_platform": this.state.platform,
      "idol_type": this.props.type,
      "username": this.state.igname,
      // "profile_pic_url": data.profile_pic_url,
      // "ig_pk": data.pk,
      // "follower_count": data.follower_count,
      // "engagement_rate": data.engagement_rate
    });
    try {
      response = await API.addIdolIg(params)
    } catch (error) {
      response = error.response
    }
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 201) {
        if (type == 1) {
          await this.setState({ isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false, success: 'Save สำเร็จ' })
          // MySwal.fire({
          //   icon: "success",
          //   title: "Success",
          // }).then((value) => {
          //   this.onHide(false);
          //   this.props.updateIdolList();
          // });
          await this.checkStatusName(this.state.success)
          await this.props.updateIdolList();
          await this.onHide(false);
        } else {
          // await this.getIdolList();
          this.addMoreIdol()
          this.props.updateIdolList();
        }
      } else {
        if (data.statusCode == 401) {
          // MySwal.fire({
          //   // customClass: 'swal-wide',
          //   icon: "error",
          //   title: "Error",
          //   text: data.message
          // })
          this.setState({ statusCode: data.message })
          this.checkStatusName(this.state.statusCode)
          // console.log('statusCode', this.state.statusCode);
        } else {
          // MySwal.fire({
          //   // customClass: 'swal-wide',
          //   icon: "error",
          //   title: "Error",
          //   text: response.data.message
          // })
          this.setState({ errorStatus: response.data.message })
          this.checkStatusName(this.state.errorStatus)
          // console.log('errorStatus', this.state.errorStatus);
        }
        // console.log(response.data.message)
      }
    } else if (response.status == 500) {
      this.setState({ error: 'ไม่สามารถดึงข้อมูล IG User นี้ได้' })
      this.checkStatusName(this.state.error)
    }
  }

  checkStatusName = (name) => {
    this.setState({ nameStatus: name })
  }

  checkStatusCode = () => {
    const { igUser, igUserName, engRate, igSelect } = this.state;
    // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', igUser)
    if (igUser.statusCode == 401) {
      return (
        <Row className="mt-3 mb-3">
          <Col className="text-center text-danger">
            <label>{igUserName}</label>
          </Col>
        </Row>)
    } else if (igUser.is_duplicate === true) {
      return (
        <Card className="bg-card border-danger">
          <Card.Header className="card-ig">
            <img width="70px" height="70px" className="border-radius mr-3" src={igSelect.profile_pic_url} /> <label className="text-ig">{igSelect.username}</label>
          </Card.Header>
          {igUser === '' ?
            <Row className="mt-3 mb-3">
              <Col className="text-center">
                <Spinner animation="border" variant="primary" />
              </Col>
            </Row>
            :
            <>
              <Row className="d-flex ml-2 mt-3">
                <Col md={2} className="">
                  <img className="pr-2" width="55px" src={window.location.origin + "/image/instagram.png"} />
                </Col>
                <Col md={3} className="">
                  <h5 className="mb-0 text-bold font-16">{helper.kFormath(igUser.follower_count)}</h5>
                  <label className="font-gray font-12">Followers</label>
                </Col>
                <Col md={3} className="p-0">
                  <h5 className="mb-0 text-bold pl-3 font-16">N/A</h5>
                  <label className="font-gray font-12">Growth rate</label>
                </Col>
                <Col md={3} className="p-0">
                  <h5 className="mb-0 text-bold pl-3 font-16">{engRate}%</h5>
                  <label className="font-gray font-12">Engaement rate</label>
                </Col>
              </Row>
              <Row className="">
                <Col className="row-check">
                  <label className="text-danger">Profile has already been added</label>
                </Col>
              </Row>
            </>
          }
        </Card>
      )
    } else {
      return (
        <Card className="bg-card">
          <Card.Header className="card-ig">
            <img width="70px" height="70px" className="border-radius mr-3" src={igSelect.profile_pic_url} /> <label className="text-ig">{igSelect.username}</label>
          </Card.Header>
          {igUser === '' ?
            <Row className="mt-3 mb-3">
              <Col className="text-center">
                <Spinner animation="border" variant="primary" />
              </Col>
            </Row>
            :
            <>
              <Row className="d-flex ml-2 mt-3">
                <Col md={2} className="">
                  <img className="pr-2" width="55px" src={window.location.origin + "/image/instagram.png"} />
                </Col>
                <Col md={3} className="">
                  <h5 className="mb-0 text-bold font-16">{helper.kFormath(igUser.follower_count)}</h5>
                  <label className="font-gray font-12">Followers</label>
                </Col>
                <Col md={3} className="p-0">
                  <h5 className="mb-0 text-bold pl-3 font-16">N/A</h5>
                  <label className="font-gray font-12">Growth rate</label>
                </Col>
                <Col md={3} className="p-0">
                  <h5 className="mb-0 text-bold pl-3 font-16">{engRate}%</h5>
                  <label className="font-gray font-12">Engaement rate</label>
                </Col>
              </Row>
            </>
          }
        </Card>
      )
    }
  }

  addMoreIdol = async () => {
    await this.setState({ isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false })
    // this.props.loading(true)
    // this.props.openModal(true);
  }


  render() {
    const { show, type } = this.props;
    const { igUser, isIgSelect, platform, addidolList, igSelect, selectButton } = this.state;
    return (
      <Modal
        show={show}
        onHide={this.onHide}
        size="md"
        centered
      >
        <div className="bg-modal">
          <Modal.Header className="modal-header-regis pb-1 ">
            <Modal.Title id="register-modal" className="ml-2 color-black font-12 font-weight-bold">Add Social Platform</Modal.Title>
          </Modal.Header>
          <Row className="position m-0">
            <Col className="pb-3 text-center">
              {/* <img src={process.env.NEXT_PUBLIC_HOST_URL + "/image/check.png"} className="img-fluid" alt="logo-registerPopup" /> */}
            </Col>
          </Row>
          <Row className="ml-4 m-0">
            <div className="justify-content-center mr-2" >
              <label className="mr-2 font-14">
                <Form.Check
                  inline
                  type="radio"
                  checked={platform === 'IG'}
                  // label="Instagram"
                  className="m-0"
                  name="socialplatforms"
                  value="IG"
                  onChange={(e) => {this.setState({ platform: e.target.value }) }}
                />
                <img
                  width="35px"
                  height="25px"
                  className="pr-2"
                  src={window.location.origin + "/image/instagram.png"}
                />
              Instagram</label>
            </div>
            {type === 'photographers' ?
              <div className="justify-content-center">
                <label className="mr-2 font-14">
                  <Form.Check
                    inline
                    type="radio"
                    // label="Facebook"
                    checked={platform === 'FB'}
                    className="m-0"
                    name="socialplatforms"
                    value="FB"
                    onChange={(e) => {
                        this.setState({ platform: e.target.value })}}
                  />
                  <img
                    width="35px"
                    height="25px"
                    className="pr-2"
                    src={window.location.origin + "/image/facebook.png"}
                  />
                Facebook</label>
              </div>
              : ''
            }
          </Row>

          {platform === 'IG' ? (
            <Row className="m-0">
              <Col className="p-2 margin-text-input">
                <label className="color-gray font-12">
                  Instagram Profile
                </label>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  Username
                </Form.Label>
                <div className="mb-2 position-modal">
                  <span className="position-igmodal">@</span>
                  <Form.Control type="text" placeholder="Instagram Name" className="pading-ig"
                    value={this.state.igname}
                    onChange={(e) => {
                      // this.getIdolIns(e.target.value)
                      let name = e.target.value.trim();
                      this.setState({ igname: name, nameStatus: '' })

                      // if (name) {
                      //   this.setState({ isIgSelect: true })
                      // } else {
                      //   this.setState({ isIgSelect: false })
                      // }
                      // setTimeout(() => { this.getIdolIns() }, 1000)
                    }}
                  // onKeyPress={(e) => {
                  //   if (e.charCode == 13) {
                  //     this.getIdolIns()
                  //     this.setState({ isIgSelect: false })
                  //   }
                  // }} 
                  />

                </div>

                <h5 className="text-danger">
                  {this.state.nameStatus}
                </h5>
                {/* {
                  isIgSelect ?
                    <div className="h-ig">
                      <Row className="p-0 m-0">
                        <Col className="p-0 m-0">
                          <ListGroup className="list-g">
                            {
                              addidolList.map((item, index) => {
                                return (
                                  <ListGroup.Item key={index} className="list-g"
                                    onClick={() => {
                                      this.setState({ igSelect: item, isIgSelect: false, selectButton: true, igname: item.username })
                                      this.getIgUser(item.pk)
                                    }}>
                                    <img className="px-1 img-profile" src={item.profile_pic_url} />{item.username}
                                  </ListGroup.Item>
                                )
                              })
                            }
                          </ListGroup>
                        </Col>
                      </Row>
                    </div>
                    : ''
                } */}
                {/* {role === 'SUPERVISOR' || role === 'ADMIN' ? <>
                  <Form.Group>
                    <Form.Label className="color-gray font-12">Product</Form.Label>
                    <FormControl type="text" placeholder="Select product" value={productTxt}
                      onChange={async (e) => {
                        let value = e.target.value;
                        await this.setState({ productTxt: value, allProduct: true })
                        // this.checkButton()
                      }}
                      onKeyPress={async (e) => {
                        if (e.charCode === 13 && e.target.value.trim() != "") {
                          let newArray = await [...productType]
                          if (newArray.length === 0) {
                            await newArray.push(productTxt)
                            this.setState({ productType: newArray, productTxt: '', allProduct: false, selectProduct: true })
                          } else {
                            let has = false
                            newArray.map((item) => {
                              if (item == productTxt) {
                                has = true
                                this.setState({ productType: newArray, productTxt: '', allProduct: false, selectProduct: true })
                              }
                            })
                            if (!has) {
                              await newArray.push(productTxt)
                              this.setState({ productType: newArray, productTxt: '', allProduct: false, selectProduct: true })
                            }
                          }
                        }
                      }}
                    />
                    {this.state.allProduct === true && productTxt !== '' ? <div className="h-list">
                      {console.log(this.state.productTxt)}
                      <Row className="p-0 m-0">
                        <Col className="p-0 m-0">
                          <ListGroup className="list-g">
                            {
                              infAttractivepoint.filter(infAttractivepoint => infAttractivepoint.includes(productTxt)).map((item, index) => {
                                return (
                                  <ListGroup.Item key={index} className="list-g"
                                    onClick={async () => {
                                      if (productType.includes(item)) {
                                        this.setState({ productTxt: '', allProduct: false, selectProduct: true })
                                      } else {
                                        let newProduct = await JSON.parse(JSON.stringify(productType));
                                        await newProduct.push(item)
                                        this.setState({ productType: newProduct, productTxt: '', allProduct: false, selectProduct: true })
                                      }
                                    }}>
                                    {item}
                                  </ListGroup.Item>
                                )
                              })
                            }
                          </ListGroup>
                        </Col>
                      </Row>
                    </div>
                      : ''}
                    {this.state.selectProduct === true || productTxt !== null ? <div className="d-flex align-items-center mt-1 flex-wrap">
                      {productType !== '' &&
                        productType.map((item, i) =>
                          <Badge pill variant="light" className="mr-1 p-2 border" key={i}>
                            {item}
                            <MdClear onClick={async () => {
                              let newProduct = [...productType]
                              await newProduct.splice(i, 1)
                              await this.setState({ productType: newProduct })
                            }} />
                          </Badge>
                        )
                      }
                    </div> : ''}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="color-gray font-12">Sales</Form.Label>
                    <Select
                      placeholder="Select sales"
                      id="saleSteam"
                      name="saleSteam"
                      value={this.state.saleSteamSelectValue}
                      options={this.state.saleSteamList}
                      onChange={(value) => {
                        this.selectSaleSteam(value)
                        // this.setState({ selectButton: true })
                      }
                      }
                      isSearchable={true}
                      isDisabled={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="color-gray font-12">Enter remark</Form.Label>
                    <Form.Control as="textarea" rows={3}
                      value={this.state.remark}
                      onChange={e => {
                        let remarks = e.target.value;
                        this.setState({ remark: remarks })
                      }} />
                  </Form.Group></> : <></>} */}
                {/* {igSelect !== '' &&

                  this.checkStatusCode()
                } */}
              </Col>
            </Row>
          ) : (
              <Row className="m-0">
                <Col className="p-2 margin-text-input">
                  <label className="color-gray font-12">
                    Facebook Profile
                  </label>
                  <Form.Control placeholder="Facebook Name"
                    value={this.state.igname}
                    onChange={(e) => {
                      // this.getIdolIns(e.target.value)
                      let name = e.target.value.trim();
                      this.setState({ igname: name, nameStatus: '' })
                    }}
                  />
                  <div className="mt-1">
                    <h5 className="text-danger">
                      {this.state.nameStatus}
                    </h5>
                  </div>
                </Col>
              </Row>

            )}

          <Row className="m-0">
            <Col className="pb-3 text-end mt-3">
              <Button className="btn-cancel" onClick={() => this.onHide()}>
                Cancel
              </Button>
              {this.state.igname ?
                // this.checkBtnSave()
                <>
                  <Button className="btn-save active mx-2 " onClick={() => this.saveIdol(1)}>
                    Save
                    </Button>
                  <Button type="submit" className="btn-addmore active mx-2" onClick={() => this.saveIdol(2)}>
                    Save & Add more
                    </Button>
                </>
                :
                <>
                  <Button className="btn-save mx-2 cursor-auto">
                    Save
                  </Button>
                  <Button className="btn-addmore mx-2 cursor-auto">
                    {"Save & Add more"}
                  </Button>
                </>
              }
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return { login: state.login };
};

function mapDispatchToProps(dispatch) {
  return {
    loading: value => dispatch(loading(value)),
    checkToken: value => dispatch(checkToken(value)),
    // openModal: (value) => dispatch(addModal(value)),
    // checkLogin: value => dispatch(checkLogin(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddIdolpopup));
