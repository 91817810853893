import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, Button, Nav, Navbar } from "react-bootstrap";
import { Table } from "antd";
import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import XLSX from "xlsx";
import api from "../utils/apis";
import helper from "../utils/helper";
import { loading, checkLogin, checkToken } from "../redux/actions";

const API = api.getAPI();
const MySwal = withReactContent(Swal);
const { Column, ColumnGroup } = Table;

class IdolByStatusReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportData: [],
      tabList: [],
      tabSelectedName: "",
      tableData: null,
      headerData: {},
      rowsData: [],
      filterOptions: [
        { value: "by_number", label: "By no. of idols" },
        { value: "by_percentage", label: "By % of total idols" },
        { value: "by_upper_level", label: "By % of upper level" },
      ],
      filterSelected: { value: "by_number", label: "By no. of idols" },
      pagination: {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 30,
        totalItems: 0,
        totalPages: 1,
      },
    };
  }

  async componentDidMount() {
    await this.getReport();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.tabSelectedName != prevState.tabSelectedName) {
      this.setTableData();
    }
    if (this.state.pagination.currentPage != prevState.pagination.currentPage) {
      this.setTableData();
    }
  }

  getReport = async () => {
    this.props.loading(true);
    let response;
    let { tabList, rowsData, pagination } = this.state;
    const { history } = this.props;
    try {
      response = await API.getIdolByStatusReport(
        pagination.currentPage,
        pagination.itemsPerPage
      );
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    // console.log("get idol status report", response.data);
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 200) {
        // const { pagination } = this.state;
        // pagination.totalItems = response.data.totalItems;
        // pagination.totalPages = response.data.totalPages;

        // response.data.map((item, index) => {
        //   item.header.splice(0, 0, " ");
        //   item.header.push({ name: "Total" });
        //   item.header_count.splice(0, 0, " ");
        //   item.header_count.push(item.header_total);
        //   item.rows.map((rItem) => {
        //     rItem.number_of_idols.splice(0, 0, " ");
        //     // rItem.number_of_idols.push(0);
        //     rItem.percent_of_idols.splice(0, 0, " ");
        //     // rItem.percent_of_idols.push(0);
        //     rItem.percent_of_upper_level.splice(0, 0, " ");
        //     // rItem.percent_of_upper_level.push(0);
        //   });
        //   item.rows.splice(0, 0, {
        //     workflow_or_status_code: "caption",
        //     item: item.header_count,
        //   });
        // });

        tabList = response.data.map((item, index) => {
          return {
            code: item.product_code,
            name: item.product_name,
            total: item.header_total,
          };
        });

        await this.setState({
          reportData: response.data,
          tabList,
          // pagination,
        });
        this.setTableData();
      } else {
        console.log(response.data.message);
      }
    }
  };

  mapExportData = async (data) => {
    // console.log("data", data)
    const { tabSelectedName, filterSelected } = this.state;
    let mapData,
      headColumn = [""],
      captionColumn = [""],
      dataArray = [];
    if (data.length) {
      mapData = data.find((item) => {
        return item.product_code == tabSelectedName;
      });
      // console.log("mapData", mapData);

      mapData.header.map((item, index) => {
        headColumn.push(item.name);
        if (index + 1 == mapData.header.length) {
          headColumn.push("Total");
        }
      });

      dataArray.push(headColumn);

      mapData.header_count.map((item, index) => {
        captionColumn.push(item);
        if (index + 1 == mapData.header_count.length) {
          captionColumn.push(mapData.header_total);
        }
      });
      dataArray.push(captionColumn);

      mapData.rows.map((rItem, _rIndex) => {
        let mRow = [];
        mRow.push(rItem.workflow_or_status_name);
        if (filterSelected.value == "by_number") {
          rItem.number_of_idols.map((cItem, cIndex) => {
            mRow.push(cItem);
            if (cIndex + 1 == rItem.number_of_idols.length) {
              mRow.push(rItem.total_number_of_idols);
            }
          });
        } else if (filterSelected.value == "by_percentage") {
          rItem.percent_of_idols.map((cItem, cIndex) => {
            let v =
              Number(cItem) % 1 != 0
                ? Number(cItem).toFixed(2) + "%"
                : Number(cItem) + "%";
            mRow.push(v);
            if (cIndex + 1 == rItem.percent_of_idols.length) {
              let vv =
                rItem.total_percent_of_idols % 1 != 0
                  ? Number(rItem.total_percent_of_idols).toFixed(2) + "%"
                  : Number(rItem.total_percent_of_idols) + "%";
              mRow.push(vv);
            }
          });
        } else if (filterSelected.value == "by_upper_level") {
          rItem.percent_of_upper_level.map((cItem, cIndex) => {
            let v =
              Number(cItem) % 1 != 0
                ? Number(cItem).toFixed(2) + "%"
                : Number(cItem) + "%";
            mRow.push(v);
            if (cIndex + 1 == rItem.percent_of_upper_level.length) {
              let vv =
                rItem.total_percent_of_upper_level % 1 != 0
                  ? Number(rItem.total_percent_of_upper_level).toFixed(2) + "%"
                  : Number(rItem.total_percent_of_upper_level) + "%";
              mRow.push(vv);
            }
          });
        }
        dataArray.push(mRow);
      });
      // console.log(dataArray)
    }

    if (dataArray.length > 0) {
      this.toExcel(dataArray);
    }
  };

  toExcel = (data, _rSpan, _gSpan) => {
    const dt = new Date();
    const filename = `idol_by_status_report_${dt.getDate()}_${
      Number(dt.getMonth()) + 1
    }_${dt.getFullYear()}`;
    let wb = XLSX.utils.book_new();

    let ws = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  toExport = async () => {
    this.props.loading(true);
    let response;
    const { pagination } = this.state;

    try {
      response = await API.getIdolByStatusReport(1, pagination.totalItems);
    } catch (error) {
      response = error.response;
    }
    // console.log("get report export", response.data);
    this.props.loading(false);
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.mapExportData(response.data);
      } else {
        console.log(response.data.message);
      }
    }
  };

  renderTabActive = (name) => {
    const { tabSelectedName } = this.state;
    // console.log('name', name)
    if (name && name == tabSelectedName) {
      return " active ";
    } else {
      return "";
    }
  };

  renderNav = () => {
    const { tabList, tabSelectedName } = this.state;
    // console.log("idol product tab", this.props);
    if (tabList.length) {
      return tabList.map((item, index) => {
        if (!tabSelectedName && index == 0) {
          this.state.tabSelectedName = item.code;
          // this.setState({ tabSelectedName: item.code });
        }
        return (
          <Navbar className="nav nav-underline pb-0" key={index}>
            <Nav.Link
              as="div"
              className={`nav-link txt-style cursor ${this.renderTabActive(
                item.code
              )}`}
              onClick={(_e) => {
                // e.preventDefault();
                this.setState({ tabSelectedName: item.code });
              }}
            >
              {item.name} ({item.total})
            </Nav.Link>
          </Navbar>
        );
      });
    }
  };

  setTableData = () => {
    const { reportData, tabSelectedName, pagination } = this.state;
    let { tableData } = this.state;
    // console.log("object", reportData)
    if (reportData.length) {
      tableData = reportData.find((item) => {
        return item.product_code == tabSelectedName;
      });
      // headerData = {
      //   header: tableData.header,
      //   header_count: tableData.header_count,
      // };
      // rowsData = tableData.rows;
      pagination.totalItems = tableData.totalItems;
      pagination.totalPages = tableData.totalPages;

      this.setState({ tableData, pagination });
    } else {
      // tableData = {
      //   header: [],
      //   // header_count: [],
      //   // header_total: 0,
      //   product_code: "",
      //   product_name: "",
      //   rows: [],
      // };
      // this.setState({ tableData });
    }
  };

  renderTable = () => {
    const {
      reportData,
      tableData,
      headerData,
      rowsData,
      pagination,
      tabSelectedName,
      filterSelected,
    } = this.state;
    // console.log("render table", tableData);

    let tempTableData;

    // let columns = [];
    // let scrolling = { x: 600 };

    // console.log("get product data", getProductData);
    // if (tableData) {
    //   tempTableData = JSON.parse(JSON.stringify(tableData));

    //   // console.log("tableData", tempTableData);
    //   tempTableData.header.splice(0, 0, " ");
    //   tempTableData.header.push({ name: "Total" });
    //   tempTableData.header_count.splice(0, 0, " ");
    //   tempTableData.header_count.push(tempTableData.header_total);
    //   tempTableData.rows.map((rItem) => {
    //     rItem.number_of_idols.splice(0, 0, " ");
    //     // rItem.number_of_idols.push(0);
    //     rItem.percent_of_idols.splice(0, 0, " ");
    //     // rItem.percent_of_idols.push(0);
    //     rItem.percent_of_upper_level.splice(0, 0, " ");
    //     // rItem.percent_of_upper_level.push(0);
    //   });
    //   tempTableData.rows.splice(0, 0, {
    //     workflow_or_status_code: "caption",
    //     item: tempTableData.header_count,
    //   });

    //   if (tempTableData && tempTableData.rows.length >= 10) {
    //     scrolling = { x: 600, y: 350 };
    //   }

    //   columns = tempTableData.header.map((hItem, hIndex) => {
    //     return {
    //       title: <div className="text-primary py-2">{hItem.name}</div>,
    //       render: (value, row, i) => {
    //         //  console.log("row", hIndex,  row, i)
    //         if (i == 0) {
    //           // console.log("i == 0", row.item[hIndex])
    //           if (hIndex != 0) {
    //             return (
    //               <span>
    //                 <b>(
    //                   {row.item[hIndex] !== null
    //                     ? helper.formatNumber(row.item[hIndex])
    //                     : ""}
    //                 )</b>
    //               </span>
    //             );
    //           }
    //         } else {
    //           if (hIndex == 0) {
    //             return (
    //               <div
    //                 className={`status-name text-wrap ${
    //                   row.is_workflow ? "is-workflow" : "not-workflow"
    //                 }`}
    //               >
    //                 {row.workflow_or_status_name}
    //               </div>
    //             );
    //           } else if (Number(hIndex) + 1 == tempTableData.header.length) {
    //             if (filterSelected.value == "by_number") {
    //               if (row.is_workflow) {
    //                 return (
    //                   <div><b>({helper.formatNumber(row.total_number_of_idols)})</b></div>
    //                 );
    //               } else {
    //                 return (
    //                   <div>{helper.formatNumber(row.total_number_of_idols)}</div>
    //                 );
    //               }
    //             } else if (filterSelected.value == "by_percentage") {
    //               if (row.is_workflow) {
    //                 return (
    //                   <div><b>(
    //                     {Number(row.total_percent_of_idols) % 1 != 0
    //                         ? Number(row.total_percent_of_idols).toFixed(2) + "%"
    //                         : Number(row.total_percent_of_idols) + "%"}
    //                   )</b></div>
    //                 );
    //               } else {
    //                 return (
    //                   <div>
    //                     {Number(row.total_percent_of_idols) % 1 != 0
    //                         ? Number(row.total_percent_of_idols).toFixed(2) + "%"
    //                         : Number(row.total_percent_of_idols) + "%"}
    //                   </div>
    //                 );
    //               }
    //             } else if (filterSelected.value == "by_upper_level") {
    //               if (row.is_workflow) {
    //                 return (
    //                   <div><b>(
    //                     {Number(row.total_percent_of_upper_level) % 1 != 0
    //                         ? Number(row.total_percent_of_upper_level).toFixed(
    //                             2
    //                           ) + "%"
    //                         : Number(row.total_percent_of_upper_level) + "%"}
    //                   )</b></div>
    //                 );
    //               } else {
    //                 return (
    //                   <div>
    //                     {Number(row.total_percent_of_upper_level) % 1 != 0
    //                         ? Number(row.total_percent_of_upper_level).toFixed(
    //                             2
    //                           ) + "%"
    //                         : Number(row.total_percent_of_upper_level) + "%"}
    //                   </div>
    //                 );
    //               }
    //             }
    //           } else {
    //             if (filterSelected.value == "by_number") {
    //               if (row.is_workflow) {
    //                 return (
    //                   <div><b>(
    //                     {helper.formatNumber(row.number_of_idols[hIndex])}
    //                   )</b></div>
    //                 );
    //               } else {
    //                 return (
    //                   <div>
    //                     {helper.formatNumber(row.number_of_idols[hIndex])}
    //                   </div>
    //                 );
    //               }
    //             } else if (filterSelected.value == "by_percentage") {
    //               if (row.is_workflow) {
    //                 return (
    //                   <div><b>(
    //                     {Number(row.percent_of_idols[hIndex]) % 1 != 0
    //                       ? Number(row.percent_of_idols[hIndex]).toFixed(2) +
    //                         "%"
    //                       : Number(row.percent_of_idols[hIndex]) + "%"}
    //                   )</b></div>
    //                 );
    //               } else {
    //                 return (
    //                   <div>
    //                     {Number(row.percent_of_idols[hIndex]) % 1 != 0
    //                       ? Number(row.percent_of_idols[hIndex]).toFixed(2) +
    //                         "%"
    //                       : Number(row.percent_of_idols[hIndex]) + "%"}
    //                   </div>
    //                 );
    //               }
    //             } else if (filterSelected.value == "by_upper_level") {
    //               if (row.is_workflow) {
    //                 return (
    //                   <div><b>(
    //                     {Number(row.percent_of_upper_level[hIndex]) % 1 != 0
    //                       ? Number(
    //                           row.percent_of_upper_level[hIndex]
    //                         ).toFixed(2) + "%"
    //                       : Number(row.percent_of_upper_level[hIndex]) + "%"}
    //                   )</b></div>
    //                 );
    //               } else {
    //                 return (
    //                   <div>
    //                     {Number(row.percent_of_upper_level[hIndex]) % 1 != 0
    //                       ? Number(
    //                           row.percent_of_upper_level[hIndex]
    //                         ).toFixed(2) + "%"
    //                       : Number(row.percent_of_upper_level[hIndex]) + "%"}
    //                   </div>
    //                 );
    //               }
    //             }
    //           }
    //         }
    //       },
    //       width: "25%",
    //       align: "center",
    //       className: "table-col",
    //       showSorterTooltip: false,
    //     };
    //   });
    // }

    /* =========================================== */

    let scrolling = { x: 600 };
    if (tableData && tableData.rows.length >= 10) {
      scrolling = { x: 600, y: 350 };
    }

    if (tableData) {
      tempTableData = JSON.parse(JSON.stringify(tableData));
      // console.log("tempTableData", tempTableData)
      // tempTableData.header_count.splice(0, 0, " ");
      tempTableData.rows.splice(0, 0, {
        workflow_or_status_code: "caption",
        // item: [...tempTableData.header_count, tempTableData.header_total],
        item: tempTableData.header_count.map(item=>{
          return item;
        }),
      });
    }

    return (
      <>
        {/* {tableData && tableData.rows.length > 0 ? ( */}
          {tempTableData && tempTableData.rows.length > 0 ? (
          <Table
            // columns={columns}
            dataSource={tempTableData.rows}
            // dataSource={tableData.rows}
            size="small"
            className={`table-width status-report`}
            scroll={scrolling}
            // scroll={{ x: 'max-content' }}
            pagination={{
              className: "my-3",
              position: ["bottomCenter"],
              size: "default",
              showSizeChanger: false,
              current: pagination.currentPage,
              total: pagination.totalItems,
              pageSize: pagination.itemsPerPage,
              hideOnSinglePage: false,
            }}
            rowKey={(record) => record.workflow_or_status_code}
            responsive
            onChange={async (page, _filters, _sorter) => {
              // console.log("paginate", paginate, "sorter", _sorter);
              pagination.currentPage = page.current;
              this.setState({ pagination });
              await this.getReport();
            }}
          >
            <Column
              title=""
              width="25%"
              align="center"
              className="table-col header-white py-2"
              render={(value, row, i) => {
                // console.log("col 0", row);
                if (i == 0) {
                  return <div></div>
                } else {
                  return (
                    <div
                      className={`status-name text-wrap ${
                        row.is_workflow ? "is-workflow" : "not-workflow"
                      }`}
                    >
                      {row.workflow_or_status_name}
                    </div>
                  );
                }
              }}
            />

            {tableData.header.map((hItem, hIndex) => (
              <Column
                align="center"
                className="py-2"
                // title={<div style={{fontSize: "1.1em"}}>{`(${helper.formatNumber(tableData.header_count[hIndex])})`}</div>}
                title={<div className="text-primary py-2">{hItem.name}</div>}
                key={hIndex}
                render={(value, row, i) => {
                  // console.log("col", hIndex, row, i);
                  if (i == 0) {
                    // console.log("i == 0", row.item[hIndex])
                      return (
                        <span>
                          <b>
                            (
                            {row.item[hIndex] !== null
                              ? helper.formatNumber(row.item[hIndex])
                              : ""}
                            )
                          </b>
                        </span>
                      );
                  } else {
                    if (filterSelected.value == "by_number") {
                      if (row.is_workflow) {
                        return (
                          <div style={{ fontWeight: 600 }}>
                            ({helper.formatNumber(row.number_of_idols[hIndex])})
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {helper.formatNumber(row.number_of_idols[hIndex])}
                          </div>
                        );
                      }
                    } else if (filterSelected.value == "by_percentage") {
                      if (row.is_workflow) {
                        return (
                          <div style={{ fontWeight: 600 }}>
                            (
                            {Number(row.percent_of_idols[hIndex]) % 1 != 0
                              ? Number(row.percent_of_idols[hIndex]).toFixed(
                                  2
                                ) + "%"
                              : Number(row.percent_of_idols[hIndex]) + "%"}
                            )
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {Number(row.percent_of_idols[hIndex]) % 1 != 0
                              ? Number(row.percent_of_idols[hIndex]).toFixed(
                                  2
                                ) + "%"
                              : Number(row.percent_of_idols[hIndex]) + "%"}
                          </div>
                        );
                      }
                    } else if (filterSelected.value == "by_upper_level") {
                      if (row.is_workflow) {
                        return (
                          <div style={{ fontWeight: 600 }}>
                            (
                            {Number(row.percent_of_upper_level[hIndex]) % 1 != 0
                              ? Number(
                                  row.percent_of_upper_level[hIndex]
                                ).toFixed(2) + "%"
                              : Number(row.percent_of_upper_level[hIndex]) +
                                "%"}
                            )
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {Number(row.percent_of_upper_level[hIndex]) % 1 != 0
                              ? Number(
                                  row.percent_of_upper_level[hIndex]
                                ).toFixed(2) + "%"
                              : Number(row.percent_of_upper_level[hIndex]) +
                                "%"}
                          </div>
                        );
                      }
                    }
                  }
                }}
              />
            ))}
            
              <Column
                align="center"
                className="py-2"
                // title={`(${helper.formatNumber(tableData.header_total)})`}
                title={<div className="text-primary py-2">Total</div>}
                render={(value, row, i) => {
                  // console.log("col last", row);
                  if (i == 0) {
                    // console.log("i == 0", row)
                      return (
                        <span>
                          <b>
                            (
                            {tempTableData.header_total !== null
                              ? helper.formatNumber(tempTableData.header_total)
                              : ""}
                            )
                          </b>
                        </span>
                      );
                  } else {
                    if (filterSelected.value == "by_number") {
                      if (row.is_workflow) {
                        return (
                          <div style={{ fontWeight: 600 }}>
                            ({helper.formatNumber(row.total_number_of_idols)})
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {helper.formatNumber(row.total_number_of_idols)}
                          </div>
                        );
                      }
                    } else if (filterSelected.value == "by_percentage") {
                      if (row.is_workflow) {
                        return (
                          <div style={{ fontWeight: 600 }}>
                            (
                            {Number(row.total_percent_of_idols) % 1 != 0
                              ? Number(row.total_percent_of_idols).toFixed(2) +
                                "%"
                              : Number(row.total_percent_of_idols) + "%"}
                            )
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {Number(row.total_percent_of_idols) % 1 != 0
                              ? Number(row.total_percent_of_idols).toFixed(2) +
                                "%"
                              : Number(row.total_percent_of_idols) + "%"}
                          </div>
                        );
                      }
                    } else if (filterSelected.value == "by_upper_level") {
                      if (row.is_workflow) {
                        return (
                          <div style={{ fontWeight: 600 }}>
                            (
                            {Number(row.total_percent_of_upper_level) % 1 != 0
                              ? Number(row.total_percent_of_upper_level).toFixed(
                                  2
                                ) + "%"
                              : Number(row.total_percent_of_upper_level) + "%"}
                            )
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {Number(row.total_percent_of_upper_level) % 1 != 0
                              ? Number(row.total_percent_of_upper_level).toFixed(
                                  2
                                ) + "%"
                              : Number(row.total_percent_of_upper_level) + "%"}
                          </div>
                        );
                      }
                    }
                  }
                }}
              />
            
          </Table>
        ) : (
          <Table
            columns={[]}
            dataSource={[]}
            size="small"
            className={`table-width status-report`}
            scroll={scrolling}
            // scroll={{ x: 'max-content' }}
            rowKey={(record) => record.workflow_or_status_code}
            responsive
          ></Table>
        )}
      </>
    );
  };

  render() {
    const { reportData } = this.state;
    return (
      <section className="pt-3 w-100 bg-gray">
        <Row className="">
          <Col xs={12} className="d-flex align-items-center mb-2">
            <h6 className="mb-0">
              <b>สถานะการติดต่อ Idol ของ Sales</b>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-2">
            <div className="nav-scroller bg-white box-shadow flex-wrap">
              {this.renderNav()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="d-flex justify-content-end-table flex-nowrap">
            <Select
              className="w-25 mr-2"
              name="statusfilter"
              value={this.state.filterSelected}
              options={this.state.filterOptions}
              onChange={(value) => {
                this.setState({ filterSelected: value });
              }}
              isSearchable={false}
              isDisabled={false}
            />
            <Button className="btn btn-success" onClick={() => this.toExport()}>
              Export to Excel
            </Button>
          </Col>
        </Row>
        <Row className="bg-gray">
          <Col className="my-2">{this.renderTable()}</Col>
        </Row>
      </section>
    );
  }
}

const mapStateToProps = (_state) => {
  // console.log('state', state)
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
};

// CreatorReport.propTypes = {
//     rolesOptions: PropTypes.array,
//     role: PropTypes.string
// };

// CreatorReport.defaultProps = {
//     rolesOptions: [],
//     role: '',
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IdolByStatusReport));
