import React from 'react';
import { connect } from 'react-redux';
import NavbarList from "../../../components/Navbar";
import Sildbar from '../component/SideMenu';

class Layout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    render() {
        return (
            <>
                <Sildbar>
                    <NavbarList />
                    {this.props.children}
                </Sildbar>
            </>
        )
    }
}

export default Layout;