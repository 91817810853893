import React from "react";
import { Modal, Row, Col, Button, Form, InputGroup, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { modalSale, checkLogin, checkAddIdol, addidolId, checkToken } from "../redux/actions";
import Rating from 'react-rating'
import Select from 'react-select'
import api from '../utils/apis';
import helper from "../utils/helper"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const API = api.getAPI();
const MySwal = withReactContent(Swal)

class ModalSale extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            selectButton: false,
            saleSteamList: [],
            saleSteamSelectValue: [],
            saleSteamId: 0,
            remark: '',
            idIdol: 0,
        };
    }

    async componentDidMount() {
        if (this.props.login ) {
            await this.getSaleSteam()
        }
        // await this.props.checkAddIdol();

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.login != prevProps.login && this.props.login ) {
            // await this.props.checkAddIdol();
            await this.getSaleSteam()
        }
    }

    onHide = (value) => {
        this.setState({ selectButton: false, saleSteamSelectValue: [], saleSteamId: '', remark: ''})
        this.props.onHide(value);
    };

    getSaleSteam = async () => {
        const { history, login } = this.props;
        let response;
        if (login.data.role == "ADMIN" || login.data.role == "SUPERVISOR") {
            try {
                response = await API.getSaleSteam()
            } catch (error) {
                response = error.response
                const token = { response, history}
                this.props.checkToken(token);
            }
            if (response && response.status != 500) {
                let saleSteamArray = [];
                if (response.status == 200) {
                    response.data.forEach(element => {
                        // saleSteamArray.push({ value: element.id, label: element.firstname });
                        saleSteamArray.push({ value: element.id, label: <div><img src={element.image_url} className="img-change-sale" />{element.firstname}</div> });
                    });
                    this.setState({
                        saleSteamList: saleSteamArray
                    });
                } else {
                    console.log(response.data.message)
                }
            }
        }
    }

    addSale = async (type) => {

        const { remark, saleSteamId } = this.state;
        const { data: {id: idolId}, idolList, history } = this.props;
        // let idolId = this.props.idol.id;
        // let id = this.props.addidolId.id;
        let response;
        const params = JSON.stringify({
            "salesId": saleSteamId,
            "remark": remark,
        });
        if (type === 'multi') {
            if (idolList.length > 0) {
                idolList.forEach(async (item, index) => {
                    try {
                        response = await API.addSale(item, params)
                    } catch (error) {
                        response = error.response
                        const token = { response, history}
                        this.props.checkToken(token);
                    }
                    if (response && response.status != 500) {
                        if (response.status == 200) {
                            if (index == idolList.length-1) {
                                this.onHide();
                                this.props.updateIdolList();
                            }
                        } else {
                            MySwal.fire({
                                // customClass: 'swal-wide',
                                icon: "error",
                                title: "Error",
                                text: response.data.message
                            })
                            console.log(response.data.message)
                        }
                    }
                })
            }
        } else if (type === 'single') {
            try {
                response = await API.addSale(idolId, params)
            } catch (error) {
                response = error.response
                const token = { response, history}
                this.props.checkToken(token);
            }
            if (response && response.status != 500) {
                if (response.status == 200) {
                    this.onHide();
                    this.props.updateIdolList();
                } else {
                    MySwal.fire({
                        // customClass: 'swal-wide',
                        icon: "error",
                        title: "Error",
                        text: response.data.message
                    })
                    console.log(response.data.message)
                }
            }
        }
    }

    selectSaleSteam = (optionSelected) => {
        this.setState({
            saleSteamId: optionSelected.value,
            saleSteamSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
        });
    }

    render() {
        const { show, data, type } = this.props;
        // console.log(this.props, "propsss");

        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="md"
                // id="login-form"
                // aria-labelledby="contained-modal-title-vcenter-l"
                centered
            >
                <div className="bg-modal">
                    <Row className="position m-3">
                        <h5 className="text-bold">Change sales</h5>
                        <Col md={12} className="p-0 mt-3">
                            {
                                type === 'single' ?
                                    <>
                                        <div className="txt-style">Do you want to change <b className="font-blue">{data.sales_name ? data.sales_name : 'sales'}</b> to:</div>
                                    </>
                                    :
                                    <>
                                        <div className="txt-style">Do you want to change <b className="font-blue">sales</b> to:</div>
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className="ml-3 mr-3 mb-3">
                        <Col md={12} className="p-0">
                            <Form>
                                <Form.Group>
                                    <Form.Label>Sales</Form.Label>
                                    <Select
                                        placeholder=""
                                        id="saleSteam"
                                        name="saleSteam"
                                        value={this.state.saleSteamSelectValue}
                                        options={this.state.saleSteamList}
                                        onChange={(value) => {
                                            this.selectSaleSteam(value)
                                            this.setState({ selectButton: true })
                                        }
                                        }
                                        isSearchable={true}
                                        isDisabled={false}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Enter remark</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        value={this.state.remark}
                                        onChange={e => {
                                            let remarks = e.target.value;
                                            this.setState({ remark: remarks })
                                        }} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="m-0">
                        <Col className="pb-3 text-end">
                            {/* <a href="/user/dashboard" style={{ cursor: "pointer" }}> */}
                            <Button className="btn-cancel" onClick={() => this.onHide('cancelMulti')}>
                                CANCEL
                                    </Button>
                            {this.state.selectButton ?
                                <>
                                    {type === 'single' ?
                                        <Button className="btn-addmore active mx-2" onClick={() => this.addSale(type)} >
                                            SAVE CHANGES
                                                </Button>
                                        :
                                        <Button className="btn btn-dark active mx-2" onClick={() => this.addSale(type)} >
                                            SAVE CHANGES
                                                </Button>
                                    }
                                </>
                                :
                                <>
                                    <Button className="btn-addmore mx-2 cursor-auto ">
                                        SAVE CHANGES
                                            </Button>
                                </>
                            }
                            {/* </a> */}
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return { login: state.login, addidolId: state.addidolId };
};

function mapDispatchToProps(dispatch) {
    return {
        // openModal: (value) => dispatch(modalSale(value)),
        // checkLogin: value => dispatch(checkLogin(value)),
        checkAddIdol: value => dispatch(checkAddIdol(value)),
        checkToken: value => dispatch(checkToken(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalSale));
