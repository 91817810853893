import React from 'react'
import {Modal} from 'react-bootstrap';
// Components
import ClipLoader from 'react-spinners/ClipLoader';

const override = {
    display: 'block',
    margin: '0 auto',
    // borderColor: 'orange'
}

class Loader extends React.Component {
   
    render() {
        const { loading } = this.props;

        return (
            
            <Modal id="modal_loading" show={loading}
                backdropClassName="loading-backdrop" 
                onHide={() => null}
                animation={false}
                centered
               >    
                <Modal.Body className="modal-loadding">
                    <ClipLoader
                        css={override}
                        size={100}
                        color={'#E6AF2E'}
                        loading={loading}
                    />

                </Modal.Body>
            </Modal>

        )
    }
  }

export default Loader