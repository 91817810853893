import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { RiCheckboxBlankCircleFill, RiChat3Fill, RiDeleteBin7Line, RiTreasureMapLine } from "react-icons/ri";
import helper from "../utils/helper"

export default class IdolSocialProfile extends Component {

    constructor(props) {
        super(props);

    }

    renderProfile() {
        const { profile_pic, profile_alias, profile_fb_username, profile_full_name, profile_ig_username, ig_follower_count, ig_engagement_rate, ig_growth_rate,
            fb_engagement_rate, fb_follower_count, fb_growth_rate, profile_display_name, ig_average_like } = this.props.data;

        return (
            <div className="bg-white rounded p-3">
                <div className="font-weight-bold font-12">Social Platform</div>
                {profile_fb_username !== null ?
                    <>
                        <div className="mt-1 font-12">Facebook profile</div>
                        <div className="p-2 bg-gray rounded">
                            <Row className="d-flex align-items-center mb-2">
                                <Col md="auto" className="pr-0">
                                    <img className="img-fluid-profile rounded-circle" width="80" height="80" src={profile_pic ? profile_pic : window.location.origin + "/image/noprofile.png"} />
                                </Col>
                                <Col className="align-middle font-14">
                                    <u className="text-primary">{profile_fb_username ? profile_fb_username : ''}</u>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-row mt-3">
                                <div className="">
                                    <img className="" width="40px" src={window.location.origin + "/image/facebook.png"} />
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(fb_follower_count) ? helper.kFormath(fb_follower_count) : 0}</h5>
                                    <label className="font-gray font-12">Followers</label>
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{fb_growth_rate ? fb_growth_rate : 0}%</h5>
                                    <label className="font-gray font-12">Growth rate</label>
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{fb_engagement_rate ? fb_engagement_rate : 0}%</h5>
                                    <label className="font-gray font-12">Engagement rate</label>
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(ig_average_like) ? helper.kFormath(ig_average_like) : 0}</h5>
                                    <label className="font-gray font-12">Avg.like</label>
                                </div>
                            </Row>
                        </div>
                    </>
                    :
                    <>
                        <div className="mt-1 font-12">Instagram profile</div>
                        <div className="p-2 bg-gray rounded">
                            <Row className="d-flex align-items-center mb-2">
                                <Col md="auto" className="pr-0">
                                    <img className="img-fluid-profile rounded-circle" width="80" height="80" src={profile_pic ? profile_pic : window.location.origin + "/image/noprofile.png"} />
                                </Col>
                                <Col className="align-middle font-14">
                                    <a href={`https://www.instagram.com/${profile_ig_username}`} target="_blank"><u className="text-primary">{profile_ig_username ? profile_ig_username : ''}</u></a>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-row mt-3">
                                <div className="">
                                    <img className="" width="40px" src={window.location.origin + "/image/instagram.png"} />
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(ig_follower_count) ? helper.kFormath(ig_follower_count) : 0}</h5>
                                    <label className="font-gray font-12">Followers</label>
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{ig_growth_rate ? ig_growth_rate : 0}%</h5>
                                    <label className="font-gray font-12">Growth rate</label>
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{ig_engagement_rate ? ig_engagement_rate : 0}%</h5>
                                    <label className="font-gray font-12">Engagement rate</label>
                                </div>
                                <div className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(ig_average_like) ? helper.kFormath(ig_average_like) : 0}</h5>
                                    <label className="font-gray font-12">Avg.like</label>
                                </div>
                            </Row>
                        </div>
                    </>
                }


                {/* <div className="mt-1 font-12">Facebook profile</div>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" placeholder="" />
                        </Form.Group>
                    </Form> */}
                {/* <div className="p-2 bg-gray rounded">
                        <Row className="d-flex align-items-center mb-2">
                            <Col md="auto" className="pr-0">
                                <img className="img-fluid-profile rounded-circle" width="80" height="80" src={window.location.origin + "/image/1.jpg"} />
                            </Col>
                            <Col className="align-middle  font-14">
                                N/A
                            </Col>
                        </Row>
                        <Row className="d-flex ml-2 mt-3">
                            <Col md={2} className="">
                                <img className="pr-2" width="55px" src={window.location.origin + "/image/facebook.png"} />
                            </Col>
                            <Col md={3} className="">
                                <h5 className="mb-0 text-bold font-16">{helper.kFormath(fb_follower_count) ? helper.kFormath(fb_follower_count) : 0}</h5>
                                <label className="font-gray font-12">Followers</label>
                            </Col>
                            <Col md={3} className="p-0">
                                <h5 className="mb-0 text-bold pl-3 font-16">{fb_growth_rate ? fb_growth_rate : 0}%</h5>
                                <label className="font-gray font-12">Growth rate</label>
                            </Col>
                            <Col md={3} className="p-0">
                                <h5 className="mb-0 text-bold pl-3 font-16">{fb_engagement_rate ? fb_engagement_rate : 0}%</h5>
                                <label className="font-gray font-12">Engaement rate</label>
                            </Col>
                        </Row>
                    </div> */}
            </div>
        )
    }
    render() {
        return (
            <React.Fragment>
                {this.renderProfile()}
            </React.Fragment>
        )
    }
}
