import React from "react";
import PropTypes from 'prop-types';
import { FormControl, ListGroup, Badge } from "react-bootstrap";
import { MdClear } from "react-icons/md";

// import Select from 'react-select'


class FormSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            selectedValue: [],
            itemSelected: false,
            listShow: false,
            multiLine: false
        };
    }

    async componentDidMount() {
        if (this.props.value) {
            this.setState({ selectedValue: this.props.value });
        }
        if (this.props.tagDisplay=="inner" && this.control.offsetHeight > 40) {
            this.setState({multiLine:true})
        } else {
            this.setState({multiLine:false})
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.value != prevProps.value) {
            this.setState({ selectedValue: this.props.value });
        }
        if (this.state.selectedValue != prevState.selectedValue && this.state.selectedValue.length) {
            if (this.props.tagDisplay=="inner" && this.control.offsetHeight > 40) {
                this.setState({multiLine:true})
            } else {
                this.setState({multiLine:false})
            }
        }
    }

    getData = () => {
        // console.log("object", this.state.selectedValue)
        this.props.getData(this.state.selectedValue);
    }

    calcInputWidth = (str) => {
            const text = document.createElement("span"); 
            document.body.appendChild(text); 
            text.style.font = "sans-serif"; 
            text.style.fontSize = 16 + "px"; 
            text.style.height = 'auto'; 
            text.style.width = 'auto'; 
            text.style.position = 'absolute'; 
            text.style.whiteSpace = 'no-wrap'; 
            text.innerHTML = str.replace(/\s/gi, "a"); 

            const width = Math.ceil(text.clientWidth); 
            const formattedWidth = width + "px"; 
            document.body.removeChild(text); 
            return formattedWidth;
    }

    render() {
        const { options, type, placeholder, insertable, tagPosition, htmlParse, labelMask } = this.props;
        let { searchText, selectedValue, listShow, multiLine } = this.state;
        // console.log("render", options, selectedValue)
        return (
            <div className={`select-form-container `}>
                { tagPosition == "inner" &&
                    <div className={`control ${listShow ? 'onfocus' : ""} ${multiLine? "pt-2":""}`} ref={ref=>this.control = ref} onClick={()=>this.inputForm.focus()}>
                        <div className="d-flex flex-wrap align-items-center">
                            {
                                selectedValue.map((item, i) =>
                                    <Badge pill variant="light" className={`mr-1 py-2 border d-flex align-items-center text-left text-wrap my-1`} key={i}>
                                        {item.label}
                                        <MdClear className="ml-1" onMouseDown={() => {
                                            selectedValue.splice(i, 1)
                                            this.setState({ selectedValue, listShow: false })
                                            this.getData();
                                            this.inputForm.blur();
                                        }} />
                                    </Badge>
                                )
                            }
                            <div style={{ display: 'inline-block' }}>
                                <input type={type} placeholder={selectedValue.length==0?placeholder:""} value={searchText}
                                    ref={el=>this.inputForm = el}
                                    style={{width:searchText.length?this.calcInputWidth(searchText):selectedValue.length==0?this.calcInputWidth(placeholder):"2px"}}
                                    onChange={async (e) => {
                                        await this.setState({ searchText: e.target.value, listShow: true })
                                    }}
                                    onFocus={(e) => {
                                        this.setState({ listShow: true })
                                    }}
                                    onBlur={(e) => {
                                        // setTimeout(() => {
                                        this.setState({ listShow: false })
                                        // }, 250);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" && e.target.value.trim() != "") {

                                            if (insertable) {
                                                if (selectedValue.length === 0) {
                                                    selectedValue.push({ label: searchText.trim(), value: searchText.trim() })
                                                    this.setState({ selectedValue, searchText: '', listShow: false })
                                                    this.getData();
                                                    
                                                } else {
                                                    let has = false
                                                    selectedValue.map((item) => {
                                                        if (item.label == searchText.trim()) {
                                                            has = true;
                                                            this.setState({ searchText: '', listShow: false })
                                                        }
                                                    })
                                                    if (!has) {
                                                        selectedValue.push({ label: searchText.trim(), value: searchText.trim() })
                                                        this.setState({ selectedValue, searchText: '', listShow: false })
                                                        this.getData()
                                                    }
                                                }
                                            } else {
                                                this.setState({ searchText: '', listShow: false })
                                            }
                                            this.inputForm.blur();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }

                { tagPosition == "bottom" &&
                    <FormControl type={type} placeholder={placeholder} value={searchText}
                        onChange={async (e) => {
                            await this.setState({ searchText: e.target.value, listShow: true })
                        }}
                        onFocus={(e) => this.setState({ listShow: true })}
                        onBlur={(e) => {
                            // setTimeout(() => {
                            this.setState({ listShow: false })
                            // }, 250);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && e.target.value.trim() != "") {

                                if (insertable) {
                                    if (selectedValue.length === 0) {
                                        selectedValue.push({ label: searchText.trim(), value: searchText.trim() })
                                        this.setState({ selectedValue, searchText: '', listShow: false })
                                        this.getData()
                                    } else {
                                        let has = false
                                        selectedValue.map((item) => {
                                            if (item.label == searchText.trim()) {
                                                has = true;
                                                this.setState({ searchText: '', listShow: false })
                                            }
                                        })
                                        if (!has) {
                                            selectedValue.push({ label: searchText.trim(), value: searchText.trim() })
                                            this.setState({ selectedValue, searchText: '', listShow: false })
                                            this.getData()
                                        }
                                    }
                                } else {
                                    this.setState({ searchText: '', listShow: false })
                                }

                            }
                        }}
                    />
                }

                <div className="h-list" style={{ display: listShow ? 'block' : 'none' }}>
                    <ListGroup className="">
                        {
                            options.filter(fItem => {
                                return fItem.label.includes(searchText);
                            }).map((item, index) => {
                                if (htmlParse) {
                                    return (
                                        <ListGroup.Item key={index} className="list-g"
                                            onMouseDown={() => {
                                                if (selectedValue.some(i => i.label.includes(item.label))) {
                                                    this.setState({ searchText: '' });
                                                } else {
                                                    selectedValue.push(item);
                                                    this.setState({ selectedValue, searchText: '' });
                                                    this.getData();
                                                }
                                            }}>
                                            {labelMask(item)}
                                        </ListGroup.Item>
                                    )
                                } else {
                                    return (
                                        <ListGroup.Item key={index} className="list-g"
                                            onMouseDown={() => {
                                                if (selectedValue.some(i => i.label.includes(item.label))) {
                                                    this.setState({ searchText: '' });
                                                } else {
                                                    selectedValue.push(item);
                                                    this.setState({ selectedValue, searchText: '' });
                                                    this.getData();
                                                }
                                            }}>
                                            {item.label}
                                        </ListGroup.Item>
                                    )
                                }
                            })
                        }
                    </ListGroup>
                </div>

                { tagPosition == "bottom" &&
                    <div className="d-flex align-items-center mt-1 flex-wrap">
                        {
                            selectedValue.map((item, i) =>
                                <Badge pill variant="light" className="mr-1 mb-1 py-2 border d-flex align-items-center" key={i}>
                                    {item.label}
                                    <MdClear className="ml-1" onClick={() => {
                                        selectedValue.splice(i, 1)
                                        this.setState({ selectedValue })
                                        this.getData();
                                    }} />
                                </Badge>
                            )
                        }
                    </div>
                }
            </div>
        );
    }
}

FormSelect.propTypes = {
    options: PropTypes.array,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    insertable: PropTypes.bool,
    getData: PropTypes.func,
    tagPosition: PropTypes.string,
    htmlParse: PropTypes.bool,
    labelMask: PropTypes.func
};

FormSelect.defaultProps = {
    options: [],
    type: 'text',
    placeholder: '',
    insertable: false,
    tagPosition: "bottom",
    htmlParse: false
};

export default FormSelect;
