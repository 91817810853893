import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Dropdown, OverlayTrigger, Popover, Nav, Modal, Form, ListGroup, Spinner } from 'react-bootstrap';
import { Table } from "antd";
import { connect } from 'react-redux';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../utils/apis';
import helper from "../utils/helper";
import { loading, checkLogin } from '../redux/actions';
import CreatorReport from "../components/ReportCreator";
import ExtractDataBySale from "../components/ReportExtractDataBySale";
import RejectReasonReport from "../components/ReportRejectReason";
import IdolByStatusReport from "../components/ReportIdolByStatus";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

const pmRole = ["ADMIN", "SUPERVISOR"];

class Dashboard extends Component {

    constructor(props) {
        super(props);
        
        this.state = {

        }
    }

    async componentDidMount() {
        this.props.checkLogin();
    }
    

    render() {
        const { login: { data: user } } = this.props;
        return (
            <Container className="width-full">
                { user && pmRole.includes(user.role) &&
                    <React.Fragment>
                        <CreatorReport />
                        <ExtractDataBySale />
                        <RejectReasonReport />
                        <IdolByStatusReport />
                    </React.Fragment>
                }
            </Container>
        );
    }
}

const mapStateToProps = state => {
    // console.log('state', state)
    return {
        login: state.login,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loading: value => dispatch(loading(value)),
        checkLogin: value => dispatch(checkLogin(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
