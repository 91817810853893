import { takeEvery, put } from "redux-saga/effects";
import { actionTypes } from './actions';
import helper from '../utils/helper';
import api from "../utils/apis";

const API = api.getAPI();

export default function* watcherSaga() {
  yield takeEvery(actionTypes.LOADING, loadingSaga);
  yield takeEvery(actionTypes.LOG_IN, loginSaga);
  yield takeEvery(actionTypes.MODAL_ADD, addModalSaga);
  yield takeEvery(actionTypes.MODAL_SALE, saleModalSaga);
  yield takeEvery(actionTypes.MODAL_EXTRACT, extractModalSaga);

  yield takeEvery(actionTypes.CHECK_LOGIN, checkLoginSaga);
  yield takeEvery(actionTypes.IDOL_ID, idolIdSaga);
  yield takeEvery(actionTypes.CHECK_IDOL_ID, checkIdolSaga);
  yield takeEvery(actionTypes.SET_EDITING, setIsEditingSaga);
  yield takeEvery(actionTypes.SET_IDOL_TABS, setIdolTabsSaga);
  yield takeEvery(actionTypes.SET_WORKFLOW_TABS, setWorkflowTabsSaga);
  yield takeEvery(actionTypes.SEARCH_IDOL, searchSaga);
  yield takeEvery(actionTypes.ADD_SEARCH, addDatasearchSaga);
  yield takeEvery(actionTypes.DELECT_SEARCH, deletesearchSaga);

  yield takeEvery(actionTypes.WORKFLOWS_CODE, workflowsCodeSaga);
  yield takeEvery(actionTypes.CHECK_WORKFLOWS_CODE, checkWorkflowsCodeSaga);
  yield takeEvery(actionTypes.LOG_OUT, logoutSaga);

  yield takeEvery(actionTypes.GET_MASTER_DATA, getMasterDataSaga);
  yield takeEvery(actionTypes.GET_MASTER_PRODUCTS, getMasterProductsDataSaga);

  yield takeEvery(actionTypes.CHECK_TOKEN, checkTokenSaga);

}

function* loginSaga(payload) {
  try {
    let saveData = Object.assign({}, {}, payload.payload);
    // saveData.token = helper.encryptData(payload.payload.token)
    helper.storageSave("login", saveData);
    yield put({ type: actionTypes.LOG_IN_SUCCESS, payload: saveData });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* checkTokenSaga(payload) {
  try {
    if (payload.payload.response.status === 401) {
      helper.storageRemove("login");
      payload.payload.history.push('/')
      yield put({ type: actionTypes.LOG_IN_SUCCESS, payload: false });
    }
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* searchSaga(payload) {
  try {
    let saveData = Object.assign({}, {}, payload.payload);
    // saveData.token = helper.encryptData(payload.payload.token)
    // helper.storageSave("searchIdol", saveData);
    yield put({ type: actionTypes.SEAECH_SUCCESS, payload: saveData });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* addDatasearchSaga(payload) {
  try {
    let saveData = Object.assign({}, {}, payload.payload);
    // saveData.token = helper.encryptData(payload.payload.token)
    // helper.storageSave("DataSearch", saveData);
    yield put({ type: actionTypes.ADD_SEAECH_SUCCESS, payload: saveData });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* addModalSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* saleModalSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* extractModalSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}


function* checkLoginSaga() {
  try {
    const items = helper.storageGet("login");
    // console.log('items',items)
    if (items) {
      yield put({ type: actionTypes.LOG_IN_SUCCESS, payload: items });
    }
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* idolIdSaga(payload) {
  try {
    let saveData = Object.assign({}, {}, payload.payload);
    // saveData.token = helper.encryptData(payload.payload.token)
    helper.storageSave("addidolId", saveData);
    yield put({ type: actionTypes.IDOL_ID_SUCCESS, payload: saveData });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* setIsEditingSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* setIdolTabsSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* setWorkflowTabsSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* checkIdolSaga() {
  try {
    const items = helper.storageGet("addidolId");
    // console.log('items',items)
    if (items) {
      yield put({ type: actionTypes.IDOL_ID_SUCCESS, payload: items });
    }
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* deletesearchSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* logoutSaga(payload) {
  try {
    console.log("logout ", payload);
    helper.storageRemove("login");
    helper.storageRemove("addidolId");
    helper.storageRemove("workflowsCode");
    window.location.href = '/';
    yield put({ type: actionTypes.LOG_OUT_SUCCESS, payload: false });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* workflowsCodeSaga(payload) {
  try {
    let saveData = Object.assign({}, {}, payload.payload);
    // saveData.token = helper.encryptData(payload.payload.token)
    helper.storageSave("workflowsCode", saveData);
    yield put({ type: actionTypes.WORKFLOWS_CODE_SUCCESS, payload: saveData });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}
function* checkWorkflowsCodeSaga() {
  try {
    const items = helper.storageGet("workflowsCode");
    // console.log('items',items)
    if (items) {
      yield put({ type: actionTypes.WORKFLOWS_CODE_SUCCESS, payload: items });
    }
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* loadingSaga(payload) {
  try {
    yield put({ payload });
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* getMasterDataSaga(payload) {
    try {
      yield put({ type: actionTypes.LOADING, payload: true });
      const res = yield API.getMasterData(payload.payload)

      if (res && res.status != 500) {
        yield put({ type: actionTypes.LOADING, payload: false });
        if (res.status === 200) {
          yield put({ type: actionTypes.SET_MASTER_DATA, payload: res.data });
        }
      }
    
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}

function* getMasterProductsDataSaga(payload) {
  try {
    yield put({ type: actionTypes.LOADING, payload: true });
    const res = yield API.getProducts(payload.payload)
    // console.log("product", res.data)
    if (res && res.status != 500) {
      yield put({ type: actionTypes.LOADING, payload: false });
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_MASTER_PRODUCTS, payload: res.data });
      }
    }
  } catch (e) {
    yield put({ type: actionTypes.FAILURE, payload: e });
  }
}



