import React from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Card,
  Form,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkToken,
  loading
} from "../redux/actions";
import Rating from "react-rating";
import Select from "react-select";
import api from "../utils/apis";
import helper from "../utils/helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class ModalProductWorkflow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: null,
      workflow_index: null,
      status_index: null,
      workflowList: [],
      workflowForm: {
        id: 0,
        step_name: "",
        checklist_name: "",
        checklist: [],
        workflow_manage_status: "ACTIVE",
        workflow_statuses: [],
      },
      checkListForm: { name: "", validated: false, invalidMsg: "" },
      statusForm: {
        id: 0,
        status_name: "",
        validated: false,
        invalidMsg: "",
        is_default: false,
        is_require_reject_reason: false,
        workflow_status_manage_status: "ACTIVE",
      },
    };
  }

  async componentDidMount() {
    this.getProductWorkflow();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.workflowList!=prevState.workflowList) {
      console.log("flow change", this.state.workflowList)
      if (this.state.workflowList.length>0) {
        this.setState({workflow_index:0})
      }
    }
  }

  onHide = (value) => {
    this.props.onHide(value);
  };

  getProductWorkflow = async () => {
    const { data, history } = this.props;
    let response;
    try {
      response = await API.getProductsWorkflow(data.id);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
    //   console.log("get workflow", response);
      if (response.status == 200) {
        this.setState({
          product_id: response.data.product_id,
          workflowList: response.data.workflows.map((item) => {
            return {
              ...item,
              checklist: item.checklist.map((cItem) => {
                return { name: cItem, validated: false, invalidMsg: "" };
              }),
              workflow_statuses: item.workflow_statuses.map((sItem) => {
                return { ...sItem, validated: false, invalidMsg: "" };
              }),
              validated: false,
              invalidMsg: "",
            };
          }),
        });
      } else {
        console.log(response.data.message);
      }
    }
  };


  renderStatusWorkflow = (steps) => {
    // console.log("status data", steps);
    const { checkListForm, workflow_index, status_index } = this.state;
    if (steps && workflow_index != null) {
      let step = steps
        .find((item,index) => {
          return index == workflow_index;
        });
        if (step) {
          return step.workflow_statuses.map((item, index) => {
            if (item.workflow_status_manage_status!="DELETE") {
              return (
                <Card key={index}
                  className={`mb-3 ${index==status_index? " active ":""}`}
                  onClick={() => {
                    this.setState({ status_index: index });
                  }}>
                  <Card.Body className="p-2">
                    <div className="textSize14 text-secondary d-flex justify-content-between">
                      <span>Status name </span>
                      <FontAwesomeIcon
                        className="text-danger ml-2"
                        size="1x"
                        icon={["fa", "trash-alt"]}
                        onClick={() => {
                          if (item.id==0){
                            steps
                            .find((step,j) => {
                              return j == workflow_index;
                            })
                            .workflow_statuses.splice(index, 1);
                          } else {
                            this.getCanDelete(item, "WORKFLOWSTATUS")
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type="text"
                            placeholder=""
                            className={`font-14 ${
                              item.validated ? "is-invalid" : ""
                            }`}
                            value={item.status_name}
                            onChange={(e) => {
                              item.status_name = e.target.value;
                              item.validated = false;
                              this.setState({
                                item,
                              });
                            }}
                          />
                          <div style={{display:"block", width:"1em", marginLeft:".85rem"}}></div>
                        </div>
                        {item.validated && (
                          <div className="select-invalid-feedback text-left">
                            กรุณากรอก Status name
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-2">
                      <Form.Check
                        inline
                        label="Default"
                        type="checkbox"
                        id={`default-${index}`}
                        onChange={(e) => {
                          item.is_default = e.target.checked;
                          this.setState({ item });
                        }}
                        checked={item.is_default}
                      />
                      <Form.Check
                        inline
                        label="Required reject reasons"
                        type="checkbox"
                        id={`reject-${index}`}
                        onChange={(e) => {
                          item.is_require_reject_reason = e.target.checked;
                          this.setState({ item });
                        }}
                        checked={item.is_require_reject_reason}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              );
            }
          });
        }

    }
  };

  renderChecklist = (data) => {
    // console.log("check list data", data);
    {
      return data.checklist.map((item, index) => {
        return (
          <div className="d-flex align-items-center" key={index}>
            <Form.Group className="w-100">
              <div className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder=""
                  className={`font-14 ${item.validated ? "is-invalid" : ""}`}
                  value={item.name}
                  onChange={(e) => {
                    item.name = e.target.value;
                    item.validated = false;
                    this.setState({
                      item,
                    });
                  }}
                />
                <FontAwesomeIcon
                  className="text-danger ml-2"
                  size="lg"
                  icon={["fa", "times-circle"]}
                  onClick={() => {
                    data.checklist.splice(index, 1);
                    this.setState({ data });
                  }}
                />
              </div>
              {item.validated && (
                <div className="select-invalid-feedback text-left">
                  กรุณากรอก Checklist
                </div>
              )}
            </Form.Group>
          </div>
        );
      });
    }
  };

  renderStepWorkflow = (steps) => {
    // console.log("step data", steps);
    const { checkListForm } = this.state;
    let { workflow_index } = this.state;
    if (steps) {
      return steps.map((item, index) => {
        if (item.workflow_manage_status!="DELETE") {
          return (
            <Card
              key={index}            
              className={`mb-3 ${index==workflow_index? " active ":""}`}
              onClick={() => {
                this.setState({ workflow_index: index });
              }}
            >
              <Card.Body className={`p-2`}>
                <div className={"textSize14 text-secondary d-flex justify-content-between"}>
                  <span>Step name </span>
                  <FontAwesomeIcon
                    className="text-danger ml-2"
                    size="1x"
                    icon={["fa", "trash-alt"]}
                    onClick={async() => {
                      if (item.id==0){
                        item.workflow_statuses = [];
                        await steps.splice(index, 1);
                        await this.setState({steps, workflow_index: null})
                      } else {
                        this.getCanDelete(item, "WORKFLOW")
                      }
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <Form.Group className="w-100">
                    <div className="d-flex align-items-center">
                      <Form.Control
                        type="text"
                        placeholder=""
                        className={`font-14 ${
                          item.validated ? "is-invalid" : ""
                        }`}
                        value={item.step_name}
                        onChange={(e) => {
                          item.step_name = e.target.value;
                          item.validated = false;
                          this.setState({
                            item,
                          });
                        }}
                      />
                      <div style={{display:"block", width:"1em", marginLeft:".85rem"}}></div>
                    </div>
                    {item.validated && (
                      <div className="select-invalid-feedback text-left">
                        กรุณากรอก Step name
                      </div>
                    )}
                  </Form.Group>
                </div>
                <Form.Group className="mb-2">
                  <Form.Label>Checklist</Form.Label>
                  {this.renderChecklist(item)}
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn text-warning btn-criteria"
                      onClick={() => {
                        item.checklist.push(
                          JSON.parse(JSON.stringify(checkListForm))
                        );
                        this.setState({ item });
                      }}
                    >
                      Add checklist item
                    </button>
                  </div>
                </Form.Group>
              </Card.Body>
            </Card>
          );
        }
      });
    }
  };

  clearForm = () => {
      this.setState({workflowList: [], workflow_index: null})
      this.onHide();
  }

  validatedForm = () => {
    const {  workflowList } = this.state;
    let { workflow_index } = this.state;
    let is_invalid = true;
    // console.log("validate", workflowList)
    if (workflowList.length==0) {
        is_invalid = false;
        MySwal.fire({
            icon: "warning",
            title: "กรุณาเพิ่มข้อมูล",
          }).then((value) => {});
    }
    workflowList.forEach((wItem, wIndex)=>{
        if (wItem.step_name.trim()==""){
            wItem.validated = true;
            is_invalid = false;
        }
        wItem.checklist.forEach(cItem=>{
            if (cItem.name.trim() == "") {
                cItem.validated = true;
                is_invalid = false;
            }
        })
        wItem.workflow_statuses.forEach(sItem=>{
            if (sItem.status_name.trim() == "") {
                sItem.validated = true;
                if (is_invalid==true) {
                  this.setState({workflow_index: wIndex})
                }
                is_invalid = false;
            }
        })
        this.setState({wItem})
    })
    
    return is_invalid;
  }

  updateData = async () => {
    const { data, history } = this.props;
    const { workflowList } = this.state;
    let response;
    if (this.validatedForm()){
        this.props.loading(true);
        const cloneData = JSON.parse(JSON.stringify(workflowList));
        // console.log("validated", cloneData)
        const formData = {
            product_id: data.id,
            workflows: cloneData.map(wItem=>{
                delete wItem.validated;
                if (wItem.checklist.length==0) {
                    wItem.checklist_name = "";
                }
                wItem.checklist = wItem.checklist.map((cItem, cIndex)=>{
                    delete cItem.validated;
                    delete cItem.invalidMsg;
                    if (cIndex==0) {
                        wItem.checklist_name = cItem.name;
                    }
                    return cItem.name;
                })
                wItem.workflow_statuses.forEach(sItem=>{
                    delete sItem.validated;
                    delete sItem.invalidMsg;
                })
                return wItem;
            })
        }
        // console.log("formdata", formData)
        // return;
        try {
        response = await API.updateProductsWorkflow(data.id, formData);
        } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
        }
        if (response && response.status != 500) {
            this.props.loading(false);
        //   console.log("save workflow", response);
            if (response.status == 200) {
                this.clearForm();
            } else {
                console.log(response.data.message);
            }
        }
    }
  }

  getCanDelete = async (item, product_data_type) => {
    const { history } = this.props;
    let { workflowList, workflow_index } = this.state;
    let response;
    try {
      response = await API.getProductsCancelData(item.id, product_data_type);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      // console.log("get delete workflow", response);
      if (response.status == 200) {
        if (response.data===true) {
          if (product_data_type=="WORKFLOW") {
            item.workflow_manage_status = "DELETE";
            item.workflow_statuses = [];
            this.setState({item, workflow_index: null})
          }
          if (product_data_type=="WORKFLOWSTATUS") {
            item.workflow_status_manage_status = "DELETE";
            this.setState({item})
          }
        } else {
             MySwal.fire({
              icon: "warning",
              html: "This data could not be removed becuase it is linked to some people in the system.<br/> Please unlink it from all people before removing.",
            });
        }
      } else {
        console.log(response.data.message);
      }
    }
  }


  render() {
    const { show, data } = this.props;
    const { workflow_index, workflowList, workflowForm, statusForm } = this.state;
    // console.log("props data", workflowList);

    return (
      <Modal show={show} onHide={this.onHide} size="lg" id="product-workflow-modal" centered>
        <div className="bg-modal">
          <Row className="my-3 mx-2">
            <Col md={6} className="d-flex align-items-center">
              <h5 className="text-bold mb-0">{data.product_name} workflow</h5>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <Button
                className="btn btn-repass mr-3"
                onClick={() => this.clearForm()}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-dark px-3"
                onClick={() => this.updateData()}
              >
                Save
              </Button>
            </Col>
          </Row>
          <Row className="ml-3 mr-3 mb-3">
            <Col xs={6} className="p-2">
              <h5>Steps</h5>
              {this.renderStepWorkflow(workflowList)}

              <div className="d-flex justify-content-center">
                <button
                  className="btn text-warning btn-criteria"
                  onClick={() => {
                    workflowList.push(JSON.parse(JSON.stringify(workflowForm)));
                    this.setState({ workflowList,
                      workflow_index: workflowList.length-1 });
                  }}
                >
                  Add step
                </button>
              </div>
            </Col>
            <Col xs={6} className="p-2">
              <h5>Statuses</h5>
              {this.renderStatusWorkflow(workflowList)}
              {(workflowList && workflow_index != null) && (
                <div className="d-flex justify-content-center">
                  <button
                    className="btn text-warning btn-criteria"
                    onClick={() => {
                      let workflow = workflowList.find((item, index) => {
                        return index == workflow_index;
                      });
                      if (workflow) {
                        workflow.workflow_statuses.push(
                          JSON.parse(JSON.stringify(statusForm))
                        );
                        this.setState({ workflowList });
                      }
                    }}
                  >
                    Add status
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return { 
    login: state.login,
     addidolId: state.addidolId };
};

function mapDispatchToProps(dispatch) {
  return {
    // checkLogin: value => dispatch(checkLogin(value)),
    loading: (value) => dispatch(loading(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalProductWorkflow));
