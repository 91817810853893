import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";

const custom_config = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "insertTable",
      "|",
      "imageUpload",
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
//   ckfinder: {
//     uploadUrl:
//      "",
//   },
};

class UploadAdapter {
    constructor( loader ) {
      this.loader = loader;
      this.upload = this.upload.bind(this)
      this.abort = this.abort.bind(this)
    }
  
    upload() {
      const data = new FormData();
      data.append('typeOption', 'upload_image');
      data.append('file', this.loader.file);
        console.log(this.loader.file)
        return;
      return axios({
          url: `${"API"}forums`,
          method: 'post',
          data,
          
          withCredentials: true
        }).then(res => {
          console.log(res)
          var resData = res.data;
          resData.default = resData.url;
          return resData;
        }).catch(error => {
          console.log(error)
          return Promise.reject(error)
        });
    }
  
    abort() {
      // Reject promise returned from upload() method.
    }
  } 

class TextEditor extends React.Component {
  constructor(props) {
      super(props)
  }

  render() {
    const { value, onChange } = this.props;

    return (
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={custom_config}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
          editor.plugins.get( 'FileRepository' ).createUploadAdapter = function( loader ) {
            return new UploadAdapter( loader );
          };
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          //this.state.handleWYSIWYGInput(this.props.id, data);
          console.log("on change",{ event, editor, data });
          onChange(data);
        }}
        onBlur={(editor) => {
        //   console.log("Blur.", editor);
        }}
        onFocus={(editor) => {
        //   console.log("Focus.", editor);
        }}
      />
    );
  }
}

export default TextEditor;
