import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { checkLogin } from './redux/actions';
import helper from './utils/helper'
import IdolList from "./pages/IdolList";
import NoProduct from "./pages/NoProduct";
import EditIdol from "./pages/EditIdol";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from  "./pages/Dashboard";
import AdminPanel from  "./pages/AdminPanel";
import NavbarList from "./components/Navbar";
import Header from "./components/Header";
// import AddIdolModal from "./components/AddIdolpopup";
import Login from "./components/Login";
import ResetPassword from "./pages/ResetPassword";
import Loader from "./components/Loader";
import ModalExtract from "./components/ModalStatus";
import PhotographerList from "./pages/Photographer/photographerList";
import EditPhotographer from "./pages/Photographer/EditPhotographer";

const isLoggedIn = () => {
  const login = helper.storageGet('login')
  if (Object.keys(login).length > 0) {
    return true;
  } else {
    return false;
  }
}


const SecureRoute = ({component: Component, ...rest }) => {
  // console.log("secureRoute", rest)
  return (
  <Route 
      {...rest}
      render={ props =>
          isLoggedIn() === true? (
              <Component {...props} />
          )
              : (
              <Redirect to="/login" />
          )
      }
  />)
}


class Routers extends Component {

  redireactToLogin = () => {
    return <Redirect to="/login" />
  }

  checkPath = (location) => {
    return location.pathname != '/login';
  }

  render() {
    const { login, location, loading, extractmodal} = this.props;
    // console.log("===>", this.props)
    return (
          <React.Fragment>
            {login && this.checkPath(location) && <Header /> }
            {/* {login && this.checkPath(location) &&  <NavbarList /> } */}
            {/* <AddIdolModal show={addmodal} /> */}
            {/* <ModalSale show={salemodal}/> */}
            <Loader loading={loading}/>
            {/* <ModalExtract show={extractmodal}/> */}
            <Switch>
              <Route exact path="/" component={this.redireactToLogin} />
              <Route path="/login" component={Login} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <SecureRoute exact path="/idol-list" component={IdolList} />
              <SecureRoute exact path="/idol-list/:productTab" component={IdolList} />
              <SecureRoute exact path="/idol-list/:productTab/:workflowTab" component={IdolList} />
              {/* <SecureRoute exact path="/no-product" component={NoProduct} /> */}
              <SecureRoute exact path="/edit-idol/:id" component={EditIdol} />
              <SecureRoute exact path="/dashboard" component={Dashboard} />
              <SecureRoute exact path="/admin" component={AdminPanel} />
              <SecureRoute exact path="/photographer-list" component={PhotographerList} />
              <SecureRoute exact path="/photographer-list/:productTab" component={PhotographerList} />
              <SecureRoute exact path="/photographer-list/:productTab/:workflowTab" component={PhotographerList} />
              <SecureRoute exact path="/edit-photographer/:id" component={EditPhotographer} />
              <Route path="*" exact={true} component={this.redireactToLogin} />
            </Switch>
          </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  // console.log('ll', state)
  return {
    // addmodal: state.addmodal,
    login: state.login,
    // salemodal: state.salemodal,
    loading: state.loading,
    extractmodal: state.extractmodal
  };
};

function mapDispatchToProps(dispatch) {
  return {
    checkLogin: value => dispatch(checkLogin(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routers));
