import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { MdMoreHoriz, } from "react-icons/md";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  OverlayTrigger,
  Popover,
  Nav,
  Modal,
  Form,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Table } from "antd";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../utils/apis";
import ImageUpload from "../components/ImageUpload";
import NavbarList from "../components/Navbar";
import helper from "../utils/helper";
import {
  loading,
  checkLogin,
  getMasterProduct,
  setIdolTypeTabName,
  checkToken,
} from "../redux/actions";
import ProductWorkflow from "../components/ModalProductWorkflow";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class MasterProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableRows: [],
      selectedRowKeys: [],
      selectedRows: [],
      isEditing: false,
      isNewDuplicate: false,
      isNewEmpty: false,
      form: {
        id: "",
        criteria: [],
        product_code: "",
        product_name: "",
        idol_type: "",
      },
      cirteriaForm: { name: "", validated: false, errorMsg: "" },
      salesList: [],
      salesSelected: [],
      invalidMsg: {
        product_code: "",
        product_name: "",
      },
      validated: {
        product_code: false,
        product_name: false,
      },
      productWorkflow: null,
      productWorkFormShow: false
    };
  }

  async componentDidMount() {
    this.props.checkLogin();
    // this.props.getMasterProduct(this.props.idolTypeTabName)
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (this.props.masterData != prevProps.masterData) {
    //   this.mapTableData();
    // }
    // if (this.props.masterDataCategory != prevProps.masterDataCategory) {
    //   this.mapTableData();
    // }
    // if (this.props.idolTypeTabName != prevProps.idolTypeTabName) {
    //   this.mapTableData();
    // }
  }

  mapTableData = () => {
    const { masterData, masterDataCategory } = this.props;
    const tableData = masterData.options.find((item) => {
      return item.option_type == masterDataCategory;
    });
    // console.log("table", tableData, masterDataCategory);
    if (tableData) {
      this.setState({ tableRows: tableData.option_values });
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
    this.setState({ selectedRowKeys, selectedRows });
  };

  renderTableData = () => {
    let scrolling = { x: 600 };
    const { selectedRowKeys, tableRows, roleList } = this.state;
    const { masterProduct } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      // getCheckboxProps: record => {
      //     if (role == 'SALES' || role == 'AGENT') {
      //         return (({ disabled: record.id }))  // Column configuration not to be checked
      //     }
      // },
    };

    const columns = [
      {
        title: "Product name",
        dataIndex: "product_code",
        render: (name, row, i) => {
          return (
            <div className="font-weight-bold ml-3">{row.product_name}</div>
          );
        },
        sorter: (a, b) => {
          return a.product_code.localeCompare(b.product_code);
        },
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Last modified by",
        dataIndex: "updatedUser.nickname",
        render: (name, row, i) => {
          return (
            <div className="d-flex align-items-center justify-content-center px-3">
              <img className="img-acc" src={row.updatedUser.image_url} />
              <div className="font-weight-bold ml-3">
                {row.updatedUser.nickname}
              </div>
            </div>
          );
        },
        sorter: (a, b) =>
          a.updatedUser.nickname.localeCompare(b.updatedUser.nickname),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Last modified",
        dataIndex: "updated_at",
        render: (name, row, i) => {
          return (
            <div className="p-0 pt-2 align-ver mr-1 text-center">
              {`${new Intl.DateTimeFormat("en-GB", {
                dateStyle: "medium",
                timeStyle: "short",
              }).format(new Date(row.updated_at))}`}
            </div>
          );
        },
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: '',
        render: (name, row, i) => 
          <div className="text-center p-0 pr-2 align-ver" style={{ width: 50 }}>
            <a style={{ cursor: "pointer" }} className="pointer-link" 
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ productWorkflow: row, isEditing: false, productWorkFormShow: true });
                this.clearForm();
              }}>
              <MdMoreHoriz className="icon-more" />
            </a>
           
          </div>,
        align: 'center',
      },
    ];

    if (masterProduct && masterProduct.length >= 20) {
      scrolling = { x: 600, y: 650 };
    }

    return (
      <>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={masterProduct}
          size="small"
          className={"table-width mt-3 master-data-table"}
          scroll={scrolling}
          rowKey={(record) => record.id}
          responsive
          pagination={{
            className: "my-4",
            position: ["bottomCenter"],
            size: "default",
            showSizeChanger: false,
            current: 1,
            total: masterProduct.length,
            pageSize: masterProduct.length,
            hideOnSinglePage: true,
          }}
          onChange={async (pagination, filters, sorter) => {
            // console.log("pagination", pagination,  "sorter", sorter);
            // const { currentSort } = await this.state;
            // currentSort.currentPage = await pagination.current;
            // currentSort.sortField = await sorter.field;
            // if (sorter.order == "ascend") {
            //     currentSort.sortOrder = "ASC";
            // } else if (sorter.order == "descend") {
            //     currentSort.sortOrder = "DESC";
            // } else {
            //     currentSort.sortOrder = "ASC";
            // }
            // await this.getIdolList(this.props.addDatasearch);
            // await helper.sessionSave("currentSort", currentSort);
            // this.setState({tableRows})
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                // console.log("row", record)
                const { validated, invalidMsg } = this.state;
                validated.product_name = false;
                invalidMsg.product_name = "";
                this.setState({
                  form: {
                    id: record.id,
                    product_code: record.product_code,
                    product_name: record.product_name,
                    criteria: record.criteria.trim()!=""? record.criteria.split("||").map(item=>{
                      return {name: item, validated: false, errorMsg: ""}
                    }):[],
                  },
                  isEditing: true,
                  validated,
                  invalidMsg,
                });
              },
            };
          }}
        />
      </>
    );
  };

  checkNewDuplicate = (value) => {
    const { tableRows } = this.state;
    let is_duplicate = false;
    tableRows.forEach((item) => {
      if (item.option_name == value) {
        is_duplicate = true;
      }
    });
    return is_duplicate;
  };

  validateForm = () => {
    const { form, validated, invalidMsg } = this.state;
    let is_invalid = false;

    if (form.product_name.trim() == "") {
      is_invalid = true;
      validated.product_name = true;
      invalidMsg.product_name = "กรุณากรอก Product name";
      this.setState({ validated, invalidMsg });
    }

    form.criteria.forEach(item=>{
      if (item.name.trim() == "") {
        is_invalid = true;
        item.validated = true;
        item.errorMsg = "กรุณากรอก Criteria ช่องที่ว่างนี้";
        this.setState({ form, item });
      }
    })
    return is_invalid;
  };

  addNewData = async () => {
    if (!this.validateForm()) {
      const { form, } = this.state;
      const { history,idolTypeTabName } = this.props;
      let criteriaStr = "";
      form.criteria.forEach((item, index)=>{
        return criteriaStr += `${item.name}${((index+1)!=form.criteria.length)?"||":""}`;
      })
      const formData = await {
        idol_type: idolTypeTabName,
        product_name: form.product_name,
        product_code: helper.converseProductNameToCode(form.product_name),
        criteria: criteriaStr
      };

      // await console.log("formdata", formData)
      // return;
      let response;
      try {
        response = await API.addProducts(formData);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      // console.log("add new product", response);
      if (response && response.status != 500) {
        if (response.status == 201) {
          this.props.getMasterProduct(idolTypeTabName);
          this.clearForm();
        } else {
          console.log(response.data.message);
        }
      }
    }
  };

  updateData = async (row) => {
    const { form,isEditing } = this.state;
    const { history,idolTypeTabName } = this.props;
    let criteriaStr = "";
    let response;
    if (!this.validateForm() && isEditing) {
      form.criteria.forEach((item, index)=>{
        return criteriaStr += `${item.name}${((index+1)!=form.criteria.length)?"||":""}`;
      })
      const formData = await {
        idol_type: idolTypeTabName,
        product_name: form.product_name,
        // product_code: helper.converseProductNameToCode(form.product_name),
        product_code: form.product_code,
        criteria: criteriaStr
      };
      
      // await console.log("formdata", formData)
      // return;

      try {
        response = await API.updateProducts(form.id, formData);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      if (response && response.status != 500) {
        // console.log("update user", response);
        if (response.status == 200) {
          this.props.getMasterProduct(idolTypeTabName);
          this.clearForm();
        } else {
          console.log(response.data.message);
        }
      }
    }
  };

  toDelete = () => {
    const { selectedRowKeys } = this.state;
    console.log("row keys", selectedRowKeys);
    if (selectedRowKeys.length === 0) {
      MySwal.fire({
        icon: "warning",
        title: "เลือกอย่างน้อย 1 รายการ",
      }).then((value) => {});
    }
    if (selectedRowKeys.length >= 1) {
      MySwal.fire({
        icon: "warning",
        // title: "ต้องการลบข้อมูล?",
        html: "ข้อมูลที่เลือกทั้งหมดจะถูกลบจากระบบ<br/> คุณยืนยันการลบหรือไม่",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteData();
        }
      });
    }
  };

  deleteData = () => {
    const { selectedRowKeys } = this.state;
    const { history, idolTypeTabName } = this.props;
    let response;
    selectedRowKeys.forEach(async (item, index) => {
      try {
        response = await API.deleteProducts(item);
        // console.log("response", response);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      if (response && response.status != 500) {
        if (response.status == 200) {
          if (index == selectedRowKeys.length - 1) {
            this.props.getMasterProduct(idolTypeTabName);
            this.clearForm();
          }
        }
      }
    });
  };

  clearForm = () => {
    this.setState({
      form: {
        id: "",
        criteria: [],
        product_code: "",
        product_name: "",
        idol_type: "",
      },
      isEditing: false,
      selectedRowKeys: [],
      selectedRows: [],
      invalidMsg: {
        criteria: "",
        product_code: "",
        product_name: "",
        idol_type: "",
      },
    });
  };

  render() {
    const {
      isNewDuplicate,
      isNewEmpty,
      isEditing,
      validated,
      form,
      roleList,
      roleSelected,
      salesList,
      salesSelected,
      invalidMsg,
      productWorkflow,
      productWorkFormShow
    } = this.state;
    const { login } = this.props;
    return (
      <>
        <NavbarList />
        <Container className="width-full">
          <Row className="bg-gray">
            <Col className="d-flex justify-content-end-table m-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn btn-action font-12 font-weight-bold "
                  // disabled={selectedRowKeys.length == 0}
                  onClick={() => {
                    this.toDelete();
                  }}
                >
                  Delete
                </Dropdown.Toggle>
                {/* <Dropdown.Menu>
                                    <Dropdown.Item href="" className="point3 point-delete">Delete</Dropdown.Item>
                                </Dropdown.Menu> */}
              </Dropdown>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-3">
              <h6 className="font-14 text-bold">
                {isEditing ? "Edit existing product" : "Add new product"}
              </h6>

              <div className="textSize14 text-secondary">Product name</div>
              <div className="d-flex align-items-center">
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={`font-14 ${
                      validated.product_name ? "is-invalid" : ""
                    }`}
                    value={form.product_name}
                    onChange={(e) => {
                      form.product_name = e.target.value;
                      validated.product_name = false;
                      this.setState({
                        form,
                        validated,
                      });
                    }}
                  />
                  {validated.product_name && (
                    <div className="invalid-feedback text-left">
                      กรุณากรอก Product name
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="textSize14 text-secondary">Criteria</div>

              {form.criteria.map((item, index) => (
                <div className="d-flex align-items-center" key={index}>
                  <Form.Group className="w-100">
                    <div className="d-flex align-items-center">
                      <Form.Control
                        type="text"
                        placeholder=""
                        className={`font-14 ${
                          item.validated ? "is-invalid" : ""
                        }`}
                        value={item.name}
                        onChange={(e) => {
                          item.name = e.target.value;
                          item.validated = false;
                          this.setState({
                            form, item
                          });
                        }}
                      />
                      <FontAwesomeIcon
                        className="text-danger ml-2"
                        size="lg"
                        icon={["fa", "times-circle"]}
                        onClick={()=>{
                          const { form } = this.state;
                          form.criteria.splice(index, 1);
                          this.setState({ form });
                        }}
                      />
                    </div>
                    {item.validated && (
                      <div className="select-invalid-feedback text-left">
                        กรุณากรอกข้อมูล criteria
                      </div>
                    )}
                  </Form.Group>
                </div>
              ))}

              <div className="d-flex justify-content-center">
                <button
                  className="btn text-warning btn-criteria"
                  onClick={() => {
                    const { form, cirteriaForm } = this.state;
                    form.criteria.push(JSON.parse(JSON.stringify(cirteriaForm)));
                    this.setState({ form });
                  }}
                >
                  Add criteria
                </button>
              </div>

              <div className="pt-2">
                {isEditing ? (
                  <>
                    <Button
                      className="btn btn-dark px-3 mr-3"
                      onClick={() => this.updateData()}
                    >
                      Save
                    </Button>
                    <Button
                      className="btn btn-repass"
                      onClick={() => this.clearForm()}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    className="btn btn-dark"
                    onClick={() => this.addNewData()}
                  >
                    Add new
                  </Button>
                )}
              </div>
            </Col>
            <Col md={8}>{this.renderTableData()}</Col>
          </Row>
          {
            productWorkFormShow && 
            <ProductWorkflow show={productWorkFormShow}
              data={productWorkflow}
              onHide={(value)=>this.setState({productWorkFormShow:value})}
            />
          }
          
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    login: state.login,
    masterData: state.allMasterData,
    masterDataCategory: state.masterDataCategory,
    idolTypeTabName: state.idolTypeTabName,
    masterProduct: state.allMasterProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkLogin: (value) => dispatch(checkLogin(value)),
    getMasterProduct: (value) => dispatch(getMasterProduct(value)),
    setIdolTypeTabName: (value) => dispatch(setIdolTypeTabName(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MasterProducts));
