import React from "react";
import { Modal, Row, Col, Card, Navbar, Nav, Tab, NavLink } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkAddIdol, loading, checkToken, setIdolTypeTabName } from "../redux/actions";
import { MdClose, MdClear } from "react-icons/md";
import { BiImage, BiBookAlt } from "react-icons/bi";
import api from "../utils/apis";
import helper from "../utils/helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import KnowledgeDocuments from "./KnowledgeDocuments";
import KnowladgeTopic from "./KnowledgeTopic";
const API = api.getAPI();
const MySwal = withReactContent(Swal);


class ModalKnowledge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabProfile: "general",
      tabRemark: "remark",
      product_code: "",
      tabSelected: "topic",

      idolListTab: [],
      available_tags: [],
      documents: [],
      products_list: [],
      tags: [],

      availableTags: [],
      uploadPercentage: [],
      dropFile: [],
      activeTab: "",

      documentsMaster: [],
      activeTags: [],
      allTags: [],
    };
  }

  async componentDidMount() {
    const { idolTabName, type, product } = this.props;
    if (
      idolTabName === "ALL_PRODUCT" ||
      idolTabName === "NO_PRODUCT" ||
      idolTabName === "PHOTO_NO_PRODUCT"
    ) {
      if (product === "PHOTO_GENERAL") {
        this.setState({ activeTab: "PHOTO_GENERAL" });
      } else {
        this.setState({ activeTab: "GENERAL" });
      }
    } else {
      this.setState({ activeTab: idolTabName });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.activeTab !== prevState.activeTab) {
      // console.log("get product", this.state.activeTab)
      // this.props.setIdolTypeTabName(this.state.activeTab)
    }
  }

  renderTabActive = (name) => {
    const { activeTab } = this.state;
    if (name == activeTab) {
      return " active ";
    } else {
      return "";
    }
  };

  renderNav = () => {
    if (this.state.products_list) {
      return this.state.products_list.map((item, index) => {
        let link = `/idol-list/${helper.converseCodeToLink(item.product_code)}`;
        // console.log('link---', link);

        return (
          <Navbar className="nav nav-underline" key={index}>
            <NavLink to={link} activeclassname="active">
              <Nav.Link
                as="div"
                className={`nav-link txt-style ${this.renderTabActive(item.product_code)}`}
                onClick={() => {
                  // console.log("product click", item.product_code)
                  // this.getDocument(item.product_code, this.props.type);
                  this.setState({ activeTab: item.product_code });
                }}
              >
                {item.product_name}
              </Nav.Link>
            </NavLink>
          </Navbar>
        );
      });
    }
  };

  getProductList = (data) => {
    this.setState({products_list: data.products_list, activeTab: data.activeTab })
  }

  onHide = (value) => {
    this.props.onHide(value);
  };

  render() {
    const { show, data, product, type, idolTabName } = this.props;
    const { tabProfile, tabRemark, uploadPercentage, tabSelected, activeTab } = this.state;
    // console.log(this.props, "propsss");

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        size="xl"
        // id="login-form"
        // aria-labelledby="contained-modal-title-vcenter-l"
        centered
      >
        <div className="bg-modal">
          <Row className="position ml-3 mr-3 mt-3 mb-1 justify-side">
            <h5 className="text-center">Knowledge sharing</h5>
            <MdClose size={24} className="" onClick={() => this.onHide()} />
          </Row>

          <Card.Body className="background-modal-knowledge p-0">
            <Tab.Container id="profile-tabs" activeKey={tabProfile}>
              <Nav variant="pills" className="flex-row tabNavGeneral font-12">
                {this.renderNav()}
              </Nav>

              <Tab.Content className="background-tab">
                <Tab.Pane eventKey="general">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="topic"
                    onSelect={(tab)=>{this.setState({tabSelected: tab})}} >
                    <Row className="pt-2">
                      <Col sm={3} className="padding-navTab">
                        <Nav
                          variant="pills"
                          className="flex-column tabNavDocument mt-1"
                        >
                          <Nav.Item className="ml-2 mb-2">
                            <Nav.Link eventKey="topic">
                              <BiBookAlt className="mr-2 mt-1" />
                              Topic
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="ml-2 mb-2">
                            <Nav.Link eventKey="document">
                              <BiImage className="mr-2 mt-1" />
                              Document
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={9} className="pl-0 padding-tab">
                        <Tab.Content>
                          <Tab.Pane eventKey="document">
                            {(tabSelected == "document") &&
                              <KnowledgeDocuments 
                                product={activeTab}
                                type={type}
                                getProductsList={(data)=>this.getProductList(data)} />
                            }
                            
                          </Tab.Pane>
                          <Tab.Pane eventKey="topic">
                            {(tabSelected == "topic") &&
                              <KnowladgeTopic
                                product={activeTab}
                                type={type}
                                getProductsList={(data)=>this.getProductList(data)} />
                            }
                            
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    addidolId: state.addidolId,
    idolTabsList: state.idolTabsList,
    idolTabName: state.idolTabName,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loading: (value) => dispatch(loading(value)),
    checkAddIdol: (value) => dispatch(checkAddIdol(value)),
    checkToken: (value) => dispatch(checkToken(value)),
    setIdolTypeTabName: (value) => dispatch(setIdolTypeTabName(value))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalKnowledge));
