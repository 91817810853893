import React from "react";
import { Navbar, Nav, Tab, Button } from "react-bootstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout, setIsEditing, addDatasearch, setWorkflowTab, setIdolTab } from "../redux/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalKnowledge from "../components/ModalKnowledge";

const MySwal = withReactContent(Swal);

// import { Link, withRouter } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      tabHeader: "idol",
      modalKnowledge: false,
      product_code: '',
      Idol_type: '',
    };
  }

  async componentDidMount() {
    // if (process.env.NEXT_PUBLIC_ENV_MODE=="development") console.log("header mounted", this.props);
    // await this.props.dispatch(checkLogin());
    // await this.props.dispatch(checkAddFarm());
    // // console.log("kkkkkk", this.props.login)
    // if (this.props.login) {
    //     if (this.props.login.companyEntity !== null) {
    //         await this.setState({
    //             companyId: this.props.login.companyEntity.companyId,
    //             pid: this.props.login.pid,
    //         })
    //     }
    //     if (this.props.login.farmerEntity) {
    //         this.setState({ farmerTypeId: this.props.login.farmerEntity.farmerTypeId })
    //     }
    //     await this.setState({ imgUrl: this.props.login.imgUrl })
    // }
    // setTimeout(() => { this.getDashboard() }, 1000)
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (this.props.idolTabsList != prevProps.idolTabsList) {
    //     console.log('=====>', this.props.idolTabsList);
    // }
  }

  logout = () => {
    MySwal.fire({
      icon: "warning",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      text: "Do you want to Logout?",
    }).then((result) => {
      if (result.value) {
        this.props.logout(false);
      }
    });
  };

  checkIsEditing = (link) => {
    if (this.props.isEditing) {
      // MySwal.fire({
      //     icon: 'warning',
      //     confirmButtonText: 'Confirm',
      //     cancelButtonText: 'Cancel',
      //     showCancelButton: true,
      //     text: 'ข้อมูลบางอย่างที่คุณแก้ไขไว้ยังไม่ได้ถูกบันทึก คุณยืนยันที่จะออกจากหน้านี้หรือไม่?'
      // }).then((result) => {
      //     // console.log('rrr', result)
      //     if (result.value) {
      //         // console.log('sssssssssssssssssssssssss', result.value)
      //         this.props.setIsEditing(false);
      //         if (link == 'logout') {
      //           this.logout();
      //         } else {
      //           this.props.history.push(link);
      //         }
      //     }
      // });
      let r = window.confirm(
        "ข้อมูลบางอย่างที่คุณแก้ไขไว้ยังไม่ได้ถูกบันทึก คุณยืนยันที่จะออกจากหน้านี้หรือไม่?"
      );
      if (r == true) {
        this.props.setIsEditing(false);
        if (link == "logout") {
          this.logout();
        } else {
          this.props.history.push(link);
        }
      }
    } else {
      if (link == "logout") {
        this.logout();
      } else {
        this.props.history.push(link);
      }
    }
  };

  renderLinkActive = (name) => {
    const { history } = this.props;
    return history.location.pathname.includes(name);
  };

  openModalKnowledge = () => {
    let url = window.location.pathname;
    if(url.includes('/idol-list') || url.includes('/edit-idol')){
      this.setState({ modalKnowledge: true, product_code: 'GENERAL', Idol_type: 'idols' });
    }else if(url.includes('/photographer-list') || url.includes('/edit-photographer')){
      this.setState({ modalKnowledge: true, product_code: 'PHOTO_GENERAL', Idol_type: 'photographers' });
    } else {
      this.setState({ modalKnowledge: true, product_code: 'GENERAL', Idol_type: 'idols' });
    }
  };

  onHide = () => {
    this.setState({ modalKnowledge: false });
  };

  render() {
    const { isEditing, login } = this.props;
    return (
      <React.Fragment>
        <Navbar
          expand="lg"
          sticky="top"
          className="background-color-headfar justify-side"
        >
          {/* <Container> */}
          <div className="d-flex align-items-baseline">
            <Link to="/idol-list/all-product">
              {/* <Navbar.Brand href={'#'} onClick={(e)=>{e.preventDefault(); this.checkIsEditing("/idol-list/all-product")}}> */}
              <Navbar.Brand as="div">
                <img
                  className="mb-1"
                  src={
                    window.location.origin.toString() +
                    "/image/playboy-hori-logo.png"
                  }
                />
              </Navbar.Brand>
            </Link>
            <Tab.Container id="tabs-header">
              <Nav variant="pills" className="flex-row tabNavHaed">
                <Nav.Item>
                  <NavLink to="/idol-list/all-product">
                    <Nav.Link
                      as="div"
                      className={
                        this.renderLinkActive("idol") ? "active" : ""
                      }
                      onClick={() => {
                        if (!isEditing) this.setState({ tabHeader: "idol" });
                        this.props.addDatasearch(null)
                      }}
                    >
                      Idol
                    </Nav.Link>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/photographer-list/all-product">
                    <Nav.Link
                      as="div"
                      className={
                        this.renderLinkActive("photographer") ? "active" : ""
                      }
                      onClick={() => {
                        if (!isEditing) this.setState({ tabHeader: "photographer" });
                        this.props.addDatasearch(null)
                      }}
                    >
                      Photographer
                    </Nav.Link>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/dashboard">
                    <Nav.Link
                      as="div"
                      className={
                        this.renderLinkActive("dashboard") ? "active" : ""
                      }
                      onClick={() => {
                        if (!isEditing)
                          this.setState({ tabHeader: "dashboard" });
                      }}
                    >
                      Dashboard
                    </Nav.Link>
                  </NavLink>
                </Nav.Item>
                { login.data.role=="ADMIN" &&
                  <Nav.Item>
                    <NavLink to="/admin">
                      <Nav.Link
                        as="div"
                        className={
                          this.renderLinkActive("admin") ? "active" : ""
                        }
                        onClick={() => {
                          if (!isEditing)
                            this.props.setWorkflowTab([]);
                            this.setState({ tabHeader: "admin" });
                        }}
                      >
                        Admin
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                }
                
              </Nav>
            </Tab.Container>
            {/* <Nav className="mr-auto">
            <Nav.Link href="#" className="nav-link pd-nav text-color-whirth active" >Idol</Nav.Link>
            <Nav.Link href="#" className="nav-link pd-nav text-color-whirth">Dashboard</Nav.Link>
          </Nav> */}
          </div>
          <div className="d-flex just-f-end">
            <Button
              className="btn btn-primary mr-2"
              onClick={() => this.openModalKnowledge()}
            >
              Knowledge hub
            </Button>
            <Button
              className="btn-logout"
              onClick={() => this.checkIsEditing("logout")}
            >
              Logout
            </Button>
            {/* <Navbar.Text className="text-color-whirth mr-4">
            |
          </Navbar.Text>
          <div className="profile-header">
            <MdDehaze fontSize={20} className="icon-profile" />
            <img className="img-fluid-profile rounded-circle" width="40px" height="40px" src={window.location.origin + "/image/1.jpg"} />
          </div> */}
          </div>
          {/* </Container> */}
        </Navbar>

        {this.state.modalKnowledge &&
          <ModalKnowledge
            show={this.state.modalKnowledge}
            onHide={(value) => this.onHide(value)}
            product={this.state.product_code}
            type={this.state.Idol_type}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    isEditing: state.isEditing,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: (value) => dispatch(logout(value)),
    setIsEditing: (value) => dispatch(setIsEditing(value)),
    addDatasearch: value => dispatch(addDatasearch(value)),
    setWorkflowTab: (value) => dispatch(setWorkflowTab(value)),
    setIdolTab: value => dispatch(setIdolTab(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
