import React from "react";
import { Modal, Row, Col, Button, Form, } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { modalSale, checkLogin, checkAddIdol, modalExtract, checkWorkflowsCode, loading, checkToken } from "../redux/actions";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import api from '../utils/apis';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const API = api.getAPI();
const MySwal = withReactContent(Swal)

class ModalIdolStatus extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            selectButton: false,
            remark: '',
            nameData: [],
            saleSteamList: [],
            saleSteamSelectValue: [],
            saleSteamId: 0,
            statusName: [],
            reject: '',
            rejectMulti: [],
        };
    }

    async componentDidMount() {
        // if (this.props.login) {
        if (this.props.idolList !== undefined) {
            await this.setNameStatus(this.props.idolList)
        }
        // }

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.idolList != prevProps.idolList) {
            if (this.props.idolList !== undefined) {
                await this.setNameStatus(this.props.idolList)
            }
        }
    }

    setNameStatus = async (name) => {
        let nameStatus = [];
        let status = [];
        name.map((item) => {
            nameStatus.push(item.ig_username)
            // status.push(item.workflowsSelected)
        })
        await this.setState({ nameData: nameStatus })
        // console.log('hhhh', this.state.nameData);
    }


    onHide = (value) => {
        const { type } = this.props;
        console.log('type', type);
        this.props.onHide(value);
        if (type === 'multi') {
            this.setState({ selectButton: false, remark: '', saleSteamSelectValue: '' })
        } else {
            this.setState({ selectButton: false, remark: '', reject: '' })
        }

    };

    saveStatus = async () => {
        const { remark, saleSteamSelectValue, reject } = this.state;
        const { data, type, workflow, idolList, history } = this.props;

        // console.log('type', type);

        let response;
        if (type === 'multi') {
            const params = JSON.stringify({
                "product_code": workflow,
                "workflow_code": saleSteamSelectValue.workflowcode,
                "workflow_status_code": saleSteamSelectValue.value,
                "remark": remark.trim()
            });
            // console.log("dataAdd", params);
            // return;
            idolList.map(async (item, index) => {
                try {
                    response = await API.changeStatus(item.id, params)
                } catch (error) {
                    response = error.response
                    const token = { response, history }
                    this.props.checkToken(token);
                }
                if (response && response.status != 500) {
                    if (response.status == 200) {
                        if (index == idolList.length - 1) {
                            this.setState({ remark: '', saleSteamSelectValue: '' })
                            await this.onHide(false);
                            await this.props.updateIdolList();
                        }
                    } else {
                        MySwal.fire({
                            // customClass: 'swal-wide',
                            icon: "error",
                            title: "Error",
                            text: response.data.message
                        })
                        console.log(response.data.message)
                    }
                }
            })
        } else {
            const params = JSON.stringify({
                "product_code": data.product_code,
                "workflow_code": data.status.workflowcode,
                "workflow_status_code": data.status.value,
                "idol_reject_reason": reject,
                "remark": remark.trim()
            });
            // console.log("dataAdd", params);
            // return;
            try {
                response = await API.changeStatus(data.id, params)
            } catch (error) {
                response = error.response
                const token = { response, history }
                this.props.checkToken(token);
            }
            if (response && response.status != 500) {
                if (response.status == 200) {
                    this.setState({ remark: '' })
                    await this.onHide(false);
                    // console.log("response change status", response.data);
                    await this.props.updateIdolList();
                } else {
                    MySwal.fire({
                        // customClass: 'swal-wide',
                        icon: "error",
                        title: "Error",
                        text: response.data.message
                    })
                    console.log(response.data.message)
                }
            }
        }

    }

    selectSaleSteam = (optionSelected) => {
        this.setState({
            saleSteamId: optionSelected.value,
            saleSteamSelectValue: optionSelected
        });
    }

    render() {
        const { show, data, type, idolList, options } = this.props;
        const { remark, nameData } = this.state;
        // console.log("this.props", options);

        return (
            <Modal
                show={show}
                onHide={this.onHide}
                size="md"
                // id="login-form"
                // aria-labelledby="contained-modal-title-vcenter-l"
                centered
            >
                <div className="bg-modal">
                    <Row className="position m-3">
                        <h5 className="text-bold">Status</h5>
                        <Col md={12} className="p-0 mt-3">
                            <div className="txt-style-modal">Do you wish to change
                                {
                                    type === '' || type === 'edit' ?
                                        <>
                                            <b className="font-blue ml-1">{` ${data.ig_username ? data.ig_username : ""} `}</b>
                                            <span className="ml-1">status to "{data.status ? data.status.label : ""}"?</span>

                                            {data.status && data.status.label === 'ขอเหตุผล' &&
                                                <>
                                                    <CreatableSelect
                                                        className="mt-2"
                                                        onChange={(value) => {
                                                            this.setState({ reject: value.value })
                                                        }
                                                        }
                                                        options={data.status ? data.status.reject.map(item => ({ label: item, value: item })) : []}
                                                    />
                                                </>
                                            }

                                        </>
                                        :
                                        <>
                                            <div className="">
                                                {nameData.map((name, index) => <label className="font-blue mb-0" key={index}>{name},</label>)}
                                            </div>
                                            <label className="">status to</label>
                                        </>
                                }

                            </div>
                        </Col>
                    </Row>
                    <Row className="ml-3 mr-3 mb-3">
                        <Col md={12} className="p-0">
                            <Form>
                                {
                                    type === 'multi' ?
                                        <>
                                            <Col md={5} className="pl-0">
                                                <Form.Group>
                                                    <Select
                                                        placeholder="Status"
                                                        id="saleSteam"
                                                        name="saleSteam"
                                                        value={this.state.saleSteamSelectValue}
                                                        options={options}
                                                        onChange={(value) => {
                                                            console.log(value);
                                                            this.selectSaleSteam(value)
                                                            this.setState({ selectButton: true, rejectMulti: value.reject })
                                                        }
                                                        }
                                                        isSearchable={true}
                                                        isDisabled={false}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        ''
                                }

                                <Form.Group>
                                    <Form.Label>Enter remark</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        value={remark}
                                        onChange={e => {
                                            this.setState({ remark: e.target.value })
                                        }} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="m-0">
                        <Col className="pb-3 text-end">
                            <Button className="btn-cancel-extarct" onClick={() => this.onHide('cancelMulti')}>
                                Cancel
                            </Button>
                            <>
                                {type === 'multi' ?
                                    <>
                                        {this.state.selectButton ?
                                            <Button className="btn-addmore-extract mx-2" onClick={() => this.saveStatus()} >
                                                Save changes
                                            </Button>
                                            :
                                            <>
                                                <Button className="btn-addmore mx-2">
                                                    Save changes
                                                </Button>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <Button className="btn-addmore-extract mx-2" onClick={() => this.saveStatus()} >
                                            Save changes
                                        </Button>
                                    </>
                                }
                            </>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return { login: state.login };
};

function mapDispatchToProps(dispatch) {
    return {
        loading: value => dispatch(loading(value)),
        checkToken: value => dispatch(checkToken(value)),
        // openModal: (value) => dispatch(modalExtract(value)),
        // checkLogin: value => dispatch(checkLogin(value)),
        // checkAddIdol: value => dispatch(checkAddIdol(value)),
        // checkWorkflowsCode: value => dispatch(checkWorkflowsCode(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalIdolStatus));
