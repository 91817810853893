import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  loading,
  addDatasearch,
  delectDataSearch,
  setMasterDataCategory,
  setAdminMenuCategory,
  setMasterProduct
} from "../redux/actions";
import { Link, withRouter } from "react-router-dom";
import helper from "../utils/helper";
import { Menu } from "antd";
import "../css/menu.css";
import api from "../utils/apis";
const API = api.getAPI();
const { SubMenu } = Menu;

class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: true,
      open: true,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.masterData != prevProps.masterData) {
      if (this.props.adminMenuCategory == "masterdata"){
        this.mapMenuData();
      }
    }
    if (this.props.masterProduct != prevProps.masterProduct) {
      if (this.props.adminMenuCategory == "products-workflow"){
        this.mapProductMenuData();
      }
    }
  }

  mapMenuData = () => {
    const {
      masterData: { options },
      masterDataCategory,
    } = this.props;
    const firstMenu = options.find((item, index) => {
      if (masterDataCategory) {
        return item.option_type == masterDataCategory;
      } else {
        return index == 0;
      }
    });
    if (firstMenu) {
      this.props.setMasterDataCategory(firstMenu.option_type);
    }

    // this.setState({ sidebarMenuDefault: firstMenu.option_type_code });
  };

  mapProductMenuData = () => {
    const {
      masterProduct,
      masterDataCategory,
    } = this.props;
    // const firstMenu = masterProduct.find((item, index) => {
    //   if (masterDataCategory) {
    //     return item.option_type == masterDataCategory;
    //   } else {
    //     return index == 0;
    //   }
    // });
    // if (firstMenu) {
    //   this.props.setMasterDataCategory(firstMenu.option_type);
    // }

    this.props.setMasterDataCategory("products");
  };

  handleClick = (e) => {
    // console.log("menu click", e);
    if (e.keyPath.includes("masterdata")) {
      this.props.setMasterDataCategory(Number(e.key));
      this.props.setAdminMenuCategory("masterdata");
    }
    if (e.keyPath.includes("users")) {
      this.props.setAdminMenuCategory("users");
    }
    if (e.keyPath.includes("products-workflow")) {
      this.props.setAdminMenuCategory("products-workflow");
    }
  };

  render() {
    // const { open, openMetric, platform, region, country, attractive, availableLanguage, availableOccupation, availableReputation, availableWork_type, infoLanguage, infoAttractivepoint, infoOccupation, infoReputation, infoWorktype, role, infoSales, saleSteamList } = this.state;
    // let sidebarClass = this.state.sidebarOpen ? 'sidebar open' : 'sidebar';
    // let sidebar = this.state.sidebarOpen ? 'icon-silde' : 'icon-sildeLeft';
    const { masterData, masterDataCategory, masterProduct } = this.props;
    // console.log("master data", masterData, String(masterDataCategory));
    return (
      <>
        <section className="">
          <Row className="m-0">
            <Col className="p-0 d-flex position-side">
              {masterData && masterDataCategory && (
                <Menu
                  onClick={this.handleClick}
                  style={{ width: "20%" }}
                  defaultSelectedKeys={[String(masterDataCategory)]}
                  defaultOpenKeys={["masterdata"]}
                  mode="inline"
                  className="admin-menu border-right"
                >
                  <Menu.Item key="users">
                    <span className="font-weight-bold pr-1">Users</span>
                  </Menu.Item>
                  <SubMenu
                    key="masterdata"
                    title={
                      <span className="font-weight-bold pr-1">Master Data</span>
                    }
                  >
                    {masterData &&
                      masterData.options.map((item) => (
                        <Menu.Item key={String(item.option_type)}>
                          {item.option_type_name}
                        </Menu.Item>
                      ))}
                  </SubMenu>
                  <SubMenu
                    key="products-workflow"
                    title={
                      <span className="font-weight-bold pr-1">Product workflow</span>
                    }
                  >
                    {/* {masterProduct &&
                      masterProduct.map((item) => (
                        <Menu.Item key={String(item.product_code)}>
                          {item.product_name}
                        </Menu.Item>
                      ))} */}
                      <Menu.Item key={String("products")}>
                          {"Products"}
                        </Menu.Item>
                  </SubMenu>
                </Menu>
              )}
              <div className="w-100 childen-table">{this.props.children}</div>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //   console.log('state', state)
  return {
    searchIdol: state.searchIdol,
    login: state.login,
    masterData: state.allMasterData,
    masterDataCategory: state.masterDataCategory,
    masterProduct: state.allMasterProduct,
    adminMenuCategory: state.adminMenuCategory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    addDatasearch: (value) => dispatch(addDatasearch(value)),
    delectDataSearch: (value) => dispatch(delectDataSearch(value)),
    setMasterDataCategory: (value) => dispatch(setMasterDataCategory(value)),
    setAdminMenuCategory: (value) => dispatch(setAdminMenuCategory(value)),
    setMasterProduct: (value) => dispatch(setMasterProduct(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideMenu));
