export const actionTypes = {
    FAILURE: 'FAILURE',
    LOG_IN: 'LOG_IN',
    CHECK_LOGIN: 'CHECK_LOGIN',
    MODAL_ADD: 'MODAL_ADD',
    LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
    MODAL_SALE: 'MODAL_SALE',
    MODAL_EXTRACT: 'MODAL_EXTRACT',
    IDOL_ID: 'IDOL_ID',
    IDOL_ID_SUCCESS: 'IDOL_ID_SUCCESS',
    CHECK_IDOL_ID: 'CHECK_IDOL_ID',

    WORKFLOWS_CODE: 'WORKFLOWS_CODE',
    WORKFLOWS_CODE_SUCCESS: 'WORKFLOWS_CODE_SUCCESS',
    CHECK_WORKFLOWS_CODE: 'CHECK_WORKFLOWS_CODE',
    SET_EDITING: 'SET_EDITING',
    SET_IDOL_TABS: 'SET_IDOL_TABS',
    SET_WORKFLOW_TABS: 'SET_WORKFLOW_TABS',
    SET_IDOL_TAB_NAME: 'SET_IDOL_TAB_NAME',
    SET_WORKFLOW_TAB_NAME: 'SET_WORKFLOW_TAB_NAME',

    SEARCH_IDOL: 'SEARCH_IDOL',
    SEAECH_SUCCESS: 'SEAECH_SUCCESS',
    ADD_SEARCH: 'ADD_SEARCH',
    ADD_SEAECH_SUCCESS: 'ADD_SEAECH_SUCCESS',
    DELECT_SEARCH: 'DELECT_SEARCH',

    GET_MASTER_DATA: "GET_MASTER_DATA",
    SET_MASTER_DATA: "SET_MASTER_DATA",
    SET_MASTER_DATA_CATEGORY: "SET_MASTER_DATA_CATEGORY",
    GET_MASTER_PRODUCTS: "GET_MASTER_PRODUCTS",
    SET_MASTER_PRODUCTS: "SET_MASTER_PRODUCTS",
    SET_MASTER_DATA_CATEGORY: "SET_MASTER_DATA_CATEGORY",
    SET_IDOL_TYPE_TAB_NAME: "SET_IDOL_TYPE_TAB_NAME",
    SET_ADMIN_MENU_CATEGORY: "SET_ADMIN_MENU_CATEGORY",
    
    LOADING: 'LOADING',
    LOG_OUT: 'LOG_OUT',
    LOG_OUT_SUCCESS: 'LOG_OUT_SUCCESS',
    CHECK_TOKEN: 'CHECK_TOKEN',
}

export function failure(error) {
    return {
        type: actionTypes.FAILURE,
        error,
    }
}

export function addLogin(payload) {
    return { type: actionTypes.LOG_IN, payload }
};

export function searchIdol(payload) {
    return { type: actionTypes.SEARCH_IDOL, payload }
};

export function addDatasearch(payload) {
    return { type: actionTypes.ADD_SEARCH, payload }
};

export function delectDataSearch(payload) {
    return { type: actionTypes.DELECT_SEARCH, payload }
};

export function addModal(payload) {
    return { type: actionTypes.MODAL_ADD, payload }
};

export function checkLogin() {
    return { type: actionTypes.CHECK_LOGIN}
};
  
export function modalSale(payload) {
    return { type: actionTypes.MODAL_SALE, payload}
};

export function modalExtract(payload) {
    return { type: actionTypes.MODAL_EXTRACT, payload}
};

export function addidolId(payload) {
    return { type: actionTypes.IDOL_ID, payload}
};

export function workflowsCode(payload) {
    return { type: actionTypes.WORKFLOWS_CODE, payload}
};
export function checkAddIdol() {
    return { type: actionTypes.CHECK_IDOL_ID}
};

export function checkWorkflowsCode() {
    return { type: actionTypes.CHECK_WORKFLOWS_CODE}
};

export function setIsEditing(payload) {
    return { type: actionTypes.SET_EDITING, payload}
};

export function setIdolTab(payload) {
    return { type: actionTypes.SET_IDOL_TABS, payload}
};

export function setWorkflowTab(payload) {
    return { type: actionTypes.SET_WORKFLOW_TABS, payload}
};

export function setIdolTabName(payload) {
    return { type: actionTypes.SET_IDOL_TAB_NAME, payload}
};

export function setWorkflowTabName(payload) {
    return { type: actionTypes.SET_WORKFLOW_TAB_NAME, payload}
};

export function getMasterData(payload) {
    return { type: actionTypes.GET_MASTER_DATA, payload}
};

export function setMasterDataCategory(payload) {
    return { type: actionTypes.SET_MASTER_DATA_CATEGORY, payload}
};

export function getMasterProduct(payload) {
    return { type: actionTypes.GET_MASTER_PRODUCTS, payload}
};

export function setMasterProduct(payload) {
    return { type: actionTypes.SET_MASTER_PRODUCTS, payload}
};

export function setIdolTypeTabName(payload) {
    return { type: actionTypes.SET_IDOL_TYPE_TAB_NAME, payload}
};

export function setAdminMenuCategory(payload) {
    return { type: actionTypes.SET_ADMIN_MENU_CATEGORY, payload}
};

export function logout(payload) {
    return { type: actionTypes.LOG_OUT, payload }
};

export function loading(payload) {
    return { type: actionTypes.LOADING, payload }
};

export function checkToken(payload) {
    return { type: actionTypes.CHECK_TOKEN, payload }
};