import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.files != prevProps.files) {
      this.setState({imagePreviewUrl: null})
    }
  }

  _handleImageChange(e) {
    e.preventDefault();
    const fileTypeArray = ["image/png", "image/jpg", "image/jpeg"];
    let reader = new window.FileReader();
    let file = e.target.files[0];

    if (file && fileTypeArray.includes(file.type)) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
    this.props.onChange(file);
  }

  renderImage = () => {
    let { imagePreviewUrl } = this.state;
    if (imagePreviewUrl) {
      return <img className="img-fluid rounded-circle" src={imagePreviewUrl} />;
    } else {
      if (this.props.files) {
        return (
          <img className="img-fluid rounded-circle" src={this.props.files} />
        );
      } else {
        return (
          <img
            className="img-fluid rounded-circle"
            src={window.origin + "/image/profile_avatar.png"}
          />
        );
      }
    }
  };

  render() {
    const { imagePreviewUrl } = this.state;
    return (
      <div className="upload-btn-wrapper">
        {imagePreviewUrl ? (
          <img
            src={imagePreviewUrl}
            className="img-fluid rounded-circle profile-pic"
            alt="User Image"
            style={{
              width: "10vmax",
              height: "10vmax",
              backgroundColor: "gray",
            }}
          />
        ) : this.props.files ? (
          <img className="img-fluid rounded-circle profile-pic" src={this.props.files} />
        ) : (
          <img
            src={window.origin + "/image/profile_avatar.png"}
            className="img-fluid rounded-circle profile-pic"
            style={{ width: "10vmax", height: "10vmax", color: "#ccc" }}
          />
        )}
        <div className="upload-btn" style={{ cursor: "pointer" }}>
        <FontAwesomeIcon  size="lg" icon={['fas', 'edit']} />
        </div>
        <input
          type="file"
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => this._handleImageChange(e)}
          onClick={(e) => e.target.value = null}
        />
      </div>
    );
  }
}

export default ImageUpload;
