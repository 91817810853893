import { actionTypes } from './actions'
const initialState = {
  login: false,
  addmodal: false,
  salemodal: false,
  extractmodal: false,
  addidolId: false,
  workflowsCode: false,
  isEditing: false,
  idolTabsList: [],
  workflowTabsList: [],
  idolTabName: "",
  workflowTabName: "",
  loading: false,
  searchIdol: "",
  addDatasearch: null,
  allMasterData: null,
  allMasterProduct: null,
  idolTypeTabName: "idols",
  masterDataCategory: null,
  adminMenuCategory: "masterdata"
};

function rootReducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOG_IN:
      return { ...state, login: action.payload };

    case actionTypes.LOG_IN_SUCCESS:
      return { ...state, login: action.payload };

    case actionTypes.MODAL_ADD:
      return { ...state, addmodal: action.payload };

    case actionTypes.MODAL_SALE:
      return { ...state, salemodal: action.payload };

    case actionTypes.IDOL_ID:
      return { ...state, addidolId: action.payload };

    case actionTypes.IDOL_ID_SUCCESS:
      return { ...state, addidolId: action.payload };

    case actionTypes.WORKFLOWS_CODE:
      return { ...state, workflowsCode: action.payload };

    case actionTypes.WORKFLOWS_CODE_SUCCESS:
      return { ...state, workflowsCode: action.payload };

    case actionTypes.LOADING:
      return { ...state, loading: action.payload };

    case actionTypes.MODAL_EXTRACT:
      return { ...state, extractmodal: action.payload };

    case actionTypes.SET_EDITING:
      return { ...state, isEditing: action.payload };

    case actionTypes.SET_IDOL_TABS:
      return { ...state, idolTabsList: action.payload };

    case actionTypes.SET_WORKFLOW_TABS:
      return { ...state, workflowTabsList: action.payload };

    case actionTypes.SET_IDOL_TAB_NAME:
      return { ...state, idolTabName: action.payload };

    case actionTypes.SET_WORKFLOW_TAB_NAME:
      return { ...state, workflowTabName: action.payload };

    case actionTypes.SEAECH_SUCCESS:
      return { ...state, searchIdol: action.payload };

    case actionTypes.ADD_SEAECH_SUCCESS:
      return { ...state, addDatasearch: action.payload };

    case actionTypes.DELECT_SEARCH:
      return { ...state, addDatasearch: action.payload };

    case actionTypes.SET_MASTER_DATA:
        return { ...state, allMasterData: action.payload };

    case actionTypes.SET_MASTER_DATA_CATEGORY:
        return { ...state, masterDataCategory: action.payload };

    case actionTypes.SET_MASTER_PRODUCTS:
        return { ...state, allMasterProduct: action.payload };
    
    case actionTypes.SET_IDOL_TYPE_TAB_NAME:
        return { ...state, idolTypeTabName: action.payload };

    case actionTypes.SET_ADMIN_MENU_CATEGORY:
          return { ...state, adminMenuCategory: action.payload };

    default:
      return state;
  }

};

export default rootReducer;