import { httpClient } from './httpClient';
import helper from './helper';

const getAPI = () => {
    
    const login = async (params) => {
        return await httpClient.post(`/auth/signin`, params);
    }

    const getIdols = async (product_code, workflow_code, page = 1, limit = 100, idolType, sort_key = "", sort_order = "ASC", data) => {

        let str = `product_code=${product_code}&page=${page}&limit=${limit}&idol_type=${idolType}`;
       
        if (workflow_code) str = `${str}&workflow_code=${workflow_code}`;
        if (sort_key) str = `${str}&sort_key=${sort_key}`;
        if (sort_order) str = `${str}&sort_order=${sort_order}`;

        for (const key in data) {
            if (data.hasOwnProperty(key) && data.key != "") {
                str = `${str}&${key}=${data[key]}`; 
            }
        }

        return await httpClient.get(`/idols?${str}`);
    }

    const getIdolInstagram = async (username) => {
        return await httpClient.get(`/ig/user/search?username=${username}`);
    }

    const getIgUser = async (id) => {
        return await httpClient.get(`/ig/user/${id}`);
    }

    const getIdol = async (id) => {
        return await httpClient.get(`/idols/${id}`);
    }

    const addIdolIg = async (params) => {
        return await httpClient.post(`/idols`, params)
    }

    const getSaleSteam = async () => {
        return await httpClient.get(`/users/salesteam`);
    }

    const addSale = async (id, params) => {
        return await httpClient.patch(`/idols/${id}/sales`, params);
    }

    const addRemark = async (id, params) => {
        return await httpClient.post(`/idols/${id}/logs`, params);
    }

    const editIdol = async (id, params) => {
        return await httpClient.patch(`/idols/${id}`, params);
    }

    const changeStatus = async (id, params) => {
        return await httpClient.patch(`/idols/${id}/status`, params);
    }

    const uploadFiles = async (id, dataFile) => {
        return await httpClient.post(`/idols/${id}/files`, dataFile);
    }

    const deleteFiles = async (id, data) => {
        return await httpClient.delete(`/idols/${id}/files`, {data: data});
    }

    const commentFiles = async (id, data) => {
        return await httpClient.post(`/idols/${id}/files/comments`, data);
    }

    const getCreatorReport = async (page = 1, limit = 30) => {
        return await httpClient.get(`/users/creatorreport?page=${page}&limit=${limit}`);
    }

    const getExtractDataBySalesReport = async (page = 1, limit = 30) => {
        return await httpClient.get(`/users/informationextractstatusreport?page=${page}&limit=${limit}`);
    }

    const getRejectReasonReport = async (page = 1, limit = 30) => {
        return await httpClient.get(`/users/idolrejectreasonreport?page=${page}&limit=${limit}`);
    }

    const getIdolByStatusReport = async (page = 1, limit = 30) => {
        return await httpClient.get(`/users/idolbystatusreport?page=${page}&limit=${limit}`);
    }
    
    const addProduct = async (id, data) => {
        return await httpClient.patch(`/idols/${id}/products`, data);
    }

    const deleteIdol = async (id) => {
        return await httpClient.delete(`/idols/${id}`);
    }

    const uploadFilesDocument = async (dataFile) => {
        return await httpClient.post(`/knowledges/documents`, dataFile);
    }

    const getDocument = async (product_code, idol_type) => {
        return await httpClient.get(`/knowledges/documents?product_code=${product_code}&idol_type=${idol_type}`);
    }

    const deleteDocument = async (id) => {
        return await httpClient.delete(`/knowledges/documents/${id}`);
    }

    const getTopic = async (product_code, idol_type, search = "") => {
        if (search.trim()!="") {
            return await httpClient.get(`/knowledges/topics?product_code=${product_code}&idol_type=${idol_type}&search_title=${search}`);
        } else {
            return await httpClient.get(`/knowledges/topics?product_code=${product_code}&idol_type=${idol_type}`);
        }
    }

    const saveTopic = async (dataFile) => {
        return await httpClient.post(`/knowledges/topics`, dataFile);
    }

    const addTags = async (id, data) => {
        return await httpClient.patch(`/knowledges/documents/${id}/tags`, data);
    }
    
    const getIdolProduct = async (id, product_code) => {
        return await httpClient.get(`/idols/${id}/${product_code}`);
    }

    const updateCheckList = async (id, data) => {
        return await httpClient.patch(`/idols/${id}/checklist_data`, data);
    }

    const getMasterData = async (idol_type) => {
        return await httpClient.get(`/masters/${idol_type}`);
    }

    const updateMasterData = async (id, data) => {
        return await httpClient.patch(`/masters/${id}`, data);
    }

    const addMasterData = async (data) => {
        return await httpClient.post(`/masters`, data);
    }

    const deleteMasterData = async (id) => {
        return await httpClient.delete(`/masters/${id}`);
    }

    const getUserData = async () => {
        return await httpClient.get(`/users`);
    }

    const createUser = async (data) => {
        return await httpClient.post(`/users`, data);
    }

    const updateUser = async (id, data) => {
        return await httpClient.patch(`/users/${id}`, data);
    }

    const deleteUser = async (id) => {
        return await httpClient.delete(`/users/${id}`);
    }

    const resetUserPassword = async (id) => {
        return await httpClient.get(`/users/${id}/resetpassword`);
    }

    const changeUserPassword = async (data) => {
        return await httpClient.patch(`/auth/password`, data);
    }

    const getProducts = async (idol_type) => {
        return await httpClient.get(`/products?idol_type=${idol_type}`);
    }

    const addProducts = async (data) => {
        return await httpClient.post(`/products`, data);
    }

    const updateProducts = async (id, data) => {
        return await httpClient.patch(`/products`, data);
    }

    const deleteProducts = async (id) => {
        return await httpClient.delete(`/products/${id}`);
    }

    const getProductsWorkflow = async (id) => {
        return await httpClient.get(`/products/${id}/manageworkflow`);
    }

    const updateProductsWorkflow = async (id, data) => {
        return await httpClient.patch(`/products/${id}/manageworkflow`, data);
    }

    const getProductsCancelData = async (id, product_data_type = "WORKFLOW") => {
        return await httpClient.get(`/products/candeletedata?id=${id}&delete_product_data_type=${product_data_type}`);
    }

    return {
        // connectTokenAPI,
        login,
        getIdols,
        getIdolInstagram,
        getIgUser,
        addIdolIg,
        getSaleSteam,
        addSale,
        getIdol,
        addRemark,
        editIdol,
        changeStatus,
        uploadFiles,
        deleteFiles,
        commentFiles,
        getCreatorReport,
        addProduct,
        getExtractDataBySalesReport,
        getRejectReasonReport,
        getIdolByStatusReport,
        deleteIdol,
        uploadFilesDocument,
        getDocument,
        deleteDocument,
        getTopic,
        saveTopic,
        addTags,
        getIdolProduct,
        updateCheckList,
        getMasterData,
        updateMasterData,
        addMasterData,
        deleteMasterData,
        getUserData,
        createUser,
        updateUser,
        deleteUser,
        resetUserPassword,
        changeUserPassword,
        getProducts,
        addProducts,
        updateProducts,
        deleteProducts,
        getProductsWorkflow,
        updateProductsWorkflow,
        getProductsCancelData
    }
}
export default {
    getAPI
}
