import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import InputMask from 'react-input-mask';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  OverlayTrigger,
  Popover,
  Nav,
  Modal,
  Form,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Table } from "antd";
import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../utils/apis";
import ImageUpload from "../components/ImageUpload";
import helper from "../utils/helper";
import {
  loading,
  checkLogin,
  getMasterData,
  setIdolTypeTabName,
  checkToken,
} from "../redux/actions";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class MasterData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableRows: [],
      selectedRowKeys: [],
      selectedRows: [],
      isEditing: false,
      isNewDuplicate: false,
      isNewEmpty: false,
      form: {
        id: "",
        userName: "",
        nickName: "",
        firstName: "",
        lastName: "",
        role: "",
        saleTeam: [],
        userImage: ""
      },
      roleList: [
        {label: "Admin", value: "ADMIN"},
        {label: "Supervisor", value: "SUPERVISOR"},
        {label: "Sales", value: "SALES"},
        {label: "Agent", value: "AGENT"}
      ],
      roleSelected: null,
      salesList: [],
      salesSelected: [],
      imageFile: null,
      invalidMsg: {
        userName: "",
      },
      validated: {
        userName: false,
        nickName: false,
        firstName: false,
        lastName: false,
        role: false,
        saleTeam: false,
        userImage: false
      },
      resetImage: true
    };
  }

  async componentDidMount() {
    this.props.checkLogin();
    this.getUsers();
    // this.getSaleTeam();
  }

  getUsers = async () => {
    const { history } = this.props;
    let response;
    this.props.loading(true);
      try {
        response = await API.getUserData();
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      if (response && response.status != 500) {
        if (response.status == 200) {
          // console.log("all users", response.data)
          this.setState({
            tableRows: response.data.users_list,
            salesList: response.data.available_sales.map(item=>{
              return {label: `${item.firstname}`, value: item.id}
            })
          })
        } else {
          console.log(response.data.message);
        }
      }
  }

  getSaleTeam = async() => {
    const { history } = this.props;
    let response;
    // this.props.loading(true);
      try {
        response = await API.getSaleSteam();
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      // this.props.loading(false);
      if (response && response.status != 500) {
        if (response.status == 200) {
          // console.log("sale team", response.data)
          this.setState({salesList: response.data.map(item=>{
            return {label: `${item.firstname}`, value: item.id}
          })})
        } else {
          console.log(response.data.message);
        }
      }
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (this.props.masterData != prevProps.masterData) {
    //   this.mapTableData();
    // }
    // if (this.props.masterDataCategory != prevProps.masterDataCategory) {
    //   this.mapTableData();
    // }
    // if (this.props.idolTypeTabName != prevProps.idolTypeTabName) {
    //   this.mapTableData();
    // }
  }

  mapTableData = () => {
    const { masterData, masterDataCategory } = this.props;
    const tableData = masterData.options.find((item) => {
      return item.option_type == masterDataCategory;
    });
    // console.log("table", tableData, masterDataCategory);
    if (tableData) {
      this.setState({ tableRows: tableData.option_values });
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
    this.setState({ selectedRowKeys, selectedRows });
  };


  renderTableData = () => {
    let scrolling = { x: 600 };
    const { selectedRowKeys, tableRows, roleList } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      // getCheckboxProps: record => {
      //     if (role == 'SALES' || role == 'AGENT') {
      //         return (({ disabled: record.id }))  // Column configuration not to be checked
      //     }
      // },
    };

    const columns = [
      {
        title: "Username",
        dataIndex: "username",
        render: (name, row, i) => {
          return (
            <div className="d-flex align-items-center px-3">
              <img className="img-acc" src={row.image_url} />
              <div className="font-weight-bold ml-3">{row.username}</div>
            </div>
          );
        },
        sorter: (a, b) => {
          return a.username.localeCompare(b.username);
        },
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Nickname",
        dataIndex: "nickname",
        render: (name, row, i) => {
          return (
            <div>{row.nickname}</div>

          );
        },
        sorter: (a, b) => a.nickname.localeCompare(b.nickname),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Roles",
        dataIndex: "role",
        render: (name, row, i) => {
          const roleName = roleList.find(item=>{
            return item.value==row.role;
          })
          return (
            <div>{roleName.label}</div>
          );
        },
        sorter: (a, b) =>  a.role.localeCompare(b.role),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Last modified by",
        dataIndex: "updated_by_name",
        render: (name, row, i) => {
          return (
            <div className="d-flex align-items-center justify-content-center px-3">
              <img className="img-acc" src={row.updated_by_image_url} />
              <div className="font-weight-bold ml-3">{row.updated_by_name}</div>
            </div>
          );
        },
        sorter: (a, b) => a.updated_by_name.localeCompare(b.updated_by_name),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Last modified",
        dataIndex: "updated_at",
        render: (name, row, i) => {
          return (
            <div className="p-0 pt-2 align-ver mr-1 text-center">
              {`${new Intl.DateTimeFormat("en-GB", {
                dateStyle: "medium",
                timeStyle: "short",
              }).format(new Date(row.updated_at))}`}
            </div>
          );
        },
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
    ];

    // if (tableRows && tableRows.length >= 20) {
    //   scrolling = { x: 600, y: 650 };
    // }

    return (
      <>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableRows}
          size="small"
          className={"table-width mt-3 master-data-table"}
          scroll={scrolling}
          rowKey={(record) => record.id}
          responsive
          pagination={{
            className: "my-4",
            position: ["bottomCenter"],
            size: "default",
            showSizeChanger: false,
            current: 1,
            total: tableRows.length,
            pageSize: tableRows.length,
            hideOnSinglePage: true,
          }}
          onChange={async (pagination, filters, sorter) => {
            // console.log("pagination", pagination,  "sorter", sorter);
            // const { currentSort } = await this.state;
            // currentSort.currentPage = await pagination.current;
            // currentSort.sortField = await sorter.field;
            // if (sorter.order == "ascend") {
            //     currentSort.sortOrder = "ASC";
            // } else if (sorter.order == "descend") {
            //     currentSort.sortOrder = "DESC";
            // } else {
            //     currentSort.sortOrder = "ASC";
            // }
            // await this.getIdolList(this.props.addDatasearch);
            // await helper.sessionSave("currentSort", currentSort);
            // this.setState({tableRows})
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => {
                // console.log("row", record)
                const { roleList, salesList, validated, invalidMsg } = this.state;
                validated.userName = false;
                invalidMsg.userName = "";
                this.setState({
                  form: {
                    id:  record.id,
                    userName: record.username,
                    nickName: record.nickname,
                    firstName: record.firstname,
                    lastName: record.lastname,
                    role: record.role,
                    userImage: null
                  },
                  isEditing: true,
                  roleSelected: roleList.find(item=>{
                    return item.value == record.role;
                  }),
                  salesSelected: record.subordinate_ids ? salesList.filter(item=>{
                    if (record.subordinate_ids.includes(item.value)) {
                      return item;
                    }
                  }) : [],
                  validated, invalidMsg
                })
                setTimeout(() => {
                  let  { form } = this.state;
                  form.userImage = record.image_url;
                  this.setState({form})
                }, 50);
              },
            };
          }}
        />
      </>
    );
  };

  checkNewDuplicate = (value) => {
    const { tableRows } = this.state;
    let is_duplicate = false;
    tableRows.forEach((item) => {
      if (item.option_name == value) {
        is_duplicate = true;
      }
    });
    return is_duplicate;
  };

  validateForm = () => {
    const { form, validated, roleSelected, imageFile, invalidMsg } = this.state;
    let is_invalid = false;
    // if (!imageFile) {
    //   is_invalid = true;
    //   validated.userImage = true;
    //   this.setState({ validated });
    // }
    
    if (form.userName.trim() == "") {
      is_invalid = true;
      validated.userName = true;
      invalidMsg.userName = "กรุณากรอก Username"
      this.setState({ validated, invalidMsg });
    }
    if (form.userName.length > 0 && form.userName.length < 7) {
      is_invalid = true;
      validated.userName = true;
      invalidMsg.userName = "กรุณาใส่ Username เป็นตัวเลขให้ครบ 7 หลัก"
      this.setState({ validated, invalidMsg });
    }
    if (form.nickName.trim() == "") {
      is_invalid = true;
      validated.nickName = true;
      this.setState({ validated });
    }
    if (form.firstName.trim() == "") {
      is_invalid = true;
      validated.firstName = true;
      this.setState({ validated });
    }
    if (form.lastName.trim() == "") {
      is_invalid = true;
      validated.lastName = true;
      this.setState({ validated });
    }
    if (!roleSelected) {
      is_invalid = true;
      validated.role = true;
      this.setState({ validated });
    }

    return is_invalid;
  };

  addNewData = async () => {
    if (!this.validateForm()) {
      const { form, roleSelected, salesSelected, imageFile } = this.state;
      const {  history } = this.props;
      let file;
      const formData = await {
        username: form.userName,
        firstname: form.firstName,
        lastname: form.lastName,
        nickname: form.nickName,
        role: roleSelected.value,
        subordinate_ids: salesSelected ?
          salesSelected.map(item=>{
            return item.value
          }) : [],
        // content_type: imageFile? imageFile.type:file.type
      }
      if (imageFile) {
        formData.content_type = imageFile.type;
      } else {
        await helper.getFileFromUrl(window.origin + "/image/profile_avatar.png").then(async result=>{
          file = await result;
          formData.content_type = await file.type;
        })
      }      
      // await console.log("formdata", formData, imageFile, file)
      let response;
      try {
        response = await API.createUser(formData);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      // console.log("add new user", response);
      if (response && response.status != 500) {
        if (response.status == 201) {
            this.uploadAvatar(response.data, imageFile? imageFile: file);
            this.clearForm();
        } else {
          console.log(response.data.message);
          if (response.data.statusCode==409) {
            const {validated, invalidMsg } = this.state;
            validated.userName = true;
            invalidMsg.userName = "Username นี้ มีในระบบแล้ว";
            this.setState({ validated, invalidMsg });
            // MySwal.fire({
            //   icon: "warning",
            //   title: "ท่านกรอก Username ที่มีการใช้งานแล้ว",
            // }).then((value) => {});
          }
        }
      }
    }
  };

  uploadAvatar = (data, file) => {
    this.props.loading(true);
    const option = {
      headers: {
          "Content-Type": data.content_type,
      }
    }
    axios.put(data.upload_link, file, option).then(res => {
      // console.log(res);
      this.props.loading(false);
      this.getUsers();
  })
  }

  updateData = async (row) => {
    const { history } = this.props;
    const { isEditing, form, roleSelected, salesSelected, imageFile } = this.state;
    if (!this.validateForm() && isEditing) {
      let response;
      const formData = await {
        username: form.userName,
        firstname: form.firstName,
        lastname: form.lastName,
        nickname: form.nickName,
        role: roleSelected.value,
        subordinate_ids: salesSelected ?
          salesSelected.map(item=>{
            return item.value
          }) : [],
      }
      if (imageFile) {
        formData.content_type = imageFile.type;
      }     
      // await console.log("formdata", formData, imageFile)
      // return;
      
      try {
        response = await API.updateUser(form.id, formData);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      if (response && response.status != 500) {
        // console.log("update user", response);
        if (response.status == 200) {
          if (imageFile) {
            this.setState({tableRows: []})
            this.uploadAvatar(response.data, imageFile);
          } else {
            this.getUsers();
          }
          this.clearForm();
        } else {
          console.log(response.data.message);
        }
      }
    }
  };


  toDelete = () => {
    const { selectedRowKeys } = this.state;
    // console.log("row keys", selectedRowKeys);
    if (selectedRowKeys.length === 0) {
      MySwal.fire({
        icon: "warning",
        title: "เลือกอย่างน้อย 1 รายการ",
      }).then((value) => {});
    }
    if (selectedRowKeys.length >= 1) {
      MySwal.fire({
        icon: "warning",
        // title: "ต้องการลบข้อมูล?",
        html: "ข้อมูลที่เลือกทั้งหมดจะถูกลบจากระบบ<br/> คุณยืนยันการลบหรือไม่",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteData();
        }
      });
    }
  };

  deleteData = () => {
    const { selectedRowKeys } = this.state;
    const { history } = this.props;
    let response;
    selectedRowKeys.forEach(async (item, index) => {
      try {
        response = await API.deleteUser(item);
        // console.log("response", response);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      if (response && response.status != 500) {
        if (response.status == 200) {
          if (index == selectedRowKeys.length - 1) {
            this.getUsers();
            this.setState({ selectedRowKeys: [], selectedRows: [] });
            this.clearForm();
          }
        }
      }
    });
  };

  toResetPassword = () => {
    MySwal.fire({
      icon: "warning",
      html: "ต้องการ reset password ของ user นี้ใช่หรือไม่",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.resetPassword();
      }
    });
  }

  resetPassword = async () => {
    const { form } = this.state;
    const { history } = this.props;
    let response;
    try {
      response = await API.resetUserPassword(form.id);
      // console.log("response", response);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      if (response.status == 200) {
        MySwal.fire({
          icon: "success",
          html: "Password of this user has already been reset to <br>Aa123456</b>.",
        });
      }
    }
  };

  clearForm = () => {
    this.setState({
      form: {
        id: "",
        userName: "",
        nickName: "",
        firstName: "",
        lastName: "",
        role: "",
        saleTeam: [],
        userImage: ""
      },
      isEditing: false,
      selectedRowKeys: [],
      selectedRows: [],
      roleSelected: null,
      salesSelected: [],
      imageFile: null,
      invalidMsg: {
        userName: "",
      },
      // resetImage: false
    })
    // setTimeout(() => {
    //   this.setState({resetImage: true})
    // }, 100);
  }

  render() {
    const { isNewDuplicate, isNewEmpty, isEditing, validated, form, roleList, roleSelected, salesList, salesSelected, invalidMsg, resetImage } = this.state;
    const { login } = this.props;
    return (
      <>
        <div style={{height: '45px'}} className="mb-3"></div>
        <Container className="width-full">
          <Row className="bg-gray">
            <Col className="d-flex justify-content-end-table m-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn btn-action font-12 font-weight-bold "
                  // disabled={selectedRowKeys.length == 0}
                  onClick={() => {
                    this.toDelete();
                  }}
                >
                  Delete
                </Dropdown.Toggle>
                {/* <Dropdown.Menu>
                                    <Dropdown.Item href="" className="point3 point-delete">Delete</Dropdown.Item>
                                </Dropdown.Menu> */}
              </Dropdown>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-3">
              <h6 className="font-14 text-bold">{isEditing ? "Edit existing user":"Add new user"}</h6>
              <div style={{width: "10vmax"}} className="my-3">
                {resetImage &&
                <ImageUpload
                  files={form.userImage}
                  onChange={(value)=>{
                    validated.userImage = false;
                    this.setState({imageFile: value,
                      validated
                    });
                  }} />
                }
                {validated.userImage && (
                    <div className="select-invalid-feedback text-left">
                      กรุณาเลือกรูป
                    </div>
                  )}
              </div>
              {
                (isEditing && login.data.role=="ADMIN") && <div className="my-3">
                  <button className="btn btn-reset-password" onClick={()=>this.toResetPassword()}>Reset password</button>
                </div>
              }
              
              <div className="textSize14 text-secondary">Username</div>
              <div className="d-flex align-items-center">
                <Form.Group className="w-100">
                <InputMask mask="9999999" maskChar="" 
                    type="text"
                    placeholder=""
                    className={`form-control font-14 ${
                      validated.userName ? "is-invalid" : ""
                    }`}
                    value={form.userName}
                    onChange={(e) => {
                      form.userName = e.target.value;
                      validated.userName = false;
                      this.setState({
                        form,
                        validated
                      });
                    }}
                    disabled={isEditing}
                  />
                  {validated.userName && (
                    <div className="invalid-feedback text-left">
                      {invalidMsg.userName}
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="textSize14 text-secondary">Nickname</div>
              <div className="d-flex align-items-center">
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={`font-14 ${
                      validated.nickName ? "is-invalid" : ""
                    }`}
                    value={form.nickName}
                    onChange={(e) => {
                      form.nickName = e.target.value;
                      validated.nickName = false;
                      this.setState({
                        form,
                        validated
                      });
                    }}
                  />
                  {validated.nickName && (
                    <div className="invalid-feedback text-left">
                      กรุณากรอก Nickname
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="textSize14 text-secondary">First name</div>
              <div className="d-flex align-items-center">
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={`font-14 ${
                      validated.firstName ? "is-invalid" : ""
                    }`}
                    value={form.firstName}
                    onChange={(e) => {
                      form.firstName = e.target.value;
                      validated.firstName = false;
                      this.setState({
                        form,
                        validated
                      });
                    }}
                  />
                  {validated.firstName && (
                    <div className="invalid-feedback text-left">
                      กรุณากรอก First name
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="textSize14 text-secondary">Last name</div>
              <div className="d-flex align-items-center">
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={`font-14 ${
                      validated.lastName ? "is-invalid" : ""
                    }`}
                    value={form.lastName}
                    onChange={(e) => {
                      form.lastName = e.target.value;
                      validated.lastName = false;
                      this.setState({
                        form,
                        validated
                      });
                    }}
                  />
                  {validated.lastName && (
                    <div className="invalid-feedback text-left">
                      กรุณากรอก Last name
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="textSize14 text-secondary">Role</div>
              <div className="mb-3">
                <Select
                  placeholder={'Select roles'}
                  styles={
                    {
                      control: (styles, state) => ({ ...styles,
                        borderColor: state.isFocused? '#80bdff': validated.role ? '#dc3545' : '#ced4da',
                        boxShadow: state.isFocused? '0 0 0 0.2rem rgba(0,123,255,.25)': null
                      }),
                      container: (styles, state) => ({...styles,
                        width: '100%',
                      })
                    }
                  }
                  id={`role`}
                  value={roleSelected}
                  options={roleList}
                  onChange={(value) => {
                    validated.role = false;
                    if (value.value=="SUPERVISOR") {
                      this.setState({ roleSelected: value,
                        validated
                        })
                    } else {
                      this.setState({ roleSelected: value,
                        validated, salesSelected: [] })
                    }
                    
                  }}
                  isSearchable={true}
                  isDisabled={false}
              />
              {validated.role && (
                    <div className="select-invalid-feedback text-left">
                      กรุณาเลือก Role
                    </div>
                  )}
              </div>
              { (roleSelected && roleSelected.value == "SUPERVISOR") &&
                <>
                  <div className="textSize14 text-secondary">Sale team</div>
                  <div className="d-flex align-items-center mb-3">
                    <Select
                      placeholder={'Select sales'}
                      styles={
                        {
                          control: (styles, state) => ({ ...styles,
                            borderColor: state.isFocused? '#80bdff': '#ced4da',
                            boxShadow: state.isFocused? '0 0 0 0.2rem rgba(0,123,255,.25)': null
                          }),
                          container: (styles, state) => ({...styles,
                            width: '100%',
                          })
                        }
                      }
                      id={`sale-team`}
                      value={salesSelected}
                      options={salesList}
                      onChange={(value) => {
                          this.setState({ salesSelected: value  })
                      }}
                      isMulti
                      isSearchable={true}
                      isDisabled={false}
                  />
                  </div>
                </>
              }

              <div className="pt-2">
                {isEditing ?
                  <>
                    <Button
                      className="btn btn-dark px-3 mr-3"
                      onClick={() => this.updateData()}
                      >
                      Save
                    </Button>
                    <Button
                      className="btn btn-repass"
                      onClick={() => this.clearForm()}
                      >
                      Cancel
                    </Button>
                  </>
                  :
                  <Button
                    className="btn btn-dark"
                    onClick={() => this.addNewData()}
                    >
                    Add new
                  </Button>
                }
                
              </div>
            </Col>
            <Col md={8}>{this.renderTableData()}</Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    login: state.login,
    masterData: state.allMasterData,
    masterDataCategory: state.masterDataCategory,
    idolTypeTabName: state.idolTypeTabName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkLogin: (value) => dispatch(checkLogin(value)),
    getMasterData: (value) => dispatch(getMasterData(value)),
    setIdolTypeTabName: (value) => dispatch(setIdolTypeTabName(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MasterData));
