import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  OverlayTrigger,
  Popover,
  Nav,
  Modal,
  Form,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Table } from "antd";
import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../utils/apis";
import helper from "../utils/helper";
import NavbarList from "../components/Navbar";
import {
  loading,
  checkLogin,
  getMasterData,
  setIdolTypeTabName,
  checkToken,
} from "../redux/actions";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class MasterData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableRows: [],
      selectedRowKeys: [],
      selectedRows: [],
      isEditing: false,
      newValue: "",
      isNewDuplicate: false,
      isNewEmpty: false,
    };
  }

  async componentDidMount() {
    this.props.checkLogin();
    if (this.props.idolTypeTabName=="photographers") {
      this.props.setIdolTypeTabName("idols");
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.masterData != prevProps.masterData) {
      this.mapTableData();
    }
    if (this.props.masterDataCategory != prevProps.masterDataCategory) {
      this.mapTableData();
    }
    if (this.props.idolTypeTabName != prevProps.idolTypeTabName) {
      this.mapTableData();
    }
  }

  mapTableData = () => {
    const { masterData, masterDataCategory } = this.props;
    const tableData = masterData.options.find((item) => {
      return item.option_type == masterDataCategory;
    });
    // console.log("table", tableData, masterDataCategory);
    if (tableData) {
      this.setState({ tableRows: tableData.option_values });
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
    this.setState({ selectedRowKeys, selectedRows });
  };

  checkRowDuplicate = (row) => {
    const { tableRows } = this.state;
    const filterRows = tableRows.filter((item) => {
      return item.id != row.id;
    });
    // console.log("check duplicate", row, tableRows, filterRows)
    let is_duplicate = false;
    filterRows.forEach((item) => {
      if (row.option_name == item.option_name) {
        is_duplicate = true;
      }
    });
    row.is_duplicate = is_duplicate;
    this.setState({ row });
  };

  updateData = async (row) => {
    const { idolTypeTabName, history } = this.props;
    const { isEditing } = this.state;
    let response;
    if (isEditing) {
      try {
        response = await API.updateMasterData(row.id, {
          option_name: row.option_name,
        });
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      if (response && response.status != 500) {
        if (response.status == 200) {
          this.props.getMasterData(idolTypeTabName);
        } else {
          console.log(response.data.message);
        }
        this.state.isEditing = false;
      }
    }
  };

  renderTableData = () => {
    let scrolling = { x: 600 };
    const { selectedRowKeys, tableRows } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      // getCheckboxProps: record => {
      //     if (role == 'SALES' || role == 'AGENT') {
      //         return (({ disabled: record.id }))  // Column configuration not to be checked
      //     }
      // },
    };

    const columns = [
      {
        title: "Value",
        dataIndex: "option_name",
        render: (name, row, i) => {
          return (
            <>
              <Form.Control
                type="text"
                className={`${
                  row.is_invalid || row.is_duplicate ? "is-invalid" : ""
                }`}
                value={row.option_name}
                key={row.id}
                onChange={(e) => {
                  this.state.isEditing = true;
                  row.option_name = e.target.value;
                  if (e.target.value.trim() == "") {
                    row.is_invalid = true;
                  } else {
                    row.is_invalid = false;
                  }
                  this.checkRowDuplicate(row);
                  this.setState({ row });
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() != "") {
                    // this.state.isEditing = true;
                    this.updateData(row);
                  }
                }}
                // onKeyPress={(e) => {
                //   if (e.key === "Enter" && e.target.value.trim() != "") {
                //     // this.state.isEditing = true;
                //     this.updateData(row);
                //   }
                // }}
              ></Form.Control>
              {row.is_invalid && (
                <div className="invalid-feedback text-left">
                  กรุณากรอกข้อมูล
                </div>
              )}
              {row.is_duplicate && (
                <div className="invalid-feedback text-left">มีข้อมูลซ้ำกัน</div>
              )}
            </>
          );
        },
        sorter: (a, b) => {
          return a.option_name.localeCompare(b.option_name);
        },
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Last modified by",
        dataIndex: "",
        render: (name, row, i) => {
          return (
            <div className="d-flex align-items-center justify-content-center px-3">
              <img className="img-acc" src={row.user_image_url} />
              <div className="font-weight-bold ml-3">{row.updated_user}</div>
            </div>
          );
        },
        sorter: (a, b) => a.updated_user.localeCompare(b.updated_user),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
      {
        title: "Last modified",
        dataIndex: "updated_at",
        render: (name, row, i) => {
          return (
            <div className="p-0 pt-2 align-ver mr-1 text-center">
              {`${new Intl.DateTimeFormat("en-GB", {
                dateStyle: "medium",
                timeStyle: "short",
              }).format(new Date(row.updated_at))}`}
            </div>
          );
        },
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
        className: "table-col",
        showSorterTooltip: false,
      },
    ];

    // if (tableRows && tableRows.length >= 20) {
    //   scrolling = { x: 600, y: 650 };
    // }

    return (
      <>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableRows}
          size="small"
          className={"table-width mt-3 master-data-table"}
          scroll={scrolling}
          rowKey={(record) => record.id}
          responsive
          pagination={{
            className: "my-4",
            position: ["bottomCenter"],
            size: "default",
            showSizeChanger: false,
            current: 1,
            total: tableRows.length,
            pageSize: tableRows.length,
            hideOnSinglePage: true,
          }}
          onChange={async (pagination, filters, sorter) => {
            // console.log("pagination", pagination,  "sorter", sorter);
            // const { currentSort } = await this.state;
            // currentSort.currentPage = await pagination.current;
            // currentSort.sortField = await sorter.field;
            // if (sorter.order == "ascend") {
            //     currentSort.sortOrder = "ASC";
            // } else if (sorter.order == "descend") {
            //     currentSort.sortOrder = "DESC";
            // } else {
            //     currentSort.sortOrder = "ASC";
            // }
            // await this.getIdolList(this.props.addDatasearch);
            // await helper.sessionSave("currentSort", currentSort);
            // this.setState({tableRows})
          }}
        />
      </>
    );
  };

  checkNewDuplicate = (value) => {
    const { tableRows } = this.state;
    let is_duplicate = false;
    tableRows.forEach((item) => {
      if (item.option_name == value) {
        is_duplicate = true;
      }
    });
    return is_duplicate;
  };

  validateForm = () => {
    const { newValue } = this.state;
    let is_invalid = false;
    if (newValue.trim() == "") {
      is_invalid = true;
      this.setState({ isNewEmpty: is_invalid });
    }
    if (this.checkNewDuplicate(newValue.trim())) {
      is_invalid = true;
      this.setState({ isNewDuplicate: is_invalid });
    }

    return is_invalid;
  };

  addNewData = async () => {
    if (!this.validateForm()) {
      const { masterDataCategory, idolTypeTabName, history } = this.props;
      const { newValue } = this.state;
      // console.log("validated", masterDataCategory, newValue);
      let response;
      try {
        response = await API.addMasterData({
          option_type: Number(masterDataCategory),
          option_name: newValue.trim(),
        });
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      this.props.loading(false);
      // console.log("add new response", response);
      if (response && response.status != 500) {
        if (response.status == 201) {
          this.props.getMasterData(idolTypeTabName);
          this.setState({
            newValue: "",
            isNewDuplicate: false,
            isNewEmpty: false,
          });
        } else {
          console.log(response.data.message);
        }
      }
    }
  };

  toDelete = () => {
    const { selectedRowKeys } = this.state;
    console.log("row keys", selectedRowKeys);
    if (selectedRowKeys.length === 0) {
      MySwal.fire({
        icon: "warning",
        title: "เลือกอย่างน้อย 1 รายการ",
      }).then((value) => {});
    }
    if (selectedRowKeys.length >= 1) {
      MySwal.fire({
        icon: "warning",
        // title: "ต้องการลบข้อมูล?",
        html: "ข้อมูลที่เลือกทั้งหมดจะถูกลบจากระบบ<br/> คุณยืนยันการลบหรือไม่",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteData();
        }
      });
    }
  };

  deleteData = () => {
    const { selectedRowKeys } = this.state;
    const { history, idolTypeTabName } = this.props;
    let response;
    selectedRowKeys.forEach(async (item, index) => {
      try {
        response = await API.deleteMasterData(item);
        // console.log("response", response);
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
      if (response && response.status != 500) {
        if (response.status == 200) {
          if (index == selectedRowKeys.length - 1) {
            this.props.getMasterData(idolTypeTabName);
            this.setState({ selectedRowKeys: [], selectedRows: [] });
          }
        }
      }
    });
  };

  render() {
    const { isNewDuplicate, isNewEmpty } = this.state;
    return (
      <>
        <NavbarList />
        <Container className="width-full">
          <Row className="bg-gray">
            <Col className="d-flex justify-content-end-table m-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn btn-action font-12 font-weight-bold "
                  // disabled={selectedRowKeys.length == 0}
                  onClick={() => {
                    this.toDelete();
                  }}
                >
                  Delete
                </Dropdown.Toggle>
                {/* <Dropdown.Menu>
                                    <Dropdown.Item href="" className="point3 point-delete">Delete</Dropdown.Item>
                                </Dropdown.Menu> */}
              </Dropdown>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-3">
              <h6 className="font-14 text-bold">Add new Value</h6>
              <div className="textSize14 text-secondary">value</div>
              <div className="d-flex align-items-center">
                <Form.Group className="w-100">
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={`font-14 ${
                      isNewEmpty || isNewDuplicate ? "is-invalid" : ""
                    }`}
                    value={this.state.newValue}
                    onChange={(e) => {
                      this.setState({
                        newValue: e.target.value,
                        isNewEmpty: false,
                        isNewDuplicate: false,
                      });
                    }}
                  />
                  {isNewEmpty && (
                    <div className="invalid-feedback text-left">
                      กรุณากรอกข้อมูล
                    </div>
                  )}
                  {isNewDuplicate && (
                    <div className="invalid-feedback text-left">
                      มีข้อมูลซ้ำกัน
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="pt-3">
                <Button
                  className="btn btn-dark"
                  onClick={() => this.addNewData()}
                >
                  Add new
                </Button>
              </div>
            </Col>
            <Col md={8}>{this.renderTableData()}</Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    login: state.login,
    masterData: state.allMasterData,
    masterDataCategory: state.masterDataCategory,
    idolTypeTabName: state.idolTypeTabName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkLogin: (value) => dispatch(checkLogin(value)),
    getMasterData: (value) => dispatch(getMasterData(value)),
    setIdolTypeTabName: (value) => dispatch(setIdolTypeTabName(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MasterData));
