import axios from 'axios';
import join from 'url-join';
import helper from './helper';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//

const connect = axios.create();

connect.interceptors.request.use(async (config)=> {
    const userInfo = helper.storageGet('login');
    // console.log("user info ", userInfo);
    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = join(process.env.REACT_APP_API_ENDPOINT, config.url);
    }
    config.headers['Content-Type'] = 'application/json';
    if (userInfo) config.headers.Authorization = `Bearer ${userInfo.token}`;

    config.timeout = 85000;
    return config;
  
});

export const httpClient = connect