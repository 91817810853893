import React, { Component } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { checkLogin, addLogin } from "../redux/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../utils/apis";
import {
  setIsEditing,
  setWorkflowTab,
  getMasterData,
  setIdolTypeTabName,
  setMasterDataCategory,
  getMasterProduct
} from "../redux/actions";
import helper from "../utils/helper";
const MySwal = withReactContent(Swal);
const API = api.getAPI();

class NavbarList extends Component {
  state = {
    data: false,
    idolListTab: [],
    pathname: "",
    searchName: "",
    AdminDataTypeList: [
      { code: "idols", name: "Idol" },
      { code: "photographers", name: "Photographer" },
    ],
  };

  async componentDidMount() {
    const { idolTypeTabName } = this.props;
    // if (this.props.login) {

    // }
    // await this.props.checkLogin();
    const { location } = this.props;
    // this.getNavList()

    // console.log(window.location.search, "mmmmooo");
    // console.log("navbar", this.props)
    if (location) {
      this.setState({
        pathname: location.pathname,
      });
      if (location.search) {
        if (location.search == "?editTab1") {
          await this.setState({
            pathname: "/idol-list",
          });
        }
        if (location.search == "?editTab2") {
          await this.setState({
            pathname: "/no-product",
          });
        }
      }
    }
    this.props.getMasterData(idolTypeTabName);
    this.props.getMasterProduct(idolTypeTabName)
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (this.props.login != prevProps.login && this.props.login) {
    // }
    if (this.props.idolTypeTabName != prevProps.idolTypeTabName) {
      // console.log("masterDataCategory", this.props.adminMenuCategory)
      if (this.props.adminMenuCategory == "masterdata"){
        this.props.getMasterData(this.props.idolTypeTabName);
        this.props.setMasterDataCategory(null);
      }
      if (this.props.adminMenuCategory == "products-workflow"){        
        this.props.getMasterProduct(this.props.idolTypeTabName);
        // this.props.setMasterDataCategory(null);
      }
    }
  }

  checkIsEditing = (link) => {
    if (this.props.isEditing) {
      MySwal.fire({
        icon: "warning",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        text:
          "ข้อมูลบางอย่างที่คุณแก้ไขไว้ยังไม่ได้ถูกบันทึก คุณยืนยันที่จะออกจากหน้านี้หรือไม่?",
      }).then((result) => {
        // console.log('rrr', result)
        if (result.value) {
          // console.log('sssssssssssssssssssssssss', result.value)
          this.props.setIsEditing(false);
          this.props.history.push(link);
        }
      });
    } else {
      this.props.history.push(link);
    }
  };

  renderTabActive = (name) => {
    const { idolTabName } = this.props;
    // console.log('name', name)
    if (name && name == idolTabName) {
      return " active ";
    } else {
      return "";
    }
  };

  renderNav = () => {
    const { isEditing, idolTypeTabName } = this.props;
    let url = window.location.pathname;
    let link = "";
    if (url.includes("/admin")) {
      return this.state.AdminDataTypeList.map((itemAd, idx) => {
        return (
          <Navbar className="nav nav-underline pb-0" key={idx}>
            <Nav.Link
              as="div"
              className={`nav-link txt-style cursor ${
                itemAd.code == idolTypeTabName ? "active" : ""
              }`}
              onClick={() => this.props.setIdolTypeTabName(itemAd.code)}
            >
              <span className="">{itemAd.name}</span>
            </Nav.Link>
          </Navbar>
        );
      });
    } else {
      // console.log("idol product tab", this.props);
      return this.props.idolTabsList.map((item, index) => {
        // let url = window.location.pathname;
        // let link = '';
        if (url.includes("/photographer-list")) {
          link = `/photographer-list/${helper.converseCodeToLink(
            item.product_code
          )}`;
        } else {
          link = `/idol-list/${helper.converseCodeToLink(item.product_code)}`;
        }

        if (item.workflows_list_tabs) {
          link =
            link +
            `/${helper.converseCodeToLink(
              item.workflows_list_tabs[0].workflow_code
            )}`;
        }

        return (
          <Navbar className="nav nav-underline pb-0" key={index}>
            <NavLink to={link} activeClassName="active">
              <Nav.Link
                as="div"
                className={`nav-link txt-style ${this.renderTabActive(
                  item.product_code
                )}`}
                onClick={(e) => {
                  // e.preventDefault();
                  // this.checkIsEditing(link);
                  if (!isEditing) this.setWorkflowTab(item);
                }}
              >
                {item.product_name}{" "}
                <span className=""> ({item.idols_count})</span>
              </Nav.Link>
            </NavLink>
          </Navbar>
        );
      });
    }
  };

  setWorkflowTab = (data) => {
    if (data.workflows_list_tabs) {
      this.props.setWorkflowTab(data.workflows_list_tabs);
    } else {
      this.props.setWorkflowTab([]);
    }
    // this.props.setWorkflowTab()
  };

  renderWorkflowActive = (name) => {
    const { workflowTabName } = this.props;
    if (name && name == workflowTabName) {
      return " active ";
    } else {
      return "";
    }
  };

  renderWorkflowTab = (id) => {
    const { workflowTabsList, idolTabName } = this.props;
    return workflowTabsList.map((item, index) => {
      let url = window.location.pathname;
      let link = "";
      if (url.includes("/photographer-list")) {
        link = `/photographer-list/${helper.converseCodeToLink(
          idolTabName
        )}/${helper.converseCodeToLink(item.workflow_code)}`;
      } else {
        link = `/idol-list/${helper.converseCodeToLink(
          idolTabName
        )}/${helper.converseCodeToLink(item.workflow_code)}`;
      }

      return (
        <NavLink to={link} activeClassName="active" key={index}>
          <Nav.Link
            as="span"
            className={`nav-link font-12 mr-2 mb-2 txt-nopro ${this.renderWorkflowActive(
              item.workflow_code
            )}`}
            onClick={() => {
              // this.checkIsEditing(link)
            }}
          >
            {item.workflow_name} ({item.idols_count})
          </Nav.Link>
        </NavLink>
      );
    });
  };

  render() {
    const { idolListTab } = this.state;
    return (
      <React.Fragment>
        <div className="nav-scroller bg-white box-shadow flex-wrap">
          {this.renderNav()}
        </div>
        <Container className="width-full">
          <Row>
            <Col className="mt-3 d-flex flex-wrap">
              {this.renderWorkflowTab()}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    login: state.login,
    isEditing: state.isEditing,
    idolTabsList: state.idolTabsList,
    workflowTabsList: state.workflowTabsList,
    idolTabName: state.idolTabName,
    workflowTabName: state.workflowTabName,
    idolTypeTabName: state.idolTypeTabName,
    masterDataCategory: state.masterDataCategory,
    adminMenuCategory: state.adminMenuCategory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLogin: (value) => dispatch(addLogin(value)),
    checkLogin: (value) => dispatch(checkLogin(value)),
    setIsEditing: (value) => dispatch(setIsEditing(value)),
    setWorkflowTab: (value) => dispatch(setWorkflowTab(value)),
    getMasterData: (value) => dispatch(getMasterData(value)),
    setIdolTypeTabName: (value) => dispatch(setIdolTypeTabName(value)),
    setMasterDataCategory: (value) => dispatch(setMasterDataCategory(value)),
    getMasterProduct: (value) => dispatch(getMasterProduct(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavbarList));
