import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Nav, OverlayTrigger, Popover, Modal, Form, ListGroup, Spinner, Badge, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MdStar, MdMoreHoriz, MdControlPoint, MdClear } from "react-icons/md";
import { loading, addModal, checkLogin, addLogin, modalSale, addidolId, modalExtract, workflowsCode } from '../redux/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Link } from 'react-router-dom';
import Rating from 'react-rating'
import Select from 'react-select'
import api from '../utils/apis';
import helper from "../utils/helper"
import ModalSale from "../components/ModalSale";
import { Table, Pagination } from "antd";
const API = api.getAPI();
const MySwal = withReactContent(Swal)
class NoProduct extends Component {

    state = {
        data: false,

        saleSteamList: [],
        saleSteamSelectValue: [],
        saleSteamId: 0,

        idolList: [],
        workflows_tab: [],

        selectedRowKeys: [],
        type: '',
        statusCheck: '',

        idolId: '',
        id_idol: 0,
        tab_index: 0,
        workflow_code: 'WAITING_DATA',
        ig_user: '',
        workflowsTable: [],
        workflowsTableSelect: [],
        role: '',
        remark: '',
        workflowsCode: [],

        selectButton: false,
        modalQuickAdd: false,
        modalSelectStatus: false,
        modalSelectStatusAssign: false,
        modalSelectStatusWaiting: false,

        platform: 'ig',
        addidolList: [],
        idolList: [],
        selectButton: false,
        isIgSelect: false,
        igname: '',
        igSelect: '',
        igUser: '',
        engRate: '',
        igUserName: '',

        productTxt: '',
        productType: [],
        allProduct: false,
        selectProduct: false,

        modalChangeSale: false,
        status: [],
    }

    async componentDidMount() {
        await this.props.checkLogin();
        if (this.props.login) {
            await this.setState({ role: this.props.login.data.role })
            await this.getSaleSteam();
        }

        // await this.props.checkAddIdol();
        // await this.props.checkWorkflowsCode();
        await this.getIdolList();
        await this.getIdolIns();

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.login != prevProps.login && this.props.login) {
            await this.setState({ role: this.props.login.data.role })
        }
    }

    openAddIdol = async () => {
        this.props.openModal(true);
    }

    ChangeSaleModal = async (text) => {
        const type = text
        if (type === 'multi') {
            if (this.state.selectedRowKeys.length === 0) {
                MySwal.fire({
                    icon: "warning",
                    title: "เลือกอย่างน้อย 1 รายการ",
                }).then((value) => {
                    this.setState({
                        modalChangeSale: false,
                    });
                });
            } else {
                this.setState({ close: '', modalChangeSale: true, type: type })
            }
        } else {
            this.setState({ close: '', modalChangeSale: true, type: type })
        }
    }

    getIdolList = async () => {
        this.props.loading(true);
        let response;
        let produce_code = "NO_PRODUCT";
        try {
            response = await API.getIdols(produce_code, this.state.workflow_code)
        } catch (error) {
            response = error.response
        }
        this.props.loading(false);
        if (response && response.status != 500) {
            if (response.status == 200) {
                let data = response.data.idols_list_tabs[1].workflows_list_tabs
                if (response.data.idols.length > 0) {
                    response.data.idols.map(item => {
                        if (item.products_info.length > 0) {
                            item.products_info.map(product => {
                                item.status = [{ value: product.workflow_status_code, label: product.workflow_status_name }]
                            })
                        }
                    });
                }
                this.setState({
                    idolList: response.data.idols,
                    workflows_tab: response.data.idols_list_tabs[1].workflows_list_tabs,

                })
                if (data) {
                    let workflows = [];
                    data.map(item => {
                        workflows.push({ value: item.workflow_code, label: item.workflow_name, index: item.tab_index })
                    })
                    this.setState({ workflowsTable: workflows })
                }
                console.log("list", this.state.idolList)
                // console.log("workflowsTable", this.state.workflowsTable)
            } else {
                console.log(response.data.message)
            }
        }
    }

    getStat = async (stat) => {
        // console.log('status', stat)
        this.props.loading(true);
        let response;
        let produce_code = "NO_PRODUCT";
        try {
            response = await API.getIdols(produce_code, stat)
        } catch (error) {
            response = error.response
        }
        this.props.loading(false);
        if (response && response.status != 500) {
            if (response.status == 200) {
                let data = response.data.idols_list_tabs[1].workflows_list_tabs
                if (response.data.idols.length > 0) {
                    response.data.idols.map(item => {
                        if (item.products_info.length > 0) {
                            item.products_info.map(product => {
                                item.status = [{ value: product.workflow_status_code, label: product.workflow_status_name }]
                            })
                        }
                    });
                }
                this.setState({
                    idolList: response.data.idols,
                    workflows_tab: response.data.idols_list_tabs[1].workflows_list_tabs,

                })
                if (data) {
                    let workflows = [];
                    data.map(item => {
                        workflows.push({ value: item.workflow_code, label: item.workflow_name, index: item.tab_index })
                    })
                    this.setState({ workflowsTable: workflows })
                }
                // console.log("list", this.state.workflows_tab)
            } else {
                console.log(response.data.message)
            }
        }
    }

    renderActive = (tab) => {
        if (tab === this.state.tab_index) {
            return ' active ';
        } else {
            return '';
        }
    }

    renderTab = (id) => {
        const { workflows_tab } = this.state;
        return workflows_tab.map((item, index) => {
            return (
                <React.Fragment key={index}>
                    <Nav.Link className={`pl-3 ml-3 nav-link font-12 txt-nopro${this.renderActive(item.tab_index)}`}>
                        <div onClick={() => (this.getStat(item.workflow_code), this.setState({ tab_index: item.tab_index }))}>{item.workflow_name} ({item.idols_count})</div>
                    </Nav.Link>
                </React.Fragment>
            )
        })
    }

    onSelectChange = (selectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    renderTableData = () => {
        const { idolList, id_idol, role, selectedRowKeys } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: (record) => {
                let newSelectedRowKeys = '';
                newSelectedRowKeys = record.status[0].value
                this.setState({ statusCheck: newSelectedRowKeys })
            },
            // onSelectAll: (selected, selectedRows, changeRows) => {
            //     console.log('ddddd',selected, selectedRows, changeRows);
            // },
            // getCheckboxProps: record => {
            //     if(role == 'SALES' || role == 'AGENT'){
            //        return(({ disabled: record.id  }))  // Column configuration not to be checked
            //     }
            // },
        };

        let statusOptions = [{
            label: 'ยังไม่มี product',
            options: this.state.workflowsTable,
        }];
        const popover = (
            <Popover id="popover-basic" >
                {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                {role == 'ADMIN' || role == 'SUPERVISOR' ?
                    <Popover.Content className="w-pop">
                        <Link to={`/edit-idol/${id_idol}?editTab2`} className="point3" onClick={() => this.setState({ close: '' })}>
                            <h6>Edit</h6>
                        </Link>
                        <h6 className="point3">Approve</h6>
                        <h6 onClick={() => (this.ChangeSaleModal('single'), this.setState({ close: '' }))} className="point3">Change sale</h6>
                        <h6 className="point3">Manage product</h6>
                        <h6 className="point-delete">Delete</h6>
                    </Popover.Content>
                    :
                    <Popover.Content className="w-pop">
                        <Link to={`/edit-idol/${id_idol}?editTab2`} className="point3" onClick={() => this.setState({ close: '' })}>
                            <h6>Edit</h6>
                        </Link>
                        <h6 className="point3">Approve</h6>
                    </Popover.Content>
                }
            </Popover>
        );
        const columns = [
            {
                title: 'Social accounts',
                render: (item) => {
                    return (<div className="text-center d-flex flex-row">
                        <img className="img-acc" src={item.profile_pic_url} />
                        <div className="mr-1 text-start a-self pl-2">
                            <Link to={`/edit-idol/${item.id}?editTab2`} style={{ cursor: "pointer" }} className="pointer-link">
                                <h5 className="text-align font-16">{item.ig_username}</h5>
                            </Link>
                            {/* <h5 className="text-detail font-16">n/a</h5> */}
                        </div>
                    </div>
                    )
                },
                sorter: (a, b) => a.ig_username.localeCompare(b.ig_username),
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col pl-3',
                showSorterTooltip: false
            },
            {
                title: 'Followers',
                render: (item) => {
                    return (
                        <div className="p-0 pt-2 align-ver mr-1 text-start">
                            <h5 className="txt-poin font-16">
                                <img width="35px" height="25px" className="pr-2" src={window.location.origin + "/image/instagram.png"} />{helper.kFormath(item.ig_follower_count)}
                            </h5>
                        </div>
                    )
                },
                sorter: (a, b) => a.ig_follower_count - b.ig_follower_count,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col w-foller',
                showSorterTooltip: false
            },
            {
                title: 'Engagement %',
                render: (item) => {
                    return (
                        <div className="p-0 pt-2 align-ver mr-1 text-center">
                            <h5 className="txt-poin font-16">{item.ig_engagement_rate.toFixed(2)}%</h5>

                            {/* <h5 className="text-detail font-16">{item.fb_engagement_rate.toFixed(2)}%</h5> */}
                        </div>
                    )
                },
                sorter: (a, b) => a.ig_engagement_rate - b.ig_engagement_rate,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Reputation',
                render: (item) => {
                    return (
                        <div className="text-start p-0 pt-2 align-ver text-center d-flex flex-column">
                            {
                                item.reputation.length > 0 ? item.reputation.map((req, index) => {
                                    return (<h5 className="btn btn-status mr-2 font-12" key={index}>{req}</h5>)
                                })
                                    : ''
                            }
                            {/* <h5 className="btn btn-status mr-2 font-14">Entertainer</h5> */}
                        </div>
                    )
                },
                // sorter: (a, b) => a.id - b.id,
                align: 'center',
            },
            {
                title: 'Beauty',
                render: (item) => {
                    return (
                        <div className="text-center p-2 align-ver flex-row">
                            <Rating
                                placeholderRating={item.beauty_level}
                                emptySymbol={<MdStar color="#E0E0E0" size={18} />}
                                placeholderSymbol={<MdStar color="#E6AF2E" size={18} />}
                                readonly={true}
                            />
                        </div>
                    )
                },
                sorter: (a, b) => a.beauty_level - b.beauty_level,
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col w-select',
                showSorterTooltip: false
            },
            {
                title: 'จุดเด่น',
                render: (item) => {
                    return (
                        <div className="text-start p-0 pt-2 align-ver text-center d-flex flex-column ">
                            {
                                item.attractive_point.length > 0 ? item.attractive_point.map((att, index) => {
                                    return (<h5 className="btn btn-status mr-2 font-12" key={index}>{att}</h5>)
                                })
                                    : ''
                            }
                        </div>
                    )
                },
                align: 'center',
                className: 'table-col w-select',
            },
            {
                title: 'Status',
                render: (item) => {
                    return (
                        <div className="text-start p-0 pt-2 align-ver d-flex flex-column">
                            <Select
                                placeholder={'Status'}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                id="product"
                                value={item.status}
                                options={statusOptions}
                                onChange={(value) => {
                                    let data = value;
                                    item.status = data;
                                    this.setState({ workflowsTableSelect: data, item })

                                    if (data.value == "EXTRACTING_DATA") {
                                        let dataExtract = {
                                            dataList: item,
                                            status: data
                                        }
                                        // console.log("data", item.ig_username);
                                        // this.props.workflowsCode(dataExtract);
                                        // this.props.ModalExtract(true);
                                        if (this.state.tab_index !== 1) {
                                            this.setState({ modalSelectStatus: true, workflowsCode: dataExtract, ig_user: item.ig_username })
                                        }
                                    } else if (data.value == "COMPLETED_DATA") {
                                        let dataExtract = {
                                            dataList: item,
                                            status: data
                                        }
                                        // console.log("data", item.ig_username);
                                        // this.props.workflowsCode(dataExtract);
                                        // this.props.ModalExtract(true);
                                        if (this.state.tab_index !== 2) {
                                            this.setState({ modalSelectStatusAssign: true, workflowsCode: dataExtract, ig_user: item.ig_username })
                                        }
                                    } else {
                                        let dataExtract = {
                                            dataList: item,
                                            status: data
                                        }
                                        // console.log("data", item.ig_username);
                                        // this.props.workflowsCode(dataExtract);
                                        // this.props.ModalExtract(true);
                                        if (this.state.tab_index !== 0) {
                                            this.setState({ modalSelectStatusWaiting: true, workflowsCode: dataExtract, ig_user: item.ig_username })
                                        }
                                    }
                                }}
                                isSearchable={true}
                                isDisabled={false}
                            />
                        </div>
                    )
                },
                // sorter: (a, b) => a.id - b.id,
                align: 'center',
                className: 'table-col width-select',
                showSorterTooltip: false
            },
            {
                title: 'Sales',
                render: (item) => {
                    return (
                        <div className={`text-center p-0 pt-2 align-ver text-center d-flex ${item.sales_name == '' ? "justify-content-center" : ""}`}>
                            {item.sales_name ?
                                <>
                                    <img className="img-sale" src={item.sales_image_url} />
                                    <h5 className="text-detail font-16 mr-2 a-self pl-2" >{item.sales_name}</h5>
                                </>
                                :
                                <h5 className="text-detail font-16">N/A</h5>
                            }
                        </div>
                    )
                },
                sorter: (a, b) => a.sales_name.localeCompare(b.sales_name),
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: 'Creator',
                render: (item) => {
                    return (
                        <div className="text-center p-0 pt-2 align-ver text-center d-flex">
                            <div className="text-center d-flex">
                                <img className="img-sale" src={item.creator_image_url} />
                                <h5 className="txt-poin font-16 a-self pl-2">{item.creator_name}</h5>
                            </div>
                        </div>
                    )
                },
                sorter: (a, b) => a.creator_name.localeCompare(b.creator_name),
                sortDirections: ["ascend", "descend", "ascend"],
                align: 'center',
                className: 'table-col',
                showSorterTooltip: false
            },
            {
                title: '',
                // selector: (item, index) => {
                //     return (
                //         <div className="text-center p-0 pr-2 align-ver">
                //             <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose >
                //                 <a style={{ cursor: "pointer" }} className="pointer-link" onClick={(e) => this.setState({ idolId: item, close: index })}><MdMoreHoriz className="icon-more" /></a>
                //             </OverlayTrigger>
                //         </div>
                //     )
                // },
                render: (item, index) => <div className="text-center p-0 pr-2 align-ver">
                    <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                        <a style={{ cursor: "pointer" }} className="pointer-link" onClick={() => this.setState({ idolId: item, id_idol: item.id, status: item.status[0], close: index })}><MdMoreHoriz className="icon-more" /></a>
                    </OverlayTrigger>
                </div>,
                align: 'center',
            },
        ];

        return (
            <React.Fragment>
                <Card.Body className="pb-2 bg-gray">
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={idolList}
                        size="small"
                        className={'table-width'}
                        onChange={this.onChange}
                        scroll={{ x: 600 }}
                        pagination={{
                            total: idolList.length,
                            pageSize: 100,
                            hideOnSinglePage: false
                        }}
                        rowKey="id"
                        responsive
                    />
                </Card.Body>
            </React.Fragment>
        )
    }


    onHide = (cancel) => {
        if (cancel === 'cancelMulti') {
            this.setState({
                selectButton: false, modalSelectStatus: false, modalSelectStatusAssign: false, modalSelectStatusWaiting: false, saleSteamSelectValue: [], saleSteamId: '', isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false, modalQuickAdd: false, productType: [], remark: '', modalChangeSale: false,
            })
        } else {
            this.setState({
                selectButton: false, modalSelectStatus: false, modalSelectStatusAssign: false, modalSelectStatusWaiting: false, saleSteamSelectValue: [], saleSteamId: '', isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false, modalQuickAdd: false, productType: [], remark: '', modalChangeSale: false,
                selectedRowKeys: []
            })
        }
        // this.props.openModal(false);
    };

    addSale = async () => {
        const { remark, saleSteamId, workflowsCode } = this.state
        let response;
        let idIdol = workflowsCode.dataList.id ? workflowsCode.dataList.id : 0;
        let workflows = workflowsCode ? workflowsCode : '';
        // console.log('ww', workflows.status);
        const params = JSON.stringify({
            "product_code": "NO_PRODUCT",
            "workflow_code": workflows.status.value,
            "workflow_status_code": workflows.status.value,
            "remark": remark ? remark : ""
        });
        // console.log("dataAdd", workflows);
        // return;
        try {
            response = await API.changeStatus(idIdol, params)
        } catch (error) {
            response = error.response
        }
        if (response && response.status != 500) {
            if (response.status == 200) {
                this.setState({ remark: '' })
                this.onHide();
                this.setState({ tab_index: workflows.status.index })
                this.getStat(workflows.status.value)
                await this.renderActive(workflows.status.index)
                // console.log("response_addSale", response)
            } else {
                MySwal.fire({
                    // customClass: 'swal-wide',
                    icon: "error",
                    title: "Error",
                    text: response.data.message
                })
                console.log(response.data.message)
            }
        }
    }

    modalTrue = async () => {
        // this.props.openModal(true);
        // handleShow
        await this.setState({
            modalQuickAdd: true,
        });
    }

    getIdolIns = async () => {
        // console.log('getIdolIns')
        if (this.state.igname == "") return;
        let response;
        try {
            response = await API.getIdolInstagram(this.state.igname)
        } catch (error) {
            response = error.response
        }
        if (response && response.status != 500) {
            if (response.status == 200) {
                this.setState({ addidolList: response.data })
                // console.log("list", this.state.addidolList)
            } else {
                console.log(response.data.message)
            }
        }
    }

    getIgUser = async (id) => {
        // console.log('--->',id)
        this.setState({ igUser: '' })
        let response;
        try {
            response = await API.getIgUser(id)
        } catch (error) {
            response = error.response
        }
        if (response && response.status != 500) {
            if (response.status == 200) {
                this.setState({ igUser: response.data, engRate: response.data.engagement_rate.toFixed(2) })
            } else {
                if (response.data.statusCode == 401) {
                    // console.log(response.data.message)
                    this.setState({ igUserName: response.data.message, igUser: response.data })
                }
            }
        }
    }

    saveIdol = async (type) => {
        // console.log(username)
        const { igUser } = this.state
        let data = igUser;
        let response;
        // return;
        const params = JSON.stringify({
            "social_platform": "IG",
            "username": data.username,
            "profile_pic_url": data.profile_pic_url,
            "ig_pk": data.pk,
            "follower_count": data.follower_count,
            "engagement_rate": data.engagement_rate
        });
        try {
            response = await API.addIdolIg(params)
            // console.log("response", response);
        } catch (error) {
            response = error.response
        }
        if (response && response.status != 500) {
            if (response.status == 201) {
                if (type == 1) {
                    await this.setState({ isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false })
                    // this.props.openModal(false);
                    MySwal.fire({
                        icon: "success",
                        title: "Success",
                    }).then((value) => {
                        this.setState({
                            modalQuickAdd: false,
                        });
                        this.getIdolList();

                    });
                } else {
                    // await this.getIdolList();
                    this.openAddIdol()
                }
            } else {
                if (data.statusCode == 401) {
                    MySwal.fire({
                        // customClass: 'swal-wide',
                        icon: "error",
                        title: "Error",
                        text: data.message
                    })
                } else {
                    MySwal.fire({
                        // customClass: 'swal-wide',
                        icon: "error",
                        title: "Error",
                        text: response.data.message
                    })
                }
                // console.log(response.data.message)
            }
        }
    }

    checkStatusCode = () => {
        const { igUser, igUserName, engRate, igSelect } = this.state;
        // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', igUser)
        if (igUser.statusCode == 401) {
            return (
                <Row className="mt-3 mb-3">
                    <Col className="text-center text-danger">
                        <label>{igUserName}</label>
                    </Col>
                </Row>)
        } else if (igUser.is_duplicate === true) {
            return (
                <Card className="bg-card border-danger">
                    <Card.Header className="card-ig">
                        <img width="70px" height="70px" className="border-radius mr-3" src={igSelect.profile_pic_url} /> <label className="text-ig">{igSelect.username}</label>
                    </Card.Header>
                    {igUser === '' ?
                        <Row className="mt-3 mb-3">
                            <Col className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                        :
                        <>
                            <Row className="d-flex ml-2 mt-3">
                                <Col md={2} className="">
                                    <img className="pr-2" width="55px" src={window.location.origin + "/image/instagram.png"} />
                                </Col>
                                <Col md={3} className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(igUser.follower_count)}</h5>
                                    <label className="font-gray font-12">Followers</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">N/A</h5>
                                    <label className="font-gray font-12">Growth rate</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{engRate}%</h5>
                                    <label className="font-gray font-12">Engaement rate</label>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="row-check">
                                    <label className="text-danger">Profile has already been added</label>
                                </Col>
                            </Row>
                        </>
                    }
                </Card>
            )
        } else {
            return (
                <Card className="bg-card">
                    <Card.Header className="card-ig">
                        <img width="70px" height="70px" className="border-radius mr-3" src={igSelect.profile_pic_url} /> <label className="text-ig">{igSelect.username}</label>
                    </Card.Header>
                    {igUser === '' ?
                        <Row className="mt-3 mb-3">
                            <Col className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                        :
                        <>
                            <Row className="d-flex ml-2 mt-3">
                                <Col md={2} className="">
                                    <img className="pr-2" width="55px" src={window.location.origin + "/image/instagram.png"} />
                                </Col>
                                <Col md={3} className="">
                                    <h5 className="mb-0 text-bold font-16">{helper.kFormath(igUser.follower_count)}</h5>
                                    <label className="font-gray font-12">Followers</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">N/A</h5>
                                    <label className="font-gray font-12">Growth rate</label>
                                </Col>
                                <Col md={3} className="p-0">
                                    <h5 className="mb-0 text-bold pl-3 font-16">{engRate}%</h5>
                                    <label className="font-gray font-12">Engaement rate</label>
                                </Col>
                            </Row>
                        </>
                    }
                </Card>
            )
        }
    }

    checkBtnSave = () => {
        const { igUser } = this.state;
        if (igUser.is_duplicate === true) {
            return (
                <>
                    <Button className="btn-save mx-2 cursor-auto">
                        Save
                    </Button>
                    <Button className="btn-addmore mx-2 cursor-auto">
                        Save & Add more
                    </Button>
                </>
            )
        } else {
            return (
                <>
                    <Button className="btn-save active mx-2 " onClick={() => this.saveIdol(1)}>
                        Save
                    </Button>
                    <Button type="submit" className="btn-addmore active mx-2" onClick={() => this.saveIdol(2)}>
                        Save & Add more
                    </Button>
                </>
            )
        }
    }

    openAddIdol = async () => {
        await this.setState({ isIgSelect: false, igSelect: '', igUser: [], igname: '', selectButton: false })
        await this.modalTrue();
        this.getIdolList();
        // this.props.loading(true)
        // this.props.openModal(true);
    }

    getSaleSteam = async () => {
        let response;
        try {
            response = await API.getSaleSteam()
        } catch (error) {
            response = error.response
        }
        if (response && response.status != 500) {
            let saleSteamArray = [];
            if (response.status == 200) {
                response.data.forEach(element => {
                    saleSteamArray.push({ value: element.id, label: <div><img src={element.image_url} className="img-change-sale" />{element.firstname}</div> });
                });
                this.setState({
                    saleSteamList: saleSteamArray
                });
            } else {
                console.log(response.data.message)
            }
        }
    }

    selectSaleSteam = (optionSelected) => {
        this.setState({
            saleSteamId: optionSelected.value,
            saleSteamSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
        });
    }


    changeSale = async (types) => {
        let type = types
        const { remark, saleSteamId, id_idol, tab_index, status, selectedRowKeys } = this.state
        // console.log('status', status.value);
        // let id = this.props.addidolId.id;
        let response;
        const params = JSON.stringify({
            "salesId": saleSteamId,
            "remark": remark,
        });

        if (type === 'multi') {
            if (selectedRowKeys.length > 0) {
                selectedRowKeys.forEach(async (item, index) => {
                    try {
                        response = await API.addSale(item, params)
                    } catch (error) {
                        response = error.response
                    }
                    if (response && response.status != 500) {
                        if (response.status == 200) {
                            this.onHide();
                            this.getStat(this.state.statusCheck);
                            await this.renderActive(tab_index)
                        } else {
                            MySwal.fire({
                                // customClass: 'swal-wide',
                                icon: "error",
                                title: "Error",
                                text: response.data.message
                            })
                            console.log(response.data.message)
                        }
                    }
                })
            }
        } else if (type === 'single') {
            try {
                response = await API.addSale(id_idol, params)
            } catch (error) {
                response = error.response
            }
            if (response && response.status != 500) {
                if (response.status == 200) {
                    this.onHide();
                    await this.getStat(status.value);
                    await this.renderActive(tab_index)
                } else {
                    MySwal.fire({
                        // customClass: 'swal-wide',
                        icon: "error",
                        title: "Error",
                        text: response.data.message
                    })
                    console.log(response.data.message)
                }
            }
        }
        // return; 
    }

    render() {
        const { modalSelectStatusAssign, modalSelectStatus, role, modalSelectStatusWaiting, igUser, isIgSelect, platform, addidolList, igSelect, selectButton, idolId, productType, productTxt } = this.state
        return (
            <React.Fragment>
                <Container className="width-full">
                    <Row className="ml-3 pt-3 flex-row">
                        {this.renderTab()}
                    </Row>
                    <Card className="border-0">
                        <Card.Header className="p-0 border-bottom-0">
                            <section className="pt-3 w-100 bg-gray">
                                <Row className="m-0">
                                    <Col className="d-flex justify-content-end-table m-0 p-0">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-action mr-3 font-12 font-weight-bold ">
                                                Action
                                            </Dropdown.Toggle>

                                            {role == 'ADMIN' || role == 'SUPERVISOR' ?
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="">Approve</Dropdown.Item>
                                                    <Dropdown.Item href="" onClick={() => (this.ChangeSaleModal('multi'), this.setState({ close: '' }))}>Change Sale</Dropdown.Item>
                                                    <Dropdown.Item href="">Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                                :
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="">Approve</Dropdown.Item>
                                                    <Dropdown.Item href="">Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            }
                                        </Dropdown>
                                        <Button className="btn btn-quick mr-3  font-12 font-weight-bold" onClick={() => this.modalTrue()}><MdControlPoint className="mr-1 icon-quick" />Quick Add</Button>
                                        {/* <Button className="btn btn-quick mr-3  font-12 font-weight-bold" onClick={this.openAddIdol}><MdControlPoint className="mr-1 icon-quick" />Quick Add</Button> */}
                                        {/* <a style={{ cursor: "pointer" }} className="pointer-link"> 
                                            <MdMoreVert className="mr-3 icon-more" />
                                        </a> */}
                                    </Col>
                                </Row>
                            </section>
                        </Card.Header>
                        {this.renderTableData()}
                    </Card>

                    {/* <ModalSale show={this.props.salemodal} /> */}

                    <Modal
                        show={this.state.modalChangeSale}
                        onHide={this.onHide}
                        size="md"
                        // id="login-form"
                        // aria-labelledby="contained-modal-title-vcenter-l"
                        centered
                    >
                        <div className="bg-modal">
                            <Row className="position m-3">
                                <h5 className="text-bold">Change sales</h5>
                                <Col md={12} className="p-0 mt-3">
                                    {
                                        this.state.type === 'single' ?
                                            <>
                                                <div className="txt-style">Do you want to change <b className="font-blue">{idolId.sales_name ? idolId.sales_name : 'sales'}</b> to:</div>
                                            </>
                                            :
                                            <>
                                                <div className="txt-style">Do you want to change <b className="font-blue">sales</b> to:</div>
                                            </>
                                    }
                                </Col>
                            </Row>
                            <Row className="ml-3 mr-3 mb-3">
                                <Col md={12} className="p-0">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Sales</Form.Label>
                                            <Select
                                                placeholder=""
                                                id="saleSteam"
                                                name="saleSteam"
                                                value={this.state.saleSteamSelectValue}
                                                options={this.state.saleSteamList}
                                                onChange={(value) => {
                                                    this.selectSaleSteam(value)
                                                    this.setState({ selectButton: true })
                                                }
                                                }
                                                isSearchable={true}
                                                isDisabled={false}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Enter remark</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={this.state.remark}
                                                onChange={e => {
                                                    let remarks = e.target.value;
                                                    this.setState({ remark: remarks })
                                                }} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>

                            <Row className="m-0">
                                <Col className="pb-3 text-end">
                                    {/* <a href="/user/dashboard" style={{ cursor: "pointer" }}> */}
                                    <Button className="btn-cancel" onClick={() => this.onHide('cancelMulti')}>
                                        CANCEL
                                    </Button>
                                    {this.state.selectButton ?
                                        <>
                                            {/* <Button className="btn-addmore active mx-2" onClick={() => this.changeSale()} >
                                                SAVE CHANGES
                                            </Button> */}
                                            {this.state.type === 'single' ?
                                                <Button className="btn-addmore active mx-2" onClick={() => this.changeSale(this.state.type)} >
                                                    SAVE CHANGES
                                                </Button>
                                                :
                                                <Button className="btn btn-dark active mx-2" onClick={() => this.changeSale(this.state.type)} >
                                                    SAVE CHANGES
                                                </Button>
                                            }
                                        </>
                                        :
                                        <>
                                            <Button className="btn-addmore mx-2 cursor-auto ">
                                                SAVE CHANGES
                                </Button>
                                        </>
                                    }
                                    {/* </a> */}
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                    <Modal
                        show={modalSelectStatusAssign}
                        onHide={this.onHide}
                        size="md"
                        // id="login-form"
                        // aria-labelledby="contained-modal-title-vcenter-l"
                        centered
                    >
                        <div className="bg-modal">
                            <Row className="position m-3">
                                <h5 className="text-bold">Status</h5>
                                <Col md={12} className="p-0 mt-3">
                                    <div className="txt-style-modal">Do you wish to change <b className="font-blue">{this.state.ig_user ? this.state.ig_user : "sales "}</b> status to "รอ assign product"?</div>
                                </Col>
                            </Row>
                            <Row className="ml-3 mr-3 mb-3">
                                <Col md={12} className="p-0">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Enter remark</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={this.state.remark}
                                                onChange={e => {
                                                    let remarks = e.target.value;
                                                    this.setState({ remark: remarks })
                                                }} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>

                            <Row className="m-0">
                                <Col className="pb-3 text-end">
                                    <Button className="btn-cancel-extarct" onClick={() => this.onHide()}>
                                        Cancel
                            </Button>
                                    <Button className="btn-addmore-extract active mx-2" onClick={() => this.addSale()} >
                                        Save changes
                            </Button>
                                    {/* {this.state.selectButton ?
                                <>
                                    <Button className="btn-addmore active mx-2" onClick={() => this.addSale()} >
                                        Save changes
                                </Button>
                                </>
                                :
                                <>
                                    <Button className="btn-addmore mx-2 cursor-auto ">
                                        Save changes
                                </Button>
                                </>
                            } */}
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                    <Modal
                        show={modalSelectStatus}
                        onHide={this.onHide}
                        size="md"
                        // id="login-form"
                        // aria-labelledby="contained-modal-title-vcenter-l"
                        centered
                    >
                        <div className="bg-modal">
                            <Row className="position m-3">
                                <h5 className="text-bold">Status</h5>
                                <Col md={12} className="p-0 mt-3">
                                    {/* {console.log('tab', this.state.workflowsCode)} */}
                                    <div className="txt-style-modal">Do you wish to change <b className="font-blue">{this.state.ig_user ? this.state.ig_user : "sales "}</b> status to "กำลังแกะข้อมูล"?</div>
                                </Col>
                            </Row>
                            <Row className="ml-3 mr-3 mb-3">
                                <Col md={12} className="p-0">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Enter remark</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={this.state.remark}
                                                onChange={e => {
                                                    let remarks = e.target.value;
                                                    this.setState({ remark: remarks })
                                                }} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>

                            <Row className="m-0">
                                <Col className="pb-3 text-end">
                                    <Button className="btn-cancel-extarct" onClick={() => this.onHide()}>
                                        Cancel
                            </Button>
                                    <Button className="btn-addmore-extract active mx-2" onClick={() => this.addSale()} >
                                        Save changes
                            </Button>
                                    {/* {this.state.selectButton ?
                                <>
                                    <Button className="btn-addmore active mx-2" onClick={() => this.addSale()} >
                                        Save changes
                                </Button>
                                </>
                                :
                                <>
                                    <Button className="btn-addmore mx-2 cursor-auto ">
                                        Save changes
                                </Button>
                                </>
                            } */}
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                    <Modal
                        show={modalSelectStatusWaiting}
                        onHide={this.onHide}
                        size="md"
                        // id="login-form"
                        // aria-labelledby="contained-modal-title-vcenter-l"
                        centered
                    >
                        <div className="bg-modal">
                            <Row className="position m-3">
                                <h5 className="text-bold">Status</h5>
                                <Col md={12} className="p-0 mt-3">
                                    {/* {console.log('tab', this.state.workflowsCode)} */}
                                    <div className="txt-style-modal">Do you wish to change <b className="font-blue">{this.state.ig_user ? this.state.ig_user : "sales "}</b> status to "รอแกะข้อมูล"?</div>
                                </Col>
                            </Row>
                            <Row className="ml-3 mr-3 mb-3">
                                <Col md={12} className="p-0">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Enter remark</Form.Label>
                                            <Form.Control as="textarea" rows={3}
                                                value={this.state.remark}
                                                onChange={e => {
                                                    let remarks = e.target.value;
                                                    this.setState({ remark: remarks })
                                                }} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>

                            <Row className="m-0">
                                <Col className="pb-3 text-end">
                                    <Button className="btn-cancel-extarct" onClick={() => this.onHide()}>
                                        Cancel
                            </Button>
                                    <Button className="btn-addmore-extract active mx-2" onClick={() => this.addSale()} >
                                        Save changes
                            </Button>
                                    {/* {this.state.selectButton ?
                                <>
                                    <Button className="btn-addmore active mx-2" onClick={() => this.addSale()} >
                                        Save changes
                                </Button>
                                </>
                                :
                                <>
                                    <Button className="btn-addmore mx-2 cursor-auto ">
                                        Save changes
                                </Button>
                                </>
                            } */}
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                    <Modal
                        show={this.state.modalQuickAdd}
                        onHide={this.onHide}
                        size="md"
                        // id="login-form"
                        // aria-labelledby="contained-modal-title-vcenter-l"
                        centered
                    >
                        <div className="bg-modal">
                            <Modal.Header className="modal-header-regis pb-1 ">
                                <Modal.Title id="register-modal" className="ml-2 color-black font-12 font-weight-bold">Add Social Platform</Modal.Title>
                            </Modal.Header>
                            <Row className="position m-0">
                                <Col className="pb-3 text-center">
                                    {/* <img src={process.env.NEXT_PUBLIC_HOST_URL + "/image/check.png"} className="img-fluid" alt="logo-registerPopup" /> */}
                                </Col>
                            </Row>
                            <Row className="ml-4 m-0">
                                <div className="justify-content-center mr-2" >
                                    <Form.Check
                                        inline
                                        type="radio"
                                        checked={platform === 'ig'}
                                        // label="Instagram"
                                        className="m-0"
                                        name="platforms"
                                        id="platform"
                                        value="ig"
                                        onChange={() => { this.setState({ platform: 'ig' }) }}
                                    />
                                    <img
                                        width="35px"
                                        height="25px"
                                        className="pr-2"
                                        src={window.location.origin + "/image/instagram.png"}
                                    />
                                    <label className="mr-2 font-14">Instagram</label>
                                </div>
                                <div className="justify-content-center">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        // label="Facebook"
                                        className="m-0"
                                        name="platforms"
                                        id="platform"
                                        value="fb"
                                        onChange={() => { this.setState({ platform: 'fb' }) }}
                                    />
                                    <img
                                        width="35px"
                                        height="25px"
                                        className="pr-2"
                                        src={window.location.origin + "/image/facebook.png"}
                                    />
                                    <label className="mr-2 font-14">Facebook</label>
                                </div>
                            </Row>
                            {platform === 'ig' ? (
                                <Row className="m-0">
                                    <Col className="p-2 margin-text-input">
                                        <label className="color-gray font-12">
                                            Instagram Profile
                                        </label>
                                        <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                                            Username
                                        </Form.Label>
                                        <div className="mb-2 position-modal">
                                            <span className="position-igmodal">@</span>
                                            <Form.Control type="text" placeholder="Instagram Name" className="pading-ig"
                                                value={this.state.igname}
                                                onChange={(e) => {
                                                    // this.getIdolIns(e.target.value)
                                                    let name = e.target.value;
                                                    this.setState({ igname: name })

                                                    if (name) {
                                                        this.setState({ isIgSelect: true })
                                                    } else {
                                                        this.setState({ isIgSelect: false })
                                                    }
                                                    setTimeout(() => { this.getIdolIns() }, 1000)
                                                }}
                                            // onKeyPress={(e) => {
                                            //   if (e.charCode == 13) {
                                            //     this.getIdolIns()
                                            //     this.setState({ isIgSelect: false })
                                            //   }
                                            // }} 
                                            />

                                        </div>
                                        {/* {role === 'SUPERVISOR' || role === 'ADMIN' ? <>
                                            <Form.Group>
                                                <Form.Label className="color-gray font-12">Product</Form.Label>
                                                <FormControl type="text" placeholder="Select product" value={productTxt}
                                                    onChange={async (e) => {
                                                        let value = e.target.value;
                                                        await this.setState({ productTxt: value, allProduct: true })
                                                        // this.checkButton()
                                                    }}
                                                    onKeyPress={async (e) => {
                                                        if (e.charCode === 13 && e.target.value.trim() != "") {
                                                            let newArray = await [...productType]
                                                            if (newArray.length === 0) {
                                                                await newArray.push(productTxt)
                                                                this.setState({ productType: newArray, productTxt: '', allProduct: false, selectProduct: true })
                                                            } else {
                                                                let has = false
                                                                newArray.map((item) => {
                                                                    if (item == productTxt) {
                                                                        has = true
                                                                        this.setState({ productType: newArray, productTxt: '', allProduct: false, selectProduct: true })
                                                                    }
                                                                })
                                                                if (!has) {
                                                                    await newArray.push(productTxt)
                                                                    this.setState({ productType: newArray, productTxt: '', allProduct: false, selectProduct: true })
                                                                }
                                                            }
                                                        }
                                                    }}
                                                /> */}
                                        {/* {this.state.allProduct === true && productTxt !== '' ? <div className="h-list">
                                                    {console.log(this.state.productTxt)}
                                                    <Row className="p-0 m-0">
                                                        <Col className="p-0 m-0">
                                                            <ListGroup className="list-g">
                                                                {
                                                                    infAttractivepoint.filter(infAttractivepoint => infAttractivepoint.includes(productTxt)).map((item, index) => {
                                                                        return (
                                                                            <ListGroup.Item key={index} className="list-g"
                                                                                onClick={async () => {
                                                                                    if (productType.includes(item)) {
                                                                                        this.setState({ productTxt: '', allProduct: false, selectProduct: true })
                                                                                    } else {
                                                                                        let newProduct = await JSON.parse(JSON.stringify(productType));
                                                                                        await newProduct.push(item)
                                                                                        this.setState({ productType: newProduct, productTxt: '', allProduct: false, selectProduct: true })
                                                                                    }
                                                                                }}>
                                                                                {item}
                                                                            </ListGroup.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </ListGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                    : ''}
                                                {this.state.selectProduct === true || productTxt !== null ? <div className="d-flex align-items-center mt-1 flex-wrap">
                                                    {productType !== '' &&
                                                        productType.map((item, i) =>
                                                            <Badge pill variant="light" className="mr-1 p-2 border" key={i}>
                                                                {item}
                                                                <MdClear onClick={async () => {
                                                                    let newProduct = [...productType]
                                                                    await newProduct.splice(i, 1)
                                                                    await this.setState({ productType: newProduct })
                                                                }} />
                                                            </Badge>
                                                        )
                                                    }
                                                </div> : ''}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label className="color-gray font-12">Sales</Form.Label>
                                                <Select
                                                    placeholder="Select sales"
                                                    id="saleSteam"
                                                    name="saleSteam"
                                                    value={this.state.saleSteamSelectValue}
                                                    options={this.state.saleSteamList}
                                                    onChange={(value) => {
                                                        this.selectSaleSteam(value)
                                                        // this.setState({ selectButton: true })
                                                    }
                                                    }
                                                    isSearchable={true}
                                                    isDisabled={false}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label className="color-gray font-12">Enter remark</Form.Label>
                                                <Form.Control as="textarea" rows={3}
                                                    value={this.state.remark}
                                                    onChange={e => {
                                                        let remarks = e.target.value;
                                                        this.setState({ remark: remarks })
                                                    }} />
                                            </Form.Group></> : <></>} */}
                                        {
                                            isIgSelect ?
                                                <div className="h-ig">
                                                    <Row className="p-0 m-0">
                                                        <Col className="p-0 m-0">
                                                            <ListGroup className="list-g">
                                                                {
                                                                    addidolList.map((item, index) => {
                                                                        return (
                                                                            <ListGroup.Item key={index} className="list-g"
                                                                                onClick={() => {
                                                                                    this.setState({ igSelect: item, isIgSelect: false, selectButton: true, igname: item.username })
                                                                                    this.getIgUser(item.pk)
                                                                                }}>
                                                                                <img className="px-1 img-profile" src={item.profile_pic_url} />{item.username}
                                                                            </ListGroup.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </ListGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : ''
                                        }
                                        {igSelect !== '' &&

                                            this.checkStatusCode()
                                        }
                                    </Col>
                                </Row>
                            ) : (
                                    <Row className="m-0">
                                        <Col className="p-2 margin-text-input">
                                            <label className="color-gray">
                                                Facebook Profile
                                            </label>
                                            <Form.Control placeholder="Facebook Name" />
                                        </Col>
                                    </Row>
                                )}
                            <Row className="m-0">
                                <Col className="pb-3 text-end mt-3">
                                    <Button className="btn-cancel" onClick={() => this.onHide()}>
                                        Cancel
                                    </Button>
                                    {selectButton ?
                                        this.checkBtnSave()
                                        :
                                        <>
                                            <Button className="btn-save mx-2 cursor-auto">
                                                Save
                                            </Button>
                                            <Button className="btn-addmore mx-2 cursor-auto">
                                                Save & Add more
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                </Container>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    // console.log('state', state)
    return { login: state.login };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loading: value => dispatch(loading(value)),
        openModal: value => dispatch(addModal(value)),
        addLogin: value => dispatch(addLogin(value)),
        checkLogin: value => dispatch(checkLogin(value)),
        opensaleModal: value => dispatch(modalSale(value)),
        addidolId: value => dispatch(addidolId(value)),
        workflowsCode: value => dispatch(workflowsCode(value)),
        ModalExtract: (value) => dispatch(modalExtract(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoProduct);