import React from "react";
import {
  Modal,
  Row,
  Col,
  Card,
  Button,
  CardDeck,
  Navbar,
  Nav,
  Tab,
  NavLink,
  Badge,
  ProgressBar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkAddIdol, loading, checkToken } from "../redux/actions";
import Rating from "react-rating";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { MdClose, MdClear } from "react-icons/md";
import { BiImage, BiBookAlt } from "react-icons/bi";
import { FaFileAlt, FaImage, FaFileVideo } from "react-icons/fa";
import SelectForm from "./SelectForm";
import api from "../utils/apis";
import helper from "../utils/helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import axios from "axios";
const API = api.getAPI();
const MySwal = withReactContent(Swal);

const TypeFiles =
  "image/*,.ppt,.pptx,file_extension,audio/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,text/plain,media_type,application/pdf,application/vnd.ms-excel,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/aac,application/x-abiword,application/x-freearc,video/x-msvideo,application/vnd.amazon.ebook,application/octet-stream,image/bmp,application/x-bzip,application/x-bzip2,application/x-csh,text/css,text/csv,application/vnd.ms-fontobject,application/epub+zip,application/gzip,text/html,image/vnd.microsoft.icon,text/calendar,application/java-archive,audio/mpeg,video/mpeg,audio/ogg,video/ogg,application/vnd.rar,audio/wav,audio/webm,video/webm,video/mp2t,image/gif,text/javascript,application/json,application/ld+json,audio/midi,audio/x-midi,application/vnd.apple.installer+xml,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/ogg,audio/opus,font/otf,application/x-httpd-php,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,application/x-sh,image/svg+xml,application/x-shockwave-flash,application/x-tar,font/ttf,application/vnd.visio,font/woff,font/woff2,application/xhtml+xml,application/xml,text/xml,application/vnd.mozilla.xul+xml,application/zip,video/3gpp,audio/3gpp,video/3gpp2,audio/3gpp2,application/x-7z-compressed,application/x-rar-compressed,application/x-zip-compressed,multipart/x-zip";

const doc = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "text/plain",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/x-sh",
  "application/rtf",
  "application/vnd.ms-powerpoint",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "text/calendar",
  "text/html",
  "application/vnd.ms-fontobject",
  "application/epub+zip",
  "application/gzip",
  "application/msword",
  "text/css",
  "text/csv",
  "application/vnd.amazon.ebook",
  "application/octet-stream",
  "text/javascript",
  "application/json",
  "application/ld+json",
  "application/vnd.apple.installer+xml",
  "application/ogg",
  "font/otf",
  "application/x-httpd-php",
  "application/vnd.rar",
  "application/x-shockwave-flash",
  "application/x-tar",
  "font/ttf",
  "application/vnd.visio",
  "font/woff",
  "font/woff2",
  "application/xhtml+xml",
  "application/vnd.ms-excel",
  "application/xml",
  "text/xml",
  "application/vnd.mozilla.xul+xml",
  "application/zip",
  "application/x-7z-compressed",
  "application/x-rar-compressed",
  "application/x-zip-compressed",
  "multipart/x-zip",
];
const image = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/tiff",
  "image/svg+xml",
  "image/bmp",
  "image/vnd.microsoft.icon",
  "crt",
  "pem",
];
const video = [
  "video/mp4",
  "audio/webm",
  "video/webm",
  "audio/wav",
  "video/ogg",
  "audio/ogg",
  "video/mpeg",
  "audio/mpeg",
  "audio/midi",
  "audio/x-midi",
  "video/x-msvideo",
  "audio/aac",
  "audio/opus",
  "video/mp2t",
  "video/3gpp",
  "audio/3gpp",
  "video/3gpp2",
  "audio/3gpp2",
];

class KnowledgeDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabProfile: "general",
      tabRemark: "remark",
      product_code: "",

      idolListTab: [],
      available_tags: [],
      documents: [],
      products_list: [],
      tags: [],

      availableTags: [],
      uploadPercentage: [],
      dropFile: [],
      activeTab: "",

      documentsMaster: [],
      activeTags: [],
      allTags: [],
    };
  }

  async componentDidMount() {
    const { idolTabName, type, product } = this.props;
    // console.log("product", product)
    if (
      product === "ALL_PRODUCT" ||
      product === "NO_PRODUCT" ||
      product === "PHOTO_NO_PRODUCT"
    ) {
      if (product === "PHOTO_GENERAL") {
        this.setState({ activeTab: "PHOTO_GENERAL" });
        await this.getDocument(product, type);
      } else {
        this.setState({ activeTab: "GENERAL" });
        await this.getDocument(product, type);
      }
    } else {
      this.setState({ activeTab: product });
      await this.getDocument(product, type);
    }
    // await this.getDocument(idolTabName)
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.props.product != prevProps.product &&
    //   this.props.product
    // ) {
    //   if (
    //     this.props.product === "ALL_PRODUCT" ||
    //     this.props.product === "NO_PRODUCT"
    //   ) {
        
    //     this.setState({ activeTab: "GENERAL" });
    //     await this.getDocument(this.props.product, this.props.type);
    //   } else {
        
    //     this.setState({ activeTab: this.props.product });
    //     await this.getDocument(this.props.product, this.props.type);
    //   }
    // }

    if (this.props.product != prevProps.product && this.props.product) {
      if (
        this.props.product === "ALL_PRODUCT" ||
        this.props.product === "PHOTO_NO_PRODUCT"
      ) {
        
        this.setState({ activeTab: "PHOTO_GENERAL" });
        await this.getDocument(this.props.product, this.props.type);
      } else {
        
        this.setState({ activeTab: this.props.product });
        await this.getDocument(this.props.product, this.props.type);
      }
    }
    // if (this.props.activeTab != prevProps.activeTab) {
    //   this.setState({ activeTab: this.props.activeTab });
    //   this.getDocument(this.props.activeTab, this.props.type);
    // }
  }


  getDocument = async (product_code, type) => {
    const { history } = this.props;
    this.props.loading(true);
    let response;
    try {
      response = await API.getDocument(product_code, type);
      // console.log("get doc", response);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      this.props.loading(false);
      if (response.status == 200) {
        let data = response.data;

        if (this.props.type === "photographers") {
          if (response.data.products_list) {
            response.data.products_list.splice(0, 0, {
              product_code: "PHOTO_GENERAL",
              product_name: "General",
            });
          }
        } else {
          if (response.data.products_list) {
            response.data.products_list.splice(0, 0, {
              product_code: "GENERAL",
              product_name: "General",
            });
          }
        }

        let tagsArray = [];
        // if(product_code !== ''){
        data.documents.map((item) => {
          if (item.tags !== null) {
            item.tags.map((tag) => {
              if (tagsArray.length > 0) {
                let noHasTag = false;
                tagsArray.map((tagA) => {
                  if (tagA.id == tag.id) {
                    noHasTag = true;
                  }
                });
                if (!noHasTag) {
                  tagsArray.push(tag);
                }
              } else {
                tagsArray.push(tag);
              }
            });
          }
        });
        // }else{
        //     tagsArray = data.available_tags
        // }
        // console.log('tagsArray', tagsArray);
        tagsArray.sort((a, b) => {
          return a.id - b.id;
        });
        this.setState({
          available_tags: data.available_tags,
          documents: data.documents,
          products_list: data.products_list,
          documentsMaster: data.documents,
          allTags: tagsArray,
        });
      } else {
        this.props.loading(false);
        console.log(response.data.message);
      }
    }
    this.props.getProductsList({products_list: response.data.products_list, activeTab: this.state.activeTab});
    this.props.loading(false);
  };

  addTags = async (id) => {
    const { activeTab, availableTags } = this.state;
    const { history } = this.props;
    let response;
    const data = {
      tags: availableTags,
      product_code: activeTab,
    };
    // console.log('datadddddddddddddd', data);
    // return;
    try {
      response = await API.addTags(id, data);
      //   console.log("res==", response);
    } catch (error) {
      response = error.response;
      const token = { response, history };
      this.props.checkToken(token);
    }
    if (response && response.status != 500) {
      if (response.status == 200) {
        this.getDocument(this.state.activeTab, this.props.type);
      } else {
        console.log(response.data.message);
      }
    }
  };

  onHide = (value) => {
    this.props.onHide(value);
  };

  uploadFiles = async (files) => {
    // console.log('file', files);
    const { history } = this.props;
    const { activeTab } = this.state;
    let params;
    files.map(async (item, index) => {
      // console.log('item', item);
      params = JSON.stringify({
        content_type: item.type,
        filename: item.name,
        file_size: item.size,
        product_code: activeTab,
      });

      // let data = await readFile(item);
      let response;
      try {
        response = await API.uploadFilesDocument(params, item).then(
          async (response) => {
            // console.log('res====>', response);
            await this.renderAxios(response, item, index);
          }
        );
      } catch (error) {
        response = error.response;
        const token = { response, history };
        this.props.checkToken(token);
      }
    });
  };

  renderAxios = async (response, data, index) => {
    let newUploadPercentage = [...this.state.uploadPercentage];
    const option = await {
      onUploadProgress: (progressEvent) => {
        let { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          newUploadPercentage[index].progress = percent;
          this.setState({ uploadPercentage: newUploadPercentage });
        }
      },
      headers: {
        "Content-Type": response.data.content_type,
      },
    };
    axios.put(response.data.upload_link, data, option).then((res) => {
      // console.log('res-----', res);
      newUploadPercentage[index].progress = 100;
      this.setState({ uploadPercentage: newUploadPercentage });

      let countcomplet = 0;
      newUploadPercentage.map((item) => {
        if (item.progress === 100) {
          countcomplet = countcomplet + 1;
        }
      });
      if (countcomplet === newUploadPercentage.length) {
        this.setState({ uploadPercentage: [], dropFile: "" });
        this.getDocument(this.state.activeTab, this.props.type);
      }
    });
  };

  handleDrop = (file) => {
    let fileArray = [];
    let percentArry = [];
    if (typeof file != "undefined") {
      file.map((item) => {
        let size = parseFloat(item.size / (1024 * 1024)).toFixed(2);
        if (size < 128) {
          fileArray.push(item);
          percentArry.push({ progress: 0 });
        } else {
          MySwal.fire({
            text: "Please select file size less than 128 MB",
            icon: "warning",
          });
        }
      });
      if (fileArray.length > 0) {
        this.setState({ dropFile: fileArray, uploadPercentage: percentArry });
        this.uploadFiles(fileArray);
      }
    }
  };

  deleteUploadFile = async (id) => {
    // console.log('id==>',id);
    const { history } = this.props;
    let response;
    MySwal.fire({
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      text: "Are you confirm to delete the file?",
    }).then(async (result) => {
      if (result.value) {
        try {
          response = await API.deleteDocument(id);
        } catch (error) {
          response = error.response;
          const token = { response, history };
          this.props.checkToken(token);
        }
        if (response && response.status != 500) {
          if (response.status == 200) {
            this.getDocument(this.state.activeTab, this.props.type);
          }
        }
      }
    });
  };

  iconImage = (type, pathImage) => {
    // console.log('icon', type);
    const typeImage = type;
    let docs = false;
    let img = false;
    let vedio = false;
    doc.map((item) => {
      if (item == typeImage) {
        docs = true;
      }
    });
    if (docs) {
      return (
        <>
          <FaFileAlt style={{ width: 150, height: 200 }} />
        </>
      );
    }
    image.map((item) => {
      if (item == typeImage) {
        img = true;
      }
    });
    if (img) {
      return (
        <>
          <img src={pathImage} className="img-fluid img-wight" />
        </>
      );
    }
    video.map((item) => {
      if (item == typeImage) {
        vedio = true;
      }
    });
    if (vedio) {
      return (
        <>
          <FaFileVideo style={{ width: 150, height: 200 }} />
        </>
      );
    }
  };

  renderTagsActive = (tag) => {
    const { activeTags } = this.state;
    // console.log('activeTab', activeTags);
    let hasTag = false;
    for (var i = 0; i < activeTags.length; i++) {
      if (activeTags[i] === tag) {
        hasTag = true;
      }
    }
    if (hasTag) {
      return "dark"; //" active ";
    } else {
      return "light";
    }
  };

  addActiveTags(tag) {
    const { activeTags } = this.state;

    let hasTag = false;
    let indexTag = null;
    let newTagList = [...activeTags];
    for (var i = 0; i < newTagList.length; i++) {
      if (newTagList[i].id === tag.id) {
        hasTag = true;
        indexTag = i;
      }
    }

    if (hasTag) {
      newTagList.splice(indexTag, 1);
    } else {
      newTagList.push(tag);
    }
    // console.log("tag", tag, newTagList)
    this.setState({ activeTags: newTagList });
  }

  filterTags = () => {
    const { activeTags, documentsMaster } = this.state;
    let newDocument = documentsMaster.filter((doc) => {
      let hasTag = false;
      activeTags.map((aTag) => {
        // console.log(doc)
        doc.tags !== null &&
          doc.tags.map((dTag) => {
            if (aTag.id == dTag.id) {
              hasTag = true;
            }
          });
      });
      if (hasTag) {
        return true;
      }
    });
    if (newDocument.length > 0) {
      this.setState({ documents: newDocument });
    } else {
      //   this.getDocument(this.state.activeTab);
      this.setState({ documents: documentsMaster });
    }
  };

  renderTags = () => {
    const { allTags, activeTags } = this.state;
    return allTags.map((tags, index) => {
      return (
        //     <React.Fragment key={index}>
        //     <NavLink activeclassname="active" className="pl-0">
        //       <Nav.Link
        //         as="div"
        //         className={`nav-linkDoc font-12 txt-modal ${this.renderTagsActive(
        //           tags
        //         )}`}
        //         onClick={async () => {
        //           await this.addActiveTags(tags);
        //           this.filterTags();
        //         }}
        //       >
        //         <div>{tags.name}</div>
        //       </Nav.Link>
        //     </NavLink>
        //   </React.Fragment>
        <Badge
          pill
          variant={`${this.renderTagsActive(tags)}`}
          key={index}
          className={`border border-dark font-16 tag-text text-wrap text-left cursor mr-2 mb-2`}
          onClick={async () => {
            await this.addActiveTags(tags);
            this.filterTags();
          }}
        >
          <div>{tags.name}</div>
        </Badge>
      );
    });
  };

  render() {
    const { show, data, type, idolTabName } = this.props;
    const {
      tabProfile,
      tabRemark,
      uploadPercentage,
      available_tags,
      availableTags,
      tags,
    } = this.state;
    // console.log(this.props, "propsss");

    return (
      <>
        <Dropzone
          onDrop={(acceptedFiles) => this.handleDrop(acceptedFiles)}
          // onDrop={this.handleDrop(acceptedFiles)}
          multiple
          accept={TypeFiles}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className="uploadBox">
                <input {...getInputProps()} />
                <h4 className="text-center">Drop files to upload</h4>
                <div className="text-secondary text-center">or</div>
                <div className="d-flex justify-content-center mt-2">
                  <Button variant="outline-dark">Select Files</Button>
                </div>
                <div className="text-secondary text-center mt-2">
                  Maximum upload file size is 128 MB.
                </div>
              </div>
            </section>
          )}
        </Dropzone>

        <div className="d-flex flex-wrap my-2">{this.renderTags()}</div>

        <Row className="mt-3 mb-3 justify-content-start">
          {this.state.dropFile.length > 0 ? (
            this.state.dropFile.map((item, index) => {
              // console.log('dorp', item);
              let progress =
                uploadPercentage.length > 0
                  ? uploadPercentage[index].progress
                  : 0;
              return (
                <Col sm={6} key={index}>
                  <Card className="card-border">
                    {this.iconImage(item.type)}
                    <Card.Body>
                      <Card.Text>
                        {item.name}
                        {item.size}
                      </Card.Text>
                      <ProgressBar
                        now={progress}
                        active={true}
                        label={`${progress}%`}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          ) : (
            <></>
          )}

          {this.state.documents.length > 0 ? (
            this.state.documents.map((item, index) => {
              // console.log('item===docu', item);
              const str = item.full_path_filename;
              const res = str.split("/");
              const arrlen = res.length;
              const FileName = res[arrlen - 1];
              // let listUpload = item.data
              return (
                <Col sm={6} key={index} className="">
                  <Card className="card-border">
                    <div className="card-hight">
                      {this.iconImage(item.content_type, item.file_display_url)}
                    </div>
                    <Card.Body className="pl-0 pr-0">
                      <Card.Text className="d-flex align-items-center">
                        <a
                          href={item.file_download_url}
                          download
                          className="text-load font-14"
                        >
                          {FileName}
                        </a>
                        <MdClear
                          className="ml-2 text-danger"
                          onClick={() => this.deleteUploadFile(item.id)}
                        />
                      </Card.Text>
                      <SelectForm
                        options={
                          available_tags
                            ? available_tags.map((item) => ({
                                label: item.name,
                                value: item.name,
                              }))
                            : []
                        }
                        placeholder="tags"
                        value={
                          item.tags
                            ? item.tags.map((item) => ({
                                label: item.name,
                                value: item.name,
                              }))
                            : []
                        }
                        insertable
                        tagPosition="inner"
                        htmlParse={true}
                        labelMask={(value) => {
                          // console.log('va---', value)
                          // const str = value.label
                          // const res = str.split(":");
                          // return <><span>{res[0]}</span><span>{res[1]}</span></>
                          return <span>{value.label}</span>;
                        }}
                        getData={async (data) => {
                          // console.log("getdata", data);
                          await this.setState({
                            availableTags: data.map((item) => item.value),
                          });
                          await this.addTags(item.id);
                        }}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          ) : (
            <></>
          )}
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    addidolId: state.addidolId,
    idolTabsList: state.idolTabsList,
    idolTabName: state.idolTabName,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loading: (value) => dispatch(loading(value)),
    checkAddIdol: (value) => dispatch(checkAddIdol(value)),
    checkToken: (value) => dispatch(checkToken(value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KnowledgeDocuments));
