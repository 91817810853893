import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loading, addLogin } from "../redux/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../utils/apis";
import JwtDecode from "jwt-decode";
import InputMask from "react-input-mask";

const API = api.getAPI();
const MySwal = withReactContent(Swal);

class ResetPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        userName: "",
        currentPassword: "",
        newPassword: "",
        cfPassword: "",
      },
      invalidMsg: {
        userName: "",
        currentPassword: "",
        newPassword: "",
        cfPassword: "",
      },
      validated: {
        userName: false,
        currentPassword: false,
        newPassword: false,
        cfPassword: false,
      },
    };
  }

  validateForm = () => {
    const { form, invalidMsg, validated } = this.state;
    let is_validated = true;

    if (form.userName.trim() == "") {
      is_validated = false;
      validated.userName = true;
      invalidMsg.userName = "Require Username."
      this.setState({ validated, invalidMsg });
    }
    if (form.userName.length > 0 && form.userName.length < 7) {
      is_validated = false;
      validated.userName = true;
      invalidMsg.userName = "Username must be 7 digits number."
      this.setState({ validated, invalidMsg });
    }
    
    if (form.currentPassword.trim() == "") {
        is_validated = false;
        validated.currentPassword = true;
        invalidMsg.currentPassword = "Require current password."
        this.setState({ validated, invalidMsg });
    }

    if (form.newPassword.trim() == "") {
        is_validated = false;
        validated.newPassword = true;
        invalidMsg.newPassword = "Require new password."
        this.setState({ validated, invalidMsg });
    }

    if (form.cfPassword.trim() == "") {
        is_validated = false;
        validated.cfPassword = true;
        invalidMsg.cfPassword = "Require confirm new password."
        this.setState({ validated, invalidMsg });
    }

    if (form.cfPassword.trim() !== form.newPassword.trim()) {
        is_validated = false;
        validated.cfPassword = true;
        invalidMsg.cfPassword = "Confirm new password must be as the same as new password."
        this.setState({ validated, invalidMsg });
    }

    return is_validated;
  };

  changePassword = async () => {
    if (this.validateForm()) {
        // console.log("validated")
        const { form } = this.state;
        const { history } = this.props;
        let response;
        const formData = {
            username: form.userName,
            password: form.currentPassword,
            new_password: form.newPassword,
            confirm_password: form.cfPassword,
        }
        try {
          response = await API.changeUserPassword(formData);
        } catch (error) {
          response = error.response;
        }
        this.props.loading(false);
        console.log("change password", response);
        if (response && response.status != 500) {
          if (response.status == 200) {
              MySwal.fire({
                icon: "success",
                title: "Your password had changed.",
                }).then((result) => {
                    this.setState({
                        form: {
                            userName: "",
                            currentPassword: "",
                            newPassword: "",
                            cfPassword: "",
                        },
                    })
                    history.push("/login")
                });
          } else {
            console.log(response.data);
            if (response.data.statusCode==401) {
              const {validated, invalidMsg } = this.state;
              validated.currentPassword = true;
              invalidMsg.currentPassword = "Current password is not correct.";
              this.setState({ validated, invalidMsg });
            }
            if (response.data.statusCode==400) {
                const {validated, invalidMsg } = this.state;
                response.data.message.filter(item=>{
                  if (item.includes("password too weak")) {
                    validated.currentPassword = true;
                    invalidMsg.currentPassword = "Current password is not correct.";
                  }
                  if (item.includes("new_password too weak")) {
                    validated.newPassword = true;
                    invalidMsg.newPassword = item.replace("new_password", "New password");
                  }
                });
                this.setState({ validated, invalidMsg });
              }
          }
        }
    }
  }

  render() {
    const { form, invalidMsg, validated } = this.state;
    return (
      <>
        <Container className="con-h paddin-log">
          <Row className="justify-content-center mt-3">
            <Col md={5}>
              <Form className="">
                <Form.Group>
                  <Form.Label>Username (7 digits number)</Form.Label>
                  {/* <Form.Control type="number" placeholder="Username (7 digits number)" maxLength={7} onChange={e => this.setState({ username: e.target.value })} /> */}
                  <InputMask
                    mask="9999999"
                    maskChar=""
                    className={`form-control ${
                      validated.userName ? "is-invalid" : ""
                    }`}
                    placeholder="Username (7 digits number)"
                    value={form.userName}
                    onChange={(e) => {
                      form.userName = e.target.value;
                      validated.userName = false;
                      this.setState({ form, validated });
                    }}
                  />
                  {validated.userName && (
                    <div className="invalid-feedback text-left">
                      {invalidMsg.userName}
                    </div>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={5}>
              <Form className="">
                <Form.Group>
                  <Form.Label>Current password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    className={`${
                        validated.currentPassword ? "is-invalid" : ""
                      }`}
                      value={form.currentPassword}
                      onChange={(e) => {
                        form.currentPassword = e.target.value;
                        validated.currentPassword = false;
                        this.setState({
                          form,
                          validated
                        });
                      }}
                    />
                    {validated.currentPassword && (
                      <div className="invalid-feedback text-left">
                        {invalidMsg.currentPassword}
                      </div>
                    )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={5}>
              <Form className="">
                <Form.Group>
                  <Form.Label>New password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    className={`${
                        validated.newPassword ? "is-invalid" : ""
                      }`}
                      value={form.newPassword}
                      onChange={(e) => {
                        form.newPassword = e.target.value;
                        validated.newPassword = false;
                        this.setState({
                          form,
                          validated
                        });
                      }}
                    />
                    {validated.newPassword && (
                      <div className="invalid-feedback text-left">
                        {invalidMsg.newPassword}
                      </div>
                    )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={5}>
              <Form className="">
                <Form.Group>
                  <Form.Label>Confirm new password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    className={`${
                        validated.cfPassword ? "is-invalid" : ""
                      }`}
                      value={form.cfPassword}
                      onChange={(e) => {
                        form.cfPassword = e.target.value;
                        validated.cfPassword = false;
                        this.setState({
                          form,
                          validated
                        });
                      }}
                    />
                    {validated.cfPassword && (
                      <div className="invalid-feedback text-left">
                        {invalidMsg.cfPassword}
                      </div>
                    )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={3}>
              <Button type="button" className="btn btn-dark w-100"
              onClick={()=>this.changePassword()}>
                Change password
              </Button>
            </Col>
            <Col md={2}>
              <a href="/login">
                <Button type="submit" className="btn-repass w-100">
                  Cancel
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    addLogin: (value) => dispatch(addLogin(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPass));
