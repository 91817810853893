import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import SideMenuAdmin from "../components/SideMenuAdmin";
import MasterData from "./AdminMasterData";
import AdminUsers from "./AdminUsers";
import MasterProducts from "./AdminProducts";
import {
  loading,
  checkLogin,
  getMasterData,
  setIdolTypeTabName,
  checkToken,
} from "../redux/actions";

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
      const { adminMenuCategory } = this.props;
    return (
        <SideMenuAdmin>
            {adminMenuCategory == 'masterdata' &&
                <MasterData />
            }
            {adminMenuCategory == 'users' &&
                <AdminUsers />
            }
            {adminMenuCategory == 'products-workflow' &&
                <MasterProducts />
            }
        </SideMenuAdmin>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    login: state.login,
    masterData: state.allMasterData,
    masterDataCategory: state.masterDataCategory,
    idolTypeTabName: state.idolTypeTabName,
    adminMenuCategory: state.adminMenuCategory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (value) => dispatch(loading(value)),
    checkLogin: (value) => dispatch(checkLogin(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminPanel));
